import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

//eslint-disable-next-line
const BE_ADDR = address;

export async function getUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getUser(id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users/' + id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function putUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users/' + userData.id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function getGroups() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'groups', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function putGroup(groupData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'groups/' + groupData._id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(groupData)
  });
  return await handleResponse(response);
}

export async function postGroup(groupData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'groups', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(groupData)
  });
  return await handleResponse(response);
}

import React from 'react';
// eslint-disable-next-line
import { Button, message, Modal, Row, Col, Icon, Checkbox } from 'antd';
import DossierTable, {
  scopes as dossierScopes
} from '../../components/DossierTable';

import { downloadReportZip } from '../../network/fin';
import { mrg } from '../../common/util';
import globalStyles from '../../styles/global';
import { Parser } from 'json2csv';

const { confirm } = Modal;

const styles = {
  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline
  },
  collapse: {
    position: 'relative',
    width: '100%'
  },
  pageHeaderBox: {
    color: globalStyles.colors.background.pink
  },
  pageHeaderButton: {
    width: 'auto',
    height: '46px',
    fontWeight: 100,
    fontSize: globalStyles.global.baseline * 1.5,
    marginLeft: globalStyles.global.baseline,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter
  },
  result: {
    width: '100%',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: globalStyles.global.baseline,
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline * 2,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      textAlign: 'left',
      fontSize: globalStyles.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: globalStyles.global.baseline
    },
    message: {
      marginBottom: globalStyles.global.baseline
    },
    error: {
      marginBottom: globalStyles.global.baseline,
      color: globalStyles.colors.feedback.error
    }
  },
  feedback: {
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace',
      textAlign: 'left'
    },
    all: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    }
  }
};

export default class Consistency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasErrors: true
    };
    this.confirmReset = this.confirmReset.bind(this);
    this.generateCsv = this.generateCsv.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const hasErrors =
      props.report && props.report.filter(elem => elem.has_errors).length > 0;
    return { hasErrors: hasErrors };
  }

  generateCsv() {
    const fields = [
      { label: 'Dossier', value: 'dossier', default: 'NULL' },
      { label: 'Enviar', value: 'info_to_send', default: 'NULL' },
      { label: 'Método', value: 'send_method', default: 'NULL' },
      { label: 'Língua', value: 'language', default: 'NULL' },
      { label: 'Contactos', value: 'email_contacts', default: 'NULL' },
      { label: 'Notas', value: 'notes', default: 'NULL' },
      { label: 'Resultado', value: 'send_result', default: 'NULL' }
    ];
    const opts = { fields };

    try {
      const parser = new Parser(opts);
      const csv = parser.parse(this.props.report);

      var blob = new Blob([csv], { type: 'text/csv' });

      var a = document.createElement('a');
      a.download = 'report.csv';
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  }

  confirmReset() {
    const self = this;
    confirm({
      okButtonProps: { ghost: true },
      title: (
        <span style={{ fontSize: globalStyles.global.baseline * 2 }}>
          Fechar processo de envio
        </span>
      ),
      icon: (
        <Icon
          type="warning"
          style={{
            fontSize: globalStyles.global.baseline * 2.5,
            color: globalStyles.colors.feedback.error
          }}
        />
      ),
      content: (
        <div style={styles.modal.body}>
          <span style={styles.modal.message}>
            Tem certeza de que pretende terminar o processo de envio?
          </span>
          <ul style={styles.modal.list}>
            <li style={styles.modal.error}>
              Todos os utilizadores serão redireccionados para o ecran inicial.
            </li>
            <li style={styles.modal.error}>
              Toda a informação, resultados e ficheiros do processo corrente
              serão apagados.
            </li>
            <li style={{ ...styles.modal.error, fontWeight: 700 }}>
              Esta operação é irreversível!
            </li>
          </ul>
        </div>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        if (self.props.reset) {
          self.props.reset();
        }
      }
    });
  }

  render() {
    return (
      <>
        {this.props.report ? (
          <div style={styles.result}>
            <div
              style={mrg([
                styles.pageHeaderContainer,
                {
                  justifyContent: this.state.hasErrors
                    ? 'space-between'
                    : 'flex-end'
                }
              ])}
            >
              <div style={styles.pageHeaderContainer}>
                <Button
                  type="primary"
                  ghost
                  style={styles.pageHeaderButton}
                  onClick={async () => await downloadReportZip()}
                  //href={address + 'financeiro/download-zip'}
                >
                  Descarregar Anexos
                </Button>
                <Button
                  type="primary"
                  ghost
                  style={styles.pageHeaderButton}
                  onClick={this.generateCsv}
                >
                  Descarregar Relatório
                </Button>
              </div>

              <Button
                type="danger"
                ghost
                style={styles.pageHeaderButton}
                onClick={this.confirmReset}
              >
                FECHAR PROCESSO
              </Button>
            </div>

            <DossierTable
              editable={false}
              scope={dossierScopes.REPORT}
              dossiers={this.props.report}
            />
          </div>
        ) : (
          <div style={styles.feedback.all}>
            <h2 style={styles.feedback.title}>
              A compilar relatório de envios
            </h2>
            <span style={styles.feedback.info}>Aguarde</span>
          </div>
        )}
      </>
    );
  }
}

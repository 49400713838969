export const bindGroupEnums = (variableGroups, ...keyNames) => {
  for (const key in variableGroups) {
    bindEnums(variableGroups[key], keyNames);
    bindHelpers(variableGroups[key]);
  }
  return variableGroups;
};

export const bindEnums = (variableGroup, keyNames) => {
  if (!variableGroup.enums) {
    // hidden field
    Object.defineProperty(variableGroup, 'enums', {
      value: {},
      enumerable: false
    });
  }
  for (const keyName of keyNames) {
    variableGroup.enums[keyName + 's'] = () => {
      const result = [];
      for (const variable in variableGroup) {
        if (['enums', 'helpers'].includes(variable)) {
          continue;
        }
        result.push(variableGroup[variable][keyName]);
      }
      return result;
    };
  }
  return variableGroup;
};

export const bindHelpers = variableGroup => {
  if (!variableGroup.lte) {
    // hidden field
    Object.defineProperty(variableGroup, 'lte', {
      value: numericValue => {
        const result = [];
        Object.keys(variableGroup).forEach(key => {
          if (variableGroup[key].numeric <= numericValue) {
            result.push(variableGroup[key]);
          }
        });
        return result;
      },
      enumerable: false
    });
  }
  if (!variableGroup.gte) {
    // hidden field
    Object.defineProperty(variableGroup, 'gte', {
      value: numericValue => {
        const result = [];
        Object.keys(variableGroup).forEach(key => {
          if (variableGroup[key].numeric >= numericValue) {
            result.push(variableGroup[key]);
          }
        });
        return result;
      },
      enumerable: false
    });
  }

  return variableGroup;
};

import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';
const BE_ADDR = address;

export async function fetchAllCases() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'cm', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function fetchCitiusCases() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'citius', {
    //awdawdasdasdasdasdsd ////
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}

export async function fetchSingleCITIUSCase(processId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(
    BE_ADDR + 'citius/' + encodeURIComponent(processId),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  return await handleResponse(response);
}

export async function updateCitiusCase(caseId, newData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(
    BE_ADDR + 'citius/' + encodeURIComponent(caseId),
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(newData)
    }
  );
  return await handleResponse(response);
}

export async function fetchOrphanCases() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  const response = await fetch(BE_ADDR + 'citius/orphans', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateNotification(processId, ref, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR +
      'citius/' +
      encodeURIComponent(processId) +
      '/notification/' +
      encodeURIComponent(ref),
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(data)
    }
  );
  return await handleResponse(response);
}

export async function deleteNotification(processId, ref) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target =
    'citius/' + encodeURIComponent(processId) + '/' + encodeURIComponent(ref);

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

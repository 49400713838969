import React from 'react';
import { Form, Button, Input, Select, message } from 'antd';

import AppContext from '../context/AppContextBase';
import globalStyles from '../styles/global';

// eslint-disable-next-line
const { Option } = Select;

const styles = {
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  },
  form: {
    body: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart,
      padding: 0,
      margin: 0
    },
    section: {
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter
    },
    item: { padding: 0, paddingRight: globalStyles.global.baseline, margin: 0 }
  }
};

class InsertPortfolio extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = { sending: false };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          values.id = values.name;
          if (this.props.action) {
            const request = await this.props.action(values);

            if (request && request.result === 'OK') {
              //clear form:
              this.props.form.resetFields();
              if (this.props.done) {
                this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
            } else {
              message.error(<span>Erro na inserção</span>);
            }
          }
        } catch (err) {
          message.error(<span>Erro na inserção</span>);
        }
      }
      this.setState({ sending: false });
    });
  };

  handleCancel() {
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} style={styles.form.body}>
        <div style={styles.form.section}>
          <Form.Item style={styles.form.item}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Obrigatório' }]
            })(
              <Input
                disabled={this.state.sending}
                placeholder="Nome (único) da Carteira"
              />
            )}
          </Form.Item>
          <Form.Item style={{ ...styles.form.item }}>
            <Button
              loading={this.state.sending}
              type="primary"
              htmlType="submit"
            >
              Submeter
            </Button>
          </Form.Item>
          <Form.Item style={{ ...styles.form.item }}>
            <Button
              onClick={async () => await this.handleCancel()}
              loading={this.state.sending}
              type="secondary"
            >
              Cancelar
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}
const InsertFolderForm = Form.create({ name: 'insert_folder' })(
  InsertPortfolio
);
export default InsertFolderForm;

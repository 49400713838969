import React from 'react';
import CreditForm from './CreditForm';
import { Button, Card } from 'antd';
import g from '../../styles/global';

const styles = {
  card: {
    overflow: 'scroll'
  },
  cardColumn: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  }
};

export default class CreditHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { showForm: false };
  }
  render() {
    return (
      <Card
        bodyStyle={{
          ...styles.card,
          ...styles.cardColumn,
          padding: g.global.baseline
        }}
      >
        <span style={{ fontSize: g.global.baseline * 2 }}>
          Credit / Money In
        </span>
        {this.props.allowCreation && (
          <>
            {this.state.showForm ? (
              <div style={{ width: '100%' }}>
                <CreditForm
                  owner={this.props.clientId ?? null}
                  source={{
                    id: this.props.currentUserId,
                    name: this.props.currentUserName
                  }}
                  cancel={() => this.setState({ showForm: false })}
                  onSaveSuccess={async entry => {
                    this.setState({ loading: true });
                    await this.props.onSaveSuccess(entry);
                    this.setState({ showForm: false });
                  }}
                ></CreditForm>
              </div>
            ) : (
              <Button
                type="primary"
                style={{
                  width: '100%'
                }}
                onClick={() => {
                  this.setState({ showForm: true });
                }}
              >
                Create new Credit
              </Button>
            )}
          </>
        )}
      </Card>
    );
  }
}

import moment from 'moment';

import mapFields from '../util/mapFields';

import { save } from './operations';
import { tryParseDate } from './../../../common/util';
import EditableField from '../../../components/util/EditableField';
const { Types } = EditableField;

const base = ({
  isDescendant = false,
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  hideInvalidFields = false,
  editable = true
}) => {
  const fieldMap = [
    {
      title: 'Passp.',
      key: 'id',
      span: 6,
      editable: editable
    },

    {
      title: 'Issued by',
      key: 'excel.passports.0.issued_by',
      span: 18,
      editable: editable
    },
    {
      title: 'On',
      key: 'excel.passports.0.issued_on',
      type: Types.date,
      options: { showTime: false },
      span: 12,
      editable: editable
    },
    {
      title: 'Valid until',
      key: 'excel.passports.0.valid_until',
      type: Types.date,
      options: { showTime: false },
      span: 12,
      editable: editable
    },
    { placeholder: 24 },
    {
      title: 'Birth Date',
      key: 'excel.birth_date',
      type: Types.date,
      options: { showTime: false },
      span: 8,
      editable: editable
    },
    {
      title: 'Birth place',
      key: 'excel.birth_place',
      span: 8,
      editable: editable
    },
    {
      title: 'Age',
      key: 'excel.birth_date',
      transform: {
        input: date => {
          const m = tryParseDate(date || 'invalid');
          return m.isValid() ? moment().diff(m, 'years') : '-';
        }
      },
      editable: false,
      span: 8
    },
    isDescendant
      ? null
      : {
          title: 'Investment Type',
          key: 'excel.investment_type',
          type: Types.select,
          options: [
            { key: ' ', value: ' ' },
            { key: '1M Shares', value: '1M Shares' },
            // { key: '1M Deposit', value: '1M Deposit' }, // removed by CG request
            { key: '1.5M Deposit', value: '1.5M Deposit' },
            { key: '500K Real Estate', value: '500K Real Estate' },
            { key: '350K Reabilitation', value: '350K Reabilitation' },
            // { key: '350K Funds', value: '350K Funds' }, // removed by CG request
            { key: '500K Funds', value: '500K Funds' },
            { key: '350K Company & Jobs', value: '350K Company & Jobs' },
            { key: '350K Research', value: '350K Research' },
            { key: '250K Donation', value: '250K Donation' },
            { key: '10 Jobs', value: '10 Jobs' },
            { key: 'Green Visa', value: 'Green Visa' },
            { key: '20% Reduction', value: '20% Reduction' },
            { key: 'Nationality', value: 'Nationality' },
            { key: 'VISA D7', value: 'VISA D7' },
            { key: 'Nomad Visa', value: 'Nomad Visa' },
            { key: 'VISA D2', value: 'VISA D2' },
            { key: 'Golden Visa Company', value: 'Golden Visa Company' },
            { key: 'Others', value: 'Others' }
          ],
          span: 12,
          editable: editable
        },
    isDescendant
      ? null
      : {
          title: 'Inv.Type Notes',
          key: 'excel.investment_type_notes',
          span: 12,
          editable: editable
        },
    { placeholder: 24 },
    {
      title: 'Foreign Addr.',
      key: 'excel.foreign_address',
      span: 24,
      editable: editable
    },
    {
      title: 'Mobile',
      key: 'excel.mobile_number',
      span: 6,
      editable: editable
    },
    {
      title: 'E-mail',
      key: 'excel.email',
      span: 6,
      editable: editable
    },
    {
      title: 'Bus. Area',
      key: 'excel.business_area',
      span: 12,
      editable: editable
    },
    {
      title: 'Civil Status',
      key: 'excel.civil_status',
      type: Types.select,
      options: [
        { key: 'Single', value: 'Single' },
        { key: 'Married', value: 'Married' },
        { key: 'Divorced', value: 'Divorced' },
        { key: 'Widow', value: 'Widow' },
        { key: 'Civil Partnership', value: 'Civil Partnership' }
      ],
      span: 6,
      editable: editable
    },
    {
      title: 'Marital Regime of Assets',
      key: 'excel.marital_regime',
      span: 18,
      editable: editable
    },
    { placeholder: 24 },
    {
      title: 'Tax Residency',
      key: 'excel.tax_residency',
      span: 24,
      editable: editable
    },
    {
      title: 'Foreign Tax Number',
      key: 'excel.foreign_tax_number',
      span: 12,
      editable: editable
    },
    {
      title: 'NIF',
      key: 'excel.nif',
      span: 8,
      editable: editable
    },
    {
      placeholder: 4
    },
    {
      title: 'Lawyer',
      key: 'excel.responsible_lawyer',
      span: 8,
      editable: editable
    },
    {
      title: 'JVRIS',
      key: 'excel.juris',
      span: 8,
      editable: editable
    },
    {
      title: 'Translator',
      key: 'excel.translator',
      span: 8,
      editable: editable
    },
    {
      title: 'How2Contact',
      key: 'excel.communication_procedures',
      span: 24,
      editable: editable
    },
    { placeholder: 24 },
    {
      title: 'Portug. Bank',
      key: 'excel.portuguese_bank.name',
      span: 8,
      editable: editable
    },
    {
      title: 'IBAN',
      key: 'excel.portuguese_bank.account_number',
      span: 8,
      editable: editable
    },
    {
      title: 'Acc. Manager',
      key: 'excel.portuguese_bank.account_manager_name',
      span: 8,
      editable: editable
    },
    {
      title: 'Email Contacts',
      key: 'excel.portuguese_bank.emails',
      span: 24,
      editable: editable
    },
    {
      title: 'Tax Representative',
      key: 'excel.tax_representative',
      span: 8,
      editable: editable
    },
    {
      title: 'Origin/Partner',
      key: 'excel.origin_partner',
      span: 8,
      editable: editable
    },
    { placeholder: 8 },
    {
      title: 'Referral Beneficiary',
      key: 'excel.referral.name',
      span: 8,
      editable: editable
    },
    {
      title: 'Referral Commission',
      key: 'excel.referral.commission',
      span: 8,
      editable: editable
    },
    {
      title: 'Relationships',
      key: 'excel.relationships',
      span: 24,
      editable: editable
    },
    { placeholder: 24 },
    {
      title: 'Entry Date',
      key: 'excel.entry_date',
      type: Types.date,
      span: 8,
      editable: editable
    },
    { placeholder: 16 },
    {
      title: 'Exit Date',
      key: 'excel.exit_date.date',
      type: Types.date,
      span: 8,
      editable: editable
    },
    { placeholder: 16 },
    {
      title: 'Reason',
      key: 'excel.exit_date.reason',
      span: 12,
      editable: editable
    },
    {
      title: 'Who informed',
      key: 'excel.exit_date.communicated_by',
      span: 12,
      editable: editable
    },
    isDescendant
      ? null
      : {
          title: 'Engag. Letter',
          key: 'excel.engagement_letter',
          type: Types.textarea,
          span: 24,
          editable: editable
        },

    { placeholder: 24 },
    { placeholder: 24 }
  ];

  return mapFields(
    data,
    fieldMap.filter(field => !!field),
    save,
    onSaveSuccess,
    onSaveError,
    style,
    hideInvalidFields
  );
};

export default base;

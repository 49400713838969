import React from 'react';
import { Form, Button, Input, Select, message } from 'antd';
import validator from 'validator';
import globalStyles from '../styles/global';

const { Option } = Select;

const styles = {
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  },
  form: {
    body: {
      width: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    section: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    item: {
      paddingRight: globalStyles.global.baseline
    }
  }
};

class InsertDossier extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { sending: false };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          //console.log('Received values of form: ', values);
          if (this.props.action) {
            const request = await this.props.action(values);

            if (request && request.result === 'OK') {
              if (this.props.done) {
                this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
              //clear form:
              this.props.form.resetFields();
            } else {
              message.error(
                <span>
                  Erro na inserção. Verifique a sua ligação à internet e tente
                  de novo.
                </span>
              );
            }
          }
        } catch (err) {
          message.error(
            <span>
              Erro na inserção. Verifique a sua ligação à internet e tente de
              novo.
            </span>
          );
        }
      }
      this.setState({ sending: false });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={styles.container}>
        <Form onSubmit={this.handleSubmit} style={styles.form.body}>
          <div style={styles.form.section}>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('dossier', {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value === '') {
                        return callback('Inválido. Formato: X.Y ou X.Y.Z');
                      }
                      if (value.match(/^[\w\d]+\.[\w\d]+(\.[\w\d]+)?$/)) {
                        return callback();
                      }
                      return callback('Inválido. Formato: X.Y');
                    }
                  }
                ]
              })(
                <Input
                  disabled={this.state.sending}
                  placeholder="Num. Dossier (X.Y ou X.Y.Z)"
                />
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('info_to_send', {
                rules: [{ required: true, message: 'Inválido' }]
              })(
                <Select
                  disabled={this.state.sending}
                  style={{ minWidth: '10rem' }}
                  placeholder="Info a enviar"
                >
                  <Option value="just_report">Relatório</Option>
                  <Option value="just_invoices">Facturas</Option>
                  <Option value="send_both">Ambos</Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item style={{ ...styles.form.item, display: 'none' }}>
              {getFieldDecorator('client', {
                initialValue: null
              })(<Input />)}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('send_method', {
                rules: [{ required: true, message: 'Inválido' }]
              })(
                <Select
                  disabled={this.state.sending}
                  style={{ minWidth: '10rem' }}
                  placeholder="Método de Envio"
                >
                  <Option value="email">Email</Option>
                  <Option value="letter">Carta</Option>
                  <Option value="both">Ambos</Option>
                  <Option value="do_not_send">Não Enviar</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item style={styles.form.item}>
              {getFieldDecorator('language', {
                rules: [{ required: true, message: 'Inválido' }]
              })(
                <Select
                  disabled={this.state.sending}
                  style={{ minWidth: '10rem' }}
                  placeholder="Língua"
                >
                  <Option value="pt-PT">Português</Option>
                  <Option value="en-US">Inglês</Option>
                </Select>
              )}
            </Form.Item>
          </div>
          <div style={styles.form.section}>
            <Form.Item style={{ ...styles.form.item, width: '45%' }}>
              {getFieldDecorator('email_contacts', {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      if (!value || value === '') {
                        return callback(
                          'Inválido. Insira uma lista de emails separados por "," ou ";", sem espaços'
                        );
                      }
                      let arr = value.split(/[,;]/);
                      if (!arr) {
                        return callback(
                          'Inválido. Insira uma lista de emails separados por "," ou ";", sem espaços'
                        );
                      }
                      let result = true;
                      arr.forEach(email => {
                        result = result && validator.isEmail(email);
                      });

                      if (result) {
                        return callback();
                      }
                      return callback(
                        'Inválido. Insira uma lista de emails separados por "," ou ";", sem espaços'
                      );
                    }
                  }
                ]
              })(
                <Input.TextArea
                  disabled={this.state.sending}
                  style={{
                    width: '100%',
                    paddingRight: globalStyles.global.baseline
                  }}
                  placeholder="Email (endereços separados por ponto-e-vírgula, sem espaços)"
                />
              )}
            </Form.Item>
            <Form.Item style={{ ...styles.form.item, width: '45%' }}>
              {getFieldDecorator('notes', {
                rules: [{ required: false }]
              })(
                <Input.TextArea
                  disabled={this.state.sending}
                  style={{ width: '100%' }}
                  placeholder="Notas"
                />
              )}
            </Form.Item>
            <Form.Item style={{ ...styles.form.item, width: '10%' }}>
              <Button
                loading={this.state.sending}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Submeter
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}
const InsertDossierForm = Form.create({ name: 'insert_dossier' })(
  InsertDossier
);
export default InsertDossierForm;

const messageTypes = ['NOTE', 'TASK'];
const messageVisibility = ['Internal', 'External'];
const auditMessageTypes = ['AUDIT'];
const auditMessageVisibility = ['Internal'];
const accountingMessageTypes = [
  'MONEY IN/CRÉDITO',
  'MONEY OUT/DÉBITO',
  'ITEM STORAGE'
];
const accountingMessageVisibility = ['Internal'];

export {
  messageTypes,
  messageVisibility,
  auditMessageTypes,
  auditMessageVisibility,
  accountingMessageTypes,
  accountingMessageVisibility
};

import React from 'react';
import moment from 'moment';
import {
  Button,
  Icon,
  Row,
  Col,
  Statistic,
  Card,
  Tooltip,
  message
} from 'antd';
import autobind from 'auto-bind/react';
import { deleteIDAccounting } from '../../network/sef';
import ConfirmDeleteModal from '../util/ConfirmDeleteModal';
import DetailedMessage from '../../components/util/DetailedMessage';
import DebitForm from './DebitForm';
import g from '../../styles/global';

const styles = {
  header: {
    width: '100%',
    marginBottom: g.global.baseline,
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter,
    fontSize: g.global.baseline,
    fontWeight: 100
  },
  fullWidthMargin: {
    width: '100%',
    marginBottom: g.global.baseline
  },
  actions: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter
  },
  action: {
    marginRight: g.global.baseline
  },
  card: {
    overflow: 'scroll'
  },
  cardColumn: {
    ...g.layout.flexVertical,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter
  }
};

export default class DebitEntry extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { editing: false, entry: props.entry };
    autobind(this);
  }

  getDeleteAction() {
    const result = (
      <ConfirmDeleteModal
        deleteLabel={<Icon type="delete"></Icon>}
        confirmLabel="Confirm Delete!"
        disabled={this.props.currentUser.id !== this.state.entry.source.id}
        onDelete={async () => {
          const response = await deleteIDAccounting(this.state.entry._id);
          if (response && response.result === 'OK') {
            message.success('Entry deleted');
            return await this.props.onDelete?.(this.state.entry);
          } else {
            DetailedMessage.error('Error deleting entry', response);
          }
        }}
      ></ConfirmDeleteModal>
    );
    return (
      <div key={'delete'} style={styles.action}>
        {this.props.currentUser.id !== this.state.entry.source.id ? (
          <Tooltip
            placement="right"
            title={'You can only delete entries you created'}
          >
            {result}
          </Tooltip>
        ) : (
          result
        )}
      </div>
    );
  }

  getEditAction() {
    const result = (
      <Button
        disabled={this.props.currentUser.id !== this.state.entry.source.id}
        onClick={async e => {
          e.stopPropagation();
          this.setState(currentState => ({ editing: !currentState.editing }));
        }}
      >
        <Icon type="edit"></Icon>
      </Button>
    );
    return (
      <div key={'edit'} style={styles.action}>
        {this.props.currentUser.id !== this.state.entry.source.id ? (
          <Tooltip
            placement="right"
            title={'You can only edit entries you created'}
          >
            {result}
          </Tooltip>
        ) : (
          result
        )}
      </div>
    );
  }

  getCardHeader() {
    return (
      <>
        <div style={styles.header}>
          <Tooltip placement="top" title="Created By">
            <span>
              <Icon
                type="user"
                style={{ marginRight: g.global.baseline / 2 }}
              ></Icon>
              <span>{this.state.entry.source.name}</span>
            </span>
          </Tooltip>
          <Row type="flex" gutter={g.global.baseline}>
            {this.getEditAction()}
            {this.getDeleteAction()}
            {this.props.actions?.map?.(action => (
              <Col>{action}</Col>
            ))}
          </Row>
        </div>
        <Row
          type="flex"
          style={styles.fullWidthMargin}
          gutter={g.global.baseline}
        >
          <Col span={12}>
            <Statistic title="Value" value={this.state.entry.value + ' €'} />
          </Col>
          <Col span={12}>
            <Statistic
              title="Date"
              value={moment(this.state.entry.date).format('YYYY-MM-DD')}
            />
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Card
        title={this.getCardHeader()}
        style={{ marginBottom: g.global.baseline }}
        bodyStyle={styles.card}
      >
        {this.state.editing ? (
          <DebitForm
            owner={this.props.clientId ?? null}
            source={{
              id: this.props.currentUserId,
              name: this.props.currentUserName
            }}
            editData={this.state.entry}
            cancel={() => this.setState({ editing: false })}
            onSaveSuccess={async entry => {
              message.success('Saved');
              this.setState({ editing: false, entry });
            }}
          ></DebitForm>
        ) : (
          <>
            <Row
              type="flex"
              style={styles.fullWidthMargin}
              gutter={g.global.baseline}
            >
              <Col span={24}>
                <Statistic title="Purpose" value={this.state.entry.purpose} />
              </Col>
            </Row>
            <Row
              type="flex"
              style={styles.fullWidthMargin}
              gutter={g.global.baseline}
            >
              <Col span={12}>
                <Statistic
                  title="Way of Paym."
                  value={this.state.entry.way_of_payment}
                />
              </Col>
            </Row>
            <Row
              type="flex"
              style={styles.fullWidthMargin}
              gutter={g.global.baseline}
            >
              <Col span={12}>
                <Statistic
                  title="Beneficiary"
                  value={this.state.entry.beneficiary}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Applicant"
                  value={this.state.entry.applicant}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
    );
  }
}

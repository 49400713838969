import React from 'react';
import { Route, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Layout, Menu, Icon } from 'antd';

import AppContext from '../../context/AppContextBase';

import Users from './users';
import Groups from './groups';

import globalStyles from '../../styles/global';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const styles = {
  container: {
    height: 'calc( 100vh - ' + globalStyles.global.baseline * 6 + 'px)',
    width: '100%'
  },
  sider: {
    borderRight: '1px solid #e0e0e0',
    backgroundColor: 'transparent'
  },
  menu: {
    header: {
      backgroundColor: globalStyles.colors.background.queenBlue,
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.typography.size.base * 2,
      fontWeight: 100,
      color: '#ffffff',
      paddingLeft: globalStyles.global.baseline * 2,
      height: globalStyles.global.baseline * 4
    },
    body: {
      fontSize: globalStyles.typography.size.base * 1.5,
      fontWeight: 300,
      height: `calc(100% - ${globalStyles.global.baseline * 8}px)`,
      border: 'unset',
      width: '100%'
    },
    subMenu: {
      body: {
        marginBottom: 0,
        marginTop: 0,
        fontSize: globalStyles.typography.size.base * 1.5,
        fontWeight: 100
      },
      item: {
        marginBottom: 0,
        marginTop: 0,
        fontSize: globalStyles.typography.size.base * 1.5,
        fontWeight: 100
      }
    },
    item: {
      marginBottom: 0,
      marginTop: 0,
      fontSize: globalStyles.typography.size.base * 1.5,
      fontWeight: 100
    },
    link: {},
    icon: {
      fontSize: globalStyles.typography.size.base * 1.5,
      fontWeight: 100,
      margin: '0 ' + globalStyles.global.baseline + 'px'
    }
  }
};
class SettingsMenu extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.state = { currentMenuItem: [], currentSubMenu: [] };
    this.updateMenuState = this.updateMenuState.bind(this);
    this.updateMenuState(true);
  }

  updateMenuState(isConstructor) {
    let match = this.props.location.pathname.match(/\/settings\/(\w+)$/);
    if (match && match.length > 1) {
      if (isConstructor) {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.currentMenuItem = [match[1]];
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.currentSubMenu = [];
      } else {
        this.setState({ currentMenuItem: [match[1]], currentSubMenu: [] });
      }
    } else {
      match = this.props.location.pathname.match(/\/settings\/(\w+)\/(\w+)/);
      if (match && match.length > 2) {
        if (isConstructor) {
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.currentMenuItem = [match[1] + '/' + match[2]];
          // eslint-disable-next-line react/no-direct-mutation-state
          this.state.currentSubMenu = [match[1]];
        } else {
          this.setState({
            currentMenuItem: [[match[1] + '/' + match[2]]],
            currentSubMenu: [match[1]]
          });
        }
      }
    }
  }

  render() {
    return (
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={this.state.currentMenuItem}
        defaultOpenKeys={this.state.currentSubMenu}
        mode="inline"
        style={styles.menu.body}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={globalStyles.global.menuIcon} type="more" />
          </div>
        }
      >
        {this.context.checkPermission('menu:settings:users') && (
          <SubMenu
            title={
              <span style={styles.menu.subMenu.item}>
                <Icon style={styles.menu.icon} type="user" />
                Users
              </span>
            }
            key="users"
            style={styles.menu.subMenu.body}
          >
            <Menu.Item style={styles.menu.item} key={'users/list'}>
              <NavLink style={styles.menu.link} to={'/settings/users/list'}>
                <Icon style={styles.menu.icon} type="team" />
                List
              </NavLink>
            </Menu.Item>
            <Menu.Item style={styles.menu.item} key={'users/create'}>
              <NavLink style={styles.menu.link} to={'/settings/users/create'}>
                <Icon style={styles.menu.icon} type="user-add" />
                Create
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
        {this.context.checkPermission('menu:settings:groups') && (
          <SubMenu
            title={
              <span style={styles.menu.subMenu.item}>
                <Icon style={styles.menu.icon} type="cluster" />
                Partners
              </span>
            }
            key="groups"
            style={styles.menu.subMenu.body}
          >
            <Menu.Item style={styles.menu.item} key={'groups/list'}>
              <NavLink style={styles.menu.link} to={'/settings/groups/list'}>
                <Icon style={styles.menu.icon} type="team" />
                List
              </NavLink>
            </Menu.Item>
            <Menu.Item style={styles.menu.item} key={'groups/create'}>
              <NavLink style={styles.menu.link} to={'/settings/groups/create'}>
                <Icon style={styles.menu.icon} type="user-add" />
                Create
              </NavLink>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    );
  }
}

class Settings extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CG - Settings</title>
        </Helmet>
        <Layout style={styles.container}>
          <Sider style={styles.sider}>
            <div style={styles.menu.header}>
              <NavLink style={{ color: '#fff' }} to="/settings">
                Settings
              </NavLink>
            </div>
            <SettingsMenu location={this.props.location} />
          </Sider>
          <Content
            style={{
              height: '100%',
              ...globalStyles.layout.flexVertical,
              ...globalStyles.layout.flexCenter
            }}
          >
            <Route
              exact
              path={'/settings'}
              render={() => (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    ...globalStyles.layout.flexVertical,
                    ...globalStyles.layout.flexCenter
                  }}
                >
                  <Icon
                    style={{
                      fontSize: 580,
                      color: '#EAEDF4'
                    }}
                    type="setting"
                  />
                </div>
              )}
            />
            {this.context.checkPermission('menu:settings:users') && (
              <Route path={'/settings/users'} component={Users} />
            )}
            {this.context.checkPermission('menu:settings:groups') && (
              <Route path={'/settings/groups'} component={Groups} />
            )}
          </Content>
        </Layout>
      </>
    );
  }
}

export default Settings;

import React from 'react';
// eslint-disable-next-line
import { Button, message, Modal, Row, Col, Icon } from 'antd';
import Upload from '../../components/Upload';
import DossierTable, {
  scopes as dossierScopes
} from '../../components/DossierTable';

import { uploadMissingFiles } from '../../network/fin';

import { mrg } from '../../common/util';
import globalStyles from '../../styles/global';

const { confirm } = Modal;

const styles = {
  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline
  },
  collapse: {
    position: 'relative',
    width: '100%'
  },
  pageHeaderBox: {
    color: globalStyles.colors.background.pink
  },
  pageHeaderButton: {
    width: 'auto',
    height: '46px',
    fontWeight: 100,
    fontSize: globalStyles.global.baseline * 1.5,
    marginLeft: globalStyles.global.baseline
  },
  result: {
    width: '100%',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  modal: {
    body: {
      height: '60vh',
      maxHeight: '90vh',
      paddingTop: globalStyles.global.baseline * 4,
      paddingBottom: globalStyles.global.baseline * 3,
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter
    }
  },
  feedback: {
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace',
      textAlign: 'left'
    },
    all: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    }
  }
};

export default class Consistency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      showUploadModal: false,
      dossiersChanged: false,
      hasErrors: true
    };

    this.doUpload = this.doUpload.bind(this);
    this.uploadFinished = this.uploadFinished.bind(this);
    this.markChanged = this.markChanged.bind(this);
    this.recheck = this.recheck.bind(this);
    this.send = this.send.bind(this);
    this.confirmReset = this.confirmReset.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const hasErrors =
      props.consistency &&
      props.consistency.filter(elem => elem.has_errors).length > 0;
    return { hasErrors: hasErrors };
  }

  async doUpload(files) {
    this.setState({ uploading: true });
    this.markChanged();
    uploadMissingFiles(files);
  }

  uploadFinished() {
    this.setState({ uploading: false });
    this.markChanged();
  }

  markChanged() {
    this.setState({ dossiersChanged: true });
  }

  recheck() {
    if (this.props.recheck) {
      this.props.recheck();
      this.setState({ dossiersChanged: false });
    }
  }

  async send() {
    this.setState({ uploading: true });
    if (this.props.onSend) {
      await this.props.onSend();
    }
    this.setState({ uploading: false });
  }

  confirmReset() {
    const self = this;
    confirm({
      okButtonProps: { ghost: true },
      title: (
        <span style={{ fontSize: globalStyles.global.baseline * 2 }}>
          Fechar processo de envio
        </span>
      ),
      icon: (
        <Icon
          type="warning"
          style={{
            fontSize: globalStyles.global.baseline * 2.5,
            color: globalStyles.colors.feedback.error
          }}
        />
      ),
      content: (
        <div style={mrg([styles.modal.body, { height: 'auto' }])}>
          <span style={styles.modal.message}>
            Tem certeza de que pretende terminar o processo de envio?
          </span>
          <ul style={styles.modal.list}>
            <li style={styles.modal.error}>
              Todos os utilizadores serão redireccionados para o ecran inicial.
            </li>
            <li style={styles.modal.error}>
              Toda a informação, resultados e ficheiros do processo corrente
              serão apagados.
            </li>
            <li style={{ ...styles.modal.error, fontWeight: 700 }}>
              Esta operação é irreversível!
            </li>
          </ul>
        </div>
      ),
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        if (self.props.reset) {
          self.props.reset();
        }
      }
    });
  }

  render() {
    return (
      <>
        {this.props.consistency ? (
          <div style={styles.result}>
            <div
              style={mrg([
                styles.pageHeaderContainer,
                {
                  justifyContent: this.state.hasErrors
                    ? 'space-between'
                    : 'flex-end'
                }
              ])}
            >
              {(this.state.hasErrors || this.state.dossiersChanged) && (
                <div style={styles.pageHeaderContainer}>
                  <Modal
                    width="70vw"
                    bodyStyle={styles.modal.body}
                    visible={this.state.showUploadModal}
                    closeable
                    onCancel={() => {
                      this.setState({ showUploadModal: false });
                    }}
                    footer={null}
                  >
                    <Upload
                      redo={true}
                      multiple={true}
                      uploadApi={this.doUpload}
                      done={this.uploadFinished}
                    />
                  </Modal>
                  <Button
                    type="primary"
                    ghost
                    style={styles.pageHeaderButton}
                    onClick={() => {
                      this.setState({ showUploadModal: true });
                    }}
                  >
                    Carregar ficheiros
                  </Button>
                  <Button
                    type="primary"
                    ghost={!this.state.dossiersChanged}
                    disabled={!this.state.dossiersChanged}
                    style={styles.pageHeaderButton}
                    onClick={this.recheck}
                  >
                    Verificar
                  </Button>
                </div>
              )}
              <Button
                type="danger"
                ghost
                style={styles.pageHeaderButton}
                onClick={this.confirmReset}
              >
                REINICIAR PROCESSO
              </Button>
              {!this.state.dossiersChanged && (
                <Button
                  type={this.state.hasErrors ? 'danger' : 'primary'}
                  ghost
                  style={styles.pageHeaderButton}
                  onClick={this.send}
                >
                  Enviar
                </Button>
              )}
            </div>

            <DossierTable
              onEdit={this.markChanged}
              scope={dossierScopes.CONSISTENCY}
              dossiers={this.props.consistency}
            />
          </div>
        ) : (
          <div style={styles.feedback.all}>
            <h2 style={styles.feedback.title}>A verificar Consistência</h2>
            <span style={styles.feedback.info}>Aguarde</span>
          </div>
        )}
      </>
    );
  }
}

import React from 'react';
import { Row, Col, Button } from 'antd';
import op from 'object-path';

import EditableField from '../../../components/util/EditableField';
import renderers from '../processes/columns/renderers';
import ArrayFieldTable from '../tables/ArrayFieldTable';
import g from '../../../styles/global';
import { mrg } from '../../../common/util';

const isValueValid = (data, field) => {
  const value = getValue(data, field);
  if (typeof value === 'undefined') {
    return false;
  }
  return true;
};

const inputTransform = (field, value) => {
  return field.transform &&
    field.transform.input &&
    typeof value !== 'undefined'
    ? field.transform.input(value)
    : value;
};

const outputTransform = (field, value) => {
  return field.transform &&
    field.transform.output &&
    typeof value !== 'undefined'
    ? field.transform.output(value)
    : value;
};

const getValue = (data, field) => {
  const value = op.get(data, field.key);
  return inputTransform(field, value);
};

const getExtras = (data, field) => {
  const value = op.get(data, field.extras);
  return value;
};

const styles = {
  title: {
    fontWeight: 300,
    paddingRight: g.global.baseline * 2,
    marginBottom: 0
  },
  tableContainer: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    flex: 1,
    width: '100%',
    height: 'auto',
    overflowY: 'visible',
    paddingRight: g.global.baseline
  },
  titleContainer: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    width: '100%',
    paddingBottom: g.global.baseline
  }
};

const mapFields = (
  data,
  fieldArray,
  onSave = () => {},
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  hideInvalidFields
) => {
  return (
    <div
      style={mrg([
        {
          width: '100%'
        },
        style
      ])}
    >
      <Row
        style={{
          width: '100%'
        }}
      >
        {fieldArray.map((field, index) => {
          const shouldHideField = hideInvalidFields && field.hide !== false;
          const isPlaceholder = field.placeholder;
          const isHr = field.hr;
          const isAddToCalendar =
            field.type === EditableField.Types.addToCalendar;

          if (
            shouldHideField &&
            (isPlaceholder ||
              isHr ||
              isAddToCalendar ||
              !isValueValid(data, field))
          ) {
            return null;
          }
          return field.type === EditableField.Types.array ? (
            <Col key={index} span={field.span}>
              <div style={styles.tableContainer}>
                <div style={styles.titleContainer}>
                  <h2 style={styles.title}>{field.title}</h2>
                  {!field.editable === false && (
                    <Button
                      shape="circle"
                      onClick={async () => {
                        const currentData = op.get(data, field.key);
                        const newIndex = currentData.length;

                        const response = await onSave(
                          field.key + '.' + newIndex,
                          currentData,
                          field.template
                        );

                        if (response && response.result === 'OK') {
                          return await onSaveSuccess({
                            key: field.key,
                            value: currentData
                          });
                        } else {
                          return onSaveError(response);
                        }
                      }}
                      type="primary"
                      icon="plus"
                    ></Button>
                  )}
                </div>
                <ArrayFieldTable
                  style={{
                    height: 'auto',
                    paddingBottom: g.global.baseline * 2
                  }}
                  rowHeight={field.rowHeight}
                  autoSizerProps={{ disableHeight: true }}
                  noDataStyle={{ height: 'auto' }}
                  onSave={async ({ value, field: fieldPath, index }) => {
                    const request = await onSave(
                      `${field.key}.${index}.${fieldPath}`,
                      data,
                      value
                    );
                    return request;
                  }}
                  data={op.get(data, field.key)}
                  extras={{
                    client: getExtras(data, field),
                    editable: field.editable
                  }}
                  getColumns={field.getColumns}
                ></ArrayFieldTable>
              </div>
            </Col>
          ) : isPlaceholder ? (
            <Col
              style={{
                ...g.layout.flexVertical,
                ...g.layout.flexBetween,
                ...g.layout.alignStart,
                paddingRight: g.global.baseline
              }}
              key={index}
              span={field.placeholder}
            >
              <div
                style={{
                  width: '100%',
                  height: '32px',
                  marginBottom: g.global.baseline
                }}
              ></div>
            </Col>
          ) : isHr ? (
            <Col
              style={{
                ...g.layout.flexVertical,
                ...g.layout.flexBetween,
                ...g.layout.alignStart,
                paddingRight: g.global.baseline
              }}
              key={index}
              span={field.placeholder}
            >
              <div
                style={{
                  width: '100%',
                  height: '0px',
                  borderBottom: '1px solid #dddddd',
                  marginTop: g.global.baseline,
                  marginBottom: g.global.baseline * 2
                }}
              ></div>
            </Col>
          ) : isAddToCalendar ? (
            <Col
              style={{
                ...g.layout.flexHorizontal,
                ...g.layout.flexStart,
                ...g.layout.alignCenter,
                paddingRight: g.global.baseline,
                height: '32px'
              }}
              key={index}
              span={field.span}
            >
              {renderers.addToCalendar({
                client: getExtras(data, field),
                date: getValue(data, field)
              })}
            </Col>
          ) : (
            <EditableField
              type={field.type}
              options={field.options}
              minWidthBefore={0}
              mode={field.mode}
              span={field.span ? field.span : 8}
              key={field.key + '' + index}
              disabled={!isValueValid(data, field)}
              editable={
                isValueValid(data, field)
                  ? field.editable === false
                    ? false
                    : true
                  : false
              }
              data={data}
              title={field.title}
              value={
                isValueValid(data, field)
                  ? getValue(data, field) === ''
                    ? ' '
                    : getValue(data, field)
                  : 'N/A'
              }
              onSave={async value =>
                await onSave(field.key, data, outputTransform(field, value))
              }
              onSaveSuccess={async result =>
                await onSaveSuccess({ key: field.key, value: result })
              }
              onSaveError={async err => await onSaveError(err)}
            ></EditableField>
          );
        })}
      </Row>
    </div>
  );
};

export default mapFields;

import { useCallback, useEffect, useReducer, useRef } from 'react';
import { log } from '../common/util';

const status = {
  IDLE: 'IDLE',
  LOADED: 'LOADED',
  INIT: 'INIT',
  PENDING: 'PENDING',
  FILES_UPLOADED: 'FILES_UPLOADED',
  UPLOAD_ERROR: 'UPLOAD_ERROR'
};

const initialState = {
  files: [],
  pending: [],
  next: null,
  uploading: false,
  uploaded: {},
  status: status.IDLE
};

const logUploadedFile = (num, color = 'green') => {
  const msg = `%cUploaded ${num} files.`;
  const style = `color:${color};font-weight:bold;`;
  console.log(msg, style);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return { ...state, files: action.files, status: status.LOADED };
    case 'submit':
      log('reducing: submit');
      return {
        ...state,
        uploading: true,
        pending: state.files,
        status: status.PENDING
      };
    case 'next':
      log('reducing: next');
      return {
        ...state,
        next: action.next,
        status: status.PENDING
      };
    case 'file-uploaded':
      log('reducing: file-uploaded');
      return {
        ...state,
        next: null,
        pending: action.pending,
        uploaded: {
          ...state.uploaded,
          [action.prev.id]: action.prev.file
        }
      };
    case 'files-uploaded':
      log('reducing: fileS-uploaded');
      return {
        ...state,
        uploading: false,
        status: status.FILES_UPLOADED,
        pending: []
      };
    case 'set-upload-error':
      return {
        ...state,
        uploadError: action.error,
        status: status.UPLOAD_ERROR
      };
    default:
      return state;
  }
};

const useFileHandlers = uploadApi => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Sets the next file when it detects that state.next can be set again
  useEffect(() => {
    if (state.pending.length && state.next == null) {
      const next = state.pending[0];
      dispatch({ type: 'next', next });
    }
  }, [state.next, state.pending]);

  const countRef = useRef(0);

  // Processes the next pending thumbnail when ready
  useEffect(() => {
    if (state.pending.length && state.next) {
      const { next } = state;
      uploadApi(next.file)
        .then(() => {
          const prev = next;
          logUploadedFile(++countRef.current);
          let pending = state.pending.slice(1);
          dispatch({ type: 'file-uploaded', prev, pending });
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: 'set-upload-error', error });
        });
    }
  }, [state, uploadApi]);

  // Ends the upload process
  useEffect(() => {
    if (!state.pending.length && state.uploading) {
      dispatch({ type: 'files-uploaded' });
    }
  }, [state.pending.length, state.uploading]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (state.files.length) {
        dispatch({ type: 'submit' });
      } else {
        // dunno?
      }
    },
    [state.files.length]
  );

  const onChange = (acceptedFiles, event) => {
    console.log('acceptedFiles', acceptedFiles, event);

    if (acceptedFiles.length) {
      const arrFiles = Array.from(acceptedFiles);
      const files = arrFiles.map((file, index) => {
        const src = window.URL.createObjectURL(file);
        return { file, id: index, src };
      });
      dispatch({ type: 'load', files });
    }
  };

  return {
    ...state,
    onSubmit,
    onChange
  };
};
export default useFileHandlers;

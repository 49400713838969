import React from 'react';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';

export default {
  addToCalendar: ({ client, date }) => {
    let formatedStartTime = moment(date, 'DD-MM-YYYY HH:mm');
    let endTime = moment(formatedStartTime).add(30, 'minutes');

    let event = {
      //title: rowData['Assunto'] + ': ' + rowData['Local'],
      title: client.Nome + ' card validy end',
      // description:
      //   'Meeting at SEF services - ' +
      //   rowData['Local'] +
      //   '. \nSubject: ' +
      //   rowData['Assunto'],
      description: client.Nome + ' card validy end',
      startTime: formatedStartTime,
      endTime: endTime
    };
    return (
      <div style={{ zIndex: 1000000 }}>
        {client && date ? (
          <AddToCalendar
            event={event}
            buttonLabel="+ Calendar"
            listItems={[{ outlook: 'Outlook' }, { google: 'Google' }]}
          />
        ) : null}
      </div>
    );
  }
};

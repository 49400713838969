import React from 'react';
import { Col, Input, Icon, DatePicker, Select, Tooltip } from 'antd';
import moment from 'moment';
import autobind from 'auto-bind/react';

import g from '../../styles/global';
import { mrg } from '../../common/util';
import { tryParseDate } from '../../common/util';

const { Option } = Select;

const styles = {
  statistic: {
    container: {
      fontSize: g.global.baseline,
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      marginBottom: g.global.baseline,
      maxWidth: '100%',
      overflowY: 'auto'
    },
    title: {
      fontWeight: 900,
      color: '#666666',
      marginRight: g.global.baseline
    },
    value: {
      fontWeight: 300
    }
  },
  column: {
    ...g.layout.flexVertical,
    ...g.layout.flexBetween,
    ...g.layout.alignStart,
    paddingRight: g.global.baseline
  },
  addon: {
    before: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      width: '100%',
      fontWeight: 'normal',
      fontSize: '12px',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderBottom: 'unset',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      lineHeight: 1.5,
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      ...g.layout.alignCenter
    },
    after: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      fontWeight: 'normal',
      fontSize: '12px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderLeft: '0',
      lineHeight: 2.5,
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween,
      ...g.layout.alignCenter
    },
    beforeInline: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      fontWeight: 'normal',
      fontSize: '12px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderRight: 'unset',
      borderRadius: '4px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      lineHeight: 1.5,
      whiteSpace: 'nowrap'
    }
  }
};

const selectModes = {
  tags: 'tags',
  default: 'default'
};

class EditableField extends React.Component {
  static Types = {
    textarea: 'textarea',
    date: 'date',
    title: 'title',
    select: 'select',
    addToCalendar: 'addToCalendar',
    array: 'array'
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dirty: false,
      saving: false,
      value: props.value,
      options: {}
    };

    if (props.mode) {
      this.state.options.selectMode =
        props.mode === selectModes.tags
          ? selectModes.tags
          : selectModes.default;
    }
    autobind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  getElement() {
    let Element;
    let elementStyle;
    switch (this.props.type) {
      case EditableField.Types.textarea:
        Element = Input.TextArea;
        elementStyle = this.props.editable
          ? { width: '100%', overflow: 'auto' }
          : {
              maxWidth: '100%',
              overflow: 'auto',
              ...styles.statistic.container
            };
        if (!this.props.hideElementBefore) {
          elementStyle.borderTopLeftRadius = 0;
          elementStyle.borderTopRightRadius = 0;
        }
        break;

      case EditableField.Types.select:
        if (this.props.editable) {
          Element = props => {
            const opts = this.props.options;
            return (
              <Select {...props}>
                {opts.map(opt => (
                  <Option key={opt.key}>
                    <Tooltip title={opt.value} placement="top">
                      {opt.value}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            );
          };

          elementStyle = {
            borderRadius: 0,
            width: '100%'
          };
        } else {
          // if not editable default to regular input
          Element = Input;
          elementStyle = { width: '100%', ...styles.statistic.container };
        }
        break;

      case EditableField.Types.date:
        if (this.props.editable) {
          Element = DatePicker;
          elementStyle = { width: '100%', minWidth: 'unset' };
          elementStyle.borderRadius = '0px';
        } else {
          // if not editable default to regular input
          Element = Input;
          elementStyle = { width: '100%', ...styles.statistic.container };
        }

        break;
      case EditableField.Types.title:
        Element = props => <h2 {...props}>{props.title}</h2>;
        elementStyle = { width: '100%', borderBottom: '1px solid #eaebeb' };
        break;
      default:
        Element = Input;
        elementStyle = this.props.editable
          ? { width: '100%' }
          : { maxWidth: '100%', ...styles.statistic.container };
    }

    return { Element, elementStyle };
  }

  async onChangeEvent(event) {
    this.setState({ value: event.target.value, dirty: true });
    if (this.props.onChange) {
      return await this.props.onChange(event);
    }
  }

  async onChangeValue(value) {
    this.setState({ value: value, dirty: true });
    if (this.props.onChange) {
      return await this.props.onChange(value);
    }
  }

  getInputProps() {
    let inputProps = {};

    switch (this.props.type) {
      case EditableField.Types.title:
        inputProps = { title: this.props.title };
        break;
      case EditableField.Types.textarea:
        // base props
        inputProps = {
          autoSize: this.props.autosize ? this.props.autosize : { minRows: 4 },
          value: this.state.value
        };
        // props when editable
        if (this.props.editable) {
          inputProps.value = this.state.value;
          inputProps.onChange = this.onChangeEvent;
          inputProps.onBlur = this.save;
        }
        break;
      case EditableField.Types.select:
        // base props

        // props when editable
        if (this.props.editable) {
          inputProps = {
            showSearch: true,
            mode: this.state.options.selectMode,
            placeholder: '',
            className: 'no-radius',
            value: this.state.value ?? [],
            style: { width: '100%', height: '32px', overflowX: 'scroll' },
            onBlur: this.save,
            filterOption: (input, option) => {
              if (!option?.props?.children?.props?.title) {
                console.error(
                  'EditableField ERROR: optio.props.children.props.title does not exist'
                );
              }
              return (
                option?.props?.children?.props?.title
                  ?.toLowerCase?.()
                  ?.indexOf?.(input?.toLowerCase?.()) >= 0
              );
            },
            onChange: async (value, options) => {
              // use option.key, not value, when saving value
              let valueToSave;
              if (this.state.options.selectMode === selectModes.tags) {
                // tags mode.
                if (options.length) {
                  // This mode forces multi-options, and we only want one.
                  // Solution: last value selected is set as the "true" option
                  valueToSave = options[options.length - 1].key;
                } else {
                  // otherwise, just empty the value
                  valueToSave = null;
                }
              } else {
                // default mode. renamed variables for clarity (singleOption)
                const singleOption = options;
                valueToSave = singleOption.key;
              }
              await this.onChangeValue(valueToSave);
              await this.save();
            }
          };
        } else {
          // if not editable, default to regular input
          inputProps = {
            value: this.state.value,
            addonBefore: this.props.hideElementBefore
              ? null
              : this.addonBefore()
          };
        }
        break;
      case EditableField.Types.date:
        // base props

        // props when editable
        if (this.props.editable) {
          let showTime = this.props.options
            ? this.props.options.showTime !== undefined
              ? this.props.options.showTime
              : true
            : true;

          inputProps = {
            value: tryParseDate(this.state.value, {
              nullable: true
            }),
            open: this.state.panelOpen,
            onOpenChange: async isOpen => {
              if (!isOpen) {
                this.setState({ panelOpen: false });
              } else {
                this.setState({ panelOpen: true });
              }
            },
            onChange: async value => {
              this.setState({ panelOpen: false });
              await this.onChangeValue(value);
              await this.save();
            },
            showTime: showTime,
            placeholder: '',
            className: 'no-radius'
          };
          // if we're editable, we'll manage this in our state. Otherwise, let DatePicker manage itself
          inputProps.value = tryParseDate(this.state.value, {
            nullable: true
          });

          if (!this.props.hideIcon) {
            inputProps.addonAfter = this.addonAfter();
          }
        } else {
          // if not editable, default to regular input
          inputProps = {
            value: this.state.value,
            addonBefore: this.props.hideElementBefore
              ? null
              : this.addonBefore()
          };

          inputProps.value = this.state.value
            ? moment(this.state.value).format('YYYY-MM-DD')
            : this.state.value;
        }
        break;
      default:
        // base props
        inputProps = {
          value: this.state.value,
          addonBefore: this.props.hideElementBefore ? null : this.addonBefore()
        };
        // props when editable
        if (this.props.editable) {
          inputProps.value = this.state.value;
          inputProps.onChange = this.onChangeEvent;
          inputProps.onBlur = this.save;
          inputProps.onPressEnter = this.save;
          // props when editable and hideIcon
          if (!this.props.hideIcon) {
            inputProps.addonAfter = this.addonAfter();
          }
        }
    }

    //disabled is the same for all field types:
    inputProps.disabled = this.props.editable
      ? this.state.saving || this.props.disabled
      : this.props.disabled;
    return inputProps;
  }

  save = async () => {
    if (!this.state.dirty) {
      return;
    }
    let value = this.state.value;
    try {
      value = value.trim();
      this.setState({ value });
    } catch (err) {
      //nothing, keep actual value
    }
    this.setState({ saving: true });
    let result = null;

    try {
      if (this.props.onSave) {
        result = await this.props.onSave(value);
      }
      if (result.result === 'OK' || result.ok) {
        //console.log('Editable field saving');
        if (this.props.onSaveSuccess) {
          const request = await this.props.onSaveSuccess(value);
          this.setState({ saving: false, dirty: false, saved: true });
          return request;
        }
      } else {
        this.setState({ saving: false, dirty: true, saved: false });
        throw result; //hack to be compatible with old code (result can be 404 and not exception error) -  works fine
      }
    } catch (err) {
      console.error(err);
      if (this.props.onSaveError) {
        this.setState({ saving: false, dirty: true, saved: false });
        return await this.props.onSaveError(err);
      }
    }
    this.setState({ dirty: false, saving: false, saved: true });
    //This below, was savnig every time - error or not error
    // [Simoes] definitely an error, cheers (in case you ever find this comment again)
    // if (this.props.onSaveSuccess) {
    //   this.setState({ saving: false });
    //   return await this.props.onSaveSuccess(result);
    // }
  };

  addonBefore() {
    return (
      <div
        style={{
          minWidth: this.props.useMinWidth ? '6rem' : 0,
          lineHeight: 2.5
        }}
      >
        {this.props.title}
      </div>
    );
  }

  addonAfter() {
    return (
      <div
        style={{
          lineHeight: 2.5
        }}
      >
        <Icon
          type={this.state.saving ? 'loading' : 'save'}
          style={{ color: this.state.saved ? '#5cab7d' : '#40a9ff' }}
          theme={
            (this.state.dirty || this.state.saved) && !this.state.saving
              ? 'filled'
              : 'outlined'
          }
          onClick={this.save}
        />
      </div>
    );
  }

  getAddons() {
    let elementBefore = null;
    let elementAfter = null;

    switch (this.props.type) {
      case EditableField.Types.date:
        if (this.props.editable) {
          elementBefore = (
            <div style={styles.addon.beforeInline}>{this.addonBefore()}</div>
          );
          elementAfter = this.props.editable ? (
            <div style={styles.addon.after}>{this.addonAfter()}</div>
          ) : null;
        }
        break;
      case EditableField.Types.select:
        if (this.props.editable) {
          elementBefore = (
            <div style={styles.addon.beforeInline}>{this.addonBefore()}</div>
          );
          elementAfter = this.props.editable ? (
            <div style={styles.addon.after}>{this.addonAfter()}</div>
          ) : null;
        }
        break;
      case EditableField.Types.textarea:
        elementBefore = (
          <div style={styles.addon.before}>
            {this.addonBefore()}
            {this.props.editable && !this.props.hideIcon && this.addonAfter()}
          </div>
        );
        break;
      default:
        break;
    }

    if (this.props.hideElementBefore) {
      elementBefore = null;
    }

    if (this.props.hideIcon) {
      elementAfter = null;
    }

    return { elementBefore, elementAfter };
  }

  render() {
    const { Element, elementStyle } = this.getElement();
    const { elementBefore, elementAfter } = this.getAddons();
    const inputProps = this.getInputProps();

    if (this.props.editable) {
      return (
        <Col
          style={{
            ...styles.column,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          <div
            style={mrg([
              styles.statistic.container,
              { width: '100%' },
              this.props.type === EditableField.Types.textarea
                ? g.layout.flexVertical
                : {},
              this.props.innerStyle
            ])}
          >
            {elementBefore}
            <Element style={elementStyle} {...inputProps} />
            {elementAfter}
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          style={{
            ...styles.column,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          {this.state.value !== undefined && (
            <>
              {elementBefore}
              <Element style={elementStyle} {...inputProps} />
              {elementAfter}
            </>
          )}
        </Col>
      );
    }
  }
}

export default EditableField;

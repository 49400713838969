import React from 'react';
import { Column } from 'react-virtualized';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';
import EditableField from '../../../../components/util/EditableField';
import { message } from 'antd';

import { tableStyles, translateColumnHeader } from '../../tables/common';
import g from '../../../../styles/global';

// {
//     date: { type: Date, default: null },
//     notes: { type: String, default: '' },
//     deadline: { type: Date, default: null },
//     status: { type: String, default: '' }
// }

const deadlinesColumns = (onSave, extras) => {
  const editable = extras ? !(extras.editable === false) : true;
  return [
    <Column
      width={20}
      flexGrow={2}
      dataKey="date"
      key="Date"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.date}
            options={{ showTime: false }}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'date', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={2}
      dataKey="notes"
      key="Notes"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Notes"
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'notes', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={20}
      flexGrow={2}
      dataKey="deadline"
      key="Deadline"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.date}
            options={{ showTime: false }}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'deadline', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={2}
      dataKey="status"
      key="Status"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.select}
            options={[
              { key: 'Client Informed', value: 'Client Informed' },
              { key: 'Partner Informed', value: 'Partner Informed' },
              { key: 'Extension Requested', value: 'Extension Requested' },
              { key: 'Solved by e-mail', value: 'Solved by e-mail' },
              { key: 'Solved by post', value: 'Solved by post' }
            ]}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Status"
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'status', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={1}
      dataKey="deadline"
      key="deadline"
      disableSort={true}
      headerRenderer={() => <div>Calendar</div>}
      style={tableStyles.calendarColumn}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        let formatedStartTime = moment(cellData, 'DD-MM-YYYY HH:mm');
        let endTime = moment(formatedStartTime).add(30, 'minutes');

        let event = {
          //title: rowData['Assunto'] + ': ' + rowData['Local'],
          title: extras.client.Nome + ' card valitidy end',
          // description:
          //   'Meeting at SEF services - ' +
          //   rowData['Local'] +
          //   '. \nSubject: ' +
          //   rowData['Assunto'],
          description: extras.client.Nome + ' card validy end',
          location: '',
          startTime: formatedStartTime,
          endTime: endTime
        };
        return (
          <div
            style={{
              height: 30,
              ...g.layout.flexHorizontal,
              ...g.layout.flexStart,
              ...g.layout.alignCenter
            }}
          >
            {extras.client && cellData ? (
              <AddToCalendar
                event={event}
                buttonLabel="Add to Cal."
                listItems={[{ outlook: 'Outlook' }, { google: 'Google' }]}
              />
            ) : null}
          </div>
        );
      }}
    />
  ];
};

export default deadlinesColumns;

import React from 'react';
import { Route, NavLink, Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Layout, Menu, Icon } from 'antd';

import AppContext from '../../context/AppContextBase';
import Portfolios from './Portfolios';
import Processes from './Processes';
import Orphans from './Orphans';
import SingleCase from './SingleCase';

import breakpoints from '../../styles/breakpoints';
import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const { Header, Content } = Layout;

const styles = {
  header: {
    height: globalStyles.global.baseline * 4,
    backgroundColor: globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    padding: 0
  },
  menu: {
    fontSize: globalStyles.typography.size.base * 1.5,
    height: globalStyles.global.baseline * 3,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    borderBottom: '1px solid ' + globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  subMenu: {
    backgroundColor: globalStyles.colors.background.queenBlue
  },
  menuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    minWidth: '20%'
  },
  subMenuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100
  },
  menuLink: {
    color: '#fff'
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  }
};
class CmMenu extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { collapsed: true };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <Menu
        mode="horizontal"
        style={styles.menu}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={styles.menuIcon} type="more" />
          </div>
        }
      >
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="1"
        >
          <NavLink style={styles.menuLink} to={'/cm/portfolios'}>
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="bank" />
              Carteiras
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="bank" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="2"
        >
          <NavLink style={styles.menuLink} to={'/cm/processes/portfolio/all'}>
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="unordered-list" />
              Processos
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="unordered-list" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="3"
        >
          <NavLink style={styles.menuLink} to={'/cm/notifications'}>
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="mail" />
              Notificações Orfãs
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="mail" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

const RouterMenu = withRouter(CmMenu);

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      citiusCases: [
        {
          Origem: 'N.A',
          'Data Elaboração': 'N.A',
          Acto: 'N.A',
          Tribunal: 'N.A',
          'Un Orgânica': 'N.A',
          Processo: 'N.A',
          Espécie: 'N.A',
          Referência: 'N.A',
          tags: ['nice', 'developer']
        }
      ]
    };
  }

  async componentDidMount() {
    /* nothing */
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CG - Contencioso de Massas</title>
        </Helmet>
        <Header style={styles.header}>{<RouterMenu />}</Header>
        <Content
          style={{
            width: '100%',
            height:
              'calc( 100vh - ' + globalStyles.global.baseline * 10 + 'px)',
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexCenter
          }}
        >
          <Route
            exact
            path={'/cm'}
            render={() => (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  ...globalStyles.layout.flexVertical,
                  ...globalStyles.layout.flexCenter
                }}
              >
                <h1> CM Root/Home</h1>
                <Redirect to="/cm/portfolios" />
              </div>
            )}
          />
          <Route path={'/cm/portfolios'} component={Portfolios} />
          <Route path={'/cm/notifications'} component={Orphans} />
          <Route
            exact
            path={'/cm/processes/portfolio/:portFolioId'}
            component={Processes}
          />
          <Route
            exact
            path={'/cm/processes/single/:id'}
            component={SingleCase}
          />
        </Content>
      </>
    );
  }
}

export default Home;

import React from 'react';
import { AutoSizer, Table } from 'react-virtualized';

import { mrg } from '../../../common/util';
import AppContext from '../../../context/AppContextBase';
import NoData from '../../../components/util/NoData';
import { tableStyles, rowHeight, rowStyle } from './common';

class ARIPayments extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: null
    };

    this.onSave = this.onSave.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, data: props.data };
  }

  async onSave(data) {
    const success = this.props.onSaveSuccess
      ? this.props.onSaveSuccess
      : result => {
          return result;
        };
    const error = this.props.onSaveError
      ? this.props.onSaveError
      : error => {
          console.error(error);
          throw error;
        };

    if (this.props.onSave) {
      const response = await this.props.onSave(data);
      if (response && response.result === 'OK') {
        this.setState(prevState => {
          const oldState = prevState.data;
          const item = oldState.filter(item => item._id === data._id);
          if (item && item.length === 1) {
            Object.assign(item[0], data);
            return { data: oldState };
          }
        });

        return success(response);
      } else {
        return error(response);
      }
    }
  }

  render() {
    return Array.isArray(this.state.data) ? (
      <AutoSizer {...this.props.autoSizerProps}>
        {({ width, height }) => (
          <Table
            style={{ ...tableStyles.body, ...this.props.style }}
            height={
              height
                ? height
                : this.state.data.length * (this.props.rowHeight ?? rowHeight)
            }
            rowHeight={this.props.rowHeight ?? rowHeight}
            rowStyle={({ index }) =>
              mrg([
                rowStyle({ index }),
                this.props.tableProps ? this.props.tableProps.rowStyle : {}
              ])
            }
            noRowsRenderer={NoData}
            rowGetter={({ index }) => {
              return this.state.data[index];
            }}
            rowCount={this.state.data.length}
            width={width}
            gridStyle={{ overflowY: 'visible' }}
            {...this.props.tableProps}
          >
            {this.props.getColumns(this.onSave, this.props.extras)}
          </Table>
        )}
      </AutoSizer>
    ) : (
      <NoData style={this.props.noDataStyle}></NoData>
    );
  }
}

export default ARIPayments;

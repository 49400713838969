import g from '../../../../styles/global';
import mapFields from '../../util/mapFields';
import EditableField from '../../../../components/util/EditableField';

import deadlinesColumns from '../columns/DeadlinesColumns';
import notesColumns from '../columns/standard/NotesColumns';
import contactsColumns from '../columns/standard/ContactsColumns';
import documentsReceivedColumns from '../columns/standard/DocumentsReceivedColumns';

const { Types } = EditableField;

const baseFields = ({
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  onSave,
  editable = true
}) => {
  const fieldMap = [
    {
      title: 'Custom Process Name',
      key: 'customName',
      span: 12,
      editable: editable
    },
    {
      title: 'Lawyer Responsible',
      key: 'base.lawyer_responsible',
      span: 12,
      editable: editable
    },
    {
      key: 'base.relevant_contacts',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Relevant Contacts',
      template: { name: '', contact: '' },
      getColumns: contactsColumns,
      editable: editable
    },
    {
      key: 'base.notes',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Notes',
      rowHeight: g.global.baseline * 8,
      template: { createdAt: '', owner: '', title: '', content: '' },
      getColumns: notesColumns,
      editable: editable
    },

    {
      key: 'base.deadlines',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Deadlines',
      template: { date: null, notes: '', deadline: null, status: '' },
      getColumns: deadlinesColumns,
      editable: editable
    },
    {
      key: 'base.documents_signed_received',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Documents Signed / Received',
      template: {
        title: '',
        type: '',
        date: null,
        validity: null,
        formalities: ''
      },
      getColumns: documentsReceivedColumns,
      editable: editable
    },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false }
  ];

  return mapFields(data, fieldMap, onSave, onSaveSuccess, onSaveError, style);
};

export { baseFields };

import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';

import uuidv4 from 'uuid/v4';

import { Storage, Auth } from 'aws-amplify';

const bucketName = 'rpacaiado';
Storage.configure({
  AWSS3: {
    bucket: bucketName
    // region: ''//Specify the region your bucket was created in;
  }
});

export async function uploadFile(file, target, realm, folderName) {
  const random = uuidv4();
  const fileID = random + file.name;

  const customPrefix = {
    public: folderName
    // protected: 'protected/',
    // private: 'private/'
  };

  try {
    const s3Result = await Storage.put(fileID, file, {
      contentType: file.type,
      customPrefix: customPrefix
    });
    const userInfo = await Auth.currentAuthenticatedUser();

    const response = await fetch(address + 'documents', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify({
        owner: userInfo.attributes.sub,
        owner_name:
          userInfo.attributes.given_name +
          ' ' +
          userInfo.attributes.family_name,
        name: file.name,
        s3_name: s3Result.key,
        [realm]: target._id,
        investor_name: target.Nome,
        url:
          'https://' +
          bucketName +
          '.s3.amazonaws.com/' +
          folderName +
          s3Result.key,
        description: realm + ' / ' + file.name
      })
    });
    return await handleResponse(response);
  } catch (err) {
    return {
      success: false,
      error: err
    };
  }
}

export async function downloadFile(key, folderName) {
  const customPrefix = {
    public: folderName
    // protected: 'protected/',
    // private: 'private/'
  };
  try {
    const result = await Storage.get(key, {
      customPrefix: customPrefix,
      expires: 60
    });
    window.open(result, '_blank');
  } catch (err) {
    console.error(err);
  }
}

export async function removeFile(key, folderName) {
  const customPrefix = {
    public: folderName
    // protected: 'protected/',
    // private: 'private/'
  };
  try {
    await Storage.remove(key, {
      customPrefix: customPrefix
    }).then(async result => {
      const userInfo = await Auth.currentAuthenticatedUser();

      await fetch(address + 'documents', {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({
          s3_name: key
        })
      });
    });
  } catch (err) {
    console.log('error', err);
  }
}

export async function fetchDocuments(realm, ownerId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    address + 'documents/' + realm + '/' + encodeURIComponent(ownerId),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        investor: ownerId,
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );

  return await handleResponse(response);
}

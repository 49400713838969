import React from 'react';
import { Column } from 'react-virtualized';
import { message } from 'antd';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';

import EditableField from '../../../../../components/util/EditableField';
import g from '../../../../../styles/global';
import { tableStyles, translateColumnHeader } from '../../../tables/common';

const documentsReceivedOptions = client => {
  let options = [
    { key: 'Passport', value: 'Passport' },
    { key: 'Address Proof', value: 'Address Proof' },
    {
      key: 'Tax Representation Contract x2',
      value: 'Tax Representation Contract x2'
    },
    { key: 'POA SEF & Tax', value: 'POA SEF & Tax' },
    { key: 'Tax declaration', value: 'Tax declaration' },
    { key: 'Certification of Copies x2', value: 'Certification of Copies x2' },
    {
      key: 'Certification of Translation x2',
      value: 'Certification of Translation x2'
    }
  ];

  if (!client.parentId) {
    Array.prototype.push.apply(options, [
      { key: 'Tax Number Origin', value: 'Tax Number Origin' },
      { key: 'Income / Job Proof', value: 'Income / Job Proof' },
      { key: 'Bank Forms', value: 'Bank Forms' },
      { key: 'Engagement Letter x2', value: 'Engagement Letter x2' },
      {
        key: 'SEF Declaration (Bona Fide)',
        value: 'SEF Declaration (Bona Fide)'
      },
      { key: 'General POA', value: 'General POA' },
      { key: 'Authentication Form', value: 'Authentication Form' },
      { key: 'Reservation Contract', value: 'Reservation Contract' },
      { key: 'CPCV', value: 'CPCV' },
      { key: 'Signatures Recognition', value: 'Signatures Recognition' },
      { key: 'Works Contract', value: 'Works Contract' },
      { key: 'Due Diligence (Report)', value: 'Due Diligence (Report)' },
      { key: 'Bank declaration ', value: 'Bank declaration ' },
      { key: 'Non Debt SS', value: 'Non Debt SS' },
      { key: 'Non Debt AT ', value: 'Non Debt AT ' }
    ]);
  }

  Array.prototype.push.apply(options, [
    { key: 'Health insurance', value: 'Health insurance' },
    { key: 'Health center register', value: 'Health center register' },
    { key: 'Criminal Record Origin', value: 'Criminal Record Origin' },
    { key: 'Criminal Record Residency', value: 'Criminal Record Residency' },
    { key: 'Marriage Certificate', value: 'Marriage Certificate' },
    { key: 'Birth Certificate', value: 'Birth Certificate' },
    { key: 'Economic Dependence Proof', value: 'Economic Dependence Proof' },
    { key: 'Single Certificate', value: 'Single Certificate' },
    { key: 'Study Proof', value: 'Study Proof' },
    { key: 'SEF Forms', value: 'SEF Forms' },
    { key: 'Staying Proof in Portugal', value: 'Staying Proof in Portugal' },
    { key: 'Outros', value: 'Outros' }
  ]);
  return options;
};

//  title: { type: String, default: '' },
//   type: { type: String, default: '' },
//   date: { type: Date, default: null },
//   validity: { type: Date, default: null }

const DocumentsPreparedColumns = (onSave, extras) => {
  const editable = extras ? !(extras.editable === false) : true;
  return [
    <Column
      width={80}
      flexGrow={2}
      dataKey="title"
      key="Name"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.select}
            options={documentsReceivedOptions(extras.client)}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Name"
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'title', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={2}
      dataKey="type"
      key="type"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.select}
            options={[
              { key: 'Original', value: 'Original' },
              { key: 'Copy', value: 'Copy' }
            ]}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Type"
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'type', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={20}
      flexGrow={2}
      dataKey="date"
      key="date"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.date}
            options={{ showTime: false }}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'date', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={20}
      flexGrow={2}
      dataKey="validity"
      key="validity"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.date}
            options={{ showTime: false }}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'validity', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={1}
      dataKey="validity"
      key="validity"
      disableSort={true}
      headerRenderer={() => <div>Calendar</div>}
      style={tableStyles.calendarColumn}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        let formatedStartTime = moment(cellData, 'DD-MM-YYYY HH:mm');
        let endTime = moment(formatedStartTime).add(30, 'minutes');

        let event = {
          //title: rowData['Assunto'] + ': ' + rowData['Local'],
          title: extras.client.Nome + ' card valitidy end',
          // description:
          //   'Meeting at SEF services - ' +
          //   rowData['Local'] +
          //   '. \nSubject: ' +
          //   rowData['Assunto'],
          description: extras.client.Nome + ' card validy end',
          location: '',
          startTime: formatedStartTime,
          endTime: endTime
        };
        return (
          <div
            style={{
              height: 30,
              ...g.layout.flexHorizontal,
              ...g.layout.flexStart,
              ...g.layout.alignCenter
            }}
          >
            {extras.client && cellData ? (
              <AddToCalendar
                event={event}
                buttonLabel="Add to Cal."
                listItems={[{ outlook: 'Outlook' }, { google: 'Google' }]}
              />
            ) : null}
          </div>
        );
      }}
    />,
    <Column
      width={80}
      flexGrow={2}
      dataKey="formalities"
      key="formalities"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.select}
            options={[
              { key: 'Apostile', value: 'Apostile' },
              { key: 'Translated', value: 'Translated' },
              {
                key: 'Authenticated at Embassy',
                value: 'Authenticated at Embassy'
              }
            ]}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Formalities"
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({
                value: value,
                field: 'formalities',
                index: rowIndex
              })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />
  ];
};

export default DocumentsPreparedColumns;

import React from 'react';
import { Tag, List, Card, Button, Icon, Tooltip, Modal } from 'antd';
import moment from 'moment';

import Reply from './Reply';
import MessageContext from '../../context/MessageContextBase';
import { downloadFile } from '../../network/documentUploads';
import MessageForm from './MessageForm';
import ReplyForm from './ReplyForm';
import g from '../../styles/global';

const styles = {
  listItem: {
    width: '100%',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart
  },
  message: {
    container: {
      width: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart
    },
    header: {},
    subheader: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    attachments: {
      borderTop: '1px solid #e8e8e8',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      alignItems: 'flex-start',
      width: '100%',
      paddingTop: g.global.baseline,
      marginTop: g.global.baseline
    },
    card: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      alignItems: 'flex-start'
    },
    type: {
      fontSize: g.global.baseline * 1.5,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      backgroundColor: '#e8e8e8',
      marginRight: g.global.baseline,
      paddingLeft: g.global.baseline,
      paddingRight: g.global.baseline,
      paddingTop: g.global.baseline * 0.5,
      paddingBottom: g.global.baseline * 0.5
    }
  }
};

class EditableMessage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editing: false,
      replying: false,
      saving: false
    };

    this.hideEditingForm = this.hideEditingForm.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, values: props.values };
  }

  async hideEditingForm() {
    this.setState({ editing: false });
  }

  isAssignedToMe = item => {
    return item?.assignee?._id === this.context?.userData?._id;
  };

  isOwnedByMe = item => {
    return item.owner === this.context?.userData?.id;
  };

  toggleUnread = async values => {
    if (this.context?.operations?.onUpdate) {
      this.setState({
        loadingMessage: true
      });
      try {
        const unread = !values.unread;
        const toSave = {
          _id: values._id,
          unread: unread
        };
        await this.context?.operations?.onUpdate?.(toSave);

        await this.context?.operations?.onSaveSuccess?.();
      } catch (err) {
        console.error(err);
        await this.context?.operations?.onSaveError?.();
      }
      this.setState({
        loadingMessage: false
      });
    }
  };

  renderMessage(message) {
    let result;
    try {
      result = message.split('\n').map((item, i) => {
        return <p key={i}>{item}</p>;
      });
    } catch (error) {
      return message;
    }
    return result;
  }

  render() {
    let { values } = this.state;
    if (this.state.editing) {
      return (
        <MessageForm
          updateValues={values}
          hide={this.hideEditingForm}
          processId={this.state.currentProcessId}
        />
      );
    } else {
      return (
        <List.Item style={styles.listItem} key={values.id}>
          <Card
            style={{ width: '100%' }}
            hoverable={true}
            title={
              <div
                style={{
                  ...g.layout.flexVertical,
                  ...g.layout.flexStart,
                  ...g.layout.alignStart,
                  width: '100%'
                }}
              >
                <div
                  style={{
                    paddingBottom: g.global.baseline,
                    ...g.layout.flexHorizontal,
                    ...g.layout.flexStart,
                    ...g.layout.alignStart,
                    width: '100%'
                  }}
                >
                  <div
                    style={{
                      width: '60%',
                      ...g.layout.flexHorizontal,
                      ...g.layout.flexStart,
                      ...g.layout.alignStart
                    }}
                  >
                    {this.context?.operations?.checkPermission(
                      'messages:tags'
                    ) && (
                      <Tag
                        color={
                          values.visibility.toLowerCase() === 'internal'
                            ? 'green'
                            : 'gold'
                        }
                      >
                        {values.visibility}
                      </Tag>
                    )}
                    {values.owner === this.context?.userData?.id && (
                      <Tag color={'cyan'}>Owner</Tag>
                    )}
                    {values.assignee ? (
                      <Tag color={'purple'}>
                        Assignee: {values.assignee.name}
                      </Tag>
                    ) : (
                      !this.context?.enableAssignment === false && (
                        <Tag color={'purple'}>Unassigned</Tag>
                      )
                    )}
                    <span
                      style={{
                        marginRight: g.global.baseline
                      }}
                    >
                      {values.create_date
                        ? moment(values.create_date).format('YYYY-MM-DD HH:mm')
                        : values.createdAt
                        ? moment(values.createdAt).format('YYYY-MM-DD HH:mm')
                        : ' - '}
                    </span>
                  </div>
                  <div
                    style={{
                      width: '40%',
                      ...g.layout.flexHorizontal,
                      ...g.layout.flexEnd,
                      ...g.layout.alignStart
                    }}
                  >
                    {this.context?.enableReplies && !values?.replies?.length && (
                      <Button
                        type="Primary"
                        loading={this.state.loadingMessage}
                        size="small"
                        icon={'retweet'}
                        style={{
                          marginRight: g.global.baseline
                        }}
                        onClick={() => {
                          return this.setState({ replying: true });
                        }}
                      >
                        Reply
                      </Button>
                    )}
                    {['TASK', 'AUDIT'].includes(values.type) &&
                      (!this.props.showUnread ? (
                        <Tooltip
                          placement="top"
                          title={
                            'Only message assignees can change message status'
                          }
                        >
                          <Tag style={{ height: '100%' }}>
                            <Icon
                              type={values.unread ? 'exclamation' : 'check'}
                            ></Icon>{' '}
                            {values.unread ? 'Unread' : 'Read'}
                          </Tag>
                        </Tooltip>
                      ) : (
                        <Button
                          type={values.unread ? 'danger' : 'primary'}
                          loading={this.state.loadingMessage}
                          ghost={
                            this.props.isOwner ||
                            (values.assignee &&
                              values.assignee?._id !==
                                this.context?.userData?.id)
                          }
                          size="small"
                          icon={values.unread ? 'exclamation' : 'check'}
                          style={{
                            marginRight: g.global.baseline
                          }}
                          onClick={() => {
                            console.log(values);
                            return this.toggleUnread(values);
                          }}
                        >
                          {values.unread ? 'Unread' : 'Read'}
                        </Button>
                      ))}
                    {this.props.editable && (
                      <Button
                        type="secondary"
                        size="small"
                        disabled={this.state.loadingMessage}
                        style={{
                          marginRight: g.global.baseline
                        }}
                        onClick={() => {
                          return this.setState({ editing: true });
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {this.context?.userData?.id === values.owner &&
                      this.props.deletable && (
                        <Button
                          type="secondary"
                          size="small"
                          disabled={this.state.loadingMessage}
                          style={{
                            marginRight: g.global.baseline
                          }}
                          onClick={async () => {
                            if (this.context?.operations?.removeMessage) {
                              await this.context?.operations?.removeMessage?.(
                                values
                              );
                              await this.context?.operations?.onSaveSuccess?.();
                            } else {
                              await this.context?.operations?.onSaveError?.();
                            }
                          }}
                        >
                          Delete
                        </Button>
                      )}
                  </div>
                </div>

                <div
                  style={{
                    textOverflow: 'ellipsis',
                    ...g.layout.flexHorizontal,
                    ...g.layout.flexStart,
                    ...g.layout.alignCenter,
                    width: '100%'
                  }}
                >
                  <div style={{ ...styles.message.type }}>{values.type}</div>
                  <div
                    style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    {values.title}
                  </div>
                </div>
              </div>
            }
            bordered={true}
            extra={null}
          >
            <div style={styles.message.card}>
              {this.renderMessage(values.message)}
              <div style={styles.message.attachments}>
                {values.attachments.map((attachment, index) => (
                  <div
                    key={attachment._id}
                    style={{
                      ...g.layout.flexHorizontal,
                      ...g.layout.flexStart,
                      ...g.layout.alignCenter,
                      marginBottom: g.global.baseline
                    }}
                  >
                    {this.context.operations.checkPermission(
                      'uploads:download'
                    ) ? (
                      <Button
                        //shape="circle"
                        type="link"
                        style={{
                          marginRight: g.global.baseline
                        }}
                        onClick={async () => {
                          return await downloadFile(
                            attachment.s3_name,
                            this.context.s3Folder
                          );
                        }}
                      >
                        {this.renderAttachment(index, attachment)}
                      </Button>
                    ) : (
                      this.renderAttachment(index, attachment)
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Card>
          {values.replies.map((reply, index) => (
            <Reply
              key={index}
              parent={values}
              showUnread={
                !values.assignee ||
                this.isAssignedToMe(values) ||
                this.isOwnedByMe(values)
              }
              enableReplies={index === values.replies.length - 1}
              onReply={() => this.setState({ replying: true })}
              editable={
                reply.type !== 'AUDIT' &&
                reply.owner === this.context?.userData?.id
              }
              deletable={
                reply.type !== 'AUDIT' &&
                reply.owner === this.context?.userData?.id
              }
              values={reply}
            ></Reply>
          ))}
          {this.state.replying && (
            <Modal
              title="Reply"
              visible={true}
              footer={null}
              onCancel={() => {
                return this.setState({ replying: false });
              }}
              width={'70vw'}
              bodyStyle={{ height: '60vh', overflowY: 'scroll' }}
            >
              <ReplyForm
                messageId={values._id}
                hide={() => this.setState({ replying: false })}
              />
            </Modal>
          )}
        </List.Item>
      );
    }
  }

  renderAttachment = (index, attachment) => {
    return (
      <div key={index}>
        Attachment {index + 1}{' '}
        {this.context.operations.checkPermission('uploads:selectVisibility') ? (
          <Tag
            color={attachment.visibility === 'internal' ? 'green' : 'orange'}
          >
            {attachment.visibility}
          </Tag>
        ) : (
          ' - '
        )}
        {attachment.name}
      </div>
    );
  };
}

EditableMessage.contextType = MessageContext;

export default EditableMessage;

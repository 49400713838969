import React from 'react';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { Button, PageHeader, message, Layout, Tooltip } from 'antd';

import EditableField from '../../components/util/EditableField';
import objectPath from 'object-path';
import InspectModule from 'docxtemplater/js/inspect-module';
import moment from 'moment';
import { WarningOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;

var iModule = InspectModule();

var variableDicionary = {
  passport: 'id',
  valid_until: 'excel.passports.0.valid_until',
  issued_on: 'excel.passports.0.issued_on',
  issued_by: 'excel.passports.0.issued_by',
  birth_date: 'Data Nascimento',
  country: 'Nacionalidade',
  name: 'Nome',
  'portuguese_bank.name': 'excel.portuguese_bank.name',
  'portuguese_bank.account_number': 'excel.portuguese_bank.account_number',
  'portuguese_bank.account_manager_name':
    'excel.portuguese_bank.account_manager_name',
  'portuguese_bank.emails': 'excel.portuguese_bank.emails',
  'referral.name': 'excel.referral.name',
  'referral.commission': 'excel.referral.commission',
  //surname: 'excel.surname',//from ARI
  work_language: 'excel.work_language',
  //"citizenship": "", //from ARI
  //"birth_date": "", //from ARI
  birth_place: 'excel.birth_place',
  foreign_address: 'excel.foreign_address',
  mobile_number: 'excel.mobile_number',
  civil_status: 'excel.civil_status',
  marital_regime: 'excel.marital_regime',
  tax_residency: 'excel.tax_residency',
  foreign_tax_number: 'excel.foreign_tax_number',
  responsible_lawyer: 'excel.responsible_lawyer',
  email: 'excel.Email',
  nif: 'excel.nif',
  juris: 'excel.juris',
  translator: 'excel.translator',
  communication_procedures: 'excel.communication_procedures',
  tax_representative: 'excel.tax_representative',
  origin_partner: 'excel.origin_partner',
  business_area: 'excel.business_area',
  entry_date: 'excel.entry_date',
  engagement_letter: 'excel.engagement_letter'
};

// try {
//   // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
//   doc.render();
// } catch (error) {
//   var e = {
//     message: error.message,
//     name: error.name,
//     stack: error.stack,
//     properties: error.properties
//   };
//   console.log(JSON.stringify({ error: e }));
//   // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
//   throw error;
// }

// var buf = doc.getZip().generate({ type: 'nodebuffer' });

// // buf is a nodejs buffer, you can either write it to a file or do anything else with it.
// fs.writeFileSync(path.resolve(__dirname, 'output.docx'), buf);

export default class Minute extends React.Component {
  constructor(props) {
    super(props);

    this.parseFile = this.parseFile.bind(this);
    this.saveByteArray = this.saveByteArray.bind(this);
    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.onFileLoaded_write = this.onFileLoaded_write.bind(this);
    this.state = { file: null, tagsOnFile: null };
  }

  //componentDidMount() {}

  componentDidMount() {}
  componentWillUnmount() {
    this.setState({ file: null, tagsOnFile: null });
  }

  handleFileSelect(uploadFiles) {
    //console.log('HERE', uploadFiles);
    let files = uploadFiles.files;
    if (files.length < 1) {
      message.error('Please select a valid docx file.');
      return;
    }
    //console.log('files', files);

    if (files > 1) {
      console.log('more then one file');
      //TODO select more than one file
      // // files.map((element, index) => { //for later use
      // files.forEach((element, index) => {
      //   //let file = files[index];
      //   console.log('analyzing file: ' + element.name);
      //   var reader = new FileReader();
      //   reader.onload = this.onFileLoaded;
      //   reader.readAsArrayBuffer(element);
      // });
    } else {
      let file = files[0];
      this.setState({ file });
      //console.log('analyzing file: ' + file);
      var reader = new FileReader();
      reader.onload = this.onFileLoaded;
      reader.readAsArrayBuffer(file);
    }
  }
  saveByteArray(reportName, byte) {
    var blob = new Blob([byte]);
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  async onFileLoaded(e) {
    //console.log('onFileLoaded', e);
    this.parseFile(e.target.result);

    //this.saveByteArray('teste.docx', buf);
  }
  async onFileLoaded_write(e) {
    let buf = this.reParseFileBeforeDownload(e.target.result);

    //let buf = this.parseFile(e.target.result);
    //console.log('Buf', buf);
    if (buf) {
      this.saveByteArray('teste.docx', buf);
    }
  }

  getFiles() {
    //console.log('analyzing file: ', this.state.file);
    var reader = new FileReader();
    reader.onload = this.onFileLoaded_write;
    reader.readAsArrayBuffer(this.state.file);
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  reParseFileBeforeDownload(content, downloadStep = false) {
    try {
      //console.log('content', content);
      var zip = new PizZip(content);
      var doc = new Docxtemplater();
      doc.attachModule(iModule);
      doc.loadZip(zip);
    } catch (e) {
      message.error('Please select a valid docx file.');
      this.setState({ file: null, tagsOnFile: null });
      return null;
    }

    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      //dummy CHANGE 2

      // console.log('IN DOC RENDER');

      //let tags = iModule.getAllTags();
      let tagToRealData = {};
      this.state.tagsOnFile.forEach(tag => {
        tagToRealData[tag.field] = tag.value;
      });

      ////console.log('tagToRealData', { tagToRealData });

      // doc.setData({
      //   ...tagToRealData
      // });

      // //console.log('DOC1', doc);
      // console.log('tagToRealData');
      // console.log(tagToRealData);
      doc.render({ ...tagToRealData });

      //console.log('Doc3: ', doc);
      ////console.log('DOC_after_render', doc);
    } catch (error) {
      var e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties
      };
      console.error(JSON.stringify({ error: e }));
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
      throw error;
    }

    return doc.getZip().generate({ type: 'nodebuffer' }); //buff

    //var sampleArr = this.base64ToArrayBuffer(buf);
  }
  parseFile(content, downloadStep = false) {
    try {
      //console.log('content', content);
      var zip = new PizZip(content);
      var doc = new Docxtemplater();
      doc.attachModule(iModule);
      doc.loadZip(zip);
    } catch (e) {
      message.error('Please select a valid docx file.');
      this.setState({ file: null, tagsOnFile: null });
      return null;
    }

    //this is a dummy change: templates returning undefined
    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)

      doc.render();

      let tags = iModule.getAllTags();

      let tagsOnFile = [];
      Object.keys(tags).forEach(tag => {
        let tagValue = objectPath.get(
          this.props.clientToMinutes,
          variableDicionary[tag]
        );

        if (
          typeof tagValue === 'string' //only string will pass - needed to avoid put garbage tags after reading files
        ) {
          if (moment(tagValue, moment.ISO_8601, true).isValid()) {
            tagValue = moment(tagValue).format('L');
          }

          tagsOnFile.push({
            field: tag,
            value: tagValue
          });
        } else {
          tagsOnFile.push({
            field: tag,
            value: ''
          });
        }
      });

      this.setState({
        tagsOnFile: tagsOnFile
      });

      let tagToRealData = {};
      tagsOnFile.forEach(tag => {
        tagToRealData[tag.field] = tag.value;
      });

      doc.setData({
        ...tagToRealData
      });
      doc.render({ ...tagToRealData });
      //console.log('tags', tags);
    } catch (error) {
      // var e = {
      //   message: error.message,
      //   name: error.name,
      //   stack: error.stack,
      //   properties: error.properties
      // };
      //console.log(JSON.stringify({ error: e }));
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
      throw error;
    }

    return doc.getZip().generate({ type: 'nodebuffer' }); //buff

    //var sampleArr = this.base64ToArrayBuffer(buf);
  }
  render() {
    //console.log('this.props', this.props);
    //console.log('this.state.tagsOnFile', this.state.tagsOnFile);
    //let file;
    return (
      <Layout>
        <Sider
          style={{
            overflow: 'hidden'
            //height: '100vh',
          }}
          width={280}
          theme={'light'}
        >
          <p
            style={{
              fontSize: 20
            }}
          >
            Possible variables:
          </p>

          {Object.keys(variableDicionary).map((key, index) => {
            return (
              <Button
                key={'' + key + index} // use keys in component arrays, man!
                shape="round"
                icon="tag"
                disabled={
                  this.state.tagsOnFile
                    ? !this.state.tagsOnFile.find(tag => tag.field === key)
                    : true
                }
              >
                {key}
              </Button>
            );
          })}
        </Sider>
        <Content>
          <div>
            <div
              style={{
                // backgroundColor: '#F5F5F5',
                padding: 24
              }}
            >
              <PageHeader
                ghost={false}
                //onBack={() => window.history.back()}
                // title="Minute"
                subTitle="Please upload a template file:"
                extra={[
                  <Button
                    key="0" // use keys in component arrays, man!
                    type="secondary"
                    shape="round"
                    icon="upload"
                    onClick={() => {
                      //console.log('Selected');
                      window.document.getElementById('myFile').value = '';
                      window.document.getElementById('myFile').click();
                    }}
                  >
                    Select File
                  </Button>,
                  <Button
                    onClick={() => {
                      window.document.getElementById('myFile').value = '';
                      this.setState({ file: null, tagsOnFile: null });
                    }}
                    type="secondary"
                    shape="round"
                    icon="eye"
                  >
                    Clear
                  </Button>,
                  <Button
                    key="3"
                    onClick={
                      () => this.getFiles()
                      // this.handleFileSelect(window.document.getElementById('myFile'))
                    }
                    type="primary"
                    shape="round"
                    disabled={
                      this.state.tagsOnFile
                        ? this.state.tagsOnFile.find(tag => tag.value === '')
                        : true
                    }
                    icon="eye"
                  >
                    Download Minute
                  </Button>
                  // <Button key="1" type="primary">
                  //   Primary
                  // </Button>
                ]}
              >
                <input
                  type="file"
                  name="file"
                  id="myFile"
                  hidden
                  onChange={() => {
                    //console.log('changed');
                    this.handleFileSelect(
                      window.document.getElementById('myFile')
                    );
                  }}
                />
                <br /> <br />
                {this.state.tagsOnFile ? (
                  <>
                    <p>
                      Below are the variables found on the uploaded Docx. Fields
                      found on local database are filled. Please fill the others
                      (if needed). See on the left, the possible variables that
                      you can add to you documents.
                      <br />
                      Fields with a warning sign, are not on VICKI data base.
                      You can still use them for any personalized document
                    </p>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }}
                    >
                      {this.state.tagsOnFile.map((tag, index) => (
                        <div
                          key={'' + tag?.field + index} // use keys in component arrays, man!
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            height: '100%'
                          }}
                        >
                          {!variableDicionary.hasOwnProperty(tag.field) ? (
                            <Tooltip title="This field is not on VICKI database. Might be a typo on your word document. You can still manually fill it.">
                              <WarningOutlined
                                twoToneColor="#f9d71c"
                                style={{ fontSize: 15, padding: 5 }}
                              />
                            </Tooltip>
                          ) : null}

                          <EditableField
                            //type={field.type}
                            minWidthBefore={0}
                            span={15}
                            key={tag.field}
                            editable={
                              true
                              // isValueValid(data, field) ?
                              // field.editable === false ? false : true
                              // : false
                            }
                            //data={data}
                            title={tag.field}
                            hideIcon={true}
                            onChange={e => {
                              let tagsState = this.state.tagsOnFile;

                              tagsState[index].value = e.target.value;

                              this.setState({
                                tagsOnFile: tagsState
                              });
                            }}
                            value={tag.value}
                            onSave={value => {
                              //console.log(value);
                              return { result: 'OK' };
                            }}
                            // onSaveSuccess={async result => await onSaveSuccess(result)}
                            //   onSaveError={async err => {
                            //     await onSaveError(err);
                            //     return await onSaveError(err);
                            //   }}
                          ></EditableField>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
                {/* <Descriptions size="small" column={3}>
              <Descriptions.Item label="File">
                {this.state.file}
              </Descriptions.Item>
            </Descriptions> */}
                {/* <Descriptions size="small" column={3}>
              <Descriptions.Item label="Created">Lili Qu</Descriptions.Item>
              <Descriptions.Item label="Association">
                <a>421421</a>
              </Descriptions.Item>
              <Descriptions.Item label="Creation Time">
                2017-01-10
              </Descriptions.Item>
              <Descriptions.Item label="Effective Time">
                2017-10-10
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
              </Descriptions.Item>
            </Descriptions> */}
              </PageHeader>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

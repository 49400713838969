import React from 'react';
import AppContext from '../../context/AppContextBase';
import { Link } from 'react-router-dom';

import { Avatar, List, Row, Col, Badge, Icon, Modal } from 'antd';
import op from 'object-path';

import SingleClient from './SingleClient';
import { NotificationContextConsumer } from '../../context/NotificationContext';

import CreateRelative from '../../components/CreateRelative';
import DetailedMessage from '../../components/util/DetailedMessage';
import NoData from '../../components/util/NoData';
import Loading from '../../components/Loading';
import globalStyles from '../../styles/global';
import { mrg, handleRequest } from '../../common/util';
import { getClient } from '../../network/sef';

const styles = {
  mainRow: {
    height: '100%',
    overflowY: 'hidden',
    width: '100%'
  },
  col: {
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter,
    height: '100%'
  },
  leftCol: {
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    height: '100%',
    backgroundColor: '#f0f2f5',
    borderRight: '1px solid #ddd',
    padding: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 100,
    overflowX: 'hidden'
  },
  rightCol: {
    height: '100%'
  },
  list: {
    item: {
      title: {},
      subtitle: {
        fontWeight: 100,
        fontSize: globalStyles.global.baseline * 0.75,
        textTransform: 'uppercase'
      },
      subsubtitle: {
        fontWeight: 100,
        fontSize: globalStyles.global.baseline * 0.5,
        textTransform: 'uppercase'
      }
    }
  }
};

const getClientLink = client => {
  let result = client._id;
  if (client.parentId) {
    result = client.parentId + '/' + result;
  }
  return result;
};

const flatten = client => {
  const result = [];
  result.push(client);
  if (!Array.isArray(client.Familia)) {
    return result;
  }
  for (let desc of client.Familia) {
    desc.parentId = client._id;
    desc.parentMongoId = client._id;
    result.push(desc);
  }
  return result;
};

class FamilyTree extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    const parent = this.props.match.params.parentId;
    const descendant = this.props.match.params.descendantId;
    this.state = {
      current: descendant ? descendant : parent
    };
    this.refresh = this.refresh.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.getClient = this.getClient.bind(this);
    this.updateClient = this.updateClient.bind(this);
  }

  async componentDidMount() {
    const clients = await this.refresh();
    if (clients) {
      this.setState({
        clients: clients
      });
    } else {
      DetailedMessage.error('Connection Problems', clients, true);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const parent = this.props.match.params.parentId;
    const oldParent = prevProps.match.params.parentId;
    const desc = this.props.match.params.descendantId;
    const oldDesc = prevProps.match.params.descendantId;

    if (parent !== oldParent || desc !== oldDesc) {
      this.setState({ loading: true });
    }

    if (parent !== oldParent) {
      //console.log('different parent');
      let clientData = await this.refresh();
      let client = clientData[0];
      if (client.Familia) {
        const search = client.Familia.filter(
          member => member._id === this.props.match.params.descendantId
        );
        if (search && search.length === 1) {
          client = search[0];
        }
      }
      this.setCurrent(client._id);
    }
    if (desc !== oldDesc) {
      if (desc) {
        //console.log('different relative');
        // Jumped to some other relative
        this.setCurrent(desc);
      } else {
        //console.log('jumped to parent');
        // Jumped to parent
        this.setCurrent(parent);
      }
    }
    if (parent !== oldParent || desc !== oldDesc) {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <NotificationContextConsumer>
        {notificationContext => (
          <Row style={styles.mainRow}>
            <Col style={mrg([styles.col, styles.leftCol])} span={4}>
              {!this.state.clients ? (
                <Loading></Loading>
              ) : (
                <div>
                  <List
                    dataSource={this.state.clients}
                    footer={
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ showCreateRelative: true });
                        }}
                      >
                        <List.Item.Meta
                          style={{
                            ...globalStyles.layout.flexHorizontal,
                            ...globalStyles.layout.flexCenter,
                            ...globalStyles.layout.alignCenter
                          }}
                          avatar={
                            <Avatar size="large">
                              <Icon type="plus"></Icon>
                            </Avatar>
                          }
                          title={
                            <span
                              style={{
                                ...globalStyles.layout.flexVertical,
                                ...globalStyles.layout.flexStart,
                                ...globalStyles.layout.alignStart
                              }}
                            >
                              <span
                                style={{
                                  ...styles.list.item.title,
                                  fontWeight: 100
                                }}
                              >
                                Create new
                              </span>
                            </span>
                          }
                        />
                        <Modal
                          title=""
                          visible={this.state.showCreateRelative}
                          footer={null}
                          onCancel={() =>
                            this.setState({
                              showCreateRelative: false
                            })
                          }
                          onSuccess={data =>
                            this.setState(state => {
                              const clients = this.state.clients;
                              const newClients = clients.concat([data]);
                              return {
                                showCreateRelative: false,
                                current: data._id,
                                clients: newClients
                              };
                            })
                          }
                          width={'70vw'}
                          bodyStyle={{ height: '60vh', overflowY: 'scroll' }}
                        >
                          <CreateRelative
                            onCancel={() =>
                              this.setState({ showCreateRelative: false })
                            }
                            parentId={this.getParent()._id}
                            onSuccess={async result => {
                              this.refresh(true);
                              this.setState({ showCreateRelative: false });
                            }}
                            onError={response => {
                              DetailedMessage.error(
                                'Error saving data - ' + response.message,
                                response
                              );
                              this.setState({ showCreateRelative: false });
                            }}
                          ></CreateRelative>
                        </Modal>
                      </List.Item>
                    }
                    renderItem={item => (
                      <Link to={'/id/clients/' + getClientLink(item)}>
                        <List.Item key={item._id}>
                          <List.Item.Meta
                            style={{
                              ...globalStyles.layout.flexHorizontal,
                              ...globalStyles.layout.flexCenter,
                              ...globalStyles.layout.alignCenter
                            }}
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor:
                                    this.state.current === item._id
                                      ? '#006494'
                                      : null,
                                  border: !item.parentId
                                    ? '1px solid #888888'
                                    : 'unset',
                                  verticalAlign: 'middle'
                                }}
                                size="large"
                              >
                                {item.Nome[0]}
                              </Avatar>
                            }
                            title={
                              <span
                                style={{
                                  ...globalStyles.layout.flexVertical,
                                  ...globalStyles.layout.flexStart,
                                  ...globalStyles.layout.alignStart
                                }}
                              >
                                <span
                                  style={{
                                    ...styles.list.item.title,
                                    fontWeight: item.parentId ? 100 : 500
                                  }}
                                >
                                  {item.Nome}
                                </span>

                                <span style={styles.list.item.subtitle}>
                                  {item.parentId ? 'relative' : 'main investor'}
                                </span>

                                <span style={styles.list.item.subsubtitle}>
                                  <span>Unassigned</span>
                                  {this.context.checkPermission(
                                    'id:client:ari'
                                  ) && (
                                    <Badge
                                      dot={true}
                                      count={notificationContext.get(
                                        item._id,
                                        'unreadAri'
                                      )}
                                    />
                                  )}
                                  <Badge
                                    style={globalStyles.badges.cyan}
                                    dot={true}
                                    count={notificationContext.get(
                                      item._id,
                                      'unreadMessages'
                                    )}
                                  />
                                  {/*
                                  // 06 Fev 2021: removed audit notifications, requested by SSR
                                  {this.context.checkPermission(
                                    'id:client:audit'
                                  ) && (
                                    <Badge
                                      style={globalStyles.badges.grey}
                                      dot={true}
                                      count={notificationContext.get(
                                        item._id,
                                        'unreadAudit'
                                      )}
                                    />
                                  )}*/}
                                </span>
                                <span style={styles.list.item.subsubtitle}>
                                  <span>To-Do</span>
                                  {this.context.checkPermission(
                                    'id:client:ari'
                                  ) && (
                                    <Badge
                                      dot={true}
                                      count={notificationContext.get(
                                        item._id,
                                        'todoAri'
                                      )}
                                    />
                                  )}
                                  <Badge
                                    style={globalStyles.badges.cyan}
                                    dot={true}
                                    count={notificationContext.get(
                                      item._id,
                                      'todoCrm'
                                    )}
                                  />
                                </span>
                              </span>
                            }
                            description={item.email}
                          />
                        </List.Item>
                      </Link>
                    )}
                  ></List>
                </div>
              )}
            </Col>
            <Col style={mrg([styles.col, styles.rightCol])} span={20}>
              {this.state.loading ? (
                <Loading></Loading>
              ) : this.state.clients ? (
                <SingleClient
                  notificationContext={notificationContext}
                  client={this.getClient(this.state.current)}
                  onSaveSuccess={this.updateClient}
                  processUnreadMessages={this.props.processUnreadMessages}
                ></SingleClient>
              ) : (
                <NoData></NoData>
              )}
            </Col>
          </Row>
        )}
      </NotificationContextConsumer>
    );
  }

  updateClient(clientId, options) {
    if (!options || !this.state.clients) {
      return;
    }
    // console.log(
    //   'Family Tree: updating client. ClientId: ',
    //   clientId,
    //   '  options: ',
    //   { options }
    // );
    this.setState(state => {
      const newState = { ...state };
      newState.clients.forEach(client => {
        if (client._id === clientId) {
          //console.log('Family Tree: found matching client');
          op.set(client, options.key, options.value);
        }
      });
      return newState;
    });
  }

  getClient(_id) {
    if (!Array.isArray(this.state.clients)) {
      throw new Error('Error: FamilyTree state.clients is not populated.');
    }
    const result = this.state.clients.filter(client => client._id === _id);
    if (!result[0]) {
      throw new Error('Error: FamilyTree state.clients does not contain id.');
    }
    return result[0];
  }

  setCurrent(_id) {
    return this.setState({ current: _id });
  }

  getParent = () => {
    return this.state.clients.find(client => !client.parentId);
  };

  async refresh(force = false) {
    this.setState({ loading: true });
    const _id = this.props.match.params.parentId;
    let clientData = this.props.clientData;

    if (!!force && clientData) {
      this.setState({ clients: clientData });
    } else {
      try {
        clientData = handleRequest(await getClient(_id));
      } catch (err) {
        DetailedMessage.error(
          'Erro a carregar dados do servidor. Verifique a sua ligação à internet e/ou permissões de acesso',
          err
        );
        this.setState({ clients: null, loading: false });
        return;
      }

      clientData = flatten(clientData.data);
      this.setState({ clients: clientData });
    }
    this.setState({
      loading: false
    });

    return clientData;
  }
}

FamilyTree.contextType = AppContext;

export default FamilyTree;

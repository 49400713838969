import autobind from 'auto-bind/react';
import React from 'react';

import { Helmet } from 'react-helmet';
import deburr from 'lodash/deburr';
import {
  Layout,
  Table,
  Input,
  Button,
  message,
  Tooltip,
  Icon,
  Row,
  Col,
  Modal
} from 'antd';
import flatten from 'flat';

import AppContext from '../../context/AppContextBase';
import g from '../../styles/global';
import DetailedMessage from '../../components/util/DetailedMessage';
import {
  fetchOrphanCases,
  deleteNotification,
  updateNotification,
  updateCitiusCase
} from '../../network/citius';
import { Storage } from 'aws-amplify';
const { Content } = Layout;
const { confirm } = Modal;

const getColumns = (
  updateCitiusCase,
  updateNotification,
  deleteNotification,
  NoteEditor,
  done
) => [
  {
    title: 'Lida?',
    dataIndex: 'unread',
    key: 'unread',
    render: (unread, record) =>
      unread ? (
        <Tooltip placement="right" title={'Por tratar'}>
          <Icon
            onClick={() => {
              updateNotification(record.Processo, record.Referência, {
                unread: false
              });
            }}
            type="close-circle"
            style={{
              fontSize: g.global.baseline * 1.5,
              color: g.colors.feedback.error
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip placement="right" title={'Tratada'}>
          <Icon
            onClick={() => {
              updateNotification(record.Processo, record.Referência, {
                unread: true
              });
            }}
            type={'check'}
            style={{
              fontSize: g.global.baseline * 1.5,
              color: g.colors.feedback.success
            }}
          />
        </Tooltip>
      )
  },
  {
    title: 'Data Elaboração',
    dataIndex: 'Data Elaboração',
    key: 'Data Elaboração'
  },
  {
    title: 'Acto',
    dataIndex: 'Acto',
    key: 'Acto'
  },
  {
    title: 'Tribunal',
    dataIndex: 'Tribunal',
    key: 'Tribunal'
  },
  {
    title: 'Un Orgânica',
    dataIndex: 'Un Orgânica',
    key: 'Un Orgânica'
  },
  {
    title: 'Processo',
    dataIndex: 'Processo',
    key: 'Processo'
  },
  {
    title: 'Espécie',
    dataIndex: 'Espécie',
    key: 'Espécie'
  },
  {
    title: 'Referência',
    dataIndex: 'Referência',
    key: 'Referência'
  },
  {
    title: 'Notas',
    dataIndex: 'notes',
    key: 'notes',
    width: 200,
    render: (notes, record) => (
      <NoteEditor
        processId={record.Processo}
        notes={notes}
        done={done}
        record={record}
      />
    )
  },
  {
    title: 'Acção',
    dataIndex: 'PDF',
    key: 'Acção',
    render: (pdf, record) => (
      <div style={styles.actions.container}>
        <Button
          type="primary"
          ghost
          style={styles.actions.button}
          disabled={pdf === 'error' ? true : false}
          onClick={async () => {
            try {
              const result = await Storage.get(pdf);
              window.location.href = result;
            } catch (err) {
              console.error(err);
              DetailedMessage('Erro a carregar documento', err);
            }
          }}
        >
          {pdf === 'error' ? 'Not Possible to download' : ' Get PDF(s)'}
        </Button>
        <Button
          type="dashed"
          style={styles.actions.button}
          onClick={async () => {
            await updateCitiusCase(record.Processo, {
              orphan: false
            });
          }}
        >
          Não-orfã
        </Button>

        <Button
          type="danger"
          ghost
          style={styles.actions.button}
          onClick={async () => {
            confirm({
              title: 'Tem a certeza que pretende apagar esta notificação?',
              content: (
                <div
                  style={{
                    color: g.colors.feedback.error,
                    fontWeight: 700
                  }}
                >
                  <div>A notificação será permanentemente apagada!</div>
                  <div>Esta operação é irreversível!</div>
                </div>
              ),
              onOk: async () => {
                await deleteNotification(
                  record['Processo'],
                  record['Referência']
                );
              },
              onCancel() {}
            });
          }}
        >
          Apagar
        </Button>
        {/* No delete nothing ever :D  */}
        {/* Apparently we do  */}
      </div>
    )
  }
];

const styles = {
  header: {
    row: {
      width: '100%',
      padding: g.global.baseline
    },
    col: {
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignStart
    }
  },
  content: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    maxHeight: 'calc(100vh - ' + 7 * g.global.baseline + 'px)',
    width: '100%'
  },
  table: {
    width: '100%',
    overflowY: 'scroll',
    padding: g.global.baseline
  },
  actions: {
    container: {
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignStart
    },
    button: {
      marginBottom: g.global.baseline
    }
  }
};

const stringifyCase = kase => {
  return JSON.stringify(Object.values(flatten(kase)));
};

class Orphans extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      cases: [],
      search: '',
      searching: false
    };

    autobind(this);
  }

  async fetchCitiusCases() {
    try {
      let request = await fetchOrphanCases();
      this.setState({
        cases: request.data.docs
      });
      this.caseCache = request.data.docs;
    } catch {
      this.setState({
        cases: []
      });
      this.caseCache = [];
      message.info(
        'Erro a carregar notificações orfãs. Verifique a sua ligação à internet e/ou permissões de acesso'
      );
    }
  }

  async search() {
    this.setState({ searching: true });
    if (!this.state.cases || this.state.searching || !this.state.search) {
      this.setState({ searching: false, cases: this.caseCache });
      return;
    }

    const cases = this.caseCache.filter(
      kase =>
        deburr(stringifyCase(kase))
          .toLowerCase()
          .indexOf(deburr(this.state.search).toLowerCase()) !== -1
    );
    this.setState({ cases, searching: false });
  }

  async updateNotification(process, ref, newData) {
    try {
      const request = await updateNotification(process, ref, newData);
      if (request && request.result === 'OK') {
        await this.fetchCitiusCases();
      } else {
        throw request;
      }
    } catch (err) {
      console.error(err);
      DetailedMessage.error(
        err.message ? err.message : 'Erro a modificar notificação',
        err
      );
    }
  }

  async updateCitiusCase(process, newData) {
    try {
      const request = await updateCitiusCase(process, newData);
      if (request && request.result === 'OK') {
        await this.fetchCitiusCases();
      } else {
        throw request;
      }
    } catch (err) {
      console.error(err);
      DetailedMessage.error(
        err.message ? err.message : 'Erro a modificar notificação',
        err
      );
    }
  }

  async deleteNotification(process, ref) {
    try {
      const request = await deleteNotification(process, ref);
      console.log(request);
      if (request && request.result === 'OK') {
        await this.fetchCitiusCases();
      } else {
        throw request;
      }
    } catch (err) {
      console.error(err);
      DetailedMessage('Erro a apagar notificação', err);
    }
  }

  async componentDidMount() {
    await this.fetchCitiusCases();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>CM - Notificações Orfãs</title>
          <meta charSet="utf-8" />
        </Helmet>
        <Layout style={{ width: '100%' }}>
          <Content style={styles.content}>
            <Row type="flex" style={styles.header.row}>
              <Col style={styles.header.col}>
                <div style={{ marginRight: g.global.baseline }}>Pesquisar</div>
              </Col>
              <Col style={styles.header.col} span={12}>
                <Input
                  onChange={e => {
                    this.setState({ search: e.target.value });

                    if (!e.target.value) {
                      // reset table
                      this.search();
                    }
                  }}
                  disabled={
                    this.state.searching || !Array.isArray(this.state.cases)
                  }
                  value={this.state.search}
                  onPressEnter={this.search}
                />
              </Col>
            </Row>
            <Table
              rowKey="_id"
              columns={getColumns(
                this.updateCitiusCase,
                this.updateNotification,
                this.deleteNotification,
                NotesEditor,
                this.fetchCitiusCases
              )}
              pagination={false}
              scroll={{ x: '100%' }}
              dataSource={
                this.state.cases
                  ? this.state.cases.reduce(
                      (prev, current) => prev.concat(current.history),
                      []
                    )
                  : []
              }
              style={styles.table}
            />
          </Content>
        </Layout>
      </>
    );
  }
}

class NotesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, value: this.props.record.notes };
    this.save = this.save.bind(this);
  }

  async save() {
    this.setState({ saving: true });
    const request = await updateNotification(
      this.props.processId,
      this.props.record.Referência,
      { notes: this.state.value }
    );
    if (request && request.result === 'OK') {
      message.success('Alterações guardadas');
      if (this.props.done) {
        this.props.done();
      }
    } else {
      console.log(request);
      DetailedMessage.error('Erro a gravar dados', request);
    }
    this.setState({ saving: false });
  }

  render() {
    return (
      <>
        <Input.TextArea
          onChange={e => this.setState({ value: e.target.value })}
          disabled={this.state.saving}
          value={this.state.value}
          style={{ marginBottom: g.global.baseline }}
        />
        <Button
          size="small"
          loading={this.state.saving}
          type="primary"
          onClick={() => {
            this.save();
          }}
        >
          Gravar
        </Button>
      </>
    );
  }
}

export default Orphans;

import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';

const BE_ADDR = address;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export async function fetchUserDataIfAuthenticated() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'users/' + userInfo.attributes.sub, {
    //awdawdasdasdasdasdsd ////
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}

import React from 'react';
import { Button, Icon, Tooltip, Input } from 'antd';
import Highlighter from 'react-highlight-words';

import Loading from './Loading';
import {
  renderColumn,
  renderDateColumn,
  textSorter,
  dateSorter,
  booleanSorter,
  renderEditableColumn
} from '../common/table';

import globalStyles from '../styles/global';

import { scopes } from './DossierTable';

const styles = {
  actionContainer: {
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignStart
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    maxWidth: '150px',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  dossier: {
    title: {
      marginBottom: globalStyles.global.baseline * 0.5,
      fontSize: globalStyles.global.baseline * 1.6,
      fontWeight: 700,
      color: '#888888',
      width: '100%'
    },
    infoBox: {
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      alignItems: 'center',
      width: '100%'
    },
    description: {
      paddingRight: globalStyles.global.baseline * 1.2,
      fontSize: globalStyles.global.baseline,
      color: '#666666'
    }
  }
};

const getConsistencyIcon = record => {
  let title;
  let type;
  let color;
  if (record.edited) {
    title = 'modificado';
    type = 'edit';
    color = globalStyles.colors.feedback.edited;
  } else if (record.has_errors) {
    title = 'Erros';
    type = 'close-circle';
    color = globalStyles.colors.feedback.error;
  } else if (record.warning) {
    title = record.warning;
    type = 'warning';
    color = globalStyles.colors.feedback.warning;
  } else {
    title = 'OK';
    type = 'check';
    color = globalStyles.colors.feedback.success;
  }
  return (
    <Tooltip title={title}>
      <Icon
        type={type}
        style={{
          fontSize: globalStyles.global.baseline * 1.5,
          color: color
        }}
      />
    </Tooltip>
  );
};

// const getReportIcon = record => {
//   return (
//     <Tooltip title={'Unknown'}>
//       <Icon
//         type={'warning'}
//         style={{
//           fontSize: globalStyles.global.baseline * 1.5,
//           color: globalStyles.colors.feedback.warning
//         }}
//       />
//     </Tooltip>
//   );
// };

let searchInput;

const getColumnSearchProps = (
  dataIndex,
  handleSearch,
  handleReset,
  extraInfo = []
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      {extraInfo && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {extraInfo.map(info => (
            <code
              key={info}
              style={{
                color: '#aaa',
                padding: '3px'
              }}
            >
              {info}
            </code>
          ))}
        </div>
      )}
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Pesquisar
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon
      type="search"
      style={{ color: filtered ? 'rgb(255, 77, 91)' : undefined }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  }
});

const defaults = {};
const consistency = {};
const report = {};

const getColumns = options => {
  const {
    scope,
    handleChange,
    edit,
    save,
    cancel,
    consistencyWarning,
    isWarned,
    setWarned,
    searchText = '',
    handleSearch,
    handleReset
  } = options;

  const isConsistency = scope === scopes.CONSISTENCY;

  defaults.dossier = width => {
    return {
      title: 'Dossier',
      dataIndex: 'dossier',
      key: 'dossier',
      width: width,
      render: (obj, record) => ({
        props: { colSpan: 1 },
        children: (
          <>
            <div style={styles.dossier.title}>
              <span>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={obj.toString()}
                />
              </span>
            </div>
            <div style={styles.dossier.infoBox}>
              <div style={styles.dossier.description}>
                <span>cliente</span>
              </div>
              <div>
                <span>dossier</span>
              </div>
            </div>
          </>
        )
      }),
      sorter: textSorter('dossier'),
      ...getColumnSearchProps('dossier', handleSearch, handleReset)
    };
  };
  defaults.info_to_send = width => {
    return {
      title: 'Enviar',
      dataIndex: 'info_to_send',
      key: 'info_to_send',
      width: '10%',
      render: (obj, record) => {
        const options = {
          just_report: 'Relatório',
          just_invoices: { label: 'Facturas', disabled: false },
          send_both: 'Ambos'
        };
        return renderEditableColumn(
          obj,
          record,
          'info_to_send',
          handleChange,
          true,
          options
        );
      },

      ...getColumnSearchProps('info_to_send', handleSearch, handleReset, [
        'just_report',
        'just_invoices',
        'send_both'
      ])
    };
  };
  defaults.send_method = width => {
    return {
      title: 'Método',
      dataIndex: 'send_method',
      key: 'send_method',
      width: '8%',
      render: (obj, record) => {
        const options = {
          letter: 'Carta',
          email: 'Email',
          both: 'Ambos',
          do_not_send: 'Nenhum'
        };
        return renderEditableColumn(
          obj,
          record,
          'send_method',
          handleChange,
          true,
          options
        );
      },
      ...getColumnSearchProps('send_method', handleSearch, handleReset, [
        'email',
        'letter',
        'both',
        'do_not_send'
      ])
    };
  };
  defaults.language = width => {
    return {
      title: (
        <Tooltip title="Língua">
          <Icon
            type="audio"
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'language',
      key: 'language',
      width: width,

      render: (obj, record) => {
        const options = {
          'pt-PT': 'PT',
          'en-US': 'EN'
        };
        return renderEditableColumn(
          obj,
          record,
          'language',
          handleChange,
          true,
          options
        );
      },
      ...getColumnSearchProps('language', handleSearch, handleReset),
      sorter: textSorter('language')
    };
  };
  defaults.email_contacts = width => {
    return {
      title: 'Contactos',
      dataIndex: 'email_contacts',
      key: 'email_contacts',
      width: width,
      render: (obj, record) =>
        renderEditableColumn(
          obj,
          record,
          'email_contacts',
          handleChange,
          false,
          [],
          searchText
        ),
      sorter: textSorter('email_contacts'),
      ...getColumnSearchProps('email_contacts', handleSearch, handleReset)
    };
  };
  defaults.notes = width => {
    return {
      title: 'Notas',
      dataIndex: 'notes',
      width: width,
      key: 'notes',
      render: (obj, record) =>
        renderEditableColumn(
          obj,
          record,
          'notes',
          handleChange,
          false,
          [],
          searchText
        ),
      sorter: textSorter('notes'),
      ...getColumnSearchProps('notes', handleSearch, handleReset)
    };
  };
  defaults.createdAt = width => {
    return {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: width,
      render: renderDateColumn(1),
      sorter: dateSorter('createdAt')
    };
  };
  defaults.action = width => {
    return {
      title: 'Acção',
      key: 'Acção',
      width: width,
      render: (text, record) => ({
        props: { colSpan: 1 },
        children: (
          <div style={styles.actionContainer}>
            {record.editable ? (
              <>
                {record.saving ? (
                  <Loading />
                ) : (
                  <>
                    <Button
                      style={styles.action}
                      type="primary"
                      onClick={() => save(record.dossier)}
                    >
                      Gravar
                    </Button>
                    <Button
                      style={styles.action}
                      ghost
                      type="danger"
                      onClick={() => cancel(record.dossier)}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Button
                style={styles.action}
                ghost
                type="primary"
                onClick={() => {
                  if (isConsistency && !isWarned) {
                    setWarned();
                    consistencyWarning();
                  }
                  return edit(record.dossier);
                }}
              >
                Editar
              </Button>
            )}
          </div>
        )
      })
    };
  };

  consistency.errors = width => {
    return {
      title: (
        <Tooltip title={'Estado'}>
          <Icon
            type={'check'}
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'has_errors',
      key: 'has_errors',
      width: width,
      render: (obj, record) => getConsistencyIcon(record),
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        booleanSorter('has_errors')(a, b)
          ? booleanSorter('has_errors')(a, b)
          : booleanSorter('warning')(a, b)
    };
  };
  consistency.state = width => {
    return {
      title: 'Descriç.',
      dataIndex: 'dossier_state',
      key: 'dossier_state',
      width: width,
      render: (obj, record) => {
        let content;
        if (record.edited) {
          content = 'Editado';
        } else if (record.has_errors) {
          content = record.dossier_state;
        } else if (record.warning) {
          content = record.warning;
        } else {
          content = 'OK';
        }
        return (
          <span
            style={{
              fontWeight: 700
            }}
          >
            {content}
          </span>
        );
      },
      sorter: textSorter('dossier_state'),
      ...getColumnSearchProps('dossier_state', handleSearch, handleReset)
    };
  };
  consistency.files = width => {
    return {
      title: 'Files',
      dataIndex: 'dossier_files',
      key: 'dossier_files',
      width: width,
      render: (obj, record) => {
        const fileList = obj.map(fileName => (
          <span key={fileName}>
            <span>{fileName}</span>
            <br />
            <br />
          </span>
        ));
        return renderColumn(1)(fileList, searchText);
      },
      ...getColumnSearchProps('dossier_files', handleSearch, handleReset)
    };
  };

  report.send_result = width => {
    return {
      title: 'Resultado',
      dataIndex: 'send_result',
      key: 'send_result',
      width: width,
      render: (obj, record) => renderColumn(1)(obj, searchText),
      defaultSortOrder: 'ascend',
      sorter: textSorter('send_result'),
      ...getColumnSearchProps('send_result', handleSearch, handleReset)
    };
  };

  report.send_date = width => {
    return {
      title: 'Data Env.',
      dataIndex: 'send_date',
      key: 'send_date',
      width: width,
      render: renderDateColumn(1, 'YYYY-MM-DD H:mm:ss'),
      sorter: dateSorter('createdAt')
    };
  };

  report.info_to_send = width => {
    return {
      title: 'Enviar',
      dataIndex: 'info_to_send',
      key: 'info_to_send',
      width: width,
      render: (obj, record) => {
        return renderColumn(
          1,
          {},
          {
            just_report: 'Relatório',
            just_invoices: 'Facturas',
            send_both: 'Ambos'
          }
        )(obj);
      },
      ...getColumnSearchProps('info_to_send', handleSearch, handleReset, [
        'just_report',
        'just_invoices',
        'send_both'
      ])
    };
  };
  report.send_method = width => {
    return {
      title: 'Método',
      dataIndex: 'send_method',
      key: 'send_method',
      width: width,
      render: (obj, record) => {
        return renderColumn(
          1,
          {},
          { letter: 'Carta', email: 'Email', do_not_send: 'N/A' }
        )(obj);
      },
      ...getColumnSearchProps('send_method', handleSearch, handleReset, [
        'email',
        'letter',
        'both',
        'do_not_send'
      ])
    };
  };
  report.language = width => {
    return {
      title: (
        <Tooltip title="Língua">
          <Icon
            type="audio"
            style={{
              fontSize: globalStyles.global.baseline * 1.5
            }}
          />
        </Tooltip>
      ),
      dataIndex: 'language',
      key: 'language',
      width: width,

      render: (obj, record) => {
        return renderColumn(
          1,
          {},
          {
            'pt-PT': 'PT',
            'en-US': 'EN'
          }
        )(obj);
      },
      sorter: textSorter('language'),
      ...getColumnSearchProps('language', handleSearch, handleReset)
    };
  };

  let result;
  if (scope === scopes.DEFAULT) {
    result = [
      defaults.dossier('11.5%'),
      defaults.info_to_send('10%'),
      defaults.send_method('8%'),
      defaults.language('4%'),
      defaults.email_contacts('18%'),
      defaults.notes('10%'),
      defaults.createdAt('10%'),
      defaults.action(null)
    ];
  } else if (scope === scopes.CONSISTENCY) {
    result = [
      defaults.dossier('11.5%'),
      defaults.info_to_send('10%'),
      defaults.send_method('8%'),
      defaults.language('4%'),
      defaults.email_contacts('18%'),
      defaults.notes('10%'),
      defaults.action('10%'),
      consistency.state('7%'),
      consistency.errors('5.5%'),
      consistency.files(null)
    ];
  } else if (scope === scopes.REPORT) {
    result = [
      defaults.dossier('12%'),
      report.info_to_send('6%'),
      report.send_method('6%'),
      report.language('6%'),
      defaults.email_contacts('18%'),
      defaults.notes('10%'),
      report.send_result('27%'),
      report.send_date('15%')
    ];
  }
  return result;
};

export default getColumns;

import React from 'react';
import { Card, Button, Tag, Tooltip, Icon } from 'antd';
import moment from 'moment';

import MessageContext from '../../context/MessageContextBase';
import { downloadFile } from '../../network/documentUploads';
import ReplyForm from './ReplyForm';
import g from '../../styles/global';

const styles = {
  message: {
    container: {
      width: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart
    },
    header: {},
    subheader: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    attachments: {
      borderTop: '1px solid #e8e8e8',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      alignItems: 'flex-start',
      width: '100%',
      paddingTop: g.global.baseline,
      marginTop: g.global.baseline
    },
    card: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      alignItems: 'flex-start'
    },
    type: {
      fontSize: g.global.baseline * 1.5,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      backgroundColor: '#e8e8e8',
      marginRight: g.global.baseline,
      paddingLeft: g.global.baseline,
      paddingRight: g.global.baseline,
      paddingTop: g.global.baseline * 0.5,
      paddingBottom: g.global.baseline * 0.5
    }
  }
};

class Reply extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      editing: false,
      replying: false,
      saving: false
    };

    this.hideEditingForm = this.hideEditingForm.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.toggleUnread = this.toggleUnread.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, values: props.values };
  }

  async hideEditingForm() {
    this.setState({ editing: false });
  }

  toggleUnread = async values => {
    if (this.context?.operations?.onUpdateReply) {
      this.setState({
        loadingMessage: true
      });
      try {
        const unread = !values.unread;
        const toSave = {
          _id: values._id,
          unread: unread
        };

        await this.context?.operations?.onUpdateReply?.(
          this.props.parent._id,
          values._id,
          toSave
        );

        await this.context?.operations?.onSaveSuccess?.();
      } catch (err) {
        console.error(err);
        await this.context?.operations?.onSaveError?.();
      }
      this.setState({
        loadingMessage: false
      });
    }
  };

  renderMessage(message) {
    let result;
    try {
      result = message.split('\n').map((item, i) => {
        return <p key={i}>{item}</p>;
      });
    } catch (error) {
      return message;
    }
    return result;
  }

  render() {
    let { values } = this.state;
    if (this.state.editing) {
      return (
        <ReplyForm
          updateValues={values}
          hide={this.hideEditingForm}
          messageId={this.props.parent._id}
        />
      );
    } else {
      return (
        <Card
          style={{
            width: '95%',
            backgroundColor: '#fafafa',
            borderRadius: 0,
            borderTopWidth: '2px'
          }}
          hoverable={true}
          title={
            <div
              style={{
                ...g.layout.flexVertical,
                ...g.layout.flexStart,
                ...g.layout.alignStart,
                width: '100%'
              }}
            >
              <div
                style={{
                  paddingBottom: g.global.baseline,
                  ...g.layout.flexHorizontal,
                  ...g.layout.flexStart,
                  ...g.layout.alignStart,
                  width: '100%'
                }}
              >
                <div
                  style={{
                    width: '60%',
                    ...g.layout.flexHorizontal,
                    ...g.layout.flexStart,
                    ...g.layout.alignStart
                  }}
                >
                  {values.owner === this.context?.userData?.id && (
                    <Tag color={'cyan'}>Owner</Tag>
                  )}
                  {values.assignee ? (
                    <Tag color={'purple'}>Assignee: {values.assignee.name}</Tag>
                  ) : (
                    !this.context?.enableAssignment === false && (
                      <Tag color={'purple'}>Unassigned</Tag>
                    )
                  )}
                  <span
                    style={{
                      marginRight: g.global.baseline
                    }}
                  >
                    {values.create_date
                      ? moment(values.create_date).format('YYYY-MM-DD HH:mm')
                      : values.createdAt
                      ? moment(values.createdAt).format('YYYY-MM-DD HH:mm')
                      : ' - '}
                  </span>
                </div>
                <div
                  style={{
                    width: '40%',
                    ...g.layout.flexHorizontal,
                    ...g.layout.flexEnd,
                    ...g.layout.alignStart
                  }}
                >
                  {this.props.enableReplies && (
                    <Button
                      type="Primary"
                      loading={this.state.loadingMessage}
                      size="small"
                      icon={'retweet'}
                      style={{
                        marginRight: g.global.baseline
                      }}
                      onClick={() => {
                        return this.props.onReply?.();
                      }}
                    >
                      Reply
                    </Button>
                  )}
                  {!this.props.showUnread ? (
                    <Tooltip
                      placement="top"
                      title={'You are not the recipient of this reply'}
                    >
                      <Tag style={{ height: '100%' }}>
                        <Icon
                          type={values.unread ? 'exclamation' : 'check'}
                        ></Icon>{' '}
                        {values.unread ? 'Unread' : 'Read'}
                      </Tag>
                    </Tooltip>
                  ) : (
                    // <Tooltip
                    //   placement="top"
                    //   title={
                    //     'You can change the state of this reply because you are its recipient'
                    //   }
                    // >
                    <Button
                      type={values.unread ? 'danger' : 'primary'}
                      loading={this.state.loadingMessage}
                      ghost={this.props.isOwner}
                      size="small"
                      icon={values.unread ? 'exclamation' : 'check'}
                      style={{
                        marginRight: g.global.baseline
                      }}
                      onClick={() => {
                        return this.toggleUnread(values);
                      }}
                    >
                      {values.unread ? 'Unread' : 'Read'}
                    </Button>
                    // </Tooltip>
                  )}
                  {this.props.editable && (
                    <Button
                      type="secondary"
                      size="small"
                      disabled={this.state.loadingMessage}
                      style={{
                        marginRight: g.global.baseline
                      }}
                      onClick={() => {
                        return this.setState({ editing: true });
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {this.context?.userData?.id === values.owner &&
                    this.props.deletable && (
                      <Button
                        type="secondary"
                        size="small"
                        disabled={this.state.loadingMessage}
                        style={{
                          marginRight: g.global.baseline
                        }}
                        onClick={async () => {
                          if (this.context?.operations?.removeReply) {
                            await this.context?.operations?.removeReply?.(
                              this.props.parent._id,
                              values._id
                            );
                            await this.context?.operations?.onSaveSuccess?.();
                          } else {
                            await this.context?.operations?.onSaveError?.();
                          }
                        }}
                      >
                        Delete
                      </Button>
                    )}
                </div>
              </div>

              <div
                style={{
                  textOverflow: 'ellipsis',
                  ...g.layout.flexHorizontal,
                  ...g.layout.flexStart,
                  ...g.layout.alignCenter,
                  width: '100%'
                }}
              >
                <div style={{ ...styles.message.type }}>
                  {/*values.type*/ 'REPLY'}
                </div>
                <div
                  style={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                >
                  {values.title}
                </div>
              </div>
            </div>
          }
          bordered={true}
          extra={null}
        >
          <div style={styles.message.card}>
            {this.renderMessage(values.message)}
            <div style={styles.message.attachments}>
              {values.attachments.map((attachment, index) => (
                <div
                  key={attachment._id}
                  style={{
                    ...g.layout.flexHorizontal,
                    ...g.layout.flexStart,
                    ...g.layout.alignCenter,
                    marginBottom: g.global.baseline
                  }}
                >
                  {this.context.operations.checkPermission(
                    'uploads:download'
                  ) ? (
                    <Button
                      //shape="circle"
                      type="link"
                      style={{
                        marginRight: g.global.baseline
                      }}
                      onClick={async () => {
                        return await downloadFile(
                          attachment.s3_name,
                          this.context.s3Folder
                        );
                      }}
                    >
                      {this.renderAttachment(index, attachment)}
                    </Button>
                  ) : (
                    this.renderAttachment(index, attachment)
                  )}
                </div>
              ))}
            </div>
          </div>
        </Card>
      );
    }
  }

  renderAttachment = (index, attachment) => {
    return (
      <div key={index}>
        Attachment {index + 1}
        {' - '} {/* <-- robot! */}
        {attachment.name}
      </div>
    );
  };
}

Reply.contextType = MessageContext;

export default Reply;

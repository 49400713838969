import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';
import { validateHTTPMethod } from '../common/util';

const BE_ADDR = address;

export async function getPortfolios() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'cm/portfolios', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postPortfolio(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(BE_ADDR + 'cm/portfolios', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(data)
    });
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function deletePortfolio(id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/portfolios/' + id;

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postProcess(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(BE_ADDR + 'cm/cases', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(data)
    });
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function postImport(processes, folders) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(BE_ADDR + 'citius/import', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify({ processes, folders })
    });
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function postFolder(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(BE_ADDR + 'cm/folders', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(data)
    });
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function deleteFolder(folderId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/folders/' + folderId;

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateProcess(caseId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(
      BE_ADDR + 'cm/cases/' + encodeURIComponent(caseId),
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(data)
      }
    );
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function fetchProcesses(portfolioId = null) {
  let path = 'cm/cases';
  if (portfolioId) {
    path = `cm/portfolios/${portfolioId}/cases`;
  }

  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function fetchSingleProcess(caseId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + 'cm/cases/' + encodeURIComponent(caseId),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  return await handleResponse(response);
}

export async function fetchFolders(portfolioId = null) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/folders';

  if (portfolioId) {
    target += '/portfolio/' + portfolioId;
  }

  const response = await fetch(BE_ADDR + target, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateFolder(folderId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(
      BE_ADDR + 'cm/folders/' + encodeURIComponent(folderId),
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(data)
      }
    );
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function fetchFolder(folderId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + `cm/folders/${encodeURIComponent(folderId)}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  return await handleResponse(response);
}

export async function fetchProcessesByFolder(folderId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + `cm/folders/${encodeURIComponent(folderId)}/cases`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  return await handleResponse(response);
}

export async function processUpdate(method, caseId, data) {
  const validMethod = validateHTTPMethod(method, ['POST', 'PUT']);

  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  try {
    const response = await fetch(
      BE_ADDR + `cm/cases/${encodeURIComponent(caseId)}/messages`,
      {
        method: validMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          jwt: userInfo.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(data)
      }
    );
    return await handleResponse(response);
  } catch (err) {
    return await handleResponse(err);
  }
}

export async function deleteMessage(messageId, caseId = null) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/messages/' + messageId;
  if (caseId) {
    target += '/' + encodeURIComponent(caseId);
  }

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postMessage(message, caseId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/messages/' + encodeURIComponent(caseId) + '/';

  const response = await fetch(BE_ADDR + target, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(message)
  });
  return await handleResponse(response);
}

export async function putMessage(messageId, message) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'cm/messages/';
  target += messageId;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(message)
  });
  return await handleResponse(response);
}

import React from 'react';
import AppContext from '../context/AppContextBase';
import { Helmet } from 'react-helmet';
import {
  Layout,
  Table,
  Input,
  Button,
  Divider,
  message,
  Tooltip,
  Icon
} from 'antd';
import globalStyles from '../styles/global';
import { mrg } from '../common/util';
import { fetchSingleCITIUSCase, updateNotification } from '../network/citius';
import { Storage } from 'aws-amplify';
const { Content } = Layout;

const getColumns = (setStatus, NoteEditor, done, processID) => [
  {
    title: 'Lida?',
    dataIndex: 'unread',
    key: 'unread',
    render: (unread, record) =>
      unread ? (
        <Tooltip placement="right" title={'Por tratar'}>
          <Icon
            onClick={() => {
              setStatus(record.Referência, false);
            }}
            type="close-circle"
            style={{
              fontSize: globalStyles.global.baseline * 1.5,
              color: globalStyles.colors.feedback.error
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip placement="right" title={'Tratada'}>
          <Icon
            onClick={() => {
              setStatus(record.Referência, true);
            }}
            type={'check'}
            style={{
              fontSize: globalStyles.global.baseline * 1.5,
              color: globalStyles.colors.feedback.success
            }}
          />
        </Tooltip>
      )
  },
  {
    title: 'Data Elaboração',
    dataIndex: 'Data Elaboração',
    key: 'Data Elaboração'
  },
  {
    title: 'Acto',
    dataIndex: 'Acto',
    key: 'Acto'
  },
  {
    title: 'Tribunal',
    dataIndex: 'Tribunal',
    key: 'Tribunal'
  },
  {
    title: 'Un Orgânica',
    dataIndex: 'Un Orgânica',
    key: 'Un Orgânica'
  },
  {
    title: 'Processo',
    dataIndex: 'Processo',
    key: 'Processo'
  },
  {
    title: 'Espécie',
    dataIndex: 'Espécie',
    key: 'Espécie'
  },
  {
    title: 'Referência',
    dataIndex: 'Referência',
    key: 'Referência'
  },
  {
    title: 'Notas',
    dataIndex: 'notes',
    key: 'notes',
    width: 200,
    render: (notes, record) => (
      <NoteEditor
        processId={processID}
        notes={notes}
        done={done}
        record={record}
      />
    )
  },
  /*
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <span>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    )
  },*/
  {
    title: 'Acção',
    dataIndex: 'PDF',
    key: 'Acção',
    render: (pdf, record) => (
      <span>
        <Button
          disabled={pdf === 'error' ? true : false}
          onClick={async () => {
            try {
              const result = await Storage.get(pdf);
              window.location.href = result;
            } catch (err) {
              //this.setState({ error: true });
              console.log(err);
            }
          }}
        >
          {pdf === 'error' ? 'Not Possible to download' : ' Get PDF(s)'}
        </Button>
        <Divider type="vertical" /> {/*
        <a href="#!">Delete</a> */}
        {/* No delete nothing ever :D  */}
      </span>
    )
  }
];

class Citius extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      processHistory: []
    };

    this.setStatus = this.setStatus.bind(this);
    this.fetchCitiusCases = this.fetchCitiusCases.bind(this);
  }

  async fetchCitiusCases() {
    try {
      let cases = await fetchSingleCITIUSCase(
        encodeURIComponent(this.props.processID)
      );
      this.setState({
        processHistory: cases.data.history.map(item => {
          return { ...item, key: item.Referência };
        })
      });
    } catch {
      this.setState({
        processHistory: []
      });
      message.info('Não existe um processo no CITIUS para este caso.');
    }
  }

  async setStatus(ref, isUnread) {
    console.log('In Class.setStatus');
    const request = await updateNotification(this.props.processID, ref, {
      unread: isUnread
    });
    if (request && request.result === 'OK') {
      console.log('request success');
      await this.fetchCitiusCases();
      this.props.onSetStatus && (await this.props.onSetStatus());
    } else {
      message.error(
        'Erro de comunicação. Verifique a sua ligação à internet e/ou permissões de acesso'
      );
    }
  }

  async componentDidMount() {
    await this.fetchCitiusCases();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        <Layout>
          <Content
            style={mrg([
              globalStyles.layout.flexVertical,
              globalStyles.layout.flexStart,
              {
                minHeight:
                  'calc(100vh - ' + 7 * globalStyles.global.baseline + 'px)',
                width: '100%'
              }
            ])}
          >
            <Table
              columns={getColumns(
                this.setStatus,
                NotesEditor,
                this.fetchCitiusCases,
                this.props.processID
              )}
              scroll={{ x: '100%' }}
              dataSource={this.state.processHistory}
              style={{ width: '100%' }}
            />
          </Content>
        </Layout>
      </>
    );
  }
}

class NotesEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saving: false, value: this.props.record.notes };
    this.save = this.save.bind(this);
  }

  async save() {
    this.setState({ saving: true });
    const request = await updateNotification(
      this.props.processId,
      this.props.record.Referência,
      { notes: this.state.value }
    );
    if (request && request.result === 'OK') {
      message.success('Alterações guardadas');
      if (this.props.done) {
        this.props.done();
      }
    } else {
      console.log(request);
      message.error(
        <span>
          Erro na inserção. Verifique a sua ligação à internet e/ou permissões
          de acesso.
        </span>
      );
    }
    this.setState({ saving: false });
  }
  render() {
    return (
      <>
        <Input.TextArea
          onChange={e => this.setState({ value: e.target.value })}
          disabled={this.state.saving}
          value={this.state.value}
          style={{ marginBottom: globalStyles.global.baseline }}
        />
        <Button
          size="small"
          loading={this.state.saving}
          type="primary"
          onClick={() => {
            this.save();
          }}
        >
          Gravar
        </Button>
      </>
    );
  }
}

export default Citius;

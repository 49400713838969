import React from 'react';
import { Form, message, Input, Button } from 'antd';

import AppContext from '../context/AppContextBase';

import g from '../styles/global';
import { postClient } from '../network/sef';
import DetailedMessage from './util/DetailedMessage';

const styles = {
  form: {
    item: {
      marginBottom: 0,
      paddingRight: g.global.baseline
    },
    actions: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween
    },
    body: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      width: '100%',
      paddingLeft: g.global.baseline,
      paddingRight: g.global.baseline,
      backgroundColor: '#e0e0e0',
      borderRadius: '4px'
    }
  }
};

class CreateClient extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = { loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const response = await postClient({
          id: values.id.trim(),
          Nome: values.Nome.trim()
        });
        if (response && response.result === 'OK') {
          message.success('Client created Successfully');
          this.props.form.resetFields();
          if (this.props.onSuccess) {
            this.setState({ loading: false });
            return this.props.onSuccess(response.data);
          }
          this.setState({ loading: false });
          return response.data;
        } else {
          DetailedMessage.error(
            'Error saving data - ' + response.message,
            response
          );
        }
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <span style={{ marginRight: g.global.baseline }}>Create Client</span>
        <Form.Item style={styles.form.item}>
          {getFieldDecorator('id', {
            rules: [
              {
                required: true,
                message: 'Required'
              }
            ]
          })(<Input disabled={this.state.loading} placeholder="Unique ID" />)}
        </Form.Item>
        <Form.Item style={styles.form.item}>
          {getFieldDecorator('Nome', {
            rules: [
              {
                required: true,
                message: 'Required'
              }
            ]
          })(<Input disabled={this.state.loading} placeholder="Nome" />)}
        </Form.Item>

        <Button
          style={{ marginRight: g.global.baseline }}
          loading={this.state.loading}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
        <Button
          loading={this.state.loading}
          type="secondary"
          onClick={() => {
            this.props.form.resetFields();
            if (this.props.onCancel) {
              return this.props.onCancel();
            }
          }}
        >
          Cancel
        </Button>
      </Form>
    );
  }
}

const CreateClientForm = Form.create({ name: 'create_user' })(CreateClient);
export default CreateClientForm;

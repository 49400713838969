import React from 'react';

import {
  Card,
  Form,
  Select,
  Input,
  Button,
  Tooltip,
  Icon,
  Transfer,
  Switch
} from 'antd';
import en_US from 'antd/lib/locale/en_US';
import moment from 'moment';
import op from 'object-path';
import deburr from 'lodash/deburr';

import constants from '../../common/constants';
import NoData from '../util/NoData';
import MessageContext from '../../context/MessageContextBase';
import Loading from '../Loading';
import { mrg } from '../../common/util';
import g from '../../styles/global';

const { Option } = Select;
const { TextArea } = Input;

const styles = {
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  attachmentsControl: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    marginBottom: g.global.baseline * 2,
    width: '100%'
  },
  attachments: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    alignItems: 'flex-start',
    marginTop: g.global.baseline * 2,
    width: '100%'
  },
  card: {
    body: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      width: '100%',
      paddingBottom: g.global.baseline
    },
    header: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      width: '100%',
      paddingBottom: 0,
      marginBotton: 0
    }
  },
  type: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    backgroundColor: '#e8e8e8',
    marginRight: g.global.baseline,
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline,
    paddingTop: g.global.baseline * 0.5,
    paddingBottom: g.global.baseline * 0.5
  },
  form: {
    item: { marginBottom: 0, width: '100%' },
    actions: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween
    },
    extras: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    body: {
      width: '100%'
    }
  }
};

class MessageForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAttachmentChange = this.handleAttachmentChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.state = { loading: false };

    const messageTypes = this.context?.types || ['UPDATE', 'NOTE', 'TASK'];
    const visibilityOptions = this.context?.visibilityOptions || [
      'Internal',
      'External'
    ];
    const source = this.context?.userData.permissions.scope;

    this.defaultValues = this.props.updateValues
      ? this.props.updateValues
      : {
          type: messageTypes[0],
          title: '',
          visibility: this.context?.operations.checkPermission(
            'messages:selectVisibility'
          )
            ? visibilityOptions[0]
            : source,
          message: '',
          attachments: [],
          assignee: ''
        };

    this.state.availableAttachments = this.context?.attachments;
    this.state.attachments = this.defaultValues.attachments;
    this.state.visibility = this.defaultValues.visibility;
    this.state.showAttachments = !!this.defaultValues?.attachments?.length;

    this.state.messageType = this.defaultValues.type;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visibility !== this.state.visibility) {
      this.setState(state => {
        const userNumericScope =
          constants.scopes[this.state.visibility]?.numeric;
        let allowedScopes = constants.scopes.lte(userNumericScope);
        allowedScopes = allowedScopes?.map(scope => scope.key);

        const filteredAvailableAttachments = this.context?.attachments.filter(
          attachment => allowedScopes.includes(attachment.visibility)
        );

        const filteredAttachments = this.state.attachments.filter?.(
          attachment => allowedScopes.includes(attachment.visibility)
        );
        return {
          availableAttachments: filteredAvailableAttachments,
          attachments: filteredAttachments
        };
      });
    }
  }

  resetFields() {
    this.props.form.resetFields([
      'type',
      'visibility',
      'message',
      'title',
      'attachments'
    ]);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values.attachments = this.state.attachments?.map(
          attachment => attachment._id
        );
        if (values.assignee) {
          // Antd again.. Our <Option> key must be an empty string so antd does not complain.
          // But our DB schema has ObjectId, so we can not send an empty string. Always send NULL for falsy values:
          values.assignee = values.assignee.key ? values.assignee.key : null;
        }

        //Only set unread if type is TASK
        if (this.props.updateValues) {
          values.unread = this.props.updateValues.unread;
          values.create_date = this.props.updateValues.create_date;
        } else {
          values.unread = values.type === 'TASK';
          values.owner = this.context?.userData.id;
          values.create_date = moment();
        }

        const isUpdate = !!this.props.updateValues;
        try {
          if (isUpdate) {
            await this.context?.operations?.onUpdate?.(values);
          } else {
            await this.context?.operations?.onSave?.(values);
          }

          await this.context?.operations?.onSaveSuccess?.();
          this.setState({ loading: false });

          if (this.props.hide) {
            await this.props.hide();
            this.setState({ loading: false });
            return;
          }
        } catch (err) {
          console.error(err);
          await this.context?.operations?.onSaveError();
        }

        if (!this.props.updateValues) {
          this.resetFields();
          this.setState({ loading: false });

          await this.props?.hide?.();
        }
      }
      this.setState({ loading: false });
    });
  };

  filterAttachmentOption = (inputValue, option) => {
    return (
      deburr(option?.name)
        .toLowerCase?.()
        .indexOf(deburr(inputValue).toLowerCase?.()) !== -1
    );
  };

  handleAttachmentChange = (targetKeys, direction) => {
    const result = this.context.attachments.filter(item =>
      targetKeys.includes(item._id)
    );
    this.setState({ attachments: result });
  };

  handleAttachmentSearch = (dir, value) => {
    //nothing for now
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const messageTypes = this.context?.types || ['UPDATE', 'NOTE', 'TASK'];
    const visibilityOptions = this.context?.visibilityOptions || [
      'Internal',
      'External'
    ];
    const source = this.context.userData.permissions.scope;

    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <Card
          bodyStyle={styles.card.body}
          headStyle={{}}
          title={
            <div style={styles.card.header}>
              {this.props.updateValues && (
                <Form.Item style={{ display: 'none' }}>
                  {getFieldDecorator('_id', {
                    initialValue: this.props.updateValues._id
                  })(<Input type="hidden" />)}
                </Form.Item>
              )}
              <Form.Item>
                {getFieldDecorator('type', {
                  initialValue: this.state.messageType,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a type'
                    }
                  ]
                })(
                  <Select
                    disabled={this.state.loading}
                    style={{ width: 120 }}
                    placeholder="Tipo"
                    onChange={value => {
                      this.setState({ messageType: value });
                      if (value !== 'TASK') {
                        this.props.form.setFieldsValue({
                          assignee: { key: '', value: null }
                        });
                      }
                    }}
                  >
                    {messageTypes.map((option, index) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('title', {
                  initialValue: this.defaultValues.title,
                  rules: [
                    {
                      required: true,
                      message: 'Insert Title',
                      whitespace: true
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="Title"
                    style={{
                      width: 360,
                      marginLeft: g.global.baseline
                    }}
                  />
                )}
              </Form.Item>
            </div>
          }
          bordered={true}
          extra={
            <div style={styles.form.extras}>
              <Form.Item
                style={{
                  display:
                    this.state.messageType === 'TASK' ? 'initial' : 'none'
                }}
              >
                {!(this.context?.enableAssignment === false) &&
                  (this.context?.users?.length ? (
                    getFieldDecorator('assignee', {
                      initialValue: {
                        key: op.get(this.defaultValues, 'assignee._id', '')
                      },
                      rules: [
                        {
                          required: true,
                          message: 'Select an assignee'
                        }
                      ]
                    })(
                      <Select
                        labelInValue
                        showSearch
                        optionFilterProp="children"
                        style={{ width: 180 }}
                        disabled={this.state.loading}
                        placeholder="Assignee"
                      >
                        <Option key={''} value={''}>
                          <b>Unassigned</b>
                        </Option>
                        {this.context?.users?.map(user => (
                          <Option key={user._id} value={user._id}>
                            {user.name}
                          </Option>
                        ))}
                      </Select>
                    )
                  ) : (
                    <Tooltip
                      placement="top"
                      title={
                        'Fetching users from server is taking a bit more than usual. If this persists check your internet connection and try refreshing this page.'
                      }
                    >
                      <Icon
                        type="sync"
                        spin={true}
                        style={{
                          fontSize: g.global.baseline * 1.5,
                          color: g.colors.feedback.error
                        }}
                      />
                    </Tooltip>
                  ))}
              </Form.Item>
              <Form.Item style={{ marginLeft: g.global.baseline }}>
                {getFieldDecorator('visibility', {
                  initialValue: this.defaultValues.visibility,
                  rules: [
                    {
                      required: true,
                      message: 'Select Visibility'
                    }
                  ]
                })(
                  <Select
                    style={
                      this.context?.operations?.checkPermission(
                        'messages:selectVisibility'
                      )
                        ? { width: 120 }
                        : { width: 120, display: 'none' }
                    }
                    disabled={this.state.loading}
                    placeholder="Visibility"
                    onChange={value => {
                      this.setState({ visibility: value });
                    }}
                  >
                    {visibilityOptions?.map(option => (
                      <Option
                        key={option?.toLowerCase()}
                        value={option?.toLowerCase()}
                      >
                        {/* TODO: translate in a smarter way */}
                        {option.toLowerCase() === 'internal'
                          ? 'Internal'
                          : 'External'}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item style={{ display: 'none' }}>
                {getFieldDecorator('source', {
                  initialValue: source,
                  rules: [
                    {
                      required: true,
                      message:
                        'An unknown error happened. Please refresh this page or try again later.'
                    }
                  ]
                })(<Input type="hidden" />)}
              </Form.Item>
            </div>
          }
        >
          <div style={styles.card.body}>
            <Form.Item style={mrg([styles.form.item, { width: '100%' }])}>
              {getFieldDecorator('message', {
                initialValue: this.defaultValues.message,
                rules: [
                  {
                    message: 'Insert a Message',
                    whitespace: true,
                    required: true
                  }
                ]
              })(
                <TextArea
                  disabled={this.state.loading}
                  placeholder="Message"
                  style={{ width: '100%' }}
                  rows={4}
                />
              )}
            </Form.Item>
            <div style={styles.attachments}>
              <div style={styles.attachmentsControl}>
                <span style={{ marginRight: g.global.baseline }}>
                  Attachments
                </span>
                <Switch
                  checked={this.state.showAttachments}
                  disabled={!!this.state.attachments?.length}
                  onChange={(checked, event) => {
                    this.setState({ showAttachments: checked });
                    if (!checked) {
                      this.setState({ attachments: [] });
                    }
                  }}
                />
              </div>
              {this.state.showAttachments &&
                (this.state.availableAttachments?.length ? (
                  <div style={mrg([styles.form.item, { width: '100%' }])}>
                    <Transfer
                      locale={en_US}
                      listStyle={{
                        width: 'calc(50% - 20px)',
                        height: 300
                      }}
                      rowKey={record => record._id}
                      dataSource={this.state.availableAttachments}
                      showSearch
                      targetKeys={this.state.attachments.map(item => item._id)}
                      filterOption={this.filterAttachmentOption}
                      onChange={this.handleAttachmentChange}
                      onSearch={this.handleAttachmentSearch}
                      render={item => ({ value: item, label: item.name })}
                    />
                  </div>
                ) : (
                  <NoData text="This entity has no files. Use the Document Uploads section to upload files."></NoData>
                ))}
            </div>
            <div style={styles.form.actions}>
              <Button
                style={{
                  marginBottom: g.global.baseline,
                  marginTop: g.global.baseline
                }}
                disabled={this.state.loading}
                onClick={async () => {
                  if (this.context?.operations?.hide) {
                    await this.context.operations.hide();
                  } else if (this.props?.hide) {
                    await this.props.hide();
                  }
                }}
                type="dashed"
              >
                Cancel
              </Button>
              <Form.Item
                style={mrg([
                  styles.form.item,
                  { width: '100%', marginBottom: 0 },
                  g.layout.flexHorizontal,
                  g.layout.flexEnd
                ])}
              >
                {this.state.loading && (
                  <Loading
                    style={{
                      margin: g.global.baseline,
                      width: g.global.baseline * 2,
                      height: g.global.baseline * 2
                    }}
                  />
                )}
                <Button
                  style={{
                    marginBottom: g.global.baseline,
                    marginTop: g.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="secondary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Card>
      </Form>
    );
  }
}

MessageForm.contextType = MessageContext;

const messageForm = Form.create({ name: 'message_board' })(MessageForm);

export default messageForm;

import React from 'react';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet';
import { Layout, Tooltip, Icon, message } from 'antd';
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  SortDirection
} from 'react-virtualized';
import sort from 'fast-sort';

import globalStyles from '../../styles/global';
import { mrg, tryParseDate } from '../../common/util';

import { getImports, putImport } from '../../network/sef';

import EditableField from '../../components/util/EditableField';
import DetailedMessage from '../../components/util/DetailedMessage';
import NoData from '../../components/util/NoData';
import Loading from '../../components/Loading';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    overflowX: 'scroll',
    paddingLeft: globalStyles.global.baseline * 2,
    paddingRight: globalStyles.global.baseline * 2
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  info: {
    body: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter
    },
    item: {
      marginBottom: globalStyles.global.baseline
    },
    icon: { marginRight: globalStyles.global.baseline / 2 }
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },

  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '40%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '60%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline,
    paddingBottom: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline / 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  },
  table: {
    body: {},
    evenRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0'
    },
    oddRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e0e0e0'
    },
    descendantRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: '#e0f0ff'
    },
    headerRow: {
      borderLeft: '1px solid #e0e0e0',
      textTransform: 'none',
      backgroundColor: '#c0c0ca',
      fontSize: globalStyles.global.baseline,
      fontWeight: 500,
      height: globalStyles.global.baseline * 4
    },
    column: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRight: '1px solid #e0e0e0'
    },
    lastColumn: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    checkboxLabel: {
      marginLeft: '5rem'
    },
    noRows: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd'
    }
  }
};

const baseColumnWidth = globalStyles.global.baseline * 4;

const labelMap = {
  id: 'Passport',
  name: 'Name',
  Nome: 'Name',
  'Data Nascimento': 'Birth Date',
  birth_date: 'Birth Date',
  Familia: 'Family',
  Nacionalidade: 'Citizenship'
};
const mapHeaderLabel = label => {
  if (labelMap[label]) {
    return labelMap[label];
  }
  return label;
};

const helpContent = (
  <div style={styles.info.body}>
    <span style={styles.info.item}>
      Imported data that could not be merged with a client is displayed here.
    </span>
    <span>
      IDs should be changed to merge the imported data with existing ARI
      clients. If a client with a matching ID is found, the data will be added
      to the client and removed from this list.
    </span>
  </div>
);

class Pending extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      sortBy: 'unread',
      sortedList: null,
      sortDirection: SortDirection.DESC,
      showDescendants: false
    };

    this.tableRef = null;

    this.columnHeader = this.columnHeader.bind(this);
    this.sort = this.sort.bind(this);
    this.sortList = this.sortList.bind(this);
    this.search = this.search.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSaveError = this.onSaveError.bind(this);
  }

  async componentDidMount() {
    if (!this.state.sortedList) {
      await this.refresh();
    }
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showDescendants !== prevState.showDescendants) {
      await this.refresh();
      await this.sort({
        sortBy: this.state.sortBy,
        sortDirection: this.state.sortDirection
      });
      if (this.tableRef) {
        await this.tableRef.recomputeRowHeights();
      }
    }
  }

  async onSaveSuccess() {
    await this.refresh();
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
    return message.success(
      'Values saved successfully. Data merged with client.'
    );
  }
  onSaveError(err) {
    return DetailedMessage.error('Error saving values', err);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>CM - Processos</title>
          {/* antd overrides */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${globalStyles.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${globalStyles.global.baseline}px;
              line-height: 1;
            }
          `}</style>
        </Helmet>
        <Content
          style={mrg([
            styles.fullWidth,
            globalStyles.layout.flexVertical,
            globalStyles.layout.flexStart,
            styles.content
          ])}
        >
          <div
            style={mrg([
              globalStyles.layout.flexVertical,
              globalStyles.layout.flexStart,
              styles.full,
              {
                alignItems: 'flex-start',
                maxHeight: '70vh'
              }
            ])}
          >
            <div style={styles.pageHeaderContainer}>
              <div style={styles.pageHeaderContainerLeft}>
                <span style={styles.pageHeaderBox}>
                  <Tooltip placement="rightTop" title={helpContent}>
                    <Icon
                      style={styles.menuIcon}
                      type={'question-circle'}
                    ></Icon>
                  </Tooltip>
                  {/* <span style={{ marginRight: globalStyles.global.baseline }}>
                    Pesquisar
                  </span>
                  <Input
                    onChange={e => {
                      this.setState({ search: e.target.value });

                      if (!e.target.value) {
                        // reset table
                        this.search();
                      }
                    }}
                    disabled={this.state.searching || !this.state.sortedList}
                    value={this.state.search}
                    onPressEnter={this.search}
                  /> */}
                </span>
              </div>
            </div>
            {this.state.sortedList ? (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={ref => (this.tableRef = ref)}
                    style={styles.table.body}
                    height={height}
                    rowHeight={this.rowHeight}
                    rowStyle={({ index }) => this.rowStyle({ index })}
                    noRowsRenderer={NoData}
                    rowGetter={({ index }) => {
                      return this.state.sortedList[index];
                    }}
                    rowCount={this.state.sortedList.length}
                    sort={this.sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    width={width}
                  >
                    <Column
                      width={baseColumnWidth}
                      flexGrow={3}
                      dataKey="id"
                      headerRenderer={this.columnHeader}
                      style={styles.table.column}
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <EditableField
                            innerStyle={{ marginBottom: 0 }}
                            span={24}
                            key={rowData._id}
                            editable
                            data={cellData}
                            title={''}
                            value={cellData}
                            onSave={async value => {
                              //EDIT2 - dont send if empty
                              //EDIT BE will need the old ID
                              if (value) {
                                return await putImport({
                                  id: value,
                                  oldId: cellData
                                });
                              }
                            }}
                            onSaveSuccess={async result =>
                              await this.onSaveSuccess(result)
                            }
                            onSaveError={async err =>
                              await this.onSaveError(err)
                            }
                          ></EditableField>
                        );
                      }}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={2}
                      dataKey="name"
                      disableSort={false}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="juris"
                      disableSort={false}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="entry_date"
                      disableSort={false}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="birth_date"
                      disableSort={false}
                      cellDataGetter={({ dataKey, rowData }) => {
                        return rowData[dataKey]
                          ? tryParseDate(rowData[dataKey]).format('YYYY-MM-DD')
                          : 'Invalid';
                      }}
                      cellRenderer={({ cellData }) => cellData}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                  </Table>
                )}
              </AutoSizer>
            ) : (
              <Loading></Loading>
            )}
          </div>
        </Content>
      </>
    );
  }
  columnHeader({ dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {
          <span
            onClick={() =>
              this.setState((state, props) => ({
                sortBy: dataKey
              }))
            }
          >
            {mapHeaderLabel(dataKey).toUpperCase()}
          </span>
        }
        {sortBy === dataKey && (
          <SortIndicator
            onClick={() =>
              this.setState((state, props) => ({
                sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
              }))
            }
            sortDirection={sortDirection}
          />
        )}
      </div>
    );
  }

  async refresh() {
    this.setState({ loading: true });
    let imports = await getImports();

    if (imports && imports.result === 'OK' && imports.data.docs.length > 0) {
      this.setState({
        sortedList: imports.data.docs
      });
    } else if (imports && imports.result === 'OK') {
      this.setState({
        sortedList: null
      });
    } else {
      DetailedMessage.error('Error loading Data', imports);
      this.setState({
        sortedList: null
      });
    }
    this.setState({ loading: false });
  }

  rowStyle({ index }) {
    if (index === -1) {
      return styles.table.headerRow;
    }
    if (index % 2 === 0) {
      return styles.table.evenRow;
    } else {
      return styles.table.oddRow;
    }
  }

  rowHeight({ index }) {
    return globalStyles.global.baseline * 3.5;
  }

  async search() {
    this.setState({ searching: true });
    if (!this.state.sortedList || this.state.searching || !this.state.search) {
      this.setState({ searching: false, sortedList: this.clientCache });
      return;
    }

    const clients = this.clientCache.filter(
      kase => JSON.stringify(kase).indexOf(this.state.search) !== -1
    );
    this.setState({ sortedList: clients, searching: false });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedClients });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'unread') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        case SortDirection.DESC:
          return toSort.desc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        default:
          return list;
      }
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(sortBy);
      case SortDirection.DESC:
        return toSort.desc(sortBy);
      default:
        return list;
    }
  }
}

export default Pending;

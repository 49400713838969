import React from 'react';
import { Column } from 'react-virtualized';
import EditableField from '../../../../../components/util/EditableField';

import { tableStyles, translateColumnHeader } from '../../../tables/common';
import { message } from 'antd';

// title: { type: String, default: '' },
// date: { type: Date, default: null }

const documentsPreparedOptions = client => {
  let options = [
    {
      key: 'Tax Representation Contract x2',
      value: 'Tax Representation Contract x2'
    },
    { key: 'POA SEF & Tax', value: 'POA SEF & Tax' },
    { key: 'Tax declaration', value: 'Tax declaration' }
  ];

  if (!client.parentId) {
    options = options.concat([
      { key: 'Engagement Letter x2', value: 'Engagement Letter x2' },
      {
        key: 'SEF Declaration (Bona Fide)',
        value: 'SEF Declaration (Bona Fide)'
      },
      { key: 'General POA', value: 'General POA' },
      { key: 'Authentication Form', value: 'Authentication Form' },
      { key: 'Reservation Contract x3', value: 'Reservation Contract x3' },
      { key: 'CPCV x3', value: 'CPCV x3' },
      { key: 'Signatures Recognition', value: 'Signatures Recognition' },
      { key: 'Works Contract x3', value: 'Works Contract x3' },
      { key: 'Due Diligence (Report)', value: 'Due Diligence (Report)' }
    ]);

    options = options.concat([
      {
        key: 'Certification of Copies x2',
        value: 'Certification of Copies x2'
      },
      {
        key: 'Certification of Translation x2',
        value: 'Certification of Translation x2'
      },
      { key: 'SEF Forms', value: 'SEF Forms' },
      { key: 'Follow Up E-mail', value: 'Follow Up E-mail' },
      { key: 'Outros', value: 'Outros' }
    ]);
  }
  return options;
};

const DocumentsPreparedColumns = (onSave, extras) => {
  const editable = extras ? !(extras.editable === false) : true;
  return [
    <Column
      width={80}
      flexGrow={2}
      dataKey="title"
      key="type"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            minWidthBefore={0}
            innerStyle={{ marginBottom: 0 }}
            span={24}
            type={EditableField.Types.select}
            options={documentsPreparedOptions(extras.client)}
            key={cellData}
            editable={editable}
            data={rowData}
            label="Name"
            hideElementBefore={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'title', index: rowIndex })
            }
            hideIcon={true}
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />,
    <Column
      width={20}
      flexGrow={1}
      dataKey="date"
      key="date"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            span={24}
            type={EditableField.Types.date}
            options={{ showTime: false }}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'date', index: rowIndex })
            }
            hideIcon={true}
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />
  ];
};

export default DocumentsPreparedColumns;

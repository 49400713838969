import React from 'react';
import { Button, Form, Input } from 'antd';

import { postRelative } from '../network/sef';
import g from '../styles/global';

const styles = {
  form: {
    item: {
      width: 'auto',
      marginBottom: 0
    },
    actions: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween
    },
    body: {
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexBetween,
      ...g.global.alignStart,
      padding: g.global.baseline
    }
  },
  row: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.global.alignCenter,
    width: '100%'
  },
  col: {
    ...g.layout.flexVertical,
    ...g.global.alignCenter,
    justifyContent: 'flex-start',
    height: '100%'
  }
};

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

class CreateRelative extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmit(e) {
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const response = await postRelative(this.props.parentId, {
          id: values.id.trim(),
          Nome: values.Nome.trim()
        });
        if (response && response.result === 'OK') {
          this.props.form.resetFields();
          if (this.props.onSuccess) {
            this.setState({ loading: false });
            return await this.props.onSuccess?.(response.data);
          }
          this.setState({ loading: false });
          return response.data;
        } else {
          return await this.props.onError?.(response);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <div style={styles.row}>
          <span style={{ marginRight: g.global.baseline }}>
            <h3>Create Client</h3>
          </span>
        </div>
        <div style={{ ...styles.col, width: '100%' }}>
          <Form.Item style={styles.form.item}>
            {getFieldDecorator('id', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                },
                { min: 2, message: 'Minimum 2 characters' }
              ]
            })(<Input disabled={this.state.loading} placeholder="ID" />)}
          </Form.Item>
          <Form.Item style={styles.form.item}>
            {getFieldDecorator('Nome', {
              rules: [
                {
                  required: true,
                  message: 'Required'
                },
                { min: 2, message: 'Minimum 2 characters' }
              ]
            })(<Input disabled={this.state.loading} placeholder="Name" />)}
          </Form.Item>
        </div>

        <div style={{ ...styles.row, ...g.layout.flexEnd }}>
          <Form.Item style={styles.form.item}>
            <Button
              style={{ marginRight: g.global.baseline }}
              disabled={hasErrors(getFieldsError() || this.state.loading)}
              type="primary"
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Form.Item>
          <Button
            loading={this.state.loading}
            type="secondary"
            onClick={async () => {
              console.log('oncancel');
              await this.props.form.resetFields();
              return await this.props?.onCancel?.();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

const CreateRelativeForm = Form.create({ name: 'create_relative' })(
  CreateRelative
);

export default CreateRelativeForm;

import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Tooltip, Input } from 'antd';
import Loading from '../components/Loading';

import Helmet from 'react-helmet';

import globalStyles from '../styles/global';

const { TextArea } = Input;

const styles = {
  container: {
    width: '100%'
  },

  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    textTransform: 'none'
  },
  buttonBox: {
    marginBottom: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  editor: {
    wrapper: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      marginBottom: globalStyles.global.baseline
    },
    main: {
      backgroundColor: '#fff',
      width: '100%',
      padding: globalStyles.global.baseline,
      overflow: 'visible'
    },
    toolbar: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      marginBottom: 1
    },
    result: {
      padding: globalStyles.global.baseline,
      width: '100%',
      marginBottom: globalStyles.global.baseline,
      minHeight: '10em',
      backgroundColor: '#fff',
      color: '#444'
    }
  }
};

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'colorPicker',
    'textAlign',
    'list',
    'link',
    'history'
  ],
  inline: {
    options: ['bold', 'italic']
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
  },
  link: {
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink']
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent']
  }
};

export default class EditorConvertToHTML extends React.Component {
  constructor(props) {
    super(props);

    let editorState;
    if (this.props.template) {
      const blocksFromHtml = htmlToDraft(this.props.template.html_body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      showPlainText: false,
      showHtml: false,
      editorState: editorState,
      saving: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.template !== prevProps.template) {
      let editorState;
      if (this.props.template) {
        const blocksFromHtml = htmlToDraft(this.props.template.html_body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        editorState = EditorState.createWithContent(contentState);
      } else {
        editorState = EditorState.createEmpty();
      }
      this.setState({ editorState: editorState });
    }
  }

  onEditorStateChange: Function = editorState => {
    this.setState({
      editorState
    });
    console.log('editor state: ', editorState.getBlockTree());
  };

  async save() {
    this.setState({ saving: true });
    const html = await draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    const plain = await this.state.editorState
      .getCurrentContent()
      .getPlainText();

    const template = await JSON.parse(JSON.stringify(this.props.template));

    template.html_body = html;
    template.raw_body = plain;

    const result = this.props.onSave && (await this.props.onSave(template));

    this.setState({ saving: false });
    return result;
  }

  render() {
    const { editorState } = this.state;
    return (
      <div style={styles.container}>
        <Helmet>
          {/* wysiwyg editor overrides - I should get shot for this */}
          <style type="text/css">{`.rdw-editor-toolbar > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin: 0 ${globalStyles.global.baseline * 2};
            }
          `}</style>
        </Helmet>
        {!this.state.saving ? (
          <>
            <Editor
              wrapperStyle={styles.editor.wrapper}
              editorStyle={styles.editor.main}
              toolbarStyle={styles.editor.toolbar}
              style={styles.editor}
              toolbar={toolbarOptions}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
            />
            <div style={styles.buttonBox}>
              <Button
                style={{ marginRight: globalStyles.global.baseline }}
                type="primary"
                disabled={!this.props.template}
                onClick={() => this.save()}
              >
                Guardar
              </Button>
              <Tooltip
                title={
                  'Texto apresentado aos utilizadores com browsers ou clientes de e-mail desactualizados'
                }
              >
                <Button
                  type="secondary"
                  style={{ marginRight: globalStyles.global.baseline }}
                  onClick={() =>
                    this.setState({ showPlainText: !this.state.showPlainText })
                  }
                >
                  {this.state.showPlainText
                    ? 'Esconder texto simples'
                    : 'Mostrar texto simples'}
                </Button>
              </Tooltip>
              <Tooltip title={'Código HTML gerado pelo template'}>
                <Button
                  type="dashed"
                  style={{ marginRight: globalStyles.global.baseline }}
                  onClick={() =>
                    this.setState({ showHtml: !this.state.showHtml })
                  }
                >
                  {this.state.showHtml ? 'Esconder código' : 'Mostrar código'}
                </Button>
              </Tooltip>
            </div>
            <div style={{ marginBottom: globalStyles.global.baseline }}>
              {this.state.showHtml && (
                <>
                  <span style={styles.pageHeaderContent}>HTML</span>
                  <TextArea
                    disabled
                    autosize={{ minRows: 2 }}
                    value={draftToHtml(
                      convertToRaw(editorState.getCurrentContent())
                    )}
                    style={styles.editor.result}
                  />
                </>
              )}
            </div>
            <div>
              {this.state.showPlainText && (
                <>
                  <span style={styles.pageHeaderContent}>Texto simples</span>
                  <br />
                  <span>
                    Apresentado aos utilizadores com clientes de e-mail que não
                    suportam HTML
                  </span>
                  <TextArea
                    disabled
                    autosize={{ minRows: 2 }}
                    value={editorState.getCurrentContent().getPlainText()}
                    style={styles.editor.result}
                  />
                  <Button
                    type="dashed"
                    onClick={() =>
                      this.setState({
                        showPlainText: !this.state.showPlainText
                      })
                    }
                  >
                    Esconder texto simples
                  </Button>
                </>
              )}
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

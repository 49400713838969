import React from 'react';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { Layout, message, Button } from 'antd';
import { Parser } from 'json2csv';

import DossierTable, {
  scopes as dossierScopes
} from '../../components/DossierTable';
import InsertDossier from '../../components/InsertDossier';
import { fetchDossiers, postDossier } from '../../network/fin';
import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline
  },
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  pageHeaderButton: {
    marginLeft: globalStyles.global.baseline,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  }
};

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      dossiers: [],
      routeData: this.props.location.state,
      show: false
    };
    this.refreshDossiers = this.refreshDossiers.bind(this);
    this.generateCsv = this.generateCsv.bind(this);
  }

  async componentDidMount() {
    await this.refreshDossiers();
  }

  async refreshDossiers() {
    let dossiers = await fetchDossiers();
    if (dossiers && dossiers.result === 'OK' && dossiers.data.docs.length > 0) {
      for (let dossier in dossiers.data.docs) {
        const curr = dossiers.data.docs[dossier];
        switch (curr.language) {
          case 'português':
            curr.language = 'PT';
            break;
          case 'inglês':
            curr.language = 'EN';
            break;
          default:
            break;
        }
        if (curr && curr.email_contacts)
          curr.email_contacts = curr.email_contacts.replace(',', ', ');
      }
      this.setState({
        dossiers: dossiers.data.docs
      });
    }
  }

  generateCsv() {
    const fields = [
      { label: 'Dossier', value: 'dossier', default: 'NULL' },
      { label: 'Enviar', value: 'info_to_send', default: 'NULL' },
      { label: 'Método', value: 'send_method', default: 'NULL' },
      { label: 'Língua', value: 'language', default: 'NULL' },
      { label: 'Contactos', value: 'email_contacts', default: 'NULL' },
      { label: 'Notas', value: 'notes', default: 'NULL' }
    ];
    const opts = { fields };

    try {
      const parser = new Parser(opts);
      const csv = parser.parse(this.state.dossiers);
      console.log(csv);

      var blob = new Blob([csv], { type: 'text/csv' });

      var a = document.createElement('a');
      a.download = 'report.csv';
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>FIN - Dossiers</title>
        </Helmet>

        <Route
          path={'/fin/dossiers'}
          render={() => (
            <Content
              id="dossiers"
              style={mrg([
                styles.fullWidth,
                globalStyles.layout.flexVertical,
                globalStyles.layout.flexStart,
                { maxHeight: '100%', overflowY: 'hidden' },
                styles.content
              ])}
            >
              <div
                style={mrg([
                  globalStyles.layout.flexVertical,
                  globalStyles.layout.flexStart,
                  globalStyles.layout.alignStart,
                  styles.fullWidth,
                  { height: '100%', overflowY: 'scroll' }
                ])}
              >
                <div style={styles.pageHeaderContainer}>
                  <span style={styles.pageHeaderContent}>Dossiers</span>

                  <Button
                    type="primary"
                    ghost
                    style={styles.pageHeaderButton}
                    onClick={this.generateCsv}
                  >
                    Descarregar Listagem
                  </Button>
                  {!this.state.show && (
                    <Button
                      style={styles.pageHeaderButton}
                      ghost
                      type="primary"
                      onClick={() => {
                        this.setState({ show: true });
                      }}
                    >
                      Inserir dossiers
                    </Button>
                  )}
                </div>
                {this.state.show && (
                  <InsertDossier
                    action={dossier => postDossier(dossier)}
                    done={() => {
                      this.refreshDossiers();
                      message.success(<span>Sucesso</span>);
                    }}
                  />
                )}
                <DossierTable
                  scope={dossierScopes.DEFAULT}
                  dossiers={this.state.dossiers}
                />
              </div>
            </Content>
          )}
        />
      </>
    );
  }
}

export default Home;

import { putClient } from '../../../network/sef';

export const save = async (fieldPath, data, value) => {
  const dataToSave = {
    id: data.id,
    parentId: data.parentId,
    _id: data._id,
    kind: data.kind,
    [fieldPath]: value
  };
  dataToSave.editedField = fieldPath;

  const request = await putClient(dataToSave);
  if (!request || request.result !== 'OK') {
    throw request;
  } else {
    return request;
  }
};

import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

//eslint-disable-next-line
const BE_ADDR = address;

export async function getClients() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/clients', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postClient(client) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/clients', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
}

export async function postRelative(parentId, data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/clients/' + parentId, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

const getPath = client => {
  let target;
  if (client.parentId) {
    target = '/' + client.parentId + '/' + client._id;
  } else {
    target = '/' + client._id;
  }
  return target;
};

export async function putClient(client) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const target = getPath(client);

  const response = await fetch(BE_ADDR + 'sef/clients' + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(client)
  });
  return await handleResponse(response);
}

export async function putClientArray(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = getPath(data);

  target += '/array/' + data.arrayName + '/' + data.itemData._id;

  const response = await fetch(BE_ADDR + 'sef/clients' + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data.itemData)
  });
  return await handleResponse(response);
}

export async function getClient(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + 'sef/clients/' + encodeURIComponent(_id),
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      }
    }
  );
  return await handleResponse(response);
}

export async function deleteMessage(
  messageId,
  parentId = null,
  descendantId = null
) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'sef/messages/' + messageId;
  if (parentId) {
    target += '/' + parentId;
    if (descendantId) {
      target += '/' + descendantId;
    }
  }

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postMessage(entry, parentId, descendantId = null) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'sef/messages/' + parentId + '/';
  if (descendantId) {
    target += descendantId + '/';
  }

  const response = await fetch(BE_ADDR + target, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(entry)
  });
  return await handleResponse(response);
}

export async function putMessage(messageId, message) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'sef/messages/';
  target += messageId;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(message)
  });
  return await handleResponse(response);
}

export async function deleteReply(messageId, replyId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'sef/messages/replies/' + messageId + '/' + replyId;

  const response = await fetch(BE_ADDR + target, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postReply(messageId, message) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = 'sef/messages/replies/' + messageId + '/';

  const response = await fetch(BE_ADDR + target, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(message)
  });
  return await handleResponse(response);
}

export async function putReply(messageId, replyId, message) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  let target = `sef/messages/replies/${messageId}/${replyId}`;

  const response = await fetch(BE_ADDR + target, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(message)
  });
  return await handleResponse(response);
}

export async function postProcess(entry) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/processes', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(entry)
  });
  return await handleResponse(response);
}

export async function putProcess(process) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/processes/' + process._id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(process)
  });
  return await handleResponse(response);
}

export async function deleteProcess(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/processes/' + _id, {
    method: 'delete',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postPipelineStage(_id, stageData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/processes/' + _id + '/pipeline', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(stageData)
  });
  return await handleResponse(response);
}

export async function getImports() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/imports', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function putImport(data) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }
  //encoder and old Id missing
  const response = await fetch(
    BE_ADDR + 'sef/imports/' + encodeURIComponent(data.oldId),
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(data)
    }
  );
  return await handleResponse(response);
}

export async function getIDAccounting(clientId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/accounting/' + clientId, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postIDAccounting(attributes) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/accounting', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(attributes)
  });
  return await handleResponse(response);
}

export async function putIDAccounting(attributes) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/accounting/' + attributes._id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(attributes)
  });
  return await handleResponse(response);
}

export async function deleteIDAccounting(entryId) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'sef/accounting/' + entryId, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import AppContext from '../../context/AppContextBase';
import { NotificationContextConsumer } from '../../context/NotificationContext';

//import Minutes from './Minutes';
import Minutes from './Minutes_updated';

import {
  Dropdown,
  Menu,
  Layout,
  Tag,
  Button,
  Input,
  Icon,
  Switch,
  Badge,
  Tooltip,
  Modal
} from 'antd';
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  SortDirection
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import deburr from 'lodash/deburr';
import sort from 'fast-sort';
import op from 'object-path';
import flatten from 'flat';

import styles from './Clients.styles.js';

import CreateClientForm from '../../components/CreateSefClient';
import Export from '../../components/export/Export';
import SortSelector from '../../components/table/SortSelector';
import NoData from '../../components/util/NoData';
import Loading from '../../components/Loading';
import g from '../../styles/global';
import { mrg } from '../../common/util';

//import Minute from './Minutes';

const { Content } = Layout;

const baseColumnWidth = g.global.baseline * 4;

const labelMap = {
  id: 'Passport',
  name: 'Name',
  Nome: 'Name',
  'Data Nascimento': 'Birth Date',
  birth_date: 'Birth Date',
  'excel.juris': 'JVRIS',
  Familia: 'Family',
  Nacionalidade: 'Citizenship',
  'excel.investment_type': 'Investm. Type',
  todo: 'To-Do',
  'fam.todo': 'Family To-Do',
  usergroups: 'Partner'
};
const mapHeaderLabel = label => {
  if (labelMap[label]) {
    return labelMap[label];
  }
  return label;
};

const helpContent = (
  <div style={styles.info.body}>
    <span style={styles.info.item}>
      All ID Clients are listed here. Every time a user navigates to this page
      these data will be refreshed. Basic info is displayed on the table below
      for all clients.
    </span>
    <span style={styles.info.item}>
      <Badge
        style={{ ...styles.info.icon, ...g.badges.cyan }}
        dot={false}
        count={1}
      />
      <b>Colored notifications</b> show any unread ARI Updates, Collaborator
      Updates, and Audit logs.
    </span>
    <span style={styles.info.item}>
      <Icon style={styles.info.icon} type={'user'}></Icon>{' '}
      <b>Show Descendants</b> will list descendants below every client.
    </span>
    <span style={styles.info.item}>
      <Icon style={styles.info.icon} type={'eye'}></Icon>
      <b>Open</b> any client to view, add, or edit further client information
    </span>
  </div>
);

const stringifyCase = kase => {
  return JSON.stringify(Object.values(flatten(kase)));
};

const sortableFields = [
  { value: 'id', label: mapHeaderLabel('id') },
  { value: 'Nome', label: mapHeaderLabel('Nome') },
  { value: 'Nacionalidade', label: mapHeaderLabel('Nacionalidade') },
  {
    value: 'excel.investment_type',
    label: mapHeaderLabel('excel.investment_type')
  },
  { value: 'excel.juris', label: mapHeaderLabel('juris') },
  { value: 'usergroups', label: mapHeaderLabel('usergroups') },
  { value: 'unread', label: mapHeaderLabel('unread') },
  { value: 'todo', label: mapHeaderLabel('todo') },
  { value: 'fam.todo', label: mapHeaderLabel('fam.todo') }
];

class Clients extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      sortBy: 'todo',
      sortedList: null,
      showDescendants: false,
      modalVisible: false,
      sortDirection: SortDirection.DESC
    };

    this.tableRef = null;

    this.columnHeader = this.columnHeader.bind(this);
    this.sort = this.sort.bind(this);
    this.sortList = this.sortList.bind(this);
    this.search = this.search.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
  }

  async componentDidMount() {
    await this.processClients();
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.clients !== prevProps.clients) {
      this.setState({ loading: true });
      await this.processClients();
      await this.sort({
        sortBy: this.state.sortBy,
        sortDirection: this.state.sortDirection
      });
      this.setState({ loading: false });
    }

    if (this.state.showDescendants !== prevState.showDescendants) {
      this.setState({ descendantsLoading: true });
      await this.processClients();
      // await this.sort({
      //   sortBy: this.state.sortBy,
      //   sortDirection: this.state.sortDirection
      // });
      if (this.tableRef) {
        await this.tableRef.recomputeRowHeights();
      }
      this.setState({ descendantsLoading: false });
    }
  }

  showModal = () => {
    //Modal
    this.setState({
      modalVisible: true
    });
  };

  handleOk = e => {
    //Modal
    this.setState({
      clientToMinutes: null,
      modalVisible: false,
      showExport: false
    });
  };

  handleCancel = e => {
    //Modal
    this.setState({
      clientToMinutes: null,
      modalVisible: false,
      showExport: false
    });
  };

  render() {
    return (
      <NotificationContextConsumer>
        {notificationContext => (
          <>
            <Helmet>
              <title>ID - Clientes</title>
            </Helmet>

            <Content
              style={mrg([
                styles.fullWidth,
                g.layout.flexVertical,
                g.layout.flexStart,
                styles.content
              ])}
            >
              <div
                style={mrg([
                  g.layout.flexVertical,
                  g.layout.flexStart,
                  styles.full,
                  {
                    alignItems: 'flex-start',
                    maxHeight: '70vh'
                  }
                ])}
              >
                <div style={styles.pageHeaderContainer}>
                  <div style={styles.pageHeaderContainerLeft}>
                    <span style={styles.pageHeaderBox}>
                      <Tooltip placement="rightTop" title={helpContent}>
                        <Icon
                          style={styles.menuIcon}
                          type={'question-circle'}
                        ></Icon>
                      </Tooltip>
                    </span>
                    {!this.state.showCreate && (
                      <span style={styles.pageHeaderBox}>
                        <Input.Search
                          placeholder="Search"
                          disabled={
                            this.state.loading ||
                            this.state.searching ||
                            !this.state.sortedList
                          }
                          loading={this.state.loading || this.state.searching}
                          onSearch={(value, e) => {
                            console.log('onSearch value: ', value);
                            this.setState({ search: value });
                            this.search();
                          }}
                          onBlur={e => {
                            console.log(e.target.value);
                            this.setState({ search: e.target.value });
                            this.search();
                          }}
                        />
                      </span>
                    )}
                    <span style={styles.pageHeaderBox}>
                      <Button
                        style={styles.pageHeaderButton}
                        disabled={
                          this.state.showCreate ||
                          this.state.loading ||
                          !this.state.sortedList
                        }
                        ghost
                        type="primary"
                        onClick={this.search}
                      >
                        <Icon
                          style={{ marginRight: g.global.baseline / 2 }}
                          type="search"
                        ></Icon>
                        Search
                      </Button>
                    </span>
                    <span style={styles.pageHeaderBox}>
                      {!this.state.showCreate ? (
                        <span style={{ marginRight: g.global.baseline }}>
                          <Button
                            style={styles.pageHeaderButton}
                            ghost
                            disabled={this.state.loading}
                            type="primary"
                            onClick={() => {
                              this.setState({ showCreate: true });
                            }}
                          >
                            <Icon
                              style={{ marginRight: g.global.baseline / 2 }}
                              type="user-add"
                            ></Icon>
                            Create Client
                          </Button>
                        </span>
                      ) : (
                        <CreateClientForm
                          onCancel={() => this.setState({ showCreate: false })}
                          onSuccess={data =>
                            this.setState({ clientCreated: data._id })
                          }
                        />
                      )}
                      {this.state.clientCreated && (
                        <Redirect
                          to={'/id/clients/' + this.state.clientCreated}
                        ></Redirect>
                      )}
                    </span>
                  </div>
                  <div style={styles.pageHeaderContainerRight}>
                    {/* <span style={{ marginRight: globalStyles.global.baseline }}>
                      {!this.state.showCreateProcess && (
                        <Button
                          style={styles.pageHeaderButton}
                          ghost
                          type="primary"
                          onClick={() => {
                            this.setState({ showCreateProcess: true });
                          }}
                        >
                          Novo Processo
                        </Button>
                      )}
                    </span> */}

                    <span style={styles.pageHeaderBox}>
                      <Button
                        style={styles.pageHeaderButton}
                        disabled={
                          this.state.showCreate ||
                          this.state.loading ||
                          !this.state.sortedList
                        }
                        type="primary"
                        onClick={async () => {
                          this.setState({ loading: true });
                          await this.props.refreshClients?.();
                          this.setState({ loading: false });
                        }}
                      >
                        <Icon
                          style={{ marginRight: g.global.baseline / 2 }}
                          type="reload"
                        ></Icon>
                        Refresh Data
                      </Button>
                    </span>
                    {this.context.checkPermission('id:export') && (
                      <span style={styles.pageHeaderBox}>
                        <Button
                          style={styles.pageHeaderButton}
                          disabled={
                            this.state.showCreate ||
                            this.state.loading ||
                            !this.state.sortedList
                          }
                          ghost
                          type="primary"
                          onClick={async () => {
                            this.setState({ showExport: true });
                          }}
                        >
                          <Icon
                            style={{ marginRight: g.global.baseline / 2 }}
                            type="file-excel"
                          ></Icon>
                          Export Data
                        </Button>
                      </span>
                    )}
                    <span style={styles.pageHeaderBox}>
                      <span style={{ marginRight: g.global.baseline }}>
                        <Icon
                          style={styles.info.icon}
                          type={'usergroup-add'}
                        ></Icon>
                        Show Descendants
                      </span>
                      <Switch
                        checked={this.state.showDescendants}
                        loading={this.state.descendantsLoading}
                        disabled={
                          this.state.loading ||
                          this.state.searching ||
                          !this.state.sortedList
                        }
                        onChange={checked =>
                          this.setState({
                            showDescendants: checked
                          })
                        }
                      ></Switch>
                    </span>
                  </div>
                </div>

                {this.state.sortedList && !this.state.loading ? (
                  <AutoSizer>
                    {({ height, width }) => (
                      <Table
                        ref={ref => (this.tableRef = ref)}
                        style={styles.table.body}
                        height={height}
                        rowHeight={this.rowHeight}
                        rowStyle={({ index }) => this.rowStyle({ index })}
                        noRowsRenderer={NoData}
                        rowGetter={({ index }) => {
                          return this.state.sortedList[index];
                        }}
                        rowCount={this.state.sortedList.length}
                        sort={this.sort}
                        sortBy={this.state.sortBy}
                        sortDirection={this.state.sortDirection}
                        width={width}
                      >
                        <Column
                          width={baseColumnWidth}
                          flexGrow={1}
                          dataKey="id"
                          headerRenderer={this.columnHeader}
                          style={styles.table.column}
                          cellRenderer={({ cellData, rowData }) => {
                            return (
                              <span>
                                {rowData.isDescendant && (
                                  <Icon
                                    style={{
                                      marginRight: g.global.baseline
                                    }}
                                    type={'user'}
                                  ></Icon>
                                )}
                                {cellData}
                              </span>
                            );
                          }}
                        />
                        <Column
                          width={baseColumnWidth}
                          flexGrow={2}
                          dataKey="Nome"
                          disableSort={false}
                          style={styles.table.column}
                          headerRenderer={this.columnHeader}
                        />
                        <Column
                          width={baseColumnWidth}
                          flexGrow={1}
                          dataKey="Nacionalidade"
                          disableSort={false}
                          style={styles.table.column}
                          headerRenderer={this.columnHeader}
                        />
                        {this.context.checkPermission(
                          'id:client:base:protectedFields'
                        ) && (
                          <Column
                            width={baseColumnWidth}
                            flexGrow={1}
                            dataKey="excel.investment_type"
                            cellRenderer={({ dataKey, rowData }) => {
                              const result = op.get(rowData, dataKey, 'N/A');
                              return (
                                <Tooltip title={result} placement={'top'}>
                                  <span>{result}</span>
                                </Tooltip>
                              );
                            }}
                            disableSort={false}
                            style={styles.table.column}
                            headerRenderer={({
                              dataKey,
                              sortBy,
                              sortDirection
                            }) => {
                              return this.columnHeader({
                                dataKey,
                                sortBy,
                                sortDirection
                              });
                            }}
                          />
                        )}
                        {this.context.checkPermission(
                          'id:client:base:protectedFields'
                        ) && (
                          <Column
                            width={baseColumnWidth}
                            flexGrow={1}
                            dataKey="excel.juris"
                            disableSort={false}
                            cellRenderer={({ rowData }) => {
                              return rowData.excel.juris;
                            }}
                            style={styles.table.column}
                            headerRenderer={this.columnHeader}
                          />
                        )}
                        {this.context.checkPermission(
                          'id:client:base:protectedFields'
                        ) && (
                          <Column
                            width={baseColumnWidth}
                            flexGrow={1}
                            dataKey="usergroups"
                            disableSort={false}
                            cellRenderer={({ rowData }) => {
                              const result = rowData.usergroups;
                              return (
                                <Tooltip title={result} placement={'top'}>
                                  <span>{result}</span>
                                </Tooltip>
                              );
                            }}
                            style={styles.table.column}
                            headerRenderer={this.columnHeader}
                          />
                        )}
                        {this.context.checkPermission(
                          'id:client:base:protectedFields'
                        ) && (
                          <Column
                            width={baseColumnWidth}
                            flexGrow={1}
                            dataKey="unread"
                            label={'Notif.'}
                            cellRenderer={({ rowData }) => {
                              return (
                                <div
                                  style={{
                                    ...g.layout.flexHorizontal,
                                    ...g.layout.flexStart,
                                    ...g.layout.alignCenter,
                                    width: '100%',
                                    height: '100%'
                                  }}
                                >
                                  <Tooltip placement="top" title={'ARI Data'}>
                                    <Badge
                                      style={styles.badge}
                                      dot={false}
                                      count={notificationContext.get(
                                        rowData._id,
                                        'unreadAri'
                                      )}
                                    />
                                  </Tooltip>

                                  <Tooltip placement="top" title={'CRM'}>
                                    <Badge
                                      style={{
                                        ...g.badges.cyan,
                                        ...styles.badge
                                      }}
                                      dot={false}
                                      count={notificationContext.get(
                                        rowData._id,
                                        'unreadMessages'
                                      )}
                                    />
                                  </Tooltip>
                                  {/* 
                                  // 06 Fev 2021: removed audit notifications, requested by SSR
                                  <Tooltip placement="top" title={'Audit Log'}>
                                    <Badge
                                      style={{
                                        ...g.badges.grey,
                                        ...styles.badge
                                      }}
                                      dot={false}
                                      count={notificationContext.get(
                                        rowData._id,
                                        'unreadAudit'
                                      )}
                                    />
                                  </Tooltip> */}
                                </div>
                              );
                            }}
                            style={styles.table.column}
                            headerRenderer={this.columnHeader}
                          />
                        )}
                        <Column
                          width={baseColumnWidth / 2}
                          flexGrow={1}
                          dataKey="todo"
                          cellRenderer={({ rowData }) => {
                            return (
                              <div
                                style={{
                                  ...g.layout.flexHorizontal,
                                  ...g.layout.flexCenter,
                                  ...g.layout.alignCenter,
                                  width: '100%',
                                  height: '100%'
                                }}
                              >
                                <Tooltip placement="top" title={'ARI To-do'}>
                                  <Badge
                                    style={styles.badge}
                                    dot={false}
                                    count={notificationContext.get(
                                      rowData._id,
                                      'todoAri'
                                    )}
                                  />
                                </Tooltip>

                                <Tooltip placement="top" title={'CRM To-do'}>
                                  <Badge
                                    style={{
                                      ...g.badges.cyan,
                                      ...styles.badge
                                    }}
                                    dot={false}
                                    count={notificationContext.get(
                                      rowData._id,
                                      'todoMessages'
                                    )}
                                  />
                                </Tooltip>
                              </div>
                            );
                          }}
                          style={styles.table.column}
                          headerRenderer={this.columnHeader}
                        />
                        <Column
                          width={baseColumnWidth / 2}
                          flexGrow={2}
                          dataKey="id"
                          disableSort={true}
                          cellRenderer={({ rowData }) => {
                            return (
                              <div
                                style={{
                                  ...g.layout.flexHorizontal,
                                  ...g.layout.flexStart,
                                  ...g.layout.alignCenter,
                                  width: '100%',
                                  height: '100%',
                                  overflowX: 'scroll'
                                }}
                              >
                                <div
                                  style={{
                                    display: 'inline',
                                    paddingRight: g.global.baseline / 2
                                  }}
                                >
                                  <Link
                                    style={{}}
                                    to={
                                      rowData.isDescendant
                                        ? `/id/clients/${rowData['parentId']}/${rowData['_id']}`
                                        : `/id/clients/${rowData['_id']}`
                                    }
                                  >
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      icon="eye"
                                    ></Button>
                                  </Link>
                                </div>
                                <Dropdown
                                  overlay={
                                    <Menu>
                                      <Menu.Item>
                                        <div
                                          style={{
                                            display: 'inline',
                                            paddingRight: g.global.baseline / 2
                                          }}
                                        >
                                          <Link
                                            style={{}}
                                            to={
                                              rowData.isDescendant
                                                ? `/id/clients/${rowData['parentId']}/${rowData['_id']}`
                                                : `/id/clients/${rowData['_id']}`
                                            }
                                          >
                                            <Button type="link" icon="eye">
                                              Open
                                            </Button>
                                          </Link>
                                        </div>
                                      </Menu.Item>
                                      {this.context.checkPermission(
                                        'id:client:minutes'
                                      ) && (
                                        <Menu.Item>
                                          <div
                                            style={{
                                              display: 'inline'
                                            }}
                                          >
                                            <Button
                                              type="link"
                                              icon="file-word"
                                              onClick={() => {
                                                this.showModal();
                                                this.setState({
                                                  clientToMinutes: rowData
                                                });
                                              }}
                                            >
                                              Minutes
                                            </Button>
                                          </div>
                                        </Menu.Item>
                                      )}
                                    </Menu>
                                  }
                                >
                                  <div
                                    style={{
                                      height: '100%',
                                      ...g.layout.flexHorizontal,
                                      ...g.layout.flexCenter,
                                      ...g.layout.alignCenter
                                    }}
                                  >
                                    <Button
                                      type="secondary"
                                      shape="circle"
                                      icon="ellipsis"
                                    ></Button>
                                  </div>
                                </Dropdown>
                              </div>
                            );
                          }}
                          style={styles.table.column}
                          headerRenderer={() => 'ACTIONS'}
                        />
                        <Column
                          width={baseColumnWidth / 2}
                          flexGrow={1}
                          dataKey="fam.todo"
                          cellRenderer={({ rowData }) => {
                            return (
                              <div
                                style={{
                                  ...g.layout.flexVertical,
                                  ...g.layout.flexCenter,
                                  ...g.layout.alignCenter,
                                  width: '100%',
                                  height: '100%',
                                  overflowX: 'scroll'
                                }}
                              >
                                {Array.isArray(rowData.Familia) &&
                                  rowData.Familia.map(fam => {
                                    return (
                                      <div
                                        key={fam._id}
                                        style={styles.table.horizontalCell}
                                      >
                                        {this.context.checkPermission(
                                          'id:client:ari'
                                        ) && (
                                          <Tooltip
                                            placement="top"
                                            title={'ARI To-do'}
                                          >
                                            <Badge
                                              style={{
                                                ...styles.badge,
                                                ...styles.smallBadge
                                              }}
                                              dot={false}
                                              count={notificationContext.get(
                                                fam._id,
                                                'todoAri'
                                              )}
                                            />
                                          </Tooltip>
                                        )}
                                        <Tooltip
                                          placement="top"
                                          title={'CRM To-do'}
                                        >
                                          <Badge
                                            style={{
                                              ...g.badges.cyan,
                                              ...styles.badge,
                                              ...styles.smallBadge
                                            }}
                                            dot={false}
                                            count={notificationContext.get(
                                              fam._id,
                                              'todoMessages'
                                            )}
                                          ></Badge>
                                        </Tooltip>
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          }}
                          style={styles.table.column}
                          headerRenderer={this.columnHeader}
                        />
                        <Column
                          width={baseColumnWidth}
                          flexGrow={3}
                          dataKey="Familia"
                          headerRenderer={this.columnHeader}
                          style={styles.table.lastColumn}
                          cellRenderer={({ cellData }) => (
                            <div
                              style={mrg([
                                g.layout.flexVertical,
                                g.layout.flexStart,
                                g.layout.alignStart
                              ])}
                            >
                              {Array.isArray(cellData)
                                ? cellData.map((member, index) => (
                                    <Tooltip
                                      key={member._id}
                                      placement="left"
                                      title={member.Nome}
                                    >
                                      <Tag
                                        style={{
                                          marginBottom: 4,
                                          maxWidth: '100%',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden'
                                        }}
                                        color="blue"
                                      >
                                        {this.context.checkPermission(
                                          'id:client:ari'
                                        ) && (
                                          <Badge
                                            style={{
                                              ...styles.badge,
                                              ...styles.smallBadge
                                            }}
                                            dot={false}
                                            count={notificationContext.get(
                                              member._id,
                                              'unreadAri'
                                            )}
                                          />
                                        )}

                                        <Badge
                                          style={{
                                            ...g.badges.cyan,
                                            ...styles.badge,
                                            ...styles.smallBadge
                                          }}
                                          dot={false}
                                          count={notificationContext.get(
                                            member._id,
                                            'unreadMessages'
                                          )}
                                        />
                                        {/* 
                                        // 06 Fev 2021: removed audit notifications, requested by SSR
                                        {this.context.checkPermission(
                                          'id:client:audit'
                                        ) && (
                                          <Badge
                                            style={{
                                              ...g.badges.grey,
                                              ...styles.badge,
                                              ...styles.smallBadge
                                            }}
                                            dot={false}
                                            count={notificationContext.get(
                                              member._id,
                                              'unreadAudit'
                                            )}
                                          />
                                        )} */}
                                        <span
                                          style={{
                                            marginLeft: g.global.baseline
                                          }}
                                        >
                                          {member.Nome}
                                        </span>
                                      </Tag>
                                    </Tooltip>
                                  ))
                                : ''}
                            </div>
                          )}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                ) : (
                  <Loading></Loading>
                )}
                {this.state.modalVisible ? (
                  <Modal
                    footer={null}
                    title="Minutes Maker"
                    visible={this.state.modalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={1000}
                    bodyStyle={{ height: 'auto', overflowY: 'scroll' }}
                  >
                    <Minutes clientToMinutes={this.state.clientToMinutes} />
                  </Modal>
                ) : null}

                <Modal
                  title=""
                  visible={this.state.showExport}
                  footer={null}
                  onCancel={this.handleCancel}
                  width={'70vw'}
                  bodyStyle={{ height: '70vh', overflowY: 'scroll' }}
                >
                  <Export
                    data={this.state.sortedList}
                    sortBy={{
                      value: this.state.sortBy,
                      direction: this.state.sortDirection,
                      label: mapHeaderLabel(this.state.sortBy)
                    }}
                    sortSelector={
                      <SortSelector
                        options={sortableFields}
                        currentOption={this.state.sortBy}
                        currentDirection={this.state.sortDirection}
                        label="Sort exported data by: "
                        onChangeField={async sortBy => {
                          this.setState({ sortBy: sortBy });
                          await this.sort({
                            sortBy: sortBy,
                            sortDirection: this.state.sortDirection
                          });
                        }}
                        onChangeDirection={async direction => {
                          this.setState({ sortDirection: direction });
                          await this.sort({
                            sortBy: this.state.sortBy,
                            sortDirection: direction
                          });
                        }}
                      ></SortSelector>
                    }
                  ></Export>
                </Modal>
              </div>
            </Content>
          </>
        )}
      </NotificationContextConsumer>
    );
  }

  columnHeader({ dataKey, sortBy, sortDirection }) {
    return (
      <div>
        <span
          onClick={() =>
            this.setState((state, props) => ({
              sortBy: dataKey
            }))
          }
        >
          {mapHeaderLabel(dataKey).toUpperCase()}
        </span>
        {sortBy === dataKey && (
          <SortIndicator
            onClick={() =>
              this.setState((state, props) => ({
                sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
              }))
            }
            sortDirection={sortDirection}
          />
        )}
      </div>
    );
  }

  async processClients() {
    let clients = this.props.clients;

    if (clients) {
      // Sort and cocatenate usergroups by name
      clients.forEach(client => {
        if (Array.isArray(client.usergroups)) {
          sort(client.usergroups).desc(group => group.name);
          try {
            client.usergroups = client.usergroups
              .map(group => group.name)
              .join(', ');
          } catch (err) {
            console.error(err);
            console.log(client.usergroups);
          }
        } else {
          client.usergroups = '';
        }
      });

      // Aggregate clients and descendants into a single array
      const result = [];
      for (let i = 0; i < clients.length; i++) {
        const client = clients[i];
        result.push(client);
        if (this.state.showDescendants) {
          for (let j = 0; j < client.Familia.length; j++) {
            const descendant = client.Familia[j];
            descendant.isDescendant = true;
            descendant.parentId = client._id;
            result.push(descendant);
          }
        }
      }

      this.setState({
        sortedList: result
      });

      this.clientCache = result;
    } else {
      this.setState({
        sortedList: null
      });
    }
  }

  rowStyle({ index }) {
    if (index === -1) {
      return styles.table.headerRow;
    }

    if (this.state.sortedList[index].isDescendant) {
      return styles.table.descendantRow;
    }
    if (index % 2 === 0) {
      return styles.table.evenRow;
    } else {
      return styles.table.oddRow;
    }
  }

  rowHeight({ index }) {
    const base = g.global.baseline * 3.5;
    const multiplier = g.global.baseline * 2;

    const family = this.state.sortedList[index].Familia;

    let familyLength = family ? family.length - 1 : 0;
    if (familyLength < 0) {
      familyLength = 0;
    }
    return base + familyLength * multiplier;
  }

  search() {
    this.setState({ searching: true });
    if (!this.state.sortedList || this.state.searching || !this.state.search) {
      this.setState({ searching: false, sortedList: this.clientCache });
      return;
    }

    // do not await this
    (async () => {
      const clients = this.clientCache.filter(
        kase =>
          deburr(stringifyCase(kase))
            .toLowerCase()
            .indexOf(deburr(this.state.search).toLowerCase()) !== -1
      );
      this.setState({ sortedList: clients, searching: false });
      if (this.tableRef) {
        await this.tableRef.recomputeRowHeights();
      }
    })();
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedClients });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'unread') {
      const searchArray = [
        client => this.props.notificationContext.get(client._id, 'unreadAri'),
        client =>
          this.props.notificationContext.get(client._id, 'unreadMessages')
        //client => this.props.notificationContext.get(client._id, 'unreadAudit')
      ];
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(searchArray);
        case SortDirection.DESC:
          return toSort.desc(searchArray);
        default:
          return list;
      }
    } else if (sortBy === 'todo') {
      const searchArray = [
        client => this.props.notificationContext.get(client._id, 'todoAri'),
        client => this.props.notificationContext.get(client._id, 'todoMessages')
      ];
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(searchArray);
        case SortDirection.DESC:
          return toSort.desc(searchArray);
        default:
          return list;
      }
    } else if (sortBy === 'fam.todo') {
      const searchArray = [
        client => {
          let result = 0;
          if (client.Familia) {
            client.Familia.forEach(
              fam =>
                (result += this.props.notificationContext.get(
                  fam._id,
                  'todoAri'
                ))
            );
          }
          return result;
        },
        client => {
          let result = 0;
          if (client.Familia) {
            client.Familia.forEach(
              fam =>
                (result += this.props.notificationContext.get(
                  fam._id,
                  'todoMessages'
                ))
            );
          }
          return result;
        }
      ];
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(searchArray);
        case SortDirection.DESC:
          return toSort.desc(searchArray);
        default:
          return list;
      }
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(client => op.get(client, sortBy));
      case SortDirection.DESC:
        return toSort.desc(client => op.get(client, sortBy));
      default:
        return list;
    }
  }
}

export default Clients;

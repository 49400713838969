import React from 'react';
import { fetchUserDataIfAuthenticated } from '../network/userInfo';
import AppContext from './AppContextBase';
import Permissions from './Permissions';

const defaultValues = { authenticated: false, userData: null, awsData: null };

class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
    this.getDefaultValues = this.getDefaultValues.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.logOut = this.logOut.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
    this.helpers = this.helpers.bind(this);
    this.reset({ inConstructor: true });
    this.state = {};
  }

  reset(options = {}) {
    if (options.inConstructor) {
      //eslint-disable-next-line react/no-direct-mutation-state
      this.state = this.getDefaultValues();
    } else {
      this.setState(this.getDefaultValues());
    }
  }

  async authenticate(awsData) {
    let userData;
    try {
      const userRequest = await fetchUserDataIfAuthenticated();
      if (!userRequest.result === 'OK') {
        throw new Error('Failure gettinng user details from backend.');
      }
      userData = userRequest.data;
    } catch (err) {
      this.logOut();
      throw err;
    }
    this.setState({
      authenticated: true,
      userData: userData,
      awsData: awsData
    });
  }

  logOut() {
    this.setState({ authenticated: false, userData: null, awsData: null });
  }

  getDefaultValues() {
    return defaultValues;
  }

  checkPermission(scope) {
    return Permissions.check(scope, this.state.userData);
  }

  helpers() {
    return {
      getUserAttribute: this.getUserAttribute.bind(this),
      checkPermission: this.checkPermission.bind(this),
      logOut: this.logOut.bind(this),
      authenticate: this.authenticate.bind(this)
    };
  }

  getUserAttribute(attributeName) {
    switch (attributeName) {
      case 'role':
        return this.state.userData.role;
      case 'scope':
        return this.state.userData.permissions.scope;
      case 'department':
        return this.state.userData.permissions.department;
      default:
        return null;
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          authenticate: this.authenticate,
          logOut: this.logOut,
          checkPermission: this.checkPermission,
          getUserAttribute: this.getUserAttribute,
          helpers: this.helpers
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppContextConsumer = AppContext.Consumer;

export { AppContextProvider, AppContextConsumer };

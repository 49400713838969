import React from 'react';
import op from 'object-path';
import NotificationContext from './NotificationContextBase';

const OPERATIONS = {
  increment: 'increment',
  decrement: 'decrement'
};

function withNotifications(InnerComponent) {
  return class NotificationContextProvider extends React.Component {
    constructor(props, context) {
      super(props, context);
      this.state = { notifications: {} };
      this.hierarchies = [];
    }
    static OPERATIONS = OPERATIONS;

    calculateOperationResult(targetUser, path, operation) {
      const oldValue = op.get(targetUser, path, 0);
      let newValue;
      switch (operation) {
        case NotificationContextProvider.OPERATIONS.increment: {
          newValue = oldValue + 1;
          break;
        }
        case NotificationContextProvider.OPERATIONS.decrement: {
          newValue = oldValue - 1;
          break;
        }
        default: {
          throw new Error(
            'Notification Context: invalid operation: ' + operation
          );
        }
      }
      return newValue;
    }

    addHierarchy(origin, target) {
      const targetHierarchy = this.hierarchies[origin];
      if (!targetHierarchy) {
        this.hierarchies[origin] = [];
      }
      this.hierarchies[origin].push({ origin, target });
    }

    removeHierarchy(origin, target) {
      const targetHierarchy = this.hierarchies[origin];
      if (
        !targetHierarchy ||
        !targetHierarchy.find(item => item.target === target)
      ) {
        return;
      } else if (targetHierarchy.length === 1) {
        delete this.hierarchies[origin];
      } else {
        this.hierarchies[origin] = targetHierarchy.filter(
          item => item.target !== target
        );
      }
    }

    update = (id, operation, path) => {
      this.setState((prevState, props) => {
        const newState = { ...prevState };
        let targetUser = newState.notifications[id];

        if (!targetUser) {
          newState.notifications[targetUser] = {};
          targetUser = newState.notifications[targetUser];
        }

        let newValue = this.calculateOperationResult(
          targetUser,
          path,
          operation
        );
        // console.log('Applying update to id ', id + ': ', {
        //   target: path + ': ' + targetUser[path],
        //   path,
        //   newValue
        // });
        op.set(targetUser, path, newValue);

        const hierarchies = this.hierarchies[path];
        if (hierarchies) {
          // apply same operation to target hierarchies
          for (const hierarchy of hierarchies) {
            newValue = this.calculateOperationResult(
              targetUser,
              hierarchy.target,
              operation
            );
            // console.log('    Applying hierarchy ', {
            //   targetUser:
            //     hierarchy.target + ': ' + targetUser[hierarchy.target],
            //   path: hierarchy.target,
            //   newValue
            // });
            op.set(targetUser, hierarchy.target, newValue);
          }
        }

        return { newState };
      });
    };

    set = notifications => {
      this.setState({ notifications });
    };

    get = (id, path = '') => {
      const target = this.state.notifications[id];
      if (!target) {
        return 0;
      }

      if (path) {
        // return single value
        const result = op.get(target, path);
        return result || 0;
      }
      // return all notifications
      return target || 0;
    };

    render() {
      return (
        <NotificationContext.Provider
          value={{
            notifications: this.state.notifications,
            update: this.update,
            get: this.get,
            set: this.set,
            addHierarchy: this.addHierarchy,
            removeHierarchy: this.removeHierarchy
          }}
        >
          <InnerComponent
            {...this.props}
            notifications={this.state.notifications}
            notificationContext={this}
          />
        </NotificationContext.Provider>
      );
    }
  };
}

const NotificationContextConsumer = NotificationContext.Consumer;

export default withNotifications;
export { NotificationContextConsumer, OPERATIONS };

import React from 'react';
import { Button, Modal } from 'antd';
import g from '../../styles/global';
const { confirm } = Modal;

export default class ConfirmDelete extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.onDelete = this.props.onDelete || (() => {});
    this.onConfirm = this.props.onConfirm || (() => {});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isConfirm !== undefined) {
      return { confirm: props.isConfirm === true };
    }
    return null;
  }

  render() {
    // <Button
    //   {...this.props.buttonProps}
    //   type={'danger'}
    //   disabled={this.props.disabled}
    //   style={this.props.buttonStyle}
    //   onClick={}
    // >
    //
    // </Button>
    return (
      <Button
        {...this.props.buttonProps}
        disabled={this.props.disabled}
        type={'danger'}
        ghost
        style={this.props.buttonStyle}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          await this.onConfirm();
          return await confirm({
            title: this.props.confirmLabel ?? 'Confirm delete',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            content: this.props.confirmContent ?? (
              <div
                style={{
                  color: g.colors.feedback.error
                }}
              >
                This operation is irreversible!
              </div>
            ),
            onOk: async e => {
              try {
                return await this.onDelete();
              } catch (err) {
                this.setState({ confirm: false });
              }
            },
            onCancel() {}
          });
        }}
      >
        {this.props.deleteLabel || 'Delete'}
      </Button>
    );
  }
}

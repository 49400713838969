import React from 'react';
import AppContext from '../../../context/AppContextBase';
import { Helmet } from 'react-helmet';
import { Layout, Tooltip, message, Switch } from 'antd';
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  SortDirection
} from 'react-virtualized';
import sort from 'fast-sort';
import op from 'object-path';

import globalStyles from '../../../styles/global';
import { mrg } from '../../../common/util';

import { getUsers, putUser, getGroups } from '../../../network/users';

import EditableField from '../../../components/util/EditableField';
import DetailedMessage from '../../../components/util/DetailedMessage';
import NoData from '../../../components/util/NoData';
import Loading from '../../../components/Loading';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll'
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  info: {
    body: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter
    },
    item: {
      marginBottom: globalStyles.global.baseline
    },
    icon: { marginRight: globalStyles.global.baseline / 2 }
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },

  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '40%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '60%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#fff',
    paddingTop: globalStyles.global.baseline,
    paddingBottom: globalStyles.global.baseline,
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline / 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  },
  table: {
    body: { height: '100%' },
    evenRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0'
    },
    oddRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e0e0e0'
    },
    headerRow: {
      borderLeft: '1px solid #e0e0e0',
      textTransform: 'none',
      backgroundColor: '#c0c0ca',
      fontSize: globalStyles.global.baseline,
      fontWeight: 500,
      height: globalStyles.global.baseline * 3
    },
    column: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    columnBorder: {
      borderRight: '1px solid #e0e0e0'
    },
    overflowColumn: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    checkboxLabel: {
      marginLeft: '5rem'
    },
    noRows: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd'
    }
  }
};

const baseColumnWidth = globalStyles.global.baseline * 4;

const labelMap = {
  id: 'ID',
  name: 'Name',
  'permissions.department': 'Dept',
  'permissions.scope': 'Scope',
  group: 'Partner'
};
const mapHeaderLabel = label => {
  if (labelMap[label]) {
    return labelMap[label];
  }
  return label;
};

class List extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = { groupOptions: [] };

    this.tableRef = null;

    this.columnHeader = this.columnHeader.bind(this);
    this.sort = this.sort.bind(this);
    this.sortList = this.sortList.bind(this);
    this.search = this.search.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSaveError = this.onSaveError.bind(this);
  }

  async componentDidMount() {
    if (!this.state.sortedList) {
      await this.refresh();
    }
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
    let groupOptions = await getGroups();
    console.log(groupOptions);
    if (
      groupOptions.result === 'OK' &&
      groupOptions.data &&
      Array.isArray(groupOptions.data)
    ) {
      groupOptions.data.unshift({
        _id: null,
        name: 'None'
      });

      console.log(groupOptions.data);
      this.setState({ groupOptions: groupOptions.data });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showDescendants !== prevState.showDescendants) {
      await this.refresh();
      await this.sort({
        sortBy: this.state.sortBy,
        sortDirection: this.state.sortDirection
      });
      if (this.tableRef) {
        await this.tableRef.recomputeRowHeights();
      }
    }
  }

  onSaveSuccess() {
    return message.success('Values saved successfully');
  }
  onSaveError(err) {
    return DetailedMessage.error('Error saving values', err);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Users - List</title>
        </Helmet>
        <Content
          style={mrg([
            styles.fullWidth,
            globalStyles.layout.flexVertical,
            globalStyles.layout.flexStart,
            globalStyles.layout.alignStart,
            styles.content
          ])}
        >
          <div
            style={{
              ...styles.pageHeaderContainer,
              height: globalStyles.global.baseline * 4,
              backgroundColor: globalStyles.colors.background.queenBlue
            }}
          >
            <span style={styles.pageHeaderTitle}>Users</span>
          </div>
          <div
            style={{
              height: `calc( 100% - ${globalStyles.global.baseline * 10}px)`,
              margin: '2%',
              width: '96%'
            }}
          >
            {this.state.sortedList ? (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={ref => (this.tableRef = ref)}
                    style={styles.table.body}
                    height={height}
                    rowHeight={this.rowHeight}
                    rowStyle={({ index }) => this.rowStyle({ index })}
                    noRowsRenderer={NoData}
                    rowGetter={({ index }) => {
                      return this.state.sortedList[index];
                    }}
                    rowCount={this.state.sortedList.length}
                    sort={this.sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    width={width}
                  >
                    <Column
                      width={baseColumnWidth}
                      flexGrow={3}
                      dataKey="email"
                      headerRenderer={this.columnHeader}
                      style={mrg([
                        styles.table.overflowColumn,
                        styles.table.columnBorder
                      ])}
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <Tooltip
                            key={cellData}
                            placement="top"
                            title={cellData}
                          >
                            {cellData}
                          </Tooltip>
                        );
                      }}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={2}
                      dataKey="name"
                      disableSort={false}
                      style={mrg([
                        styles.table.overflowColumn,
                        styles.table.columnBorder
                      ])}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="role"
                      disableSort={false}
                      style={mrg([
                        styles.table.overflowColumn,
                        styles.table.columnBorder
                      ])}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="approved"
                      disableSort={false}
                      style={mrg([
                        styles.table.overflowColumn,
                        styles.table.columnBorder
                      ])}
                      headerRenderer={() => 'Active'}
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <Switch
                            defaultChecked={cellData}
                            loading={this.state.savingClientId === rowData.id}
                            disabled={
                              this.state.searching || !this.state.sortedList
                            }
                            onChange={async checked => {
                              console.log('saving:', {
                                id: rowData.id,
                                approved: checked
                              });
                              this.setState({ savingClientId: rowData.id });
                              const response = await putUser({
                                id: rowData.id,
                                approved: checked
                              });
                              if (!(response && response.result === 'OK')) {
                                this.setState({ savingClientId: null });
                                return await this.onSaveError(response);
                              }
                              await this.refresh();
                              this.setState({ savingClientId: null });
                              return await this.onSaveSuccess(response);
                            }}
                          ></Switch>
                        );
                      }}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="permissions.department"
                      disableSort={false}
                      style={mrg([
                        styles.table.overflowColumn,
                        styles.table.columnBorder
                      ])}
                      cellDataGetter={({ dataKey, rowData }) => {
                        return op.get(rowData, dataKey, 'N/A');
                      }}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="permissions.scope"
                      disableSort={false}
                      style={styles.table.column}
                      cellDataGetter={({ dataKey, rowData }) => {
                        return op.get(rowData, dataKey, 'N/A');
                      }}
                      headerRenderer={this.columnHeader}
                    />
                    {this.context.checkPermission(
                      'menu:settings:users:groups'
                    ) && (
                      <Column
                        width={baseColumnWidth}
                        flexGrow={3}
                        dataKey="group"
                        disableSort={false}
                        style={styles.table.column}
                        cellDataGetter={({ dataKey, rowData }) => {
                          return op.get(rowData, dataKey, null);
                        }}
                        headerRenderer={this.columnHeader}
                        cellRenderer={({ cellData, rowData }) => {
                          if (rowData.permissions.scope !== 'external') {
                            return (
                              <Tooltip
                                key={rowData._id}
                                placement="top"
                                title={'Only available for external ID users'}
                              >
                                <span>---</span>
                              </Tooltip>
                            );
                          }
                          return (
                            <EditableField
                              type={EditableField.Types.select}
                              options={this.state.groupOptions.map(opt => {
                                return { key: opt._id, value: opt.name };
                              })}
                              hideElementBefore
                              minWidthBefore={0}
                              innerStyle={{ marginBottom: 0 }}
                              span={24}
                              key={rowData._id}
                              editable
                              value={cellData}
                              onSave={async value => {
                                if (value === 'null') {
                                  // Antd made me do it. Bad design
                                  value = null;
                                }
                                const response = await putUser({
                                  id: rowData.id,
                                  group: value
                                });
                                if (!(response && response.result === 'OK')) {
                                  throw new Error(response);
                                }
                                return response;
                              }}
                              onSaveSuccess={async result =>
                                await this.onSaveSuccess(result)
                              }
                              onSaveError={async err =>
                                await this.onSaveError(err)
                              }
                            ></EditableField>
                          );
                        }}
                      />
                    )}
                  </Table>
                )}
              </AutoSizer>
            ) : (
              <Loading></Loading>
            )}
          </div>
        </Content>
      </>
    );
  }
  columnHeader({ dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {
          <span
            onClick={() =>
              this.setState((state, props) => ({
                sortBy: dataKey
              }))
            }
          >
            {mapHeaderLabel(dataKey).toUpperCase()}
          </span>
        }
        {sortBy === dataKey && (
          <SortIndicator
            onClick={() =>
              this.setState((state, props) => ({
                sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
              }))
            }
            sortDirection={sortDirection}
          />
        )}
      </div>
    );
  }

  async refresh() {
    this.setState({ loading: true });
    let users = await getUsers(this.context.getUserAttribute('department'));

    if (users && users.result === 'OK' && users.data.length > 0) {
      this.setState({
        sortedList: users.data
      });
    } else if (users && users.result === 'OK') {
      this.setState({
        sortedList: []
      });
    } else {
      DetailedMessage.error('Error loading Data', users);
      this.setState({
        sortedList: []
      });
    }
    this.setState({ loading: false });
  }

  rowStyle({ index }) {
    if (index === -1) {
      return styles.table.headerRow;
    }
    if (index % 2 === 0) {
      return styles.table.evenRow;
    } else {
      return styles.table.oddRow;
    }
  }

  rowHeight({ index }) {
    return globalStyles.global.baseline * 3.5;
  }

  async search() {
    this.setState({ searching: true });
    if (!this.state.sortedList || this.state.searching || !this.state.search) {
      this.setState({ searching: false, sortedList: this.clientCache });
      return;
    }

    const clients = this.clientCache.filter(
      kase => JSON.stringify(kase).indexOf(this.state.search) !== -1
    );
    this.setState({ sortedList: clients, searching: false });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedClients });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'unread') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        case SortDirection.DESC:
          return toSort.desc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        default:
          return list;
      }
    }

    let sb = sortBy;

    if (sortBy && sortBy.includes('.')) {
      sb = data => op.get(data, sortBy);
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(sb);
      case SortDirection.DESC:
        return toSort.desc(sb);
      default:
        return list;
    }
  }
}

export default List;

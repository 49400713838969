import React from 'react';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet';
import { NavLink, Redirect } from 'react-router-dom';
import {
  Row,
  Col,
  Tag,
  Tabs,
  Collapse,
  List,
  Tooltip,
  Badge,
  Icon,
  message,
  Input,
  Button,
  Modal
} from 'antd';
import moment from 'moment';
import CitiusTable from '../../components/CitiusProcesses';
import g from '../../styles/global';
import MessageBoard from '../../components/messages/MessageBoard';
import ConfirmLogin from '../../components/auth/ConfirmLogin';
import {
  fetchProcessesByFolder,
  fetchFolder,
  updateFolder,
  deleteMessage,
  putMessage,
  postMessage,
  updateProcess,
  deleteFolder
} from '../../network/cm';
import { fetchSingleCITIUSCase } from '../../network/citius';
import { mrg } from '../../common/util';
import Loading from '../../components/Loading';
import DetailedMessage from '../../components/util/DetailedMessage';
import EditableField from '../../components/util/EditableField';
import UploadDocumentsComponent from '../../components/DocumentUploadsList';
import { fetchDocuments } from '../../network/documentUploads';

import {
  messageTypes,
  messageVisibility
} from '../../components/messages/messageTypes';

const { TabPane } = Tabs;
const Panel = Collapse.Panel;

const styles = {
  panel: {
    backgroundColor: '#f0f2f5',
    borderRadius: 0,
    border: 'unset',
    marginBottom: g.global.baseline / 2
  },
  pageHeader: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #ddd'
  },
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  headerSpan: {
    fontSize: g.global.baseline * 1.5
  },
  mainRow: {
    height: 'calc(100vh - ' + g.global.baseline * 7 + 'px - 60px)',
    overflowY: 'hidden',
    width: '100%'
  },
  leftCol: {
    height: '100%',
    backgroundColor: '#f0f2f5',
    borderRight: '1px solid #ddd'
  },
  sidebar: {
    backgroundColor: 'transparent',
    maxHeight: '100%',
    overflowY: 'scroll',
    borderRadius: 0
  },
  processList: {
    marginLeft: g.global.baseline
  },
  process: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 100,
    color: '#444444'
  },
  activeProcess: {
    color: '#40a9ff'
  },
  sidebarHeader: {
    background: '#f0f2f5',
    borderRadius: 0,
    marginBottom: g.global.baseline,
    border: 'unset',
    padding: g.global.baseline,
    fontSize: 16
  },
  rightCol: {
    padding: 0,
    maxHeight: '100%',
    overflowY: 'scroll',
    position: 'relative'
  },
  rightColInner: {
    padding: g.global.baseline,
    paddingTop: 0,
    borderTop: '1px solid #ddd'
  },
  processHeader: {
    body: {
      backgroundColor: '#fff'
    }
  },
  folder: {
    operations: {
      container: {
        border: '1px solid #d9d9d9',
        background: '#f9f9f9',
        borderRadius: 4,
        padding: g.global.baseline,
        marginBottom: g.global.baseline
      },
      title: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        height: 32,
        paddingBottom: g.global.baseline
      },
      item: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        height: 32,
        paddingBottom: g.global.baseline,
        paddingRight: g.global.baseline
      }
    }
  },
  statistic: {
    container: {
      fontSize: g.global.baseline,
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      marginBottom: g.global.baseline
    },
    title: {
      fontWeight: 900,
      color: '#666666',
      marginRight: g.global.baseline
    },
    value: {
      fontWeight: 300
    }
  },
  crm: {
    container: {
      width: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart
    },

    subheader: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    }
  },
  modal: {
    body: {
      padding: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline,
      fontSize: g.global.baseline * 1.5
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  }
};

const documentRealm = 'process';

const makeHeaderSpan = title => {
  return <span style={styles.headerSpan}>{title}</span>;
};

//eslint-disable-next-line no-unused-vars
const defaultDetailedError = async err =>
  DetailedMessage.error(
    'Error! Check your internet connection or access permissions.',
    err
  );

//eslint-disable-next-line no-unused-vars
const defaultSuccess = () => message.success('Changes Saved');

const reverse = data => {
  let result = data;
  if (Array.isArray(data)) {
    result = [].concat(data).reverse();
  }
  return result;
};

class SingleProcess extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      routeData: this.props.location.state,
      folderInfo: null,
      folderProcesses: null,
      folderProcessesMap: null,
      currentProcessId: null,
      citiusCase: null,
      processAttachments: []
    };

    this.refreshProcesses = this.refreshProcesses.bind(this);
  }

  async componentDidMount() {
    await this.refreshFolderInfo();
    await this.refreshProcesses();
    await this.setCurrentProcess(
      decodeURIComponent(this.props.match.params.id)
    );
    await this.refreshCitiusCase();

    let processAttachments = [];

    const request = await fetchDocuments(
      documentRealm,
      this.state.currentProcessId
    );
    if (request.result === 'OK' && request.data.docs) {
      processAttachments = request.data.docs;
    }
    this.setState({ processAttachments });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      decodeURIComponent(this.props.match.params.id) !==
      prevState.currentProcessId
    ) {
      this.setState({ switchingProcesses: true, citiusCase: null });
      await this.setCurrentProcess(
        decodeURIComponent(this.props.match.params.id)
      );
      await this.refreshCitiusCase();

      this.setState({
        switchingProcesses: setTimeout(() => {
          this.setState({ switchingProcesses: false });
        }, 300)
      });
    }
  }

  componentWillUnmount() {
    if (this.state.switchingProcesses) {
      clearTimeout(this.state.switchingProcesses);
    }
  }

  setAttachments = attachments =>
    this.setState({ processAttachments: attachments });

  setCurrentProcess = async id => {
    if (this.state.folderProcessesMap) {
      this.setState({
        currentProcessId: id
      });
    } else {
      this.setState({
        currentProcessId: id
      });
    }
  };

  refreshFolderInfo = async () => {
    // routeData is always in state, and is always provided as a prop by a Route in a parent
    let folderInfo = await fetchFolder(this.state.routeData.folder_id);
    if (folderInfo && folderInfo.result === 'OK') {
      this.setState({
        folderInfo: folderInfo.data
      });
    } else {
      console.error('error (fetching folder): ', folderInfo.error);
    }
  };

  refreshProcesses = async () => {
    let folderProcesses = await fetchProcessesByFolder(
      this.state.routeData.folder_id
    );
    if (folderProcesses && folderProcesses.result === 'OK') {
      let processesMap = {};
      //organize processes by id:
      for (let kase_key in folderProcesses.data.docs) {
        processesMap[folderProcesses.data.docs[kase_key].id] =
          folderProcesses.data.docs[kase_key];
      }

      this.setState({
        folderProcesses: folderProcesses.data.docs,
        folderProcessesMap: processesMap
      });
    } else {
      console.log('error (fetching processes): ', folderProcesses.error);
      this.setState({ folderProcessesMap: null });
    }
  };

  refreshCitiusCase = async () => {
    const map = this.state.folderProcessesMap;
    const current = this.state.currentProcessId;

    if (!map || !map[current]) {
      return;
    }
    const citiusId = this.state.folderProcessesMap[this.state.currentProcessId]
      ?.process_id;
    if (citiusId) {
      const citiusCase = await fetchSingleCITIUSCase(citiusId);
      if (citiusCase.result === 'OK' && citiusCase.data && citiusCase.data) {
        this.setState({ citiusCase: citiusCase.data });
      }
    }
  };

  refreshMessages = async () => {
    //organize messages for current process:
    let messageDataSource;
    const map = this.state.folderProcessesMap;
    const current = this.state.currentProcessId;

    if (map && map[current] && map[current].messages) {
      messageDataSource = map[current].messages.filter(message => {
        return (
          message.visibility.toLowerCase() === 'external' ||
          (message.visibility.toLowerCase() === 'internal' &&
            this.context.getUserAttribute('scope').toLowerCase() === 'internal')
        );
      });
      messageDataSource = messageDataSource.reverse();
      for (let case_key in messageDataSource) {
        const kase = messageDataSource[case_key];
        kase.create_date = moment(kase.create_date);
        delete kase.create_time;
      }
    } else {
      messageDataSource = null;
    }
    return messageDataSource;
  };

  removeFolder = async () => {
    const request = await deleteFolder(this.state.folderInfo.id);
    if (!request || request.result !== 'OK') {
      throw request;
    } else {
      return request;
    }
  };

  getDescriptionGroup = () => {
    if (!this.state.folderInfo) {
      return <Loading />;
    }

    const save = field_name => async value => {
      const dataToSave = { ...data };
      dataToSave[field_name] = value;
      const request = await updateFolder(data.id, dataToSave);
      if (!request || request.result !== 'OK') {
        throw request;
      } else {
        return request;
      }
    };

    // Same FN for all fields
    const saveSuccess = async () => {
      message.success('Alterações guardadas');
      await this.refreshFolderInfo();
    };

    //Same FN for all fields:
    const saveError = async err => {
      return DetailedMessage.error(
        'Erro! Verifique a sua ligação à internet',
        err
      );
    };

    const data = this.state.folderInfo;
    const aggregated = [
      {
        title: 'ID',
        value: data.id
      },
      {
        title: 'Portfolio',
        value: data.portfolioName
      },
      {
        title: 'Tipo',
        value: data.type,
        editable: true,
        onSave: save('type'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Sub-Tipo',
        value: data.sub_type,
        editable: true,
        onSave: save('sub_type'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      }
    ];

    return aggregated.map(field => (
      <EditableField
        span={12}
        key={field.title}
        editable={field.editable}
        data={data}
        title={field.title}
        value={field.value}
        onSave={field.onSave}
        onSaveSuccess={field.onSaveSuccess}
        onSaveError={field.onSaveError}
      />
    ));
  };

  getProcessDescriptionGroup = () => {
    if (!this.state.folderProcessesMap[this.state.currentProcessId]) {
      return <span>Sem informação</span>;
    }

    const save = field_name => async value => {
      const dataToSave = { ...data };
      dataToSave[field_name] = value;
      const request = await updateProcess(data.id, dataToSave);
      if (!request || request.result !== 'OK') {
        throw request;
      } else {
        return request;
      }
    };

    // Same FN for all fields
    const saveSuccess = async (result, extras = () => {}) => {
      message.success('Alterações guardadas');
      this.setState({ citiusCase: null });
      await this.refreshProcesses();
      await this.refreshCitiusCase();
      if (extras) {
        await extras();
      }
    };

    //Same FN for all fields:
    const saveError = async err => {
      return DetailedMessage.error(
        'Erro! Verifique a sua ligação à internet',
        err
      );
    };

    const data = this.state.folderProcessesMap[this.state.currentProcessId];
    const aggregated = [
      {
        title: 'ID',
        value: data.id
      },

      {
        title: 'Pasta',
        value: data.folder_id,
        editable: true,
        onSave: save('folder_id'),
        onSaveSuccess: result =>
          saveSuccess(result, async () => {
            await this.refreshFolderInfo();
            await this.refreshProcesses();
            await this.refreshCitiusCase();
          }),
        onSaveError: saveError
      },
      { title: 'Portfolio', value: data.portfolioName },
      {
        title: 'CITIUS',
        value: data.process_id,
        editable: true,
        onSave: save('process_id'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Tipo',
        value: data.type,
        editable: true,
        onSave: save('type'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Sub Tipo',
        value: data.sub_type,
        editable: true,
        onSave: save('sub_type'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Estado',
        value: data.status,
        editable: true,
        onSave: save('status'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Fase',
        value: data.stage,
        editable: true,
        onSave: save('stage'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      { title: 'Criação', value: data.create_date },
      {
        title: 'Adv. Int.',
        editable: true,
        value: data.int_lawyer,
        onSave: save('int_lawyer'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      },
      {
        title: 'Adv. Ext.',
        value: data.ext_lawyer,
        editable: true,
        onSave: save('ext_lawyer'),
        onSaveSuccess: saveSuccess,
        onSaveError: saveError
      }
    ];

    return (
      <div
        style={mrg([
          g.layout.flexVertical,
          g.layout.flexStart,
          g.layout.alignStart,
          {
            paddingTop: g.global.baseline,
            paddingBottom: g.global.baseline
          }
        ])}
      >
        <div>
          {aggregated.map(field => (
            <EditableField
              key={field.title}
              editable={field.editable}
              data={data}
              title={field.title}
              value={field.value}
              onSave={field.onSave}
              onSaveSuccess={field.onSaveSuccess}
              onSaveError={field.onSaveError}
            />
          ))}
        </div>
        <div
          style={mrg([
            g.layout.flexHorizontal,
            g.layout.flexStart,
            g.layout.alignCenter
          ])}
        >
          <span style={{ marginRight: g.global.baseline }}>Partes</span>
          <EditableTagGroup
            editable={true}
            tags={
              this.state.folderProcessesMap[this.state.currentProcessId].parts
            }
            onSave={async tags => {
              const currentProcess = this.state.folderProcessesMap[
                this.state.currentProcessId
              ];
              currentProcess.parts = tags;
              const request = await updateProcess(
                currentProcess.id,
                currentProcess
              );
              if (!request || request.result !== 'OK') {
                throw request;
              } else {
                return request;
              }
            }}
            onSaveSuccess={async () => {
              message.success('Alterações guardadas');
              return;
            }}
            onSaveError={async err => {
              DetailedMessage.error(
                'Erro! Verifique a sua ligação à internet',
                err
              );
              return;
            }}
          />
        </div>
      </div>
    );
  };

  getCitiusDescriptionGroup = () => {
    if (!this.state.citiusCase) {
      return null;
    }
    const data = this.state.citiusCase;
    const titles = ['Tribunal', 'Un Orgânica', 'Espécie', 'Estado', 'Valor'];
    const values = [
      data.Tribunal,
      data['Un Orgânica'],
      data.Espécie,
      data.Estado,
      data.Valor
    ];
    const result = [];
    for (let i = 0; i < titles.length; i += 2) {
      result.push(
        <Col
          style={{
            ...g.layout.flexVertical,
            ...g.layout.flexBetween,
            ...g.layout.alignStart,
            paddingRight: g.global.baseline
          }}
          key={i}
          span={8}
        >
          {values[i] && (
            <div style={styles.statistic.container}>
              <span style={mrg([styles.statistic.title, { minWidth: '6rem' }])}>
                {titles[i]}{' '}
              </span>
              <span style={styles.statistic.value}>{values[i]}</span>
            </div>
          )}
          {titles[i + 1] && values[i + 1] && (
            <div style={styles.statistic.container}>
              <span style={mrg([styles.statistic.title, { minWidth: '6rem' }])}>
                {titles[i + 1]}{' '}
              </span>
              <span style={styles.statistic.value}>{values[i + 1]}</span>
            </div>
          )}
        </Col>
      );
    }

    return result;
  };

  onMessageSave = async message => {
    const response = await postMessage(message, this.state.currentProcessId);
    if (!(response && response.result === 'OK')) {
      throw response;
    }
  };

  onMessageUpdate = async message => {
    const response = await putMessage(message._id, message);

    if (!(response && response.result === 'OK')) {
      throw response;
    }
  };

  removeMessage = async message => {
    const request = await deleteMessage(
      message._id,
      this.state.currentProcessId
    );
    if (request && request.result === 'OK') {
      this.setState({ loadingMessages: true });
      await this.refreshProcesses();
      this.setState({ loadingMessages: false });
    }
  };

  onMessageSaveSuccess = async () => {
    message.success('Alterações guardadas com sucesso');
    await this.refreshProcesses();
  };

  handleCollapseChange = key => {
    //nothing
  };

  render() {
    const canDelete = this.context.checkPermission('cm:folders:delete');
    const deleteFolderButton = (
      <Button
        type="danger"
        icon="delete"
        ghost
        disabled={!canDelete}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({ deleteFolderStage: 'confirm-login' });
        }}
      ></Button>
    );
    const deleteFolderModal =
      this.state.folderInfo && this.state.deleteFolderStage ? (
        <Modal
          footer={null}
          visible
          onCancel={() => this.setState({ deleteFolderStage: null })}
        >
          {this.state.deleteFolderStage === 'confirm-login' && (
            <ConfirmLogin
              success={() => {
                console.log('login success');
                this.setState({ deleteFolderStage: 'confirm-delete' });
              }}
              error={() => {
                console.log('login error');
                message.error('Credenciais inválidas');
              }}
              username={this.context.state.userData.email}
            ></ConfirmLogin>
          )}
          {this.state.deleteFolderStage === 'confirm-delete' && (
            <div style={styles.modal.body}>
              <span style={styles.modal.message}>
                Tem a certeza que pretende apagar esta pasta?
              </span>
              <ul style={styles.modal.list}>
                <li style={{ ...styles.modal.error }}>
                  Esta operação é irreversível!
                </li>
                <li style={{ ...styles.modal.error, fontWeight: 700 }}>
                  Todos os processos da pasta serão apagados!
                </li>
              </ul>
              <Button
                type="danger"
                icon="delete"
                onClick={async () => {
                  const response = await this.removeFolder(
                    this.state.folderInfo.id
                  );
                  if (!response || !response.result === 'OK') {
                    DetailedMessage.error('Erro a apagar registo', response);
                  } else {
                    message.success('Apagado com sucesso');
                    this.setState({ deleteFolderStage: 'redirect' });
                  }
                }}
              >
                Apagar
              </Button>
            </div>
          )}
          {this.state.deleteFolderStage === 'redirect' && (
            <Redirect to="/cm/portfolios"></Redirect>
          )}
        </Modal>
      ) : null;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Processo {decodeURIComponent(this.props.match.params.id)}
          </title>
        </Helmet>

        <>
          <Collapse
            style={styles.pageHeader}
            defaultActiveKey={'info'}
            bordered={false}
          >
            {this.state.folderInfo ? (
              <Panel
                key="info"
                header={
                  <div
                    style={mrg([
                      g.layout.flexHorizontal,
                      g.layout.flexStart,
                      { fontSize: g.global.baseline * 2 }
                    ])}
                  >
                    <span style={styles.pageHeaderBox}>
                      Pasta{' '}
                      <span style={styles.pageHeaderContent}>
                        {this.state.folderInfo.name}
                      </span>
                    </span>
                  </div>
                }
                bordered={false}
                style={{ padding: 0, border: 'none' }}
              >
                <div style={mrg([g.layout.flexHorizontal, g.layout.flexStart])}>
                  <Row style={{ width: '100%' }}>
                    <Col span={12}>{this.getDescriptionGroup()}</Col>
                    <Col span={6}></Col>
                    <Col span={6} style={styles.folder.operations.container}>
                      <div style={styles.folder.operations.title}>
                        Operações de Pasta
                      </div>
                      <div style={styles.folder.operations.item}>
                        {deleteFolderButton}
                        {deleteFolderModal}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Panel>
            ) : (
              <Panel key="error">
                <div
                  style={mrg([
                    g.layout.flexHorizontal,
                    g.layout.flexStart,
                    {
                      fontSize: g.global.baseline * 2,
                      paddingLeft: g.global.baseline
                    }
                  ])}
                >
                  <span style={styles.pageHeaderBox}>
                    Pasta{' '}
                    <span style={styles.pageHeaderContent}>
                      indefinida (inexistente)
                    </span>
                  </span>
                </div>
              </Panel>
            )}
          </Collapse>
          <Row style={styles.mainRow}>
            {this.state.folderProcessesMap ? (
              <>
                <Col style={styles.leftCol} span={4}>
                  <Collapse
                    accordion={true}
                    bordered={false}
                    defaultActiveKey={['1']}
                    onChange={this.handleCollapseChange}
                    style={styles.sidebar}
                  >
                    <Panel
                      style={styles.panel}
                      header={makeHeaderSpan('Processos')}
                      key="1"
                    >
                      <List
                        style={styles.processList}
                        itemLayout="vertical"
                        dataSource={this.state.folderProcesses}
                        renderItem={item => (
                          <List.Item style={{ border: 'unset' }} key={item.id}>
                            <List.Item.Meta />
                            <NavLink
                              to={{
                                pathname:
                                  '/cm/processes/single/' +
                                  encodeURIComponent(item.id),
                                state: { folder_id: item.folder_id }
                              }}
                              style={styles.process}
                              activeStyle={styles.activeProcess}
                              isActive={() => {
                                return this.state.currentProcessId === item.id;
                              }}
                            >
                              <span
                                style={{
                                  marginRight: g.global.baseline
                                }}
                              >
                                {item.id}
                              </span>
                              <Tooltip
                                placement="right"
                                title={'Notificações por tratar'}
                              >
                                <Badge dot count={item.totalUnread} />
                                <Badge
                                  dot
                                  style={g.badges.cyan}
                                  count={item.totalUnreadMessage}
                                />
                              </Tooltip>
                            </NavLink>
                          </List.Item>
                        )}
                      />
                    </Panel>
                  </Collapse>
                </Col>

                <Col style={styles.rightCol} span={20}>
                  {this.state.switchingProcesses ? (
                    <Loading />
                  ) : (
                    <>
                      <Collapse
                        style={styles.processHeader.body}
                        defaultActiveKey={/*'info'*/ null}
                        bordered={false}
                      >
                        <Panel
                          key="info"
                          header={
                            <div
                              style={mrg([
                                g.layout.flexHorizontal,
                                g.layout.flexStart,
                                { fontSize: g.global.baseline * 2 }
                              ])}
                            >
                              {this.state.folderProcessesMap[
                                this.state.currentProcessId
                              ] ? (
                                <span style={styles.pageHeaderBox}>
                                  Processo{' '}
                                  <span style={styles.pageHeaderContent}>
                                    {
                                      this.state.folderProcessesMap[
                                        this.state.currentProcessId
                                      ].id
                                    }
                                  </span>
                                </span>
                              ) : (
                                <span style={styles.pageHeaderBox}>
                                  Detalhes de processo em falta
                                </span>
                              )}
                            </div>
                          }
                          bordered={false}
                          style={{ padding: 0, border: 'none' }}
                        >
                          {this.getProcessDescriptionGroup()}
                        </Panel>
                      </Collapse>
                      <div style={styles.rightColInner}>
                        <Tabs defaultActiveKey="citius">
                          {this.context.checkPermission('cm:citius') && (
                            <TabPane
                              tab={
                                <span>
                                  <span
                                    style={{
                                      marginRight: g.global.baseline
                                    }}
                                  >
                                    CITIUS
                                  </span>
                                  <Badge
                                    count={
                                      this.state.folderProcessesMap[
                                        this.state.currentProcessId
                                      ]
                                        ? this.state.folderProcessesMap[
                                            this.state.currentProcessId
                                          ].totalUnread
                                        : 0
                                    }
                                  />
                                </span>
                              }
                              key="citius"
                            >
                              {this.state.folderProcessesMap &&
                              this.state.currentProcessId ? (
                                <>
                                  <div
                                    style={mrg([
                                      g.layout.flexHorizontal,
                                      g.layout.flexStart,
                                      {
                                        borderTop: '1px solid #f0f2f5',
                                        paddingTop: g.global.baseline
                                      }
                                    ])}
                                  >
                                    {this.state.citiusCase ? (
                                      <Row
                                        style={{
                                          width: '100%',
                                          backgroundColor: '#fafafa',
                                          padding: g.global.baseline,
                                          borderBottom: '1px solid #eee'
                                        }}
                                      >
                                        {this.getCitiusDescriptionGroup()}
                                      </Row>
                                    ) : (
                                      <Row style={{ width: '100%' }}>
                                        Sem informação
                                      </Row>
                                    )}
                                  </div>
                                  {this.state.citiusCase && (
                                    <CitiusTable
                                      processID={
                                        this.state.folderProcessesMap[
                                          this.state.currentProcessId
                                        ].process_id
                                      }
                                      onSetStatus={this.refreshProcesses}
                                    />
                                  )}
                                </>
                              ) : null}
                            </TabPane>
                          )}
                          {this.context.checkPermission('cm:uploads') &&
                            this.state.folderProcessesMap?.[
                              this.state.currentProcessId
                            ] && (
                              <TabPane
                                tab={<span>Document Uploads</span>}
                                key="uploads"
                              >
                                <UploadDocumentsComponent
                                  realm={documentRealm}
                                  s3Folder={'cm/'}
                                  client={
                                    this.state.folderProcessesMap?.[
                                      this.state.currentProcessId
                                    ]
                                  }
                                  onUpdate={this.setAttachments}
                                />
                              </TabPane>
                            )}
                          {this.context.checkPermission('cm:messages') && (
                            <TabPane
                              tab={
                                <span>
                                  <span
                                    style={{
                                      marginRight: g.global.baseline
                                    }}
                                  >
                                    Updates
                                  </span>
                                  <Badge
                                    style={g.badges.cyan}
                                    dot={false}
                                    count={
                                      this.state.folderProcessesMap[
                                        this.state.currentProcessId
                                      ]
                                        ? this.state.folderProcessesMap[
                                            this.state.currentProcessId
                                          ].totalUnreadMessage
                                        : 0
                                    }
                                  />
                                </span>
                              }
                              key="message"
                            >
                              <MessageBoard
                                operations={{
                                  checkPermission: this.context.checkPermission,
                                  refreshMessages: this.refreshMessages,
                                  removeMessage: this.removeMessage,
                                  onSave: this.onMessageSave,
                                  onUpdate: this.onMessageUpdate,
                                  onSaveError: defaultDetailedError,
                                  onSaveSuccess: this.onMessageSaveSuccess
                                }}
                                data={{
                                  s3Folder: 'cm/',
                                  enableAssignment: false,
                                  messages: reverse(
                                    this.state.folderProcessesMap?.[
                                      this.state.currentProcessId
                                    ]?.messages
                                  ),
                                  userData: this.context.state.userData,
                                  types: messageTypes.concat(['UPDATE']),
                                  visibilityOptions: messageVisibility,
                                  attachments: this.state.processAttachments
                                }}
                              ></MessageBoard>
                            </TabPane>
                          )}
                          {this.context.checkPermission('cm:process:json') && (
                            <TabPane tab="Process JSON" key="case_json">
                              <pre>
                                {this.state.folderProcessesMap ? (
                                  JSON.stringify(
                                    this.state.folderProcessesMap[
                                      this.state.currentProcessId
                                    ],
                                    null,
                                    2
                                  )
                                ) : (
                                  <Loading />
                                )}
                              </pre>
                            </TabPane>
                          )}
                          {this.context.checkPermission('cm:process:json') && (
                            <TabPane tab="Folder JSON" key="folder_json">
                              <pre>
                                {this.state.folderInfo ? (
                                  JSON.stringify(this.state.folderInfo, null, 2)
                                ) : (
                                  <Loading />
                                )}
                              </pre>
                            </TabPane>
                          )}
                        </Tabs>
                      </div>
                    </>
                  )}
                </Col>
              </>
            ) : (
              <Loading />
            )}
          </Row>
        </>
      </>
    );
  }
}

SingleProcess.contextType = AppContext;

export default SingleProcess;

class EditableTagGroup extends React.Component {
  state = {
    tags: this.props.tags || [],
    inputVisible: false,
    inputValue: '',
    saving: false
  };

  handleClose = async removedTag => {
    this.setState({ saving: true });
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    if (this.props.onSave) {
      try {
        await this.props.onSave(tags);
      } catch (err) {
        if (this.props.onSaveError) {
          await this.props.onSaveError(err);
        } else {
          DetailedMessage.error('Erro!', err);
        }
        return;
      }
      if (this.props.onSaveSuccess) {
        await this.props.onSaveSuccess();
      } else {
        message.success('Sucesso');
      }
    }
    this.setState({ tags, saving: false });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = async () => {
    this.setState({ saving: true });
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    if (this.props.onSave) {
      try {
        await this.props.onSave(tags);
      } catch (err) {
        if (this.props.onSaveError) {
          await this.props.onSaveError(err);
        } else {
          DetailedMessage.error('Erro!', err);
        }
        return;
      }
      if (this.props.onSaveSuccess) {
        await this.props.onSaveSuccess();
      } else {
        message.success('Sucesso');
      }
    }

    this.setState({
      saving: false,
      tags,
      inputVisible: false,
      inputValue: ''
    });
  };

  handleCancel = () => {
    this.setState({ inputValue: '', inputVisible: false });
  };

  saveInputRef = input => (this.input = input);

  render() {
    const { tags, inputVisible, inputValue } = this.state;
    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              closable={this.props.editable}
              onClose={() => this.handleClose(tag)}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            disabled={this.state.saving}
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleCancel}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
          >
            <Icon type="plus" /> New Tag
          </Tag>
        )}
      </div>
    );
  }
}

import React from 'react';

import autobind from 'auto-bind/react';
import { Helmet } from 'react-helmet';
import { Route, Link } from 'react-router-dom';
import {
  Layout,
  Table,
  Button,
  Tooltip,
  Badge,
  message,
  Input,
  Select
} from 'antd';
import moment from 'moment';
import deburr from 'lodash/deburr';

import AppContext from '../../context/AppContextBase';
import globalStyles from '../../styles/global';
import {
  fetchProcesses,
  postProcess,
  postFolder,
  getPortfolios
} from '../../network/cm';

import DetailedMessage from '../../components/util/DetailedMessage';
import InsertProcess from '../../components/InsertProcess';

import ImportProcesses from '../../components/ImportProcesses';
import InsertFolder from '../../components/InsertFolder';
import { mrg } from '../../common/util';
import {
  renderColumn,
  renderDateColumn,
  textSorter,
  dateSorter
} from '../../common/table';

const { Content } = Layout;
const { Option } = Select;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    width: '100%',
    overflowX: 'scroll',
    paddingLeft: globalStyles.global.baseline * 2,
    paddingRight: globalStyles.global.baseline * 2
  },
  table: {
    width: '100%',
    fontSize: globalStyles.global.baseline * 1.2,
    fontWeight: 100,
    border: '1px solid #e8e8e8'
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },

  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '40%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '60%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline,
    paddingBottom: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline / 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  }
};

const columns = [
  {
    title: 'Portfolio',
    dataIndex: 'portfolioName',
    key: 'portfolio',
    width: 100,
    render: (obj, record) => renderColumn(0.5)(obj),
    sorter: textSorter('portfolioName')
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    render: (obj, record) => renderColumn(0.5)(obj),
    sorter: textSorter('id')
  },
  {
    title: 'Pasta',
    dataIndex: 'folder_id',
    key: 'folder_id',
    width: 100,
    render: (obj, record) => renderColumn(0.5)(obj),
    sorter: textSorter('folder_id')
  },
  {
    title: 'Data de Criação',
    dataIndex: 'create_date',
    key: 'create_date',
    width: 100,
    render: renderDateColumn(1),
    sorter: dateSorter('create_date')
  },
  {
    title: 'Num. Processo',
    dataIndex: 'process_id',
    key: 'process_id',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('process_id')
  },

  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('type')
  },
  {
    title: 'Sub-Tipo',
    dataIndex: 'sub_type',
    key: 'sub_type',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('sub_type')
  },
  {
    title: 'Stage',
    dataIndex: 'stage',
    key: 'stage',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('stage')
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('status')
  },
  /*{
    title: 'Advogado Interno',
    dataIndex: 'int_lawyer',
    key: 'int_lawyer',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('int_lawyer')
  },
  {
    title: 'Advogado Externo',
    dataIndex: 'ext_lawyer',
    key: 'ext_lawyer',
    width: 100,
    render: (obj, record) => renderColumn(1)(obj),
    sorter: textSorter('ext_lawyer')
  },*/
  {
    title: 'Notif.',
    key: 'totalUnread',
    width: 100,
    dataIndex: 'totalUnread',
    render: (totalUnread, record) => {
      return {
        props: { colSpan: 1 },
        children: (
          <>
            <Tooltip placement="left" title={'Notificações por tratar'}>
              <Badge
                style={{
                  backgroundColor: 'transparent',
                  color: 'red',
                  boxShadow: '0 0 0 1px red inset',
                  marginRight: globalStyles.global.baseline / 2
                }}
                count={record.totalUnread}
              />
            </Tooltip>
            <Tooltip placement="left" title={'Notificações por tratar'}>
              <Badge
                style={{
                  backgroundColor: 'transparent',
                  color: '#2db7f5',
                  boxShadow: '0 0 0 1px #2db7f5 inset'
                }}
                count={record.totalUnreadMessages}
              />
            </Tooltip>
          </>
        )
      };
    },
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      return (
        (a.totalUnread || 0) + (a.totalUnreadMessages || 0) >
        (b.totalUnread || 0) + (b.totalUnreadMessages || 0)
      );
    }
  },
  {
    title: 'Acção',
    key: 'Acção',
    width: 100,
    render: (text, record) => ({
      props: { colSpan: 1 },
      children: (
        <span>
          <Link
            to={{
              pathname: '/cm/processes/single/' + encodeURIComponent(record.id),
              state: { folder_id: record.folder_id }
            }}
          >
            <Button style={styles.action}>Ver</Button>
          </Link>
        </span>
      )
    })
  }
];

class Processes extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      cmCases: [],
      search: '',
      searching: false,
      showCreateProcess: false,
      showCreateFolder: false,
      portfolioId: this.props.match.params.portFolioId,
      portfolioName: this.props.match.params.portFolioId
    };
    autobind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.refreshPortfolios();
    await this.refresh();
    await this.changePortfolio(this.state.portfolioId);
    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps?.match?.params?.portFolioId !==
      this.props?.match?.params?.portFolioId
    ) {
      await this.changePortfolio(this.props?.match?.params?.portFolioId);
    }
  }

  refreshPortfolios = async () => {
    const request = await getPortfolios();
    if (request && request.result === 'OK') {
      this.portfolios = {};
      //eslint-disable-next-line no-unused-vars
      for (const portfolio of request.data.docs) {
        this.portfolios[portfolio.id] = portfolio;
      }
    }
  };

  refreshUnreads = () => {
    this.setState(state => {
      let totalUnread = 0;
      let totalUnreadMessages = 0;

      const cases = state.cmCases ? state.cmCases : [];
      for (let kase of cases) {
        totalUnread += kase.totalUnread ? kase.totalUnread : 0; // prevent NaNs
        totalUnreadMessages += kase.totalUnreadMessages
          ? kase.totalUnreadMessages
          : 0; // prevent NaNs
      }
      return {
        totalUnread,
        totalUnreadMessages
      };
    });
  };

  async refresh() {
    let processes = await fetchProcesses();

    if (processes?.result === 'OK' && processes?.data?.docs?.length > 0) {
      processes.data.docs.forEach(process => {
        process.create_date = moment(process.create_date);
      });

      this.setState({
        cmCases: processes.data.docs
      });
      this.cmCaseCache = processes.data.docs;
    } else if (processes && processes.result === 'OK') {
      this.setState({
        cmCases: []
      });
      this.cmCaseCache = [];
    } else {
      DetailedMessage.error(
        'Erro a carregar processos da carteira ' + this.state.portfolioId,
        processes
      );
      this.setState({
        cmCases: []
      });
      this.cmCaseCache = [];
    }
  }

  changePortfolio = async (portfolioId, portfolioName) => {
    this.setState(state => {
      let pName = portfolioName;
      if (!portfolioName) {
        const tentativeName = this.portfolios?.[portfolioId]?.name;
        if (tentativeName) {
          pName = tentativeName;
        } else {
          pName = portfolioId;
        }
      }
      let cmCases = this.cmCaseCache;

      if (portfolioId !== 'all') {
        cmCases = cmCases.filter(kase => kase.portfolio_id === portfolioId);
      }

      return { portfolioId, portfolioName: pName, cmCases };
    });
    await this.refreshUnreads();
  };

  async search() {
    this.setState({ searching: true });
    if (!this.state.cmCases || this.state.searching || !this.state.search) {
      this.setState({ searching: false, cmCases: this.cmCaseCache });
      return;
    }

    const cmCases = this.cmCaseCache.filter(kase => {
      const filterByPortfolio = this.state.portfolioId !== 'all';
      if (filterByPortfolio) {
        const notInPortfolio = kase.portfolio_id !== this.state.portfolioId;
        if (notInPortfolio) {
          return false;
        }
      }
      const matchesSearch =
        deburr(JSON.stringify(kase))
          .toLowerCase()
          .indexOf(deburr(this.state.search).toLowerCase()) !== -1;
      return matchesSearch;
    });
    this.setState({ cmCases: cmCases, searching: false });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Processos</title>
          {/* antd overrides */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${globalStyles.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${globalStyles.global.baseline}px;
              line-height: 1;
            }
          `}</style>
        </Helmet>

        <Route
          render={({ history }) => (
            <Content
              style={mrg([
                styles.fullWidth,
                globalStyles.layout.flexVertical,
                globalStyles.layout.flexStart,
                styles.content
              ])}
            >
              <div
                style={mrg([
                  globalStyles.layout.flexVertical,
                  globalStyles.layout.flexStart,
                  styles.fullWidth,
                  {
                    alignItems: 'flex-start'
                  }
                ])}
              >
                <div style={styles.pageHeaderContainer}>
                  <div style={styles.pageHeaderContainerLeft}>
                    <div style={styles.pageHeaderTitle}>
                      <Select
                        style={{ minWidth: '12rem' }}
                        disabled={this.state.loading}
                        value={this.state.portfolioId}
                        onChange={async value => {
                          await this.changePortfolio(value);
                          history.push('/cm/processes/portfolio/' + value);
                        }}
                      >
                        {this.portfolios ? (
                          Object.values(this.portfolios)
                            .map(portfolio => {
                              return (
                                <Option key={portfolio.id} value={portfolio.id}>
                                  {portfolio.name}
                                </Option>
                              );
                            })
                            .concat([
                              <Option key={'all'} value={'all'}>
                                Todos
                              </Option>
                            ])
                        ) : (
                          <Option
                            key={this.state.portfolioId}
                            value={this.state.portfolioId}
                          >
                            {this.state.portfolioName}
                          </Option>
                        )}
                      </Select>

                      <div style={styles.pageHeaderContent}>
                        {this.state?.cmCases?.length} Processos
                      </div>
                    </div>

                    <Tooltip
                      placement="left"
                      title={'Notificações CITIUS por tratar neste portfolio'}
                    >
                      <Badge
                        showZero
                        style={{
                          marginRight: globalStyles.global.baseline / 2
                        }}
                        count={this.state.totalUnread || 0}
                      />
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={'Mensagens por tratar nesta carteira'}
                    >
                      <Badge
                        showZero
                        style={globalStyles.badges.cyan}
                        count={this.state.totalUnreadMessages}
                      />
                    </Tooltip>
                  </div>
                  <div style={styles.pageHeaderContainerRight}>
                    <span style={{ marginRight: globalStyles.global.baseline }}>
                      {!this.state.showCreateFolder && (
                        <Button
                          style={styles.pageHeaderButton}
                          ghost
                          disabled={this.state.loading}
                          type="primary"
                          onClick={() => {
                            this.setState({ showCreateFolder: true });
                          }}
                        >
                          Nova Pasta
                        </Button>
                      )}
                    </span>
                    <span style={{ marginRight: globalStyles.global.baseline }}>
                      <ImportProcesses
                        portfolio={{
                          id: this.state.portfolioId,
                          name: this.state.portfolioName
                        }}
                        disabled={this.state.loading}
                        action={async folder => {
                          return await postFolder(folder);
                        }}
                        error={request => {
                          console.log(request);
                          if (request?.message?.startsWith?.('E11000')) {
                            message.error('Erro a criar pasta: ID duplicado.');
                          } else {
                            message.error(
                              'Erro a gravar pasta: ' + request.message
                            );
                          }
                        }}
                        cancel={() =>
                          this.setState({ showCreateFolder: false })
                        }
                        done={() => {
                          this.refresh();
                          message.success(<span>Sucesso</span>);
                        }}
                      />
                    </span>
                    <span style={{ marginRight: globalStyles.global.baseline }}>
                      {!this.state.showCreateProcess && (
                        <Button
                          disabled={this.state.loading}
                          style={styles.pageHeaderButton}
                          ghost
                          type="primary"
                          onClick={() => {
                            this.setState({ showCreateProcess: true });
                          }}
                        >
                          Novo Processo
                        </Button>
                      )}
                    </span>
                    <span style={styles.pageHeaderBox}>
                      <Input
                        placeholder="Pesquisar"
                        onChange={e => {
                          this.setState({ search: e.target.value });

                          if (!e.target.value) {
                            // reset table
                            this.search();
                          }
                        }}
                        disabled={
                          this.state.loading ||
                          this.state.searching ||
                          !Array.isArray(this.state.cmCases)
                        }
                        value={this.state.search}
                        onPressEnter={this.search}
                      />
                    </span>
                  </div>
                </div>

                {this.state.showCreateFolder && (
                  <InsertFolder
                    action={async folder => {
                      return await postFolder(folder);
                    }}
                    error={request => {
                      console.log(request);
                      if (request?.message?.startsWith?.('E11000')) {
                        message.error('Erro a criar pasta: ID duplicado.');
                      } else {
                        message.error(
                          'Erro a gravar pasta: ' + request.message
                        );
                      }
                    }}
                    cancel={() => this.setState({ showCreateFolder: false })}
                    portfolio={{
                      name: this.props.match.params.portFolioId,
                      id: this.props.match.params.portFolioId
                    }}
                    done={() => {
                      this.refresh();
                      message.success(<span>Sucesso</span>);
                    }}
                  />
                )}
                {this.state.showCreateProcess && (
                  <InsertProcess
                    action={async cmCase => await postProcess(cmCase)}
                    cancel={() => this.setState({ showCreateProcess: false })}
                    portfolio={{
                      name: this.props.match.params.portFolioId,
                      id: this.props.match.params.portFolioId
                    }}
                    error={request => {
                      console.log(request);
                      if (request?.message?.startsWith?.('E11000')) {
                        message.error('Erro a criar processo: ID duplicado.');
                      } else {
                        message.error(
                          'Erro a gravar processo: ' + request.message
                        );
                      }
                    }}
                    done={async request => {
                      await this.refresh();
                      message.success(<span>Alterações Guardadas</span>);
                    }}
                  />
                )}
                <Table
                  loading={this.state.loading}
                  style={styles.table}
                  rowKey={record => record.id}
                  columns={columns}
                  dataSource={this.state.cmCases}
                  pagination={{
                    position: 'bottom',
                    showSizeChanger: true,
                    total: this.state.cmCases?.length || 0,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 100,
                    pageSizeOptions: [
                      '10',
                      '20',
                      '30',
                      '40',
                      '50',
                      '100',
                      '200'
                    ],
                    onShowSizeChange: (current, size) => {
                      console.warn(
                        'save page size change on user settings - current: ',
                        current,
                        ' size: ',
                        size
                      );
                    }
                  }}
                />
              </div>
            </Content>
          )}
        />
      </>
    );
  }
}

export default Processes;

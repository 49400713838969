import mapFields from '../util/mapFields';
import { save } from './operations';
import EditableField from '../../../components/util/EditableField';
const { Types } = EditableField;

const base = ({
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {}
}) => {
  const fieldMap = [
    {
      title: 'Situation',
      key: 'Situação',
      editable: false,
      span: 12
    },
    { placeholder: 24 },
    {
      title: 'Passp.',
      key: 'id',
      editable: false,
      span: 6
    },
    {
      title: 'NIF',
      key: 'nif',
      editable: false,
      span: 6
    },
    {
      title: 'Name',
      key: 'Nome',
      editable: false,
      span: 12
    },
    {
      title: 'Email',
      key: 'Email',
      editable: false,
      span: 12
    },
    {
      title: 'National.',
      key: 'Nacionalidade',
      editable: false,
      span: 6
    },

    {
      title: 'Birth Date',
      key: 'Data Nascimento',
      type: Types.date,
      editable: false,
      span: 6
    }
  ];

  return mapFields(data, fieldMap, save, onSaveSuccess, onSaveError, style);
};

export default base;

import React from 'react';
import AppContext from '../../context/AppContextBase';
import {
  NotificationContextConsumer,
  OPERATIONS
} from '../../context/NotificationContext';
import { Helmet } from 'react-helmet';

import { Tabs, Badge, message, Icon, Modal } from 'antd';

import Accouting from '../../components/accounting/Accouting';
import MessageBoard from '../../components/messages/MessageBoard';
import {
  messageTypes,
  messageVisibility,
  auditMessageTypes,
  auditMessageVisibility
} from '../../components/messages/messageTypes';

import ArrayFieldTable from './tables/ArrayFieldTable';
import paymentColumns from './tables/paymentColumns';
import scheduleColumns from './tables/scheduleColumns';
import documentColumns from './tables/documentColumns';

import DetailedMessage from '../../components/util/DetailedMessage';
import Loading from '../../components/Loading';
import NoData from '../../components/util/NoData';

import headerFields from './fields/header';
import baseFields from './fields/base';
import ariFields from './fields/ari';

import ProcessTabs from './tables/ProcessTabs';
import GoldenVisa from './processes/GoldenVisa';
import PermanentResidecy from './processes/PermanentResidency';
import Standard from './processes/Standard';

import UploadDocumentsComponent from '../../components/DocumentUploadsList';

import {
  getClient,
  putClientArray,
  deleteMessage,
  postMessage,
  putMessage,
  deleteReply,
  postReply,
  putReply,
  postProcess,
  deleteProcess,
  postPipelineStage
} from '../../network/sef';
import { fetchDocuments } from '../../network/documentUploads';

import { getUsers } from '../../network/users';
import g from '../../styles/global';

const { TabPane } = Tabs;
const { confirm } = Modal;

const styles = {
  container: {
    full: {
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      padding: g.global.baseline,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  tabs: {
    main: {
      width: '100%',
      height: '100%'
    },
    tabBar: { marginBottom: 0 },
    pane: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      borderTop: '1px solid #f0f2f5',
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      height: '100%',
      overflowY: 'scroll'
    },
    label: {
      marginRight: g.global.baseline
    }
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  }
};

const processTypes = {
  golden_visa: {
    key: 'golden_visa',
    component: GoldenVisa,
    label: 'Golden Visa',
    pipelineStages: {
      concession: { key: 'concession', label: 'Concession' },
      renewal: { key: 'renewal', label: 'Renewal' }
    }
  },
  permanent_residency: {
    key: 'permanent_residency',
    component: PermanentResidecy,
    label: 'Permanent Residency',
    pipelineStages: {
      concession: { key: 'concession', label: 'Concession' },
      renewal: { key: 'renewal', label: 'Renewal' }
    }
  },
  standard: {
    key: 'standard',
    component: Standard,
    label: 'Standard',
    pipelineStages: {
      // changeMe: { key: 'changeMe', label: 'Change Me' },
    }
  }
};

const defaultDetailedError = async err =>
  DetailedMessage.error(
    'Error! Check your internet connection or access permissions.',
    err
  );

const defaultSuccess = async () => {
  // do not await or return. this blocks async calls that use await, which we don't want in this case.
  message.success('Changes Saved');
};

const reverse = data => {
  let result = data;
  if (Array.isArray(data)) {
    result = [].concat(data).reverse();
  }
  return result;
};

const documentRealm = 'investor';

class SingleClient extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.state = {
      activeTabKey: 'ari',
      client: props.client,
      isDescendant: !!props.client.parentId,
      clientAttachments: []
    };
  }

  async componentDidMount() {
    let request = await getUsers();
    let users = [],
      clientAttachments = [];
    if (request && request.result === 'OK') {
      users = request.data.filter(
        user =>
          user.permissions.department.toLowerCase() === 'id' ||
          user.role === 'dev'
      );
    }

    const parentId = this.props.client?.parentMongoId || this.props.client._id;
    request = await fetchDocuments(documentRealm, parentId);
    if (request.result === 'OK' && request.data.docs) {
      clientAttachments = request.data.docs;
    }
    this.setState({ users, clientAttachments });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.client._id !== this.props.client._id) {
      this.setState({ loading: true });

      this.setState((state, props) => ({
        client: props.client,
        isDescendant: !!props.client.parentId,
        loading: false
      }));
    }
  }

  isAssignedToMe = item => {
    return item.assignee?._id === this.context.state.userData._id;
  };

  getArrayItem(arrayName, _id) {
    let arrayToSearch = this.state.client?.[arrayName];
    if (!arrayToSearch) {
      arrayToSearch = this.state.client?.[arrayName?.toLowerCase?.()];
    }

    return arrayToSearch?.find?.(item => item._id === _id);
  }

  setAttachments = attachments =>
    this.setState({ clientAttachments: attachments });

  getUnreadActions = (oldItem, newItem, arrayName) => {
    // Here be dragons

    const result = [];

    const newItemAssignedToMe = this.isAssignedToMe(newItem);
    const oldItemAssignedToMe = this.isAssignedToMe(oldItem);
    const oldItemUnassigned = oldItem.assignee === null;
    const newItemUnassigned = newItem.assignee === null;

    // console.group('client');
    const unreadsExist =
      oldItem.unread !== undefined && newItem.unread !== undefined;
    const unreadsDiffer = oldItem.unread !== newItem.unread;
    if (unreadsExist && unreadsDiffer) {
      // console.log('unreads differ');
      const action1 = { operation: null, path: '' };

      if (oldItem.unread) {
        action1.operation = OPERATIONS.decrement;
      } else {
        action1.operation = OPERATIONS.increment;
      }

      if (oldItemAssignedToMe) {
        action1.path += 'todo';
      } else {
        action1.path += 'unread';
      }

      if (arrayName) {
        action1.path += arrayName;
        result.push(action1);
      } else {
        throw new Error('getUnreadOperations: missing arrayName');
      }
    }

    // console.log('--- finished simple unreads ---');

    const oldAssignee = oldItem.assignee;
    const newAssignee = newItem.assignee;
    const assigneesDiffer = oldAssignee !== newAssignee;
    const assigneesExist =
      oldAssignee !== undefined && newAssignee !== undefined;

    // FIXME: itemIsUnread only "looks" at the old item. This works because assignees and unread states change separately.
    // If they change both at the same time this will break, and we will need more cases (else-ifs)
    const itemIsUnread = oldItem.unread === true;
    if (assigneesExist && assigneesDiffer && itemIsUnread) {
      // console.log('assignees differ');
      const action2 = { operation: null, path: 'todo' + arrayName };

      if (newItemAssignedToMe) {
        // console.log('new item is assigned to me');

        action2.operation = OPERATIONS.increment;
        result.push(action2);

        if (oldItemUnassigned) {
          // console.log('old item is unassigned');
          const action3 = {
            operation: OPERATIONS.decrement,
            path: 'unread' + arrayName
          };
          result.push(action3);
        }
      } else if (oldItemAssignedToMe) {
        // console.log('old item was assigned to me');
        action2.operation = OPERATIONS.decrement;
        result.push(action2);

        if (newItemUnassigned) {
          // console.log('new item is unassigned');
          const action3 = {
            operation: OPERATIONS.increment,
            path: 'unread' + arrayName
          };
          result.push(action3);
        }
      } else if (oldItemUnassigned) {
        // console.log('old item was unassigned');
        const action2 = {
          operation: OPERATIONS.decrement,
          path: 'unread' + arrayName
        };
        result.push(action2);
      } else if (newItemUnassigned) {
        // console.log('new item is unassigned');
        const action2 = {
          operation: OPERATIONS.increment,
          path: 'unread' + arrayName
        };
        result.push(action2);
      } else {
        //nothing. Assignees have changed, but it does not affect current user
      }
    }

    // console.groupEnd('client');

    return result;
  };

  updateUnreads = (oldItem = null, newItem, arrayName = null) => {
    if (!oldItem) {
      oldItem = this.getArrayItem(arrayName, newItem._id);
    }
    const actions = this.getUnreadActions(oldItem, newItem, arrayName);

    if (Array.isArray(actions)) {
      for (const action of actions) {
        this.props.notificationContext.update(
          this.state.client._id,
          action.operation,
          action.path
        );
      }
    }
  };

  saveMessage = async data => {
    let result;

    result = await postMessage(
      data,
      this.state.isDescendant
        ? this.state.client.parentId
        : this.state.client._id,
      this.state.isDescendant ? this.state.client._id : null
    );

    if (result.error) {
      throw result.error;
    }
    if (data.type === 'TASK' && data.unread === true) {
      const path = this.isAssignedToMe(data)
        ? 'todoMessages'
        : 'unreadMessages';
      this.props.notificationContext.update(
        this.state.client._id,
        OPERATIONS.increment,
        path
      );
    }
    await this.refreshMessageState();
  };

  updateMessage = async message => {
    const previousData = this.getArrayItem('Messages', message._id);

    this.updateUnreads(previousData, message, 'Messages');

    const result = await putMessage(message._id, message);

    if (result.error) {
      throw result.error;
    }

    await this.refreshMessageState();
  };

  removeMessage = async message => {
    const self = this;
    return new Promise(r =>
      confirm({
        okButtonProps: { ghost: true },
        title: (
          <span style={{ fontSize: g.global.baseline * 2 }}>Delete entry</span>
        ),
        icon: (
          <Icon
            type="warning"
            style={{
              fontSize: g.global.baseline * 2.5,
              color: g.colors.feedback.error
            }}
          />
        ),
        content: (
          <div style={styles.modal.body}>
            <span style={styles.modal.message}>
              Are you sure you want to delete this entry?
            </span>
            <ul style={styles.modal.list}>
              <li style={{ ...styles.modal.error, fontWeight: 700 }}>
                This operation is irreversible!
              </li>
            </ul>
          </div>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        async onOk() {
          const result = await deleteMessage(
            message._id,
            self.state.isDescendant
              ? self.state.client.parentId
              : self.state.client._id,
            self.state.isDescendant ? self.state.client._id : null
          );
          if (result.error) {
            throw result.error;
          }
          await self.updateUnreads(message, { unread: false }, 'Messages');
          await self.refreshMessageState();
          return r();
        }
      })
    );
  };

  saveReply = async (messageId, data) => {
    let result;

    result = await postReply(messageId, data);

    if (result.error) {
      throw result.error;
    }
    if (data.unread === true) {
      const path = this.isAssignedToMe(data)
        ? 'todoMessages'
        : 'unreadMessages';
      this.props.notificationContext.update(
        this.state.client._id,
        OPERATIONS.increment,
        path
      );
    }
    await this.refreshMessageState();
  };

  updateReply = async (messageId, replyId, newData) => {
    const previousMessage = this.getArrayItem('Messages', messageId);
    const previousReply = previousMessage?.replies?.find(
      reply => reply?._id === replyId
    );

    this.updateUnreads(previousReply, newData, 'Messages');

    const result = await putReply(messageId, replyId, newData);

    if (result.error) {
      throw result.error;
    }

    this.setState(state => {
      const newClient = { ...state.client };
      const prevMessage = newClient.messages.find(
        item => item._id === messageId
      );
      const reply = prevMessage?.replies?.find?.(
        reply => reply._id === replyId
      );
      if (reply) {
        for (const key in newData) {
          reply[key] = newData[key];
        }

        return { client: newClient };
      }
      return null;
    });
  };

  removeReply = async (messageId, replyId) => {
    const self = this;
    return new Promise(r =>
      confirm({
        okButtonProps: { ghost: true },
        title: (
          <span style={{ fontSize: g.global.baseline * 2 }}>Delete entry</span>
        ),
        icon: (
          <Icon
            type="warning"
            style={{
              fontSize: g.global.baseline * 2.5,
              color: g.colors.feedback.error
            }}
          />
        ),
        content: (
          <div style={styles.modal.body}>
            <span style={styles.modal.message}>
              Are you sure you want to delete this entry?
            </span>
            <ul style={styles.modal.list}>
              <li style={{ ...styles.modal.error, fontWeight: 700 }}>
                This operation is irreversible!
              </li>
            </ul>
          </div>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        async onOk() {
          const result = await deleteReply(messageId, replyId);

          if (result.error) {
            throw result.error;
          }

          self.setState(state => {
            const newClient = { ...state.client };
            const prevMessage = newClient.messages.find(
              item => item._id === messageId
            );
            if (prevMessage) {
              prevMessage.replies = prevMessage.replies.filter(
                reply => reply._id !== replyId
              );
              return { client: newClient };
            }
            return null;
          });
          return r();
        }
      })
    );
  };

  getCurrentClientMessages = async () => {
    let client;
    if (this.state.isDescendant) {
      client = await getClient(this.state.client.parentId);
      if (!(client && client.result === 'OK')) {
        return this.state.client.messages;
      }
      client = client.data;
      const family = client.Familia.filter(
        fam => fam._id === this.state.client._id
      );
      if (!family || !family.length === 1) {
        return this.state.client.messages;
      }
      client = family[0];
      return client.messages;
    } else {
      client = await getClient(this.state.client._id);
      if (!(client && client.result === 'OK')) {
        return this.state.client.messages;
      }
      client = client.data;
      return client.messages;
    }
  };

  refreshMessageState = async () => {
    const client = this.state.client;
    if (client) {
      client.messages = await this.getCurrentClientMessages();
      this.setState({ client: { ...client } });
      await this.props.processUnreadMessages();
    }
  };

  async saveArrayData(arrayName, itemData) {
    this.setState({ loading: true });
    const toSave = {
      _id: this.state.client._id,
      parentId: this.state.client.parentId,
      arrayName: arrayName,
      itemData: itemData
    };

    const response = await putClientArray(toSave);

    if (response && response.result === 'OK') {
      await this.setState((state, props) => {
        const newClient = { ...state.client };

        const item = newClient[arrayName].filter(
          item => item._id === itemData._id
        );
        if (item && item.length === 1) {
          this.updateUnreads(item[0], itemData, arrayName);
          Object.assign(item[0], itemData);

          return { client: newClient, loading: false };
        } else {
          console.error(
            'SingleClient.saveArrayData - tried to update ONE item in an array [' +
              arrayName +
              '] but found more than one item: ',
            { item }
          );
        }
      });
    }
    this.setState({ loading: false });

    return response;
  }

  async updateClient(options = null) {
    if (!options || !this.props.onSaveSuccess) {
      return;
    }
    return await this.props.onSaveSuccess(this.state.client._id, options);
  }

  render() {
    return (
      <NotificationContextConsumer>
        {notificationContext => (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{this.state.client.Nome}</title>
              <style type="text/css">{`
          .ReactVirtualized__Table__Grid { overflow: visible !important; }
          `}</style>
            </Helmet>

            <div style={styles.container.full}>
              {this.state.client &&
                headerFields({
                  data: this.state.client,
                  style: { paddingLeft: 0, paddingRight: 0 },
                  onSaveSuccess: async () => {
                    await this.refreshMessageState();
                    message.success('Value saved');
                  }
                })}
              <Tabs
                onChange={activeKey =>
                  this.setState({ activeTabKey: activeKey })
                }
                style={styles.tabs.main}
                tabBarStyle={styles.tabs.tabBar}
                defaultActiveKey={this.state.activeTabKey}
              >
                {this.context.checkPermission('id:client:ari') && (
                  <TabPane
                    tab={
                      <span>
                        <span style={styles.tabs.label}>ARI</span>
                        <Badge
                          count={
                            notificationContext.get(
                              this.state.client._id,
                              'unreadAri'
                            ) +
                            notificationContext.get(
                              this.state.client._id,
                              'todoAri'
                            )
                          }
                        />
                      </span>
                    }
                    key="ari"
                  >
                    <div style={styles.tabs.pane}>
                      {this.state.client ? (
                        ariFields({
                          data: this.state.client,
                          style: { paddingLeft: 0, paddingRight: 0 },
                          onSaveSuccess: async () => {
                            await this.refreshMessageState();
                            message.success('Value saved');
                            return;
                          }
                        })
                      ) : (
                        <NoData></NoData>
                      )}
                      <Tabs
                        style={styles.tabs.main}
                        defaultActiveKey="pagamentos"
                      >
                        <TabPane
                          tab={
                            <span>
                              <span style={styles.tabs.label}>Payments</span>
                              <Badge
                                count={
                                  notificationContext.get(
                                    this.state.client._id,
                                    'unreadPagamentos'
                                  ) +
                                  notificationContext.get(
                                    this.state.client._id,
                                    'todoPagamentos'
                                  )
                                }
                              />
                            </span>
                          }
                          style={styles.tabs.pane}
                          key="pagamentos"
                        >
                          <ArrayFieldTable
                            style={{
                              height: 'auto',
                              paddingBottom: g.global.baseline * 6
                            }}
                            autoSizerProps={{ disableHeight: true }}
                            extras={{
                              client: this.state.client,
                              user: this.context.state.userData,
                              users: this.state.users
                                ? this.state.users.filter(
                                    user =>
                                      user.permissions.scope === 'internal'
                                  )
                                : [],
                              loading: this.state.loading
                            }}
                            data={this.state.client.Pagamentos}
                            getColumns={paymentColumns}
                            onSave={async data => {
                              return await this.saveArrayData(
                                'Pagamentos',
                                data
                              );
                            }}
                            onSaveSuccess={async response => {
                              await this.refreshMessageState();
                            }}
                            onSaveError={async response => {
                              DetailedMessage.error(
                                'Error saving data',
                                response
                              );
                            }}
                          ></ArrayFieldTable>
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              <span style={styles.tabs.label}>Schedules</span>
                              <Badge
                                count={
                                  notificationContext.get(
                                    this.state.client._id,
                                    'unreadAgendamentos'
                                  ) +
                                  notificationContext.get(
                                    this.state.client._id,
                                    'todoAgendamentos'
                                  )
                                }
                              />
                            </span>
                          }
                          style={styles.tabs.pane}
                          key="agendamentos"
                        >
                          <ArrayFieldTable
                            style={{
                              height: 'auto',
                              paddingBottom: g.global.baseline * 6
                            }}
                            autoSizerProps={{ disableHeight: true }}
                            data={this.state.client.Agendamentos}
                            getColumns={scheduleColumns}
                            extras={{
                              client: this.state.client,
                              user: this.context.state.userData,
                              users: this.state.users
                                ? this.state.users.filter(
                                    user =>
                                      user.permissions.scope === 'internal'
                                  )
                                : [],
                              loading: this.state.loading
                            }}
                            onSave={async data => {
                              return await this.saveArrayData(
                                'Agendamentos',
                                data
                              );
                            }}
                            onSaveSuccess={async response => {
                              await this.refreshMessageState();
                            }}
                          ></ArrayFieldTable>
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              <span style={styles.tabs.label}>Documents</span>
                              <Badge
                                count={
                                  notificationContext.get(
                                    this.state.client._id,
                                    'unreadDocumentos'
                                  ) +
                                  notificationContext.get(
                                    this.state.client._id,
                                    'todoDocumentos'
                                  )
                                }
                              />
                            </span>
                          }
                          style={styles.tabs.pane}
                          key="documentos"
                        >
                          <ArrayFieldTable
                            style={{
                              height: 'auto',
                              paddingBottom: g.global.baseline * 6
                            }}
                            autoSizerProps={{ disableHeight: true }}
                            extras={{
                              client: this.state.client,
                              user: this.context.state.userData,
                              users: this.state.users
                                ? this.state.users.filter(
                                    user =>
                                      user.permissions.scope === 'internal'
                                  )
                                : [],
                              loading: this.state.loading
                            }}
                            data={this.state.client.Documentos}
                            getColumns={documentColumns}
                            onSave={async data => {
                              return await this.saveArrayData(
                                'Documentos',
                                data
                              );
                            }}
                            onSaveSuccess={async response => {
                              await this.refreshMessageState();
                            }}
                          ></ArrayFieldTable>
                        </TabPane>
                      </Tabs>
                    </div>
                  </TabPane>
                )}
                {this.context.checkPermission('id:client:base') && (
                  <TabPane
                    tab={<span style={styles.tabs.label}>Client Details</span>}
                    key="base"
                  >
                    <div style={styles.tabs.pane}>
                      {this.state.client ? (
                        baseFields({
                          isDescendant: this.state.isDescendant,
                          data: this.state.client,
                          style: { paddingLeft: 0, paddingRight: 0 },
                          onSaveSuccess: async options => {
                            //await this.refreshMessageState();
                            //console.log('SingleClient saving ', options);
                            await this.updateClient(options);
                            message.success('Value saved');
                            return;
                          },
                          onSaveError: error => {
                            DetailedMessage.error(
                              'Error saving data - ' + error.message,
                              error
                            );
                          },
                          editable: this.context.checkPermission(
                            'id:client:processes:editable'
                          ),
                          hideInvalidFields: !this.context.checkPermission(
                            'id:client:processes:protectedFields'
                          )
                        })
                      ) : (
                        <NoData></NoData>
                      )}
                    </div>
                  </TabPane>
                )}

                {this.context.checkPermission('id:client:uploads') &&
                  !this.state.isDescendant &&
                  this.state.client && (
                    <TabPane
                      tab={
                        <span style={styles.tabs.label}>Document Uploads</span>
                      }
                      key="uploads"
                    >
                      {!this.state.isDescendant && this.state.client ? (
                        <UploadDocumentsComponent
                          realm={documentRealm}
                          s3Folder="id/"
                          client={this.state.client}
                          onUpdate={this.setAttachments}
                        />
                      ) : (
                        <NoData></NoData>
                      )}
                    </TabPane>
                  )}
                {!this.state.isDescendant &&
                  this.context.checkPermission('id:client:accounting') && (
                    <TabPane
                      tab={
                        <span>
                          <span
                            style={{
                              marginRight: g.global.baseline
                            }}
                          >
                            Accounting
                          </span>
                        </span>
                      }
                      key="accounting"
                    >
                      <div style={styles.tabs.pane}>
                        <Accouting
                          allowCreation={this.context.checkPermission(
                            'id:client:accounting'
                          )}
                          client={this.state.client}
                        ></Accouting>
                      </div>
                    </TabPane>
                  )}
                {this.context.checkPermission('id:client:messages') && (
                  <TabPane
                    tab={
                      <span>
                        <span style={styles.tabs.label}>Updates</span>
                        <Badge
                          style={g.badges.cyan}
                          dot={false}
                          count={
                            notificationContext.get(
                              this.state.client._id,
                              'unreadMessages'
                            ) +
                            notificationContext.get(
                              this.state.client._id,
                              'todoMessages'
                            )
                          }
                        />
                      </span>
                    }
                    key="messages"
                  >
                    <div style={styles.tabs.pane}>
                      {!this.state.loading && (
                        <MessageBoard
                          operations={{
                            checkPermission: this.context.checkPermission,
                            refreshMessages: async () =>
                              await this.refreshMessageState(messageTypes),
                            removeMessage: this.removeMessage,
                            onSave: this.saveMessage,
                            onUpdate: this.updateMessage,
                            removeReply: this.removeReply,
                            onSaveReply: this.saveReply,
                            onUpdateReply: this.updateReply,
                            onSaveError: defaultDetailedError,
                            onSaveSuccess: defaultSuccess
                          }}
                          data={{
                            enableReplies: true,
                            s3Folder: 'id/',
                            messages: reverse(this.state.client.messages),
                            users: this.state.users,
                            types: messageTypes,
                            userData: this.context.state.userData,
                            visibilityOptions: messageVisibility,
                            attachments: this.state.clientAttachments
                          }}
                        ></MessageBoard>
                      )}
                    </div>
                  </TabPane>
                )}
                {this.context.checkPermission('id:client:processes') && (
                  <TabPane
                    style={styles.tabs.pane}
                    tab={
                      <span>
                        <span style={styles.tabs.label}>Processes</span>
                        {/* <Badge count={'!'} /> */}
                      </span>
                    }
                    key="processes"
                  >
                    <ProcessTabs
                      newProcess={this.newProcess}
                      deleteProcess={this.deleteProcess}
                      newPipelineStage={this.newPipelineStage}
                      onSaveError={defaultDetailedError}
                      onSaveSuccess={defaultSuccess}
                      types={processTypes}
                      data={this.state.client}
                    ></ProcessTabs>
                  </TabPane>
                )}
                {this.context.checkPermission('id:client:audit') && (
                  <TabPane
                    tab={
                      <span>
                        <span
                          style={{
                            marginRight: g.global.baseline
                          }}
                        >
                          Audit
                        </span>
                        {/* // 06 Fev 2021: removed audit notifications, requested by SSR
                        <Badge
                          style={g.badges.grey}
                          dot={false}
                          count={notificationContext.get(
                            this.state.client._id,
                            'unreadAudit'
                          )}
                        /> */}
                      </span>
                    }
                    key="audit"
                  >
                    <div style={styles.tabs.pane}>
                      <MessageBoard
                        operations={{
                          // DEPRECATED
                          // getUserAttribute: this.context.getUserAttribute,
                          checkPermission: this.context.checkPermission,
                          refreshMessages: async () =>
                            await this.refreshMessageState(auditMessageTypes),
                          removeMessage: this.removeMessage,
                          onSave: this.saveMessage,
                          onUpdate: this.updateMessage,
                          onSaveError: defaultDetailedError,
                          onSaveSuccess: defaultSuccess
                        }}
                        data={{
                          s3Folder: 'id/',
                          messages: reverse(this.state.client.messages),
                          users: this.state.users,
                          types: auditMessageTypes,
                          userData: this.context.state.userData,
                          visibilityOptions: auditMessageVisibility,
                          allowCreation: false,
                          allowDelete: false,
                          attachments: this.state.clientAttachments
                        }}
                      ></MessageBoard>
                    </div>
                  </TabPane>
                )}
                {this.context.checkPermission('id:client:json') && (
                  <TabPane
                    style={{
                      height: '100%',
                      width: '100%'
                    }}
                    tab="JSON"
                    key="client_json"
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        overflowY: 'scroll',
                        overflowWrap: 'normal'
                      }}
                    >
                      <pre
                        style={{
                          width: '100%'
                        }}
                      >
                        {this.state.client ? (
                          JSON.stringify(this.state.client, null, 2)
                        ) : (
                          <Loading />
                        )}
                      </pre>
                    </div>
                  </TabPane>
                )}
              </Tabs>
            </div>
          </>
        )}
      </NotificationContextConsumer>
    );
  }

  /* Processes */

  newProcess = async kind => {
    const attributes = {
      owner: this.state.client._id,
      kind: kind
    };
    if (this.state.client.parentId) {
      attributes.parentId = this.state.client.parentId;
    }
    const result = await postProcess(attributes);
    if (result && result.result === 'OK') {
      this.setState((state, props) => {
        const currentClient = { ...this.state.client };
        currentClient.processes.push(result.data);
        return { client: currentClient };
      });
    }
    return result;
  };
  deleteProcess = async _id => {
    const result = await deleteProcess(_id);
    if (result && result.result === 'OK') {
      this.setState((state, props) => {
        const currentClient = { ...this.state.client };
        currentClient.processes = currentClient.processes.filter(
          item => item._id !== _id
        );
        return { client: currentClient };
      });
    }
    return result;
  };
  newPipelineStage = async (_id, type) => {
    const result = await postPipelineStage(_id, {
      type: type
    });
    if (result && result.result === 'OK') {
      this.setState((state, props) => {
        const currentClient = { ...this.state.client };
        try {
          const proc = currentClient.processes.filter(
            item => item._id === _id
          )[0];
          proc.pipeline.push(result.data);
        } catch (err) {
          console.error('Pipeline push error: ', err);
          DetailedMessage.error(
            'Error refreshing pipeline stages. Please try refreshing this page.',
            err
          );
        }
        return { client: currentClient };
      });
    }
    return result;
  };
}

SingleClient.contextType = AppContext;

export default SingleClient;

import { countUnread } from '../../../common/table';
import {
  messageTypes,
  auditMessageTypes
} from '../../../components/messages/messageTypes';

const isUnread = item => {
  return !item.assignee;
};

const isUserTodo = user_id => item => {
  return item.assignee && item.assignee._id === user_id;
};

const processClient = (client, user_id, skip) => {
  let result = {};
  const isTodo = isUserTodo(user_id);
  if (!skip.includes('ari')) {
    // unreads

    const unreadPagamentos = client.Pagamentos
      ? countUnread(client.Pagamentos.filter(isUnread))
      : 0;
    result.unreadPagamentos = unreadPagamentos;

    const unreadAgendamentos = client.Agendamentos
      ? countUnread(client.Agendamentos.filter(isUnread))
      : 0;
    result.unreadAgendamentos = unreadAgendamentos;

    const unreadDocumentos = client.Documentos
      ? countUnread(client.Documentos.filter(isUnread))
      : 0;
    result.unreadDocumentos = unreadDocumentos;

    // total unreads
    result.unreadAri = unreadAgendamentos + unreadDocumentos + unreadPagamentos;

    // todos

    const todoPagamentos = client.Pagamentos
      ? countUnread(client.Pagamentos.filter(isTodo))
      : 0;
    result.todoPagamentos = todoPagamentos;

    const todoAgendamentos = client.Agendamentos
      ? countUnread(client.Agendamentos.filter(isTodo))
      : 0;
    result.todoAgendamentos = todoAgendamentos;

    const todoDocumentos = client.Documentos
      ? countUnread(client.Documentos.filter(isTodo))
      : 0;
    result.todoDocumentos = todoDocumentos;

    // totol todos
    result.todoAri = todoPagamentos + todoAgendamentos + todoDocumentos;
  }

  const log = (client, message) => {
    if (client.Nome === 'AAAA') {
      console.log(message);
    }
  };

  let unreadMessages = 0;
  let todoMessages = 0;
  if (client.messages) {
    const allReplies = client.messages.reduce((accumulator, message) => {
      return accumulator.concat(message.replies);
    }, []);

    // unread

    const messagesUnassigned = client.messages.filter(
      message => messageTypes.includes(message.type) && !message.assignee
    );
    log(client, { messagesUnassigned });
    unreadMessages = countUnread(messagesUnassigned);
    log(client, { unreadMessages });

    const repliesUnassigned = allReplies.filter(reply => !reply.assignee);
    unreadMessages += countUnread(repliesUnassigned);

    // todo

    const messagesAssignedToMe = client.messages.filter(
      message =>
        messageTypes.includes(message.type) && message.assignee?._id === user_id
    );
    log(client, { messagesAssignedToMe });
    todoMessages = countUnread(messagesAssignedToMe);
    log(client, { todoMessages });

    const repliesAssignedToMe = allReplies.filter(
      reply => reply.assignee?._id === user_id
    );
    todoMessages += countUnread(repliesAssignedToMe);
  }

  let unreadAudit = 0;
  if (client.messages) {
    unreadAudit = countUnread(
      client.messages.filter(message =>
        auditMessageTypes.includes(message.type)
      )
    );
  }

  if (!skip.includes('messages')) {
    result.unreadMessages = unreadMessages ? unreadMessages : false;
  }
  if (!skip.includes('audit')) {
    result.unreadAudit = unreadAudit ? unreadAudit : false;
  }
  if (!skip.includes('todoMessages')) {
    result.todoMessages = todoMessages ? todoMessages : false;
  }

  return result;
};

const processUnread = (clientList, countDescendants, user_id, skip = []) => {
  const result = {};

  for (const client of clientList) {
    result[client._id] = processClient(client, user_id, skip);

    if (countDescendants && Array.isArray(client.Familia)) {
      for (const fam of client.Familia) {
        result[fam._id] = processClient(fam, user_id, skip);
      }
    }
  }
  return result;
};

export default processUnread;

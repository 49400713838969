import React from 'react';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import op from 'object-path';
import { Radio, Button, Input, Select, Tooltip } from 'antd';
import moment from 'moment';

import g from '../../styles/global';
import {
  prepareWorkbook,
  workbook2Blob,
  prepareExcelData,
  insertExcelData
} from './excelReports';
import { anchorDownload } from '../../common/util';

const { Option } = Select;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    ...g.layout.flexVertical,
    ...g.layout.flexBetween,
    ...g.global.alignStart,
    padding: g.global.baseline
  },
  row: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.global.alignStart,
    width: '100%'
  },
  col: {
    ...g.layout.flexVertical,
    ...g.global.alignStart,
    justifyContent: 'flex-start',
    height: '100%'
  },
  colItem: { marginBottom: g.global.baseline },
  radio: {
    item: {
      paddingBottom: g.global.baseline * 2,
      marginBottom: g.global.baseline
    }
  }
};

const processTypes = [
  { value: 'golden_visa', label: 'Golden Visa' },
  { value: 'permanent_residency', label: 'Permanent Residency' }
];

const pipelineStages = {
  concession: {
    type: 'concession',
    statuses: {
      clientInformed: 'Client Informed',
      partnerInformed: 'Partner Informed',
      paymentRequested: 'Payment Requested',
      paymentThroughCG: 'Payment Through CG'
    }
  },
  renewal: {
    type: 'renewal',
    statuses: {
      clientInformed: 'Client Informed',
      partnerInformed: 'Partner Informed',
      paymentRequested: 'Payment Requested',
      paymentThroughCG: 'Payment Through CG'
    }
  }
};

const ariPagamentosStatuses = {
  pago: 'Pago',
  aguardaEmissao: 'Aguarda Emissão',
  naoPago: 'Não Pago',
  anulado: 'Anulado',
  emitido: 'Emitido'
};

const exportFields = {
  default: [
    { header: 'ID', key: 'id', dataPath: 'id', width: 20 },
    { header: 'Nome', key: 'Nome', dataPath: 'Nome', width: 20 },
    { header: 'Email', key: 'Email', dataPath: 'Email', width: 20 },
    {
      header: 'Nacionalidade',
      key: 'Nacionalidade',
      dataPath: 'Nacionalidade',
      width: 20
    },
    {
      header: 'Data Nascimento',
      key: 'Data Nascimento',
      dataPath: 'Data Nascimento',
      width: 20
    },
    { header: 'NIF', key: 'nif', dataPath: 'nif', width: 20 },
    { header: 'JVRIS', key: 'excel.juris', dataPath: 'excel.juris', width: 20 },
    { header: 'Situação', key: 'Situação', dataPath: 'Situação', width: 20 },
    { header: 'Partners', key: 'usergroups', dataPath: 'usergroups', width: 20 }
  ]
};

const exportFilters = {
  allClients: {
    name: 'allClients',
    fields: exportFields.default,
    filter: ({ data }) => {
      return data;
    }
  },
  concessionApproval: {
    name: 'concessionApproval',
    fields: exportFields.default,
    filter: ({ data, processKinds }) => {
      const result = [];

      for (const item of data) {
        //has processes?
        if (item.processes) {
          //has the process kinds we want?
          const process = item.processes.find(item =>
            processKinds.includes(item.kind)
          );
          //has concession?
          const concession = process?.pipeline?.find?.(
            item => item.type === pipelineStages.concession.type
          );

          if (
            concession &&
            concession?.approval?.status ===
              pipelineStages.concession.statuses.paymentThroughCG
          ) {
            result.push(item);
          }
        }
      }
      return result;
    }
  },
  renewalApproval: {
    name: 'renewalApproval',
    fields: exportFields.default,
    filter: ({ data, processKinds, renewalNumber }) => {
      const result = [];
      let processesFound = 0;
      let renewalsFound = 0;
      let approvalStatusesFound = 0;

      for (const item of data) {
        //has processes?
        if (item.processes) {
          //has the process kinds we want?
          const process = item.processes.find(item =>
            processKinds.includes(item.kind)
          );
          if (process) {
            processesFound++;
          }
          //has concession?
          const renewals = process?.pipeline?.filter?.(
            item => item.type === pipelineStages.renewal.type
          );

          if (!renewals || renewals.length < renewalNumber) {
            continue;
          }
          renewalsFound++;
          const targetRenewal = renewals[renewalNumber - 1];

          if (
            targetRenewal &&
            targetRenewal?.approval?.status ===
              pipelineStages.concession.statuses.paymentThroughCG
          ) {
            approvalStatusesFound++;
            result.push(item);
          }
        }
      }
      console.log({
        filterName: 'renewalApproval',
        processFound: processesFound,
        renewalFound: renewalsFound,
        approvalStatusFound: approvalStatusesFound
      });
      return result;
    }
  },

  paymentPhase: {
    name: 'paymentPhase',
    fields: exportFields.default,
    filter: ({ data }) => {
      // console.log('applying filter: paymentPhase');
      return data;
    }
  },
  pickUpPhase: {
    name: 'pickUpPhase',
    fields: exportFields.default,
    filter: ({ data }) => {
      // console.log('applying filter: pickUpPhase');
      return data;
    }
  },
  ducPaymentPending: {
    name: 'ducPaymentPending',
    fields: exportFields.default,
    filter: ({ data }) => {
      const result = [];
      data.forEach(item => {
        if (
          item.Pagamentos.some(
            pagamento => pagamento['Situação'] === ariPagamentosStatuses.naoPago
          )
        ) {
          result.push(item);
        }
      });
      // console.log('applying filter: ducPaymentPending');
      return result;
    }
  }
};

export default class Export extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: props.data?.slice?.() || [],
      filter: null,
      renewalNumber: null,
      processKinds: [],
      sortBy: this.props.sortBy.value,
      sortDirection: this.props.sortBy.direction,
      canExport: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const filterChanged = prevState.filter !== this.state.filter;
    const extrasChanged =
      prevState.renewalNumber !== this.state.renewalNumber ||
      !isEqual(sortBy(prevState.processKinds), sortBy(this.state.processKinds));

    if (filterChanged || extrasChanged) {
      this.setState(currentState => {
        let canExport = currentState.filter;
        switch (currentState.filter) {
          case exportFilters.concessionApproval:
            canExport = canExport && currentState.processKinds?.length;
            break;
          case exportFilters.renewalApproval:
            canExport =
              canExport &&
              currentState.processKinds?.length &&
              currentState.renewalNumber;
            break;
          default:
            //nothing
            break;
        }
        return { canExport, resultsFound: undefined };
      });
    }
  }

  static transformData = (data, fieldList) => {
    return data.map(item => {
      const result = {};
      console.group('transforming item ' + item);
      for (const field of fieldList) {
        let datum = op.get(item, field.dataPath);
        console.log('getting field: "', field, '" with value ' + datum);
        if (field.transform) {
          datum = field.transform(datum);
        }
        op.set(result, field.key, datum);
      }
      console.groupEnd('transforming item ' + item);
      return result;
    });
  };

  export = async () => {
    this.setState({ loading: true });
    let result = await this.state.filter.filter({
      data: this.state.data,
      processKinds: this.state.processKinds,
      renewalNumber: this.state.renewalNumber
    });

    result = await Export.transformData(result, this.state.filter.fields);
    this.setState({ resultsFound: result.length });
    if (result.length) {
      return await this.generateReportFile(exportFields.default, result);
    }
  };

  async generateReportFile(excelColumns, data) {
    const { workbook, worksheet } = prepareWorkbook({
      worksheetColumns: excelColumns,
      sheetTitle: 'ID Clients'
    });

    const reportData = !data ? [] : prepareExcelData(data, excelColumns);

    insertExcelData(worksheet, reportData);

    try {
      const blob = await workbook2Blob(workbook);

      const filename =
        'id_clients_' + moment().format('YYYY-MM-DD_HH:ss') + '.xlsx';
      anchorDownload(blob, filename);
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const isRenewalApproval =
      this.state.filter === exportFilters.renewalApproval;
    const isConcessionApproval =
      this.state.filter === exportFilters.concessionApproval;
    return (
      <div style={styles.container}>
        <div
          style={{
            ...styles.row,
            maxHeight: '10%',
            paddingBottom: g.global.baseline,
            borderBottom: '1px solid #eae8e8'
          }}
        >
          <div style={{ ...styles.row, ...g.layout.flexBetween }}>
            <h2 style={{ marginBottom: 0 }}>Export parameters</h2>
            {this.props.sortSelector && <div>{this.props.sortSelector}</div>}
          </div>
        </div>
        <div
          style={{
            ...styles.col,
            ...g.layout.flexStart,
            width: '100%',
            flexGrow: 10,
            paddingTop: g.global.baseline * 2,
            paddingBottom: g.global.baseline * 2
          }}
        >
          <div style={{ ...styles.row }}>
            <div
              style={{
                ...styles.col,
                ...g.layout.alignEnd,
                width: '50%',
                padding: g.global.baseline,
                borderRight: '1px solid #eae8e8'
              }}
            >
              <h3 style={{}}>Filters</h3>
              <div
                style={{
                  padding: g.global.baseline
                }}
              >
                <Radio.Group
                  style={{
                    ...styles.col,
                    ...g.layout.flexEnd,
                    ...g.layout.alignEnd
                  }}
                  disabled={this.state.loading}
                  onChange={e => this.setState({ filter: e.target.value })}
                >
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.allClients}
                  >
                    <span>All Clients</span>
                  </Radio.Button>
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.concessionApproval}
                  >
                    <span>Clients approved on Concession stage</span>
                  </Radio.Button>
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.renewalApproval}
                  >
                    <span>Clients approved on nth Renewal stage</span>
                  </Radio.Button>
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.paymentPhase}
                    disabled={true}
                  >
                    <Tooltip title="Coming soon" placement="left">
                      <span>Clients in payment phase</span>
                    </Tooltip>
                  </Radio.Button>
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.pickUpPhase}
                    disabled={true}
                  >
                    <Tooltip title="Coming soon" placement="left">
                      <span>Clients in pick-up phase</span>
                    </Tooltip>
                  </Radio.Button>
                  <Radio.Button
                    style={styles.radio.item}
                    value={exportFilters.ducPaymentPending}
                  >
                    <span>Clients with DUC payment pending</span>
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <div
              style={{
                ...styles.col,
                ...g.layout.alignStart,
                width: '50%',
                padding: g.global.baseline
              }}
            >
              <h3 style={{}}>Options</h3>
              <div
                style={{
                  padding: g.global.baseline
                }}
              >
                <div
                  style={{
                    ...styles.row,
                    ...g.layout.flexStart,
                    paddingBottom: g.global.baseline
                  }}
                >
                  <span
                    style={{
                      paddingRight: g.global.baseline,
                      minWidth: g.global.baseline * 12
                    }}
                  >
                    Process Type{' '}
                  </span>
                  <Select
                    style={{
                      minWidth: g.global.baseline * 12
                    }}
                    mode="multiple"
                    maxTagCount={2}
                    value={this.state.processKinds}
                    disabled={!(isConcessionApproval || isRenewalApproval)}
                    onChange={value => {
                      return this.setState({ processKinds: value });
                    }}
                  >
                    {processTypes.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    ...styles.row,
                    ...g.layout.flexStart,
                    paddingBottom: g.global.baseline
                  }}
                >
                  <span
                    style={{
                      paddingRight: g.global.baseline,
                      minWidth: g.global.baseline * 12
                    }}
                  >
                    Renewal no.{' '}
                  </span>
                  <Input
                    style={{
                      minWidth: g.global.baseline * 4,
                      maxWidth: g.global.baseline * 6
                    }}
                    value={this.state.renewalNumber || ''}
                    disabled={!isRenewalApproval}
                    onChange={e => {
                      console.log(
                        e.target.value,
                        parseInt(e.target.value) || null
                      );
                      this.setState({
                        renewalNumber: parseInt(e.target.value) || null
                      });
                    }}
                  ></Input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ ...styles.row, ...g.layout.flexEnd, maxHeight: '10%' }}>
          {Number.isInteger(this.state.resultsFound) && (
            <span style={{ paddingRight: g.global.baseline }}>
              Results found: {this.state.resultsFound}
            </span>
          )}
          <Button
            disabled={this.state.loading || !this.state.canExport}
            type="primary"
            onClick={async () => {
              this.setState({ loading: true });
              await this.export();
              this.setState({ loading: false });
            }}
          >
            Export
          </Button>
        </div>
      </div>
    );
  }
}

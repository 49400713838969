import React from 'react';
import { Form, Button, Input, Select, message } from 'antd';
// eslint-disable-next-line
import validator from 'validator';

import AppContext from '../context/AppContextBase';
import globalStyles from '../styles/global';
import { fetchFolders } from '../network/cm';
import moment from 'moment';

// eslint-disable-next-line
const { Option } = Select;

const styles = {
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  },
  form: {
    body: {
      width: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    section: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    item: {
      paddingRight: globalStyles.global.baseline,
      marginBottom: globalStyles.global.baseline
    }
  }
};

class InsertProcess extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = { sending: true, folders: [] };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const request = await fetchFolders(this.props.portfolio.id);
    if (request && request.result === 'OK' && request.data.docs) {
      this.setState({ sending: false, folders: request.data.docs });
    } else {
      message.error(
        'Erro a carregar dados do servidor. Verifique a sua ligação à internet e/ou permissões de acesso'
      );
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          if (values.folder_id && values.folder_id.key) {
            values.folder_id = values.folder_id.key;
          }

          //console.log('Received values of form: ', values);

          if (this.props.action) {
            const request = await this.props.action(values);

            if (request && request.result === 'OK') {
              if (this.props.done) {
                await this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
              //clear form:
              this.props.form.resetFields();
            } else {
              if (this.props.error) {
                this.props.error(request);
              } else {
                message.error(
                  <span>
                    Erro na inserção. Verifique a sua ligação à internet e tente
                    de novo.
                  </span>
                );
              }
            }
          }
        } catch (err) {
          if (this.props.onError) {
            this.props.onError(err);
          }
        }
      }
      this.setState({ sending: false });
    });
  };

  handleCancel() {
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <span>Criar Processo</span>
        <div style={styles.container}>
          <Form onSubmit={this.handleSubmit} style={styles.form.body}>
            <div style={styles.form.section}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('id', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(
                  <Input
                    disabled={this.state.sending}
                    placeholder="ID (único) do processo"
                  />
                )}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, width: '25%' }}>
                {getFieldDecorator('folder_id', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    labelInValue
                    placeholder="ID da Pasta"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.folders.map(d => (
                      <Option key={d.id}>{d.id}</Option>
                    ))}
                  </Select>
                  // <Input
                  //   disabled={this.state.sending}
                  //   placeholder="ID da Pasta"
                  // />
                )}
              </Form.Item>
              {/* hidden / automatic values */}
              <Form.Item style={{ ...styles.form.item, display: 'none' }}>
                {getFieldDecorator('owner', {
                  initialValue: this.context.state.userData.id
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, display: 'none' }}>
                {getFieldDecorator('portfolio_id', {
                  initialValue: this.props.portfolio.id
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, display: 'none' }}>
                {getFieldDecorator('create_date', {
                  initialValue: moment().format('YYYY-MM-DD')
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item }}>
                {getFieldDecorator('portfolioName', {
                  initialValue: this.props.portfolio.name.toUpperCase()
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('process_id', {
                  rules: [{ required: false, message: 'Inválido' }]
                })(
                  <Input
                    disabled={this.state.sending}
                    placeholder="Nº Processo CITIUS"
                  />
                )}
              </Form.Item>

              <Form.Item style={{ ...styles.form.item, width: '20%' }}>
                <Button
                  loading={this.state.sending}
                  type="primary"
                  ghost
                  className="login-form-button"
                  onClick={this.handleCancel}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </div>
            <div style={styles.form.section}>
              {/* INFO Fields */}
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('type', {
                  rules: [{ required: false, message: 'Inválido' }]
                })(<Input disabled={this.state.sending} placeholder="Tipo" />)}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('sub_type', {
                  rules: [{ required: false, message: 'Inválido' }]
                })(
                  <Input disabled={this.state.sending} placeholder="Subtipo" />
                )}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('status', {
                  rules: [{ required: false, message: 'Inválido' }]
                })(
                  <Input disabled={this.state.sending} placeholder="Estado" />
                )}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('stage', {
                  rules: [{ required: false, message: 'Inválido' }]
                })(<Input disabled={this.state.sending} placeholder="Fase" />)}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, width: '20%' }}>
                <Button
                  loading={this.state.sending}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Submeter
                </Button>
              </Form.Item>
            </div>
            {false && (
              <div style={styles.form.section}>
                {/* RESERVED (hidden) fields */}
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('ext_lawyer', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Advogado Externo"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('int_lawyer', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Advogado Externo"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('visibility', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Visibilidade"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('description', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Descrição"
                    />
                  )}
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
const InsertDossierForm = Form.create({ name: 'insert_dossier' })(
  InsertProcess
);
export default InsertDossierForm;

import mapFields from '../../util/mapFields';
import EditableField from '../../../../components/util/EditableField';
import deadlinesColumns from '../columns/DeadlinesColumns';
import DocumentsReceivedColumns from '../columns/goldenVisa/DocumentsReceivedColumns';
import DocumentsPreparedColumns from '../columns/goldenVisa/DocumentsPreparedColumns';

const { Types } = EditableField;

const baseFields = ({
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  onSave,
  editable = true
}) => {
  const fieldMap = [
    {
      title: 'Option of Invest.',
      key: 'base.option_of_investment',
      type: Types.select,
      mode: 'tags',
      options: [
        { key: ' ', value: ' ' },
        { key: '1M Shares', value: '1M Shares' },
        { key: '1M Deposit', value: '1M Deposit' },
        { key: '500K Real Estate', value: '500K Real Estate' },
        { key: '350K Reabilitation', value: '350K Reabilitation' },
        { key: '350K Funds', value: '350K Funds' },
        { key: '350K Company & Jobs', value: '350K Company & Jobs' },
        { key: '350K Research', value: '350K Research' },
        { key: '250K Donation', value: '250K Donation' },
        { key: '10 Jobs', value: '10 Jobs' },
        { key: 'Green Visa', value: 'Green Visa' },
        { key: '20% Reduction', value: '20% Reduction' }
      ],
      span: 12,
      editable: editable
    },
    {
      title: 'Investment Type',
      key: 'base.investment_type',
      type: Types.select,
      mode: 'tags',
      options: [
        { key: 'T', value: 'T' },
        { key: 'Unit', value: 'Unit' },
        { key: 'Land', value: 'Land' },
        { key: 'Apartment', value: 'Apartment' },
        { key: 'Villa', value: 'Villa' },
        { key: 'Building', value: 'Building' },
        { key: 'Hotel', value: 'Hotel' },
        { key: 'Office', value: 'Office' },
        { key: 'Shop', value: 'Shop' }
      ],
      span: 12,
      editable: editable
    },

    {
      title: 'Project',
      key: 'base.project',
      span: 12,
      editable: editable
    },
    {
      title: 'Investment Value',
      key: 'base.investment_value',
      span: 12,
      editable: editable
    },
    {
      title: 'Location/Address',
      key: 'base.location_address',
      span: 12,
      editable: editable
    },
    {
      title: 'Seller/Benefic.',
      key: 'base.seller_beneficiary',
      span: 12,
      editable: editable
    },
    {
      title: 'Seller Contact',
      key: 'base.seller_contact',
      span: 12,
      editable: editable
    },
    {
      title: 'No. of Assets',
      key: 'base.number_of_assets',
      span: 12,
      editable: editable
    },

    {
      title: 'Broker',
      key: 'base.broker',
      span: 12,
      editable: editable
    },
    {
      title: 'Broker Contact',
      key: 'base.broker_contact',
      span: 12,
      editable: editable
    },
    { placeholder: 12 },
    {
      title: 'Notes',
      key: 'base.notes',
      span: 24,
      type: Types.textarea,
      editable: editable
    },
    { placeholder: 12 },

    {
      key: 'base.deadlines2',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Deadlines',
      template: { date: null, notes: '', deadline: null, status: '' },
      getColumns: deadlinesColumns,
      editable: editable
    },
    {
      key: 'base.documents_prepared',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Documents Prepared',
      template: { title: '', date: null },
      getColumns: DocumentsPreparedColumns,
      editable: editable
    },

    {
      key: 'base.documents_signed_received',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Documents Signed / Received',
      template: {
        title: '',
        type: '',
        date: null,
        validity: null,
        formalities: ''
      },
      getColumns: DocumentsReceivedColumns,
      editable: editable
    },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false }
  ];

  return mapFields(data, fieldMap, onSave, onSaveSuccess, onSaveError, style);
};

const renewalMap = (index, { editable = true }) => [
  {
    title: 'Request',
    span: 24,
    type: Types.title,
    editable: editable
  },
  {
    title: 'Status',
    key: `pipeline.${index}.request.status`,
    type: Types.select,
    options: [
      { key: 'Client Informed', value: 'Client Informed' },
      { key: 'Partner Informed', value: 'Partner Informed' },
      { key: 'Payment Requested', value: 'Payment Requested' },
      { key: 'Payment Through CG', value: 'Payment Through CG' }
    ],
    span: 24,
    editable: editable
  },
  {
    title: 'Status Notes',
    key: `pipeline.${index}.request.status_extras`,
    span: 24,
    type: Types.textarea,
    editable: editable
  },

  // Simoes: Removed 26 Dec 2019
  // {
  //   title: 'Appointment Date',
  //   key: `pipeline.${index}.request.appointment.date`,
  //   span: 12,
  //   type: Types.date
  // },
  // {
  //   title: 'Appointment Place',
  //   key: `pipeline.${index}.request.appointment.place`,
  //   span: 24,
  //   type: Types.date
  // },

  {
    title: 'NIPC',
    key: `pipeline.${index}.request.nipc`,
    span: 24,
    editable: editable
  },
  {
    key: `pipeline.${index}.request.notifications2`,
    type: Types.array,
    span: 24,
    extras: 'client',
    title: 'Notifications',
    template: { date: null, notes: '', deadline: null, status: '' },
    getColumns: deadlinesColumns,
    editable: editable
  },
  { placeholder: 24 },
  {
    title: 'Approval',
    span: 24,
    type: Types.title,
    editable: editable
  },
  {
    title: 'Date',
    key: `pipeline.${index}.approval.date`,
    span: 12,
    type: Types.date,
    options: { showTime: false },
    editable: editable
  },
  {
    title: 'DUC Payment Date',
    key: `pipeline.${index}.approval.duc_payment_date`,
    span: 12,
    type: Types.date,
    options: { showTime: false },
    editable: editable
  },
  {
    title: 'Status',
    key: `pipeline.${index}.approval.status`,
    type: Types.select,
    options: [
      { key: 'Client Informed', value: 'Client Informed' },
      { key: 'Partner Informed', value: 'Partner Informed' },
      { key: 'Payment Requested', value: 'Payment Requested' },
      { key: 'Payment Through CG', value: 'Payment Through CG' }
    ],
    span: 12,
    editable: editable
  },
  {
    title: 'Card Status',
    key: `pipeline.${index}.approval.card_status`,
    type: Types.select,
    options: [
      { key: 'At SEF', value: 'At SEF' },
      { key: 'At the Office', value: 'At the Office' },
      {
        key: "At the Client's PT Address",
        value: "At the Client's PT Address"
      },
      { key: 'Delivered', value: 'Delivered' }
    ],
    span: 12,
    editable: editable
  },
  {
    title: 'Card Status Notes',
    key: `pipeline.${index}.approval.card_status_extras`,
    span: 24,
    type: Types.textarea,
    editable: editable
  },
  {
    title: 'Validity Start',
    key: `pipeline.${index}.approval.validity_start`,
    span: 12,
    type: Types.date,
    options: { showTime: false },
    editable: editable
  },
  {
    title: 'Validity End',
    key: `pipeline.${index}.approval.validity_end`,
    span: 12,
    type: Types.date,
    options: { showTime: false },
    editable: editable
  },

  {
    title: 'Add to Calendar',
    key: `pipeline.${index}.approval.validity_end`,
    extras: 'client',
    span: 4,
    type: Types.addToCalendar,
    editable: editable
  },

  { placeholder: 24, hide: false }
];

const concessionMap = (index, { editable = true }) => {
  const concession = [
    {
      title: 'Request',
      span: 24,
      type: Types.title,
      editable: editable
    },
    {
      title: 'Acceptance Date',
      key: `pipeline.${index}.request.acceptance_date`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'DUC Payment Date',
      key: `pipeline.${index}.request.duc_payment_date`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'Filling Date',
      key: `pipeline.${index}.request.filling_date`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'Status',
      key: `pipeline.${index}.request.status`,
      type: Types.select,
      options: [
        { key: 'Client Informed', value: 'Client Informed' },
        { key: 'Partner Informed', value: 'Partner Informed' },
        { key: 'Payment Requested', value: 'Payment Requested' },
        { key: 'Payment Through CG', value: 'Payment Through CG' }
      ],
      span: 12,
      editable: editable
    },
    {
      title: 'Status Notes',
      key: `pipeline.${index}.request.status_extras`,
      span: 24,
      type: Types.textarea,
      editable: editable
    },

    // Simoes: Removed 11 Dec 2019
    // {
    //   title: 'Appointment Date',
    //   key: `pipeline.${index}.request.appointment.date`,
    //   span: 12,
    //   type: Types.date
    //   options: { showTime: false },
    // },
    // {
    //   title: 'Appointment Place',
    //   key: `pipeline.${index}.request.appointment.place`,
    //   span: 24,
    //   type: Types.date
    //   options: { showTime: false },
    // },

    {
      title: 'NIPC',
      key: `pipeline.${index}.request.nipc`,
      span: 24,
      editable: editable
    },
    {
      key: `pipeline.${index}.request.notifications2`,
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Notifications',
      template: { date: null, notes: '', deadline: null, status: '' },
      getColumns: deadlinesColumns,
      editable: editable
    },
    { placeholder: 24 },
    {
      title: 'Approval',
      span: 24,
      type: Types.title,
      editable: editable
    },
    {
      title: 'Date',
      key: `pipeline.${index}.approval.date`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'DUC Payment Date',
      key: `pipeline.${index}.approval.duc_payment_date`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'Status',
      key: `pipeline.${index}.approval.status`,
      type: Types.select,
      options: [
        { key: 'Client Informed', value: 'Client Informed' },
        { key: 'Partner Informed', value: 'Partner Informed' },
        { key: 'Payment Requested', value: 'Payment Requested' },
        { key: 'Payment Through CG', value: 'Payment Through CG' }
      ],
      span: 12,
      editable: editable
    },
    {
      title: 'Card Status',
      key: `pipeline.${index}.approval.card_status`,
      type: Types.select,
      options: [
        { key: 'At SEF', value: 'At SEF' },
        { key: 'At the Office', value: 'At the Office' },
        {
          key: "At the Client's PT Address",
          value: "At the Client's PT Address"
        },
        { key: 'Delivered', value: 'Delivered' }
      ],
      span: 12,
      editable: editable
    },
    {
      title: 'Card Status Notes',
      key: `pipeline.${index}.approval.card_status_extras`,
      span: 24,
      type: Types.textarea,
      editable: editable
    },
    {
      title: 'Validity Start',
      key: `pipeline.${index}.approval.validity_start`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'Validity End',
      key: `pipeline.${index}.approval.validity_end`,
      span: 12,
      options: { showTime: false },
      type: Types.date,
      editable: editable
    },
    {
      title: 'Add to Calendar',
      key: `pipeline.${index}.approval.validity_end`,
      extras: 'client',
      span: 4,
      type: Types.addToCalendar,
      editable: editable
    },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false }
  ];

  return concession;
};

const concessionFields = ({
  index,
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  onSave,
  editable = true
}) => {
  return mapFields(
    data,
    concessionMap(index, { editable }),
    onSave,
    onSaveSuccess,
    onSaveError,
    style
  );
};

const renewalFields = ({
  index,
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  onSave,
  editable = true
}) => {
  return mapFields(
    data,
    renewalMap(index, { editable }),
    onSave,
    onSaveSuccess,
    onSaveError,
    style
  );
};

export { baseFields, concessionFields, renewalFields };

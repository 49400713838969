import React from 'react';
import g from '../../styles/global';
import { Radio, Select } from 'antd';

const { Option } = Select;

const SortSelector = ({
  options,
  currentOption,
  currentDirection,
  onChangeField,
  onChangeDirection,
  label = 'Sort by: ',
  containerStyle = {}
}) => {
  return (
    <div
      style={{
        ...g.layout.flexHorizontal,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        ...containerStyle
      }}
    >
      {label && <span style={{ marginRight: g.global.baseline }}>{label}</span>}
      <Select
        style={{
          marginRight: g.global.baseline,
          width: g.global.baseline * 10
        }}
        value={currentOption}
        onChange={onChangeField}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      <Radio.Group
        onChange={e => onChangeDirection(e.target.value)}
        value={currentDirection}
      >
        <Radio value={'ASC'}>ASC</Radio>
        <Radio value={'DESC'}>DESC</Radio>
      </Radio.Group>
    </div>
  );
};

export default SortSelector;

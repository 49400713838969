import React from 'react';
import { Column } from 'react-virtualized';

import { tableStyles, translateColumnHeader, renderers } from './common';

const paymentColumns = (onSave, extras = {}) => [
  <Column
    width={50}
    flexGrow={4}
    dataKey="Documento"
    key="Documento"
    disableSort={true}
    headerRenderer={translateColumnHeader}
    style={tableStyles.column}
  />,
  <Column
    width={50}
    flexGrow={4}
    dataKey="urlDocumento"
    key="urlDocumento"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={100}
    flexGrow={2}
    dataKey="assignee"
    key="assignee"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
    cellRenderer={value => {
      return (
        <div>
          {renderers.assignee({
            user: extras.user,
            users: extras.users,
            onSave: onSave,
            loading: extras.loading
          })(value)}
        </div>
      );
    }}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="_id"
    key="_id"
    label={'Acções'}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={() => 'Acções'}
    cellRenderer={values => {
      return (
        <div>
          {renderers.unread({
            user: extras.user,
            onSave: onSave
          })(values)}
        </div>
      );
    }}
  />
];

export default paymentColumns;

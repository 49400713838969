// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
  aws_app_analytics: 'disabled',
  aws_cognito_identity_pool_id:
    'us-east-1:40b47106-4446-42b6-9d22-ce53616d87ba',
  aws_cognito_region: 'us-east-1',
  //aws_project_id: '33c680ac-8767-4cab-a5a0-1f6fb0899de6',
  aws_project_name: 'caiado-rpa',
  aws_project_region: 'us-east-1',
  aws_resource_name_prefix: 'RPA Caiado Users',
  aws_sign_in_enabled: 'enable',
  aws_user_files: 'enable',
  aws_user_files_s3_bucket: 'rpacaiado',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'us-east-1_bCr4hoClc',
  aws_user_pools_web_client_id: '2tuu661vkeqrfl971qlfruah3'
};

export default awsmobile;

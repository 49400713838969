import { get } from '../common/util';

/* Every property translated to integer here follows ascending order.
 * Eg, Dev > Admin > User, therefore { Dev: 2 Admin: 1 User: 0 }
 *
 * This is done for quick comparisons without IFs or switch
 */
const debug = false;
export const log = debug ? console.log.bind(window.console) : () => {};

const depts = {
  id: 'id',
  cm: 'cm',
  fin: 'fin',
  all: '*'
};
const roles = {
  dev: 3,
  admin: 2,
  user: 1
};

const scopes = {
  external: 1,
  internal: 2
};

const permissions = {
  menu: {
    cm: {
      requirements: {
        department: depts.cm,
        scope: scopes.external,
        role: roles.user
      }
    },
    id: {
      requirements: {
        department: depts.id,
        scope: scopes.external,
        role: roles.user
      },
      pending: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.user
        }
      }
    },
    fin: {
      requirements: {
        department: depts.fin,
        scope: scopes.internal,
        role: roles.user
      }
    },
    settings: {
      requirements: {
        department: depts.all,
        scope: scopes.internal,
        role: roles.admin
      },
      users: {
        requirements: {
          department: depts.all,
          scope: scopes.internal,
          role: roles.admin
        },
        groups: {
          requirements: {
            department: depts.id,
            scope: scopes.internal,
            role: roles.admin
          }
        }
      },
      groups: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.admin
        }
      }
    }
  },
  cm: {
    citius: {
      requirements: {
        department: depts.cm,
        scope: scopes.internal,
        role: roles.user
      }
    },
    portfolios: {
      delete: {
        requirements: {
          department: depts.cm,
          scope: scopes.internal,
          role: roles.admin
        }
      }
    },
    folders: {
      delete: {
        requirements: {
          department: depts.cm,
          scope: scopes.internal,
          role: roles.admin
        }
      }
    },
    uploads: {
      requirements: {
        department: depts.cm,
        scope: scopes.external,
        role: roles.user
      }
    },
    process: {
      json: {
        requirements: {
          department: depts.cm,
          scope: scopes.internal,
          role: roles.dev
        }
      }
    },
    messages: {
      requirements: {
        department: depts.cm,
        scope: scopes.external,
        role: roles.user
      }
    }
  },
  fin: {
    reports: {
      requirements: {
        department: depts.fin,
        scope: scopes.internal,
        role: roles.user
      },
      step: {
        requirements: {
          department: depts.fin,
          scope: scopes.internal,
          role: roles.dev
        }
      }
    }
  },
  id: {
    export: {
      requirements: {
        department: depts.id,
        scope: scopes.internal,
        role: roles.user
      }
    },
    client: {
      base: {
        requirements: {
          department: depts.id,
          scope: scopes.external,
          role: roles.user
        },
        protectedFields: {
          requirements: {
            department: depts.id,
            scope: scopes.internal,
            role: roles.user
          }
        }
      },
      ari: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.user
        }
      },
      minutes: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.user
        }
      },
      uploads: {
        requirements: {
          department: depts.id,
          scope: scopes.external,
          role: roles.user
        }
      },
      messages: {
        requirements: {
          department: depts.id,
          scope: scopes.external,
          role: roles.user
        }
      },
      accounting: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.user
        }
      },
      processes: {
        requirements: {
          department: depts.id,
          scope: scopes.external,
          role: roles.user
        },
        editable: {
          requirements: {
            department: depts.id,
            scope: scopes.internal,
            role: roles.user
          }
        },
        protectedFields: {
          requirements: {
            department: depts.id,
            scope: scopes.internal,
            role: roles.user
          }
        }
      },
      audit: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.admin
        }
      },
      json: {
        requirements: {
          department: depts.id,
          scope: scopes.internal,
          role: roles.dev
        }
      }
    }
  },
  messages: {
    requirements: {
      department: depts.all,
      scope: scopes.external,
      role: roles.user
    },
    tags: {
      requirements: {
        department: depts.all,
        scope: scopes.internal,
        role: roles.user
      }
    },
    selectVisibility: {
      requirements: {
        department: depts.all,
        scope: scopes.internal,
        role: roles.user
      }
    }
  },
  uploads: {
    requirements: {
      department: depts.all,
      scope: scopes.external,
      role: roles.user
    },
    selectVisibility: {
      requirements: {
        department: depts.all,
        scope: scopes.internal,
        role: roles.user
      }
    },
    delete: {
      requirements: {
        department: depts.all,
        scope: scopes.internal,
        role: roles.admin
      }
    },
    download: {
      requirements: {
        department: depts.all,
        scope: scopes.external,
        role: roles.user
      }
    }
  }
};

export default class Permissions {
  static check(scope, userData) {
    if (!userData) {
      console.warn('Permissions: userData not valid ');
      return false;
    }

    const translated = {
      role: roles[userData.role],
      scope: scopes[userData.permissions.scope],
      department: userData.permissions.department.toLowerCase()
    };

    if (!translated.role || !translated.scope || !translated.department) {
      log(
        'Permissions: User data is missing role or permissions: ',
        translated
      );
      throw new Error(
        'Permissions: User data is missing role or permissions: ',
        translated
      );
    }

    const coordinates = scope.replace(/:/g, '.');

    const target = get(coordinates, permissions);

    if (!target) {
      log('Permissions: Missing user permissions: ', scope);
      throw new Error('Permissions: Missing user permissions: ' + scope);
    }

    const requirements = target.requirements;
    log('permissions - translated :', translated);
    log('permissions - target :', target);

    const isDev = false; //translated.role >= roles.dev;

    if (isDev) {
      log('Permissions: is dev,returning true');
      return true;
    } else {
      log(
        'Permissions: not admin, returning: ',
        translated.role >= requirements.role,
        translated.scope >= requirements.scope,
        requirements.department === depts.all ||
          translated.department === depts.all ||
          translated.department === requirements.department
      );
      return (
        translated.role >= requirements.role &&
        translated.scope >= requirements.scope &&
        (requirements.department === depts.all ||
          translated.department === depts.all ||
          translated.department === requirements.department)
      );
    }
  }
}

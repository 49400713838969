import React from 'react';
import moment from 'moment';
import { Column } from 'react-virtualized';
import { message } from 'antd';
//import moment from 'moment';

import EditableField from '../../../../../components/util/EditableField';
import { tableStyles, translateColumnHeader } from '../../../tables/common';

const NotesColumns = (onSave, extras) => {
  const editable = extras ? !(extras.editable === false) : true;
  return [
    <Column
      width={20}
      flexGrow={1}
      dataKey="createdAt"
      key="createdAt"
      disableSort={true}
      headerRenderer={() => <div>CREATED BY</div>}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        console.log({ cellData, rowData, rowIndex });
        return (
          <div>
            <div>{rowData.owner?.name}</div>
            <div>{moment(rowData.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }}
    />,
    // <Column
    //   width={20}
    //   flexGrow={1}
    //   dataKey="owner"
    //   key="owner"
    //   disableSort={true}
    //   headerRenderer={translateColumnHeader}
    //   style={tableStyles.column}
    //   cellRenderer={({ cellData, rowData, rowIndex }) => {
    //     return <span>{cellData.name}</span>;
    //   }}
    // />,
    // <Column
    //   width={40}
    //   flexGrow={2}
    //   dataKey="title"
    //   key="title"
    //   disableSort={true}
    //   headerRenderer={translateColumnHeader}
    //   style={tableStyles.column}
    //   cellRenderer={({ cellData, rowData, rowIndex }) => {
    //     return (
    //       <EditableField
    //         innerStyle={{ marginBottom: 0 }}
    //         minWidthBefore={0}
    //         span={24}
    //         options={{ showTime: false }}
    //         key={cellData}
    //         editable={editable}
    //         data={rowData}
    //         hideElementBefore={true}
    //         hideIcon={true}
    //         value={cellData}
    //         onSave={async value =>
    //           await onSave({ value: value, field: 'title', index: rowIndex })
    //         }
    //         onSaveSuccess={() => message.success('Value Saved')}
    //         onSaveError={() =>
    //           message.error('Error saving value! Please try again.')
    //         }
    //       ></EditableField>
    //     );
    //   }}
    // />,
    <Column
      width={80}
      flexGrow={5}
      dataKey="content"
      key="content"
      disableSort={true}
      headerRenderer={translateColumnHeader}
      style={tableStyles.column}
      cellRenderer={({ cellData, rowData, rowIndex }) => {
        return (
          <EditableField
            innerStyle={{ marginBottom: 0 }}
            minWidthBefore={0}
            type={EditableField.Types.textarea}
            autosize={{ minRows: 4, maxRows: 4 }}
            span={24}
            key={cellData}
            editable={editable}
            data={rowData}
            hideElementBefore={true}
            hideIcon={true}
            value={cellData}
            onSave={async value =>
              await onSave({ value: value, field: 'content', index: rowIndex })
            }
            onSaveSuccess={() => message.success('Value Saved')}
            onSaveError={() =>
              message.error('Error saving value! Please try again.')
            }
          ></EditableField>
        );
      }}
    />
  ];
};

export default NotesColumns;

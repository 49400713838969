import React from 'react';
import autobind from 'auto-bind/react';
import { Route, NavLink, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Layout, Menu, Icon } from 'antd';

import AppContext from '../../context/AppContextBase';
import withNotifications from '../../context/NotificationContext';

import Clients from './Clients';
import Pending from './Pending';
import FamilyTree from './FamilyTree';
import { getClients } from '../../network/sef';
import processUnread from './util/processUnread';

import DetailedMessage from '../../components/util/DetailedMessage';
import breakpoints from '../../styles/breakpoints';
import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const { Header, Content } = Layout;

const styles = {
  header: {
    height: globalStyles.global.baseline * 4,
    backgroundColor: globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    padding: 0
  },
  menu: {
    fontSize: globalStyles.typography.size.base * 1.5,
    height: globalStyles.global.baseline * 3,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    borderBottom: '1px solid ' + globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  subMenu: {
    backgroundColor: globalStyles.colors.background.queenBlue
  },
  menuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    minWidth: '20%'
  },
  subMenuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100
  },
  menuLink: {
    color: '#fff'
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  }
};
class IdMenu extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = { currentMenuItem: this.props.activeKey };
  }
  render() {
    return (
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={[this.state.currentMenuItem]}
        activeKey={this.state.currentMenuItem}
        mode="horizontal"
        style={styles.menu}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={globalStyles.global.menuIcon} type="more" />
          </div>
        }
      >
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="clients"
        >
          <NavLink style={styles.menuLink} exact to="/id/clients">
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="team" />
              Investors
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="team" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
        {this.context.checkPermission('menu:id:pending') && (
          <Menu.Item
            style={mrg([
              styles.menuItem,
              globalStyles.layout.flexHorizontal,
              globalStyles.layout.flexCenter
            ])}
            key="pending"
          >
            <NavLink style={styles.menuLink} to="/id/pending">
              <MediaQuery minWidth={breakpoints.lgMin}>
                <Icon style={styles.menuIcon} type="pull-request" />
                Pending Import Data
              </MediaQuery>
              <MediaQuery maxWidth={breakpoints.mdMax}>
                <Icon style={styles.menuIcon} type="pull-request" />
              </MediaQuery>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );
  }
}

IdMenu.contextType = AppContext;

const idNotificationHierarchies = [
  { origin: 'unreadPagamentos', target: 'unreadAri' },
  { origin: 'unreadDocumentos', target: 'unreadAri' },
  { origin: 'unreadAgendamentos', target: 'unreadAri' },
  { origin: 'todoPagamentos', target: 'todoAri' },
  { origin: 'todoDocumentos', target: 'todoAri' },
  { origin: 'todoAgendamentos', target: 'todoAri' }
];

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    for (const hierarchy of idNotificationHierarchies) {
      this.props.notificationContext.addHierarchy(
        hierarchy.origin,
        hierarchy.target
      );
    }
    this.state = {};
    autobind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const defaultState = { activeKey: 'clients' };
    const urlComponents = props.location.pathname.split('/');
    if (!urlComponents.length) {
      return defaultState;
    }
    const last = urlComponents[urlComponents.length - 1];
    if (!last || !last.length) {
      return defaultState;
    }
    return { activeKey: last };
  }

  async componentDidMount() {
    return await this.refreshClients();
  }

  async processUnreadMessages(clients) {
    let clientsToProcess = clients;

    if (!clientsToProcess) {
      const response = await getClients();
      if (response && response.result === 'OK') {
        clientsToProcess = response.data.docs;
      } else {
        return;
      }
    }
    const notifications = processUnread(
      clientsToProcess,
      true, // always count descendant notifications
      this.context.state.userData._id,
      [/*this.context.checkPermission('id:client:audit') ? '' :*/ 'audit'] // skip counting audits
    );
    this.props.notificationContext.set(notifications);
  }

  refreshClients = async () => {
    // console.log('Home: refreshing');
    const clients = await getClients();

    if (clients && clients.result === 'OK') {
      const result = clients.data.docs;
      await this.processUnreadMessages(result);
      this.setState({ clients: result });
    } else if (clients && clients.result === 'OK') {
      this.setState({
        clients: null
      });
    } else {
      DetailedMessage.error('Error loading clients: ', clients);
      this.setState({
        clients: null
      });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CG - Immigration Desk</title>
        </Helmet>
        <Header style={styles.header}>
          {<IdMenu activeKey={this.state.activeKey} />}
        </Header>
        <Content
          style={{
            height:
              'calc( 100vh - ' + globalStyles.global.baseline * 10 + 'px)',
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexCenter
          }}
        >
          <Route
            path={'/id/clients/:parentId/:descendantId?'}
            render={props => (
              <FamilyTree
                notificationContext={this.props.notificationContext}
                processUnreadMessages={this.processUnreadMessages}
                {...props}
              ></FamilyTree>
            )}
          />
          <Route
            exact
            path={'/id/clients'}
            render={props => (
              <Clients
                notificationContext={this.props.notificationContext}
                clients={this.state.clients}
                refreshClients={this.refreshClients}
                {...props}
              ></Clients>
            )}
          />
          <Route exact path={'/id/pending'} component={Pending} />
          <Route
            exact
            path={'/id'}
            render={() => (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  ...globalStyles.layout.flexVertical,
                  ...globalStyles.layout.flexCenter
                }}
              >
                <h1> ID Root/Home</h1>
                <Redirect to="/id/clients" />
              </div>
            )}
          />
        </Content>
      </>
    );
  }
}

Home.contextType = AppContext;

export default withNotifications(Home);

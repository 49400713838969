import { address } from '../common/variables';
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

import { scopes } from '../components/DossierTable';

//eslint-disable-next-line
const BE_ADDR = address;

export async function fetchDossiers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/dossiers', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function postDossier(dossier) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/dossiers', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(dossier)
  });
  return await handleResponse(response);
}

export async function uploadZipFile(file) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  // Fake form submission
  const data = new FormData();
  data.append('sampleFile', file);
  console.log('Uploading', file);
  const response = await fetch(BE_ADDR + 'financeiro/upload', {
    method: 'POST',
    headers: {
      // Accept: 'application/json',
      /* Explicitly omit content-type header, otherwise the browser sets the wrong boundary
       * https://stackoverflow.com/questions/35192841/fetch-post-with-multipart-form-data#comment91367674_40714217
       */
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: data
  });
  return await handleResponse(response);
}

export async function fetchSystemState() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/system-state', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}
export async function downloadReportZip() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/download-zip', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  console.log(response);

  response.blob().then(blob => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'reportZip.zip';
    a.click();
  });

  return response;
}

export async function setSystemState(stateName) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/system-state', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify({ current_state: stateName })
  });
  return await handleResponse(response);
}

export async function fetchConsistency() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/consistency', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function updateDossier(dossier_id, data, scope = scopes.DEFAULT) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const path = scope === scopes.DEFAULT ? 'dossier/' : 'consistency/';
  const endpoint = BE_ADDR + 'financeiro/' + path + dossier_id;

  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(data)
  });
  return await handleResponse(response);
}

export async function uploadMissingFiles(files) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  // Fake form submission
  const data = new FormData();
  data.append('files', files);

  const response = await fetch(BE_ADDR + 'financeiro/consistency/upload-many', {
    method: 'POST',
    headers: {
      // Accept: 'application/json',
      /* Explicitly omit content-type header, otherwise the browser sets the wrong boundary
       * https://stackoverflow.com/questions/35192841/fetch-post-with-multipart-form-data#comment91367674_40714217
       */
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: data
  });
  return await handleResponse(response);
}

export async function consistencyRecheck() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/consistency/recheck', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function getTemplates() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/templates', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function saveTemplate(template) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    BE_ADDR + 'financeiro/templates/' + template.id,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo.signInUserSession.accessToken.jwtToken
      },
      body: JSON.stringify(template)
    }
  );
  return await handleResponse(response);
}

export async function fetchReport() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/report', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function sendEmails() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/send', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

export async function reset() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(BE_ADDR + 'financeiro/reset', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  return await handleResponse(response);
}

import g from '../../styles/global';

const styles = {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    overflowX: 'scroll',
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2
  },
  action: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    width: '100%',
    marginBottom: g.global.baseline,
    fontSize: g.global.baseline * 1.2
  },
  info: {
    body: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    item: {
      marginBottom: g.global.baseline
    },
    icon: { marginRight: g.global.baseline / 2 }
  },
  tag: {
    marginBottom: g.global.baseline * 0.5,
    fontSize: g.global.baseline * 0.8,
    padding: '0 ' + g.global.baseline * 0.5 + 'px'
  },
  badge: {
    marginRight: 0
  },
  smallBadge: {
    fontSize: g.global.baseline / 1.5,
    minWidth: g.global.baseline,
    height: g.global.baseline,
    lineHeight: 1.5,
    padding: 0
  },
  menuIcon: {
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + g.global.baseline + 'px'
  },
  pageHeaderContainer: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '80%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '20%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexEnd,
    ...g.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: g.global.baseline,
    paddingBottom: g.global.baseline,
    paddingRight: g.global.baseline / 2,
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: g.global.baseline,
    paddingTop: g.global.baseline,
    paddingRight: g.global.baseline,
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: g.global.baseline
  },
  table: {
    body: {},
    evenRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0'
    },
    oddRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e0e0e0'
    },
    descendantRow: {
      borderLeft: '1px solid #e0e0e0',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: '#e0f0ff'
    },
    headerRow: {
      borderLeft: '1px solid #e0e0e0',
      textTransform: 'none',
      backgroundColor: '#c0c0ca',
      fontSize: g.global.baseline,
      fontWeight: 500,
      height: g.global.baseline * 4
    },
    column: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRight: '1px solid #e0e0e0',
      width: '100%',
      height: '100%',
      marginRight: g.global.baseline / 2,
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    lastColumn: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    checkboxLabel: {
      marginLeft: '5rem'
    },
    noRows: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd'
    },
    horizontalCell: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      width: '100%',
      height: '100%'
    }
  }
};

export default styles;

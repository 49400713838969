// AntD table related stuff

import React from 'react';
import { Input, Radio } from 'antd';
import moment from 'moment';
import g from '../styles/global';
import { mrg } from '../common/util';
import Highlighter from 'react-highlight-words';

const styles = {
  column: {
    fontSize: g.global.baseline * 1.2,
    fontWeight: 100,
    padding: 0,
    width: 'auto',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  radio: {
    container: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    button: {
      height: 'auto',
      borderRadius: 0,
      display: 'block',
      marginBottom: g.global.baseline / 4,
      textAlign: 'center',
      lineHeight: '30px',
      padding: '0 9px'
    },
    group: {},
    title: {
      paddingBottom: g.global.baseline * 0.5,
      marginBottom: g.global.baseline / 2
    }
  }
};

const EditableCell = ({
  editable,
  value,
  onChange,
  style = {},
  highlight = ''
}) => (
  <div>
    {editable ? (
      <Input.TextArea
        style={style}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    ) : highlight && typeof value === 'string' ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[highlight]}
        autoEscape
        textToHighlight={value.toString()}
      />
    ) : (
      value
    )}
  </div>
);
const EditableRadioCell = ({
  editable,
  options,
  value,
  recordKey,
  onChange
}) => (
  <div style={styles.radio.container}>
    <Radio.Group
      size="large"
      style={mrg([styles.radio.group, !editable && { pointerEvents: 'none' }])}
      buttonStyle={editable ? 'dashed' : 'solid'}
      onChange={e => (editable ? onChange(e.target.value) : null)}
      value={value}
    >
      {Object.keys(options).map((key, index) => {
        let disabled;
        let label;
        if (typeof options[key] === 'string') {
          label = options[key];
          disabled = false;
        } else {
          label = options[key].label;
          disabled = options[key].disabled;
        }
        return (
          <Radio.Button
            disabled={disabled}
            key={key}
            value={key}
            style={styles.radio.button}
          >
            {label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  </div>
);

const renderEditableColumn = (
  obj,
  record,
  column,
  onChange,
  isRadio = false,
  options = [],
  highlight = ''
) => {
  if (isRadio) {
    return (
      <EditableRadioCell
        editable={record.editable}
        options={options}
        recordKey={record.dossier}
        value={obj}
        onChange={value => onChange(value, record.dossier, column, isRadio)}
      />
    );
  }
  return (
    <EditableCell
      editable={record.editable}
      value={obj}
      onChange={value => onChange(value, record.dossier, column)}
      highlight={highlight}
    />
  );
};

const renderColumn = (colSpan, styles = {}, translations = null) => (
  text,
  highlight = ''
) => {
  const result = text ? (translations ? translations[text] : text) : '-';
  return {
    props: { colSpan: colSpan },
    children:
      highlight && typeof text === 'string' ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[highlight]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        <span style={mrg([styles.column, styles])}>{result}</span>
      )
  };
};
const renderDateColumn = (colSpan, format = 'YYYY-MM-DD') => date => {
  return {
    children: <span style={styles.column}>{moment(date).format(format)}</span>
  };
};

const textSorter = fieldName => (a, b) => {
  const aExists = a[fieldName] && a[fieldName].localeCompare;
  const bExists = typeof b[fieldName] === 'string';
  if (!aExists && !bExists) {
    return 0;
  } else if (!aExists) {
    return -1;
  } else if (!bExists) {
    return 1;
  }
  return a[fieldName].localeCompare(b[fieldName]);
};
const dateSorter = fieldName => (a, b) => {
  const aExists = a[fieldName] && a[fieldName].diff;
  const bExists = b[fieldName] instanceof Date;
  if (!aExists && !bExists) {
    return 0;
  } else if (!aExists) {
    return 1;
  } else if (!bExists) {
    return -1;
  }
  return a[fieldName].diff(b[fieldName]);
};

const booleanSorter = fieldName => (a, b) => {
  if (a[fieldName]) return -1;
  if (b[fieldName]) return 1;
  // if both are false, this should keep the default order:
  return 0;
};

const countUnread = array =>
  Array.isArray(array)
    ? array.reduce((accumulator, item) => {
        return accumulator + (item.unread ? 1 : 0);
      }, 0)
    : 0;

export {
  renderColumn,
  renderDateColumn,
  renderEditableColumn,
  textSorter,
  dateSorter,
  booleanSorter,
  EditableCell,
  EditableRadioCell,
  countUnread
};

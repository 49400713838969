import React from 'react';
import { Column } from 'react-virtualized';

import { tableStyles, translateColumnHeader, renderers } from './common';

const paymentColumns = (onSave, extras = {}) => [
  <Column
    width={50}
    flexGrow={2}
    dataKey="Situação"
    key="Situação"
    disableSort={true}
    headerRenderer={translateColumnHeader}
    style={tableStyles.column}
  />,
  <Column
    width={50}
    flexGrow={4}
    dataKey="url"
    key="url"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Data"
    key="Data"
    cellDataGetter={renderers.date} //no need - for showing purposes all dates come correct from ARI
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Data Vencimento"
    key="Data Vencimento"
    cellDataGetter={renderers.date} //no need - for showing purposes all dates come correct from ARI
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Valor"
    key="Valor"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={8}
    dataKey="Descrição"
    key="Descrição"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Documento de Cobrança"
    key="Documento de Cobrança"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={() => 'DOC. COBRANÇA'}
  />,
  <Column
    width={100}
    flexGrow={2}
    dataKey="assignee"
    key="assignee"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
    cellRenderer={value => {
      return (
        <div>
          {renderers.assignee({
            user: extras.user,
            users: extras.users,
            onSave: onSave,
            loading: extras.loading
          })(value)}
        </div>
      );
    }}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="_id"
    key="_id"
    label={'Acções'}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={() => 'ACÇÕES'}
    cellRenderer={values => {
      return (
        <div>
          {renderers.unread({
            user: extras.user,
            onSave: onSave
          })(values)}
        </div>
      );
    }}
  />
];

export default paymentColumns;

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Layout, Menu, Icon } from 'antd';
import AppContext from './context/AppContextBase';
import breakpoints from './styles/breakpoints';
import { address } from './common/variables';
import CmHome from './pages/cm/Home';
import FinHome from './pages/fin/Home';
import IdHome from './pages/id/Home';
import Settings from './pages/settings';
import Profile from './components/Profile';
import globalStyles from './styles/global';
import './styles/override/index.css';
import { mrg, log } from './common/util';
import logo from './img/logo03.png';
//import { fetchAllCases } from './network/citius';

const { Header, Sider, Content } = Layout;

let styles = {
  header: {
    height: globalStyles.global.baseline * 6,
    backgroundColor: globalStyles.colors.background.neutral,
    padding: 0,
    borderBottom: '1px solid #e0e0e0',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: globalStyles.global.baseline * 2,
    minHeight: globalStyles.global.baseline * 2,
    borderRight: '1px solid #f0f2f5'
  },
  logo: {
    color: globalStyles.colors.background.caiadoBlue,
    fontSize: globalStyles.typography.size.base * 5.5,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    lineHeight: 1
  },
  menu: {
    height: '100%',
    fontWeight: 100,
    backgroundColor: 'transparent',
    width: '100%',
    borderBottom: 0
  },
  menuItem: {
    minWidth: '20%',
    fontSize: globalStyles.typography.size.base * 2,
    fontWeight: 100,
    height: globalStyles.global.baseline * 6,
    padding: 0,
    margin: 0
  },
  menuIconContainer: {
    minWidth: globalStyles.global.baseline * 5
  },
  menuIcon: {
    fontSize: globalStyles.global.baseline * 3,
    fontWeight: 100,
    lineHeight: 1,
    svg: {
      height: '100%'
    }
  },
  mobileMenuSider: {
    zIndex: 10,
    minWidth: '100vh',
    position: 'fixed',
    top: 0,
    left: 0
  },
  mobileMenuContainer: {
    minHeight: '100vh',
    padding: '24vmin',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    backgroundColor: 'white'
  },
  mobileMenu: {
    borderRight: 'unset'
  },
  mobileMenuToggle: {
    position: 'fixed',
    right: globalStyles.global.baseline * 2,
    top: globalStyles.global.baseline * 2,
    fontSize: globalStyles.typography.size.base * 2,
    transform: 'translateY(-50%)',
    zIndex: 20,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexCenter
  },
  mobileMenuHeader: {
    height: globalStyles.global.baseline * 4,
    paddingRight: globalStyles.global.baseline * 2,
    marginBottom: globalStyles.global.baseline * 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter
  },
  mobileMenuItem: {
    fontSize: globalStyles.typography.size.base * 2,
    fontWeight: 100,
    minHeight: globalStyles.global.baseline * 6,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  link: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    height: '100%'
  },
  icon: {
    fontSize: globalStyles.typography.size.base * 2,
    alignSelf: 'center',
    fontWeight: 100,
    margin: 0
  }
};

styles = mrg([globalStyles, styles]);
class AppLogo extends React.Component {
  render() {
    const link = (
      <Link
        style={mrg([
          styles.logo,
          styles.link,
          { height: '100%', width: '100%' }
        ])}
        onClick={this.props.onClick}
        key="logo"
        to="/"
      >
        <img height="100%" width="250px" src={logo} alt="logo" />
      </Link>
    );
    return (
      <>
        <MediaQuery maxWidth={breakpoints.mdMax}>
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              { width: '250px', height: '100%', fontSize: 'unset' }
            ])}
          >
            {link}
          </div>
        </MediaQuery>

        <MediaQuery minWidth={breakpoints.lgMin}>
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              { width: '250px', height: '100%', fontSize: 'unset' }
            ])}
          >
            {link}
          </div>
        </MediaQuery>
      </>
    );
  }
}

class AppMenu extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { collapsed: true, showProfile: false };

    this.toggle = this.toggle.bind(this);
    this.menu = this.menu.bind(this);
    this.checkPermission = this.context.checkPermission;
  }

  menu(isMobile) {
    return (
      <Menu
        theme="light"
        mode={isMobile ? 'inline' : 'horizontal'}
        defaultSelectedKeys={[this.props.currentMenuItem]}
        style={isMobile ? styles.mobileMenu : styles.menu}
      >
        {isMobile && (
          <div key="toggle" style={styles.mobileMenuHeader}>
            {<AppLogo style={{ width: 'auto' }} />}
          </div>
        )}
        {this.checkPermission('menu:cm') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="menu2"
          >
            <Link style={styles.link} to="/cm">
              CM
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:id') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="menu3"
          >
            <Link style={styles.link} to="/id">
              ID
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:fin') && (
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="menu4"
          >
            <Link style={styles.link} to="/fin">
              FIN
            </Link>
          </Menu.Item>
        )}
        {this.checkPermission('menu:settings') && (
          <Menu.Item
            style={
              isMobile
                ? styles.mobileMenuItem
                : mrg([
                    styles.menuItem,
                    styles.menuIconContainer,
                    { fontSize: 'unset', float: 'right' }
                  ])
            }
            key="menu5"
          >
            <Link style={styles.link} to="/settings">
              <Icon
                style={{
                  fontSize: globalStyles.global.baseline * 1.5
                }}
                type="setting"
              />
            </Link>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            this.setState((prevState, props) => ({
              ...prevState,
              showProfile: !prevState.showProfile
            }));
          }}
          style={
            isMobile
              ? styles.mobileMenuItem
              : mrg([
                  styles.menuItem,
                  styles.menuIconContainer,
                  { fontSize: 'unset', float: 'right' }
                ])
          }
          key="profile"
        >
          <Profile
            visible={this.state.showProfile}
            handleClick={false}
            user={this.props.user}
            drawerWidth={isMobile ? '100vw' : '50vw'}
          />
        </Menu.Item>
      </Menu>
    );
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <>
        <MediaQuery maxWidth={breakpoints.mdMax}>
          <Layout>
            <Icon
              className="trigger"
              style={styles.mobileMenuToggle}
              type={this.state.collapsed ? 'menu' : 'close'}
              onClick={this.toggle}
            />
            <Sider
              trigger={null}
              reverseArrow={true}
              collapsible={true}
              collapsedWidth="0"
              collapsed={this.state.collapsed}
              width={'100vw'}
              onCollapse={(collapsed, type) => {
                log(collapsed, type);
              }}
              style={styles.mobileMenuSider}
            >
              <div style={styles.mobileMenuContainer}>{this.menu(true)}</div>
            </Sider>
          </Layout>
        </MediaQuery>
        <MediaQuery minWidth={breakpoints.lgMin}>
          <div
            key="menu"
            style={{ width: '80%', height: '100%', position: 'relative' }}
          >
            {this.menu(false)}
          </div>
        </MediaQuery>
      </>
    );
  }
}

AppMenu.contextType = AppContext;

class Root extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { currentMenuItem: 'home' };
    this.handleClick = this.handleClick.bind(this);
    this.checkPermission = this.context.checkPermission;
  }

  handleClick = e => {
    this.setState({
      currentMenuItem: e.key
    });
  };

  render() {
    return (
      <div className="app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Caiado Guerreiro</title>
          <link rel="canonical" href={address} />
        </Helmet>
        <>
          <Router>
            <Layout style={{ maxWidth: '100%' }}>
              <Header style={styles.header}>
                <AppLogo onClick={this.handleClick} />
                <AppMenu
                  user={this.context.state.userData}
                  handleClick={this.handleClick}
                  currentMenuItem={this.state.currentMenuItem}
                />
              </Header>
              <Content
                style={{
                  height:
                    'calc(100vh - ' + globalStyles.global.baseline * 6 + 'px'
                }}
              >
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          ...globalStyles.layout.flexVertical,
                          ...globalStyles.layout.flexCenter
                        }}
                      >
                        <Icon
                          style={{
                            fontSize: 580,
                            color: '#EAEDF4'
                          }}
                          type="fund"
                        />
                      </div>
                    )}
                  />
                  <Route path="/cm" component={CmHome} />
                  <Route path="/fin" component={FinHome} />
                  <Route path="/id" component={IdHome} />
                  <Route path="/settings" component={Settings} />
                </Switch>
              </Content>
            </Layout>
          </Router>
        </>
      </div>
    );
  }
}

Root.contextType = AppContext;

export default Root;

import React from 'react';

import {
  Card,
  Form,
  Select,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  DatePicker
} from 'antd';
import moment from 'moment';

import Loading from '../Loading';
import { mrg } from '../../common/util';
import g from '../../styles/global';
import { postIDAccounting, putIDAccounting } from '../../network/sef';
import DetailedMessage from '../../components/util/DetailedMessage';

const { Option } = Select;

const styles = {
  pageHeaderBox: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: g.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: g.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  attachments: {
    borderTop: '1px solid #e8e8e8',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    alignItems: 'flex-start',
    marginTop: g.global.baseline * 2,
    width: '100%'
  },
  row: {
    width: '100%'
  },
  col: {
    ...g.layout.alignCenter
  },
  card: {
    body: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      width: '100%',
      paddingBottom: g.global.baseline
    },
    header: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      width: '100%',
      paddingBottom: 0,
      marginBotton: 0
    }
  },
  type: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    backgroundColor: '#e8e8e8',
    marginRight: g.global.baseline,
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline,
    paddingTop: g.global.baseline * 0.5,
    paddingBottom: g.global.baseline * 0.5
  },
  form: {
    item: { marginBottom: 0, width: '100%' },
    input: { width: '100%' },
    actions: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexBetween
    },
    extras: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    body: {
      width: '100%'
    }
  }
};

const waysOfPayment = ['Transfer', 'Cartão SSR', 'Cartão MMT', 'Cartão VAN'];

const currencyFormatter = value => {
  if (!value) {
    // empty
    return '';
  }
  if (value.replace) {
    // string
    const clean = value.replace('€', '').trim(); // remove previous symbols
    return `€ ${clean}`;
  }

  return `€ ${value.toString()}`;
};

const currencyParser = value => {
  if (value.replace) {
    return value.replace('€ ', '').trim();
  }
  return value.toString();
};

class CRMForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { loading: false };

    this.defaultValues = this.props.editData ?? {
      owner: this.props.owner,
      department: 'ID',
      kind: 'id_debit',
      date: moment()
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        let result;

        try {
          if (this.props.editData) {
            values._id = this.props.editData._id;
            values.kind = this.props.editData.kind;
            result = await putIDAccounting(values);
          } else {
            values.source = this.props.source;
            values.kind = this.defaultValues.kind;

            result = await postIDAccounting(values);
          }

          if (result && result.result === 'OK') {
            if (this.props.editData) {
              const final = { ...this.props.editData, ...values };
              await this.props.onSaveSuccess?.(final);
            } else {
              await this.props.onSaveSuccess?.(result.data);
            }
          }
        } catch (err) {
          console.error(err);
          DetailedMessage.error('error! ', err);
        }
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <Card
          bodyStyle={styles.card.body}
          headStyle={{}}
          title={'New Debit / Money Out'}
          bordered={true}
        >
          <div style={styles.card.body}>
            <Form.Item style={{ display: 'none' }}>
              {getFieldDecorator('owner', {
                initialValue: this.defaultValues.owner
              })(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item style={{ display: 'none' }}>
              {getFieldDecorator('department', {
                initialValue: this.defaultValues.department
              })(<Input type="hidden" />)}
            </Form.Item>
            <Row style={styles.row} type="flex" gutter={g.global.baseline}>
              <Col span={12} style={styles.col}>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('value', {
                    initialValue: this.defaultValues.value,
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <InputNumber
                      style={styles.form.input}
                      formatter={currencyFormatter}
                      parser={currencyParser}
                      placeholder="Value"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={styles.col}>
                <Form.Item>
                  {getFieldDecorator('date', {
                    initialValue: moment(this.defaultValues.date)
                  })(
                    <DatePicker
                      style={styles.form.input}
                      showTime={false}
                      disabled={this.state.loading}
                    ></DatePicker>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row style={styles.row} type="flex" gutter={g.global.baseline}>
              <Col span={12} style={styles.col}>
                <Form.Item>
                  {getFieldDecorator('purpose', {
                    initialValue: this.defaultValues.purpose,
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      style={styles.form.input}
                      disabled={this.state.loading}
                      placeholder="Purpose"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={styles.col}>
                <Form.Item>
                  {getFieldDecorator('way_of_payment', {
                    initialValue: this.defaultValues.way_of_payment,
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Select
                      style={styles.form.input}
                      disabled={this.state.loading}
                      placeholder="Way of Payment"
                    >
                      {waysOfPayment.map((option, index) => (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row style={styles.row} type="flex" gutter={g.global.baseline}>
              <Col span={12} style={styles.col}>
                <Form.Item>
                  {getFieldDecorator('beneficiary', {
                    initialValue: this.defaultValues.beneficiary,
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      style={styles.form.input}
                      disabled={this.state.loading}
                      placeholder="Beneficiary"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={styles.col}>
                <Form.Item>
                  {getFieldDecorator('applicant', {
                    initialValue: this.defaultValues.applicant,
                    rules: [
                      {
                        required: true,
                        message: 'Required'
                      }
                    ]
                  })(
                    <Input
                      style={styles.form.input}
                      disabled={this.state.loading}
                      placeholder="Applicant"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <div style={styles.form.actions}>
              <Button
                style={{
                  marginBottom: g.global.baseline,
                  marginTop: g.global.baseline
                }}
                disabled={this.state.loading}
                onClick={() => {
                  if (this.props.cancel) {
                    this.props.cancel();
                  }
                }}
                type="dashed"
              >
                Cancel
              </Button>
              <Form.Item
                style={mrg([
                  styles.form.item,
                  { width: '100%', marginBottom: 0 },
                  g.layout.flexHorizontal,
                  g.layout.flexEnd
                ])}
              >
                {this.state.loading && (
                  <Loading
                    style={{
                      margin: g.global.baseline,
                      width: g.global.baseline * 2,
                      height: g.global.baseline * 2
                    }}
                  />
                )}
                <Button
                  style={{
                    marginBottom: g.global.baseline,
                    marginTop: g.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="primary"
                  ghost
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Card>
      </Form>
    );
  }
}

const crmForm = Form.create({ name: 'crm' })(CRMForm);

export default crmForm;

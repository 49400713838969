import React from 'react';
import {
  Upload,
  Button,
  Row,
  Icon,
  Modal,
  List,
  Col,
  Collapse,
  Spin
} from 'antd';
import Papa from 'papaparse';
import autobind from 'auto-bind/react';

import AppContext from '../context/AppContextBase';
import { postImport } from '../network/cm';
import DetailedMessage from './util/DetailedMessage';
import g from '../styles/global';

const { Panel } = Collapse;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    border: '1px solid #e8e8e8',
    background: '#f8f8f8',
    borderRadius: 4,
    padding: g.global.baseline * 2
  },
  error: {
    container: {
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      marginBottom: g.global.baseline,
      borderBottom: '1px solid #e8e8e8'
    },
    title: {
      width: '100%',
      color: g.colors.feedback.error,
      paddingBottom: g.global.baseline / 2,
      marginBottom: g.global.baseline,
      fontSize: g.global.baseline * 1.2,
      fontWeight: 400,
      borderBottom: '1px solid #e8e8e8'
    },
    code: {
      color: '#888',
      margin: 0
    }
  },
  upload: {
    header: {
      fontSize: g.global.baseline * 1.2,
      fontWeight: 500
    },
    results: {
      width: '100%',
      flexDirection: 'row',
      paddingBottom: g.global.baseline
    },
    errors: {
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      marginBottom: g.global.baseline,
      borderBottom: '1px solid #e8e8e8'
    }
  },
  paddingBottom: {
    paddingBottom: g.global.baseline
  },
  modal: {
    body: {
      padding: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    row: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    title: {
      paddingRight: g.global.baseline,
      margin: 0
    },
    message: {
      marginBottom: g.global.baseline,
      fontSize: g.global.baseline * 1.5
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    },
    collapse: {
      width: '100%'
    }
  }
};

const NOOP = () => {};

const STAGES = {
  initial: 'initial',
  upload: 'upload',
  uploading: 'uploading',
  transforming: 'transforming',
  confirm: 'confirm',
  request: 'request',
  response: 'response',
  error: 'error'
};

const CONSTRAINTS = {
  file: [
    'Apenas aceita ficheiros .CSV',
    'A transferência e processamento podem demorar alguns segundos',
    'As colunas do ficheiro deverão rigorosamente seguir a ordem "Process Number", "Folder Name", "Judicial Process Type", "Court"',
    'Se o campo "Folder Name" não corresponder a nenhuma pasta, será criada uma pasta nova',
    'Apenas o campo "Process Number" é obrigatório'
  ],
  confirm: [
    'Processos/Pastas com erros não serão enviados',
    'Após importação será apresentado um relatório'
  ]
};

const validHeaders = [
  'process number',
  'folder name',
  'judicial process type',
  'court'
];
const throwIfHeadersInvalid = obj => {
  for (const key in obj) {
    const lower = key?.toLowerCase?.();
    if (!validHeaders.includes(lower)) {
      const error = new Error('Header inválido ');
      error.header = key;
      throw error;
    }
  }
  return true;
};

const transformHeader = header => header.toLowerCase();

class ImportProcesses extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { stage: STAGES.initial };

    autobind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.stage !== this.state.stage) {
      console.log('Stage transition to ' + this.state.stage);
    }
  }

  setError(message, error) {
    console.error({ message, error });
    return this.setState({
      stage: STAGES.error,
      error: (
        <div style={styles.error.container}>
          <div style={styles.error.title}>
            <Icon
              style={{
                paddingRight: g.global.baseline
              }}
              type="alert"
            ></Icon>
            {message}
          </div>
          {error && <pre style={styles.error.code}>{error.toString()}</pre>}
        </div>
      )
    });
  }

  uploadAndProcessFile = async file => {
    let csvString;
    let parsed;

    this.setState({ stage: STAGES.transforming });
    try {
      csvString = await this.readFile(file);
    } catch (err) {
      this.setError(
        'Erro na leitura do ficheiro. Ficheiro corrupto ou leitura interrompida',
        err
      );
      return;
    }
    try {
      parsed = await this.parseCsv(csvString);
    } catch (err) {
      this.setError(
        'Erro a processar conteúdo do ficheiro. Formato Inválido.',
        err
      );
      return;
    }
    let folders = [];
    let correctProcesses;
    const errors = parsed.errors;

    if (parsed?.data) {
      const folderSet = new Set();
      const idKey = validHeaders[0];
      const folderKey = validHeaders[1];
      const typeKey = validHeaders[2];
      const courtKey = validHeaders[3];
      correctProcesses = parsed.data.filter(process => process[idKey]);
      parsed.data
        .filter(process => !process[idKey])
        .forEach(process =>
          errors.push({
            message: `Empty process ID field.
Folder: "${process[folderKey]}", 
Type:  "${process[typeKey]}",
Court: "${process[courtKey]}"`,
            code: 'Empty Process ID',
            row: '-'
          })
        );
      for (const process of correctProcesses) {
        try {
          throwIfHeadersInvalid(process);
        } catch (err) {
          this.setError(
            'Erro a processar conteúdo do ficheiro. Header inválido: ' +
              err.header,
            err
          );
          return;
        }

        if (process[folderKey]) {
          folderSet.add(process[folderKey]);
        }
        process.id = process[idKey];
        process.portfolio_id = this.props.portfolio?.id;
        process.portfolioName = this.props.portfolio?.name;
      }

      folders = Array.from(folderSet);
      folders.forEach(id => {
        if (!id) {
          errors.push({
            message: `Empty Folder ID field.
Process: "${process[idKey]}"
Type:  "${process[typeKey]}",
Court: "${process[courtKey]}"`,
            code: 'Empty Folder ID',
            row: '-'
          });
        }
      });
      folders = folders
        .filter(id => id)
        .map(id => ({
          id,
          name: id,
          portfolioName: this.props.portfolio?.name,
          portfolio: this.props.portfolio?.id
        }));
    }

    this.setState({
      processes: correctProcesses,
      folders,
      errors,
      stage: STAGES.confirm
    });
  };

  readFile = file => {
    this.setState({ stage: STAGES.transforming });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.onabort = reject;
    });
  };

  parseCsv = csvString => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvString, {
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: transformHeader,
        complete: resolve,
        error: reject
      });
    });
  };

  async doImport() {
    let request;
    console.log({
      processes: this.state.processes,
      folders: this.state.folders
    });
    try {
      request = await postImport(this.state.processes, this.state.folders);
      if (!request || !request.result === 'OK') {
        throw new Error('Could not send import request');
      }
    } catch (err) {
      DetailedMessage.error(err.message, err);
      this.setState({ stage: STAGES.confirm });
      return;
    }
    try {
      const { importedProcesses, importedFolders, errors } = request.data;
      console.log({ importedProcesses, importedFolders, errors });
      this.setState({
        stage: STAGES.response,
        processes: importedProcesses,
        folders: importedFolders,
        errors
      });
    } catch (error) {
      DetailedMessage.error('Erro: Resposta inválida', error);
    }
  }

  renderInfo(infoList) {
    return (
      <Row type="flex" style={styles.upload.results}>
        <List
          size="small"
          header={null}
          footer={null}
          bordered={false}
          dataSource={infoList}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Row>
    );
  }

  renderReport() {
    return (
      <Collapse style={styles.modal.collapse}>
        {this.state.errors?.length && (
          <Panel
            header={
              <Row
                type="flex"
                style={{ ...styles.modal.row, ...styles.paddingBottom }}
              >
                <h2
                  style={{
                    ...styles.modal.error,
                    ...styles.modal.title
                  }}
                >
                  Erros
                </h2>
                <div>Total: {this.state.errors?.length}</div>
              </Row>
            }
            key="Errors"
          >
            <List
              style={{ width: '100%' }}
              size="small"
              header={
                <Row
                  type="flex"
                  style={{
                    ...styles.modal.row,
                    ...styles.upload.header
                  }}
                >
                  <Col span={6}>Código</Col>
                  <Col span={12}>Mensagem</Col>
                  <Col span={6}>Linha</Col>
                </Row>
              }
              footer={null}
              bordered={false}
              dataSource={this.state.errors}
              renderItem={item => (
                <List.Item>
                  <Row
                    type="flex"
                    style={{
                      ...styles.modal.row,
                      color: g.colors.feedback.error
                    }}
                  >
                    <Col span={6}>{item.code}</Col>
                    <Col span={12}>{item.message}</Col>
                    <Col span={6}>{item.row}</Col>
                  </Row>
                </List.Item>
              )}
            />
          </Panel>
        )}
        {this.state.processes?.length && (
          <Panel
            header={
              <Row type="flex" style={styles.modal.row}>
                <h2 style={styles.modal.title}>Processos</h2>
                <span>Total: {this.state.processes?.length || 0}</span>
              </Row>
            }
            key="processes"
          >
            <List
              size="small"
              header={
                <Row
                  type="flex"
                  style={{ ...styles.modal.row, ...styles.upload.header }}
                >
                  {validHeaders.map(key => (
                    <Col key={key} span={6}>
                      {key}
                    </Col>
                  ))}
                </Row>
              }
              footer={null}
              bordered={false}
              dataSource={this.state.processes || []}
              renderItem={item => (
                <List.Item>
                  <Row type="flex" style={styles.modal.row}>
                    {validHeaders.map(key => (
                      <Col key={key} span={6}>
                        {item[key]}
                      </Col>
                    ))}
                  </Row>
                </List.Item>
              )}
            />
          </Panel>
        )}
        {this.state.folders?.length && (
          <Panel
            header={
              <Row type="flex" style={styles.modal.row}>
                <h2 style={styles.modal.title}>Pastas</h2>
                <span>Total: {this.state.folders?.length || 0}</span>
              </Row>
            }
            key="pastas"
          >
            <List
              size="small"
              header={
                <Row
                  type="flex"
                  style={{ ...styles.modal.row, ...styles.upload.header }}
                >
                  <Col span={12}>ID da pasta</Col>
                </Row>
              }
              footer={null}
              bordered={false}
              dataSource={this.state.folders || []}
              renderItem={item => (
                <List.Item>
                  <Row type="flex" style={styles.modal.row}>
                    <Col span={12}>{item.id}</Col>
                  </Row>
                </List.Item>
              )}
            />
          </Panel>
        )}
      </Collapse>
    );
  }

  getContent = () => {
    switch (this.state.stage) {
      case STAGES.error:
        return [
          <Row
            key={1}
            type="flex"
            style={{ ...styles.modal.row, ...styles.paddingBottom }}
          >
            {this.state.error}
          </Row>,
          <Row
            key={2}
            type="flex"
            style={{ ...styles.modal.row, ...styles.paddingBottom }}
          >
            <Button
              style={styles.pageHeaderButton}
              ghost
              type="danger"
              onClick={() => {
                this.setState({ stage: STAGES.upload });
              }}
            >
              Reiniciar
            </Button>
          </Row>
        ];
      case STAGES.response:
        return <>{this.renderReport()}</>;
      case STAGES.request:
        return (
          <div>
            <Spin style={{ marginnRight: g.global.baseline * 2 }}></Spin>A
            enviar
          </div>
        );
      case STAGES.confirm:
        return (
          <>
            <Row type="flex" style={styles.upload.results}>
              <Button
                style={styles.pageHeaderButton}
                ghost
                type="primary"
                onClick={async () => {
                  this.setState({ stage: STAGES.request });
                  return await this.doImport();
                }}
              >
                Enviar
              </Button>
            </Row>
            {this.renderInfo(CONSTRAINTS.confirm)}
            {this.renderReport()}
          </>
        );

      case STAGES.uploading:
      // inntentional fallthrough
      case STAGES.transforming:
      // inntentional fallthrough
      case STAGES.upload:
        return (
          <>
            <Row
              key={1}
              type="flex"
              style={{ ...styles.modal.row, ...styles.paddingBottom }}
            >
              <h2>Escolher ficheiro</h2>
            </Row>
            <Row
              key={2}
              type="flex"
              style={{ ...styles.modal.row, ...styles.paddingBottom }}
            >
              <Upload
                accept=".csv"
                customRequest={NOOP}
                transformFile={this.uploadAndProcessFile}
              >
                <Button loading={this.state.stage !== STAGES.upload}>
                  <>
                    <Icon type="upload"></Icon> Upload
                  </>
                </Button>
              </Upload>
            </Row>
            {this.renderInfo(CONSTRAINTS.file)}
          </>
        );

      default:
        return <div>erro desconhecido</div>;
    }
  };

  render() {
    return (
      <>
        <Button
          style={styles.pageHeaderButton}
          ghost
          disabled={this.props.disabled}
          type="primary"
          onClick={() => {
            this.setState({ stage: STAGES.upload });
          }}
        >
          Importar
        </Button>
        {this.state.stage !== STAGES.initial && (
          <Modal
            width="80vw"
            bodyStyle={{
              height: '80vh',
              padding: g.ant.modal.body.padding
            }}
            title=""
            visible={!this.props.disabled}
            footer={null}
            onCancel={() => this.setState({ stage: STAGES.initial })}
          >
            <div style={styles.container}>
              <Row type="flex" style={styles.modal.row}>
                <h1>Importação de Processos</h1>
              </Row>
              {this.getContent()}
            </div>
          </Modal>
        )}
      </>
    );
  }
}

ImportProcesses.contextType = AppContext;

export default ImportProcesses;

import React from 'react';
import { Button, message, Tag, Tooltip, Icon, Select } from 'antd';
import AddToCalendar from 'react-add-to-calendar';
import moment from 'moment';
import { tryParseDate } from './../../../common/util';
import g from '../../../styles/global';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';

const { Option } = Select;

export const tableStyles = {
  body: { width: '100%' },
  evenRow: {
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    overflowY: 'visible'
  },
  oddRow: {
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #e0e0e0',
    overflowY: 'visible'
  },
  descendantRow: {
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: '#e0f0ff'
  },
  headerRow: {
    borderLeft: '1px solid #e0e0e0',
    textTransform: 'none',
    backgroundColor: '#c0c0ca',
    fontSize: g.global.baseline,
    fontWeight: 500,
    height: g.global.baseline * 4,
    overflowY: 'visible'
  },
  column: {
    whiteSpace: 'nowrap',
    overflowY: 'visible',
    textOverflow: 'ellipsis',
    borderRight: '1px solid #e0e0e0'
  },
  calendarColumn: {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    textOverflow: 'ellipsis',
    borderRight: '1px solid #e0e0e0'
  },
  lastColumn: {
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    textOverflow: 'ellipsis'
  },
  checkboxLabel: {
    marginLeft: '5rem'
  },
  noRows: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1em',
    color: '#bdbdbd'
  }
};

export const rowHeight = g.global.baseline * 5;

export const mapHeaderLabel = label => {
  const map = {
    id: 'Passaporte',
    name: 'Nome',
    birth_date: 'Data Nasc.'
  };
  if (map[label.toLowerCase()]) {
    return map[label];
  }
  return label;
};

export const simpleColumnHeader = ({ dataKey }) => (
  <div>{dataKey.toUpperCase()}</div>
);

export const translateColumnHeader = ({ dataKey }) => (
  <div>{mapHeaderLabel(dataKey).toUpperCase()}</div>
);

export const rowStyle = ({ index }) => {
  if (index === -1) {
    return tableStyles.headerRow;
  }
  if (index % 2 === 0) {
    return tableStyles.evenRow;
  } else {
    return tableStyles.oddRow;
  }
};

export const renderers = {
  date: ({ dataKey, rowData }) => {
    return rowData[dataKey]
      ? tryParseDate(rowData[dataKey]).format('YYYY/MM/DD')
      : '#Inválido';
  },
  assignee: ({ onSave, users = [], loading = false }) => ({ rowData }) => {
    // [ANT] antd requires a string or a number for <Option> keys. Use empty string instead of null:
    const assignee = rowData.assignee || { _id: '' };
    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        onChange={async (value, option) => {
          if (onSave) {
            const toSave = {
              _id: rowData._id,
              // [ANT] here replace the empty string (falsy value) with a null again
              assignee: value && value.key ? { _id: value.key } : null
            };
            const result = await onSave(toSave);
            return result;
          }
        }}
        labelInValue
        optionFilterProp="children"
        disabled={!(users && users.length)}
        loading={loading}
        defaultValue={{ key: assignee?._id }}
        placeholder="Assignee"
      >
        <Option key={''} value={''}>
          <b>Unassigned</b>
        </Option>
        {users.map(user => (
          <Option key={user._id} value={user._id}>
            {user.name}
          </Option>
        ))}
      </Select>
    );
  },
  unread: ({
    onSave,
    onSaveSuccess = () => {},
    onSaveError = () => {
      message.error('Error saving value');
    },
    user
  }) => ({ dataKey, rowData }) => {
    let unread = rowData['unread'];
    const canEdit =
      user && rowData.assignee ? user._id === rowData.assignee._id : true;
    return !canEdit ? (
      <Tooltip
        placement="topRight"
        title={'You must be assigned to this task to change its status'}
      >
        <Tag style={{ height: '100%' }}>
          <Icon type={unread ? 'exclamation' : 'check'}></Icon>{' '}
          {unread ? 'Unread' : 'Read'}
        </Tag>
      </Tooltip>
    ) : (
      <Button
        type={unread ? 'danger' : 'primary'}
        ghost
        size="small"
        style={{
          marginRight: g.global.baseline
        }}
        onClick={async () => {
          if (onSave) {
            const toSave = {
              _id: rowData._id,
              unread: !unread
            };
            const result = await onSave(toSave);
            return result;
          }
        }}
      >
        {unread ? 'Unread' : 'Read'}
      </Button>
    );
  },
  addToCalendar: ({ client, rowData }) => {
    let startTime = rowData['Data'] + ' ' + rowData['Hora'];
    let formatedStartTime = moment(startTime, 'DD-MM-YYYY HH:mm');
    let endTime = moment(formatedStartTime).add(30, 'minutes');

    let event = {
      //title: rowData['Assunto'] + ': ' + rowData['Local'],
      title: client.Nome,
      // description:
      //   'Meeting at SEF services - ' +
      //   rowData['Local'] +
      //   '. \nSubject: ' +
      //   rowData['Assunto'],
      description: ' ',
      location: rowData['Morada'],
      startTime: formatedStartTime,
      endTime: endTime
    };
    return (
      <div style={{ zIndex: 1000000 }}>
        <AddToCalendar
          event={event}
          buttonLabel="Add to Calendar"
          listItems={[{ outlook: 'Outlook' }, { google: 'Google' }]}
        />
      </div>
    );
  }
};

import React from 'react';
import { List, Button } from 'antd';
import MessagesContext from '../../context/MessageContextBase';

import EditableMessage from './EditableMessage';

import MessageForm from './MessageForm';
import Loading from '../Loading';
import NoData from '../util/NoData';
import g from '../../styles/global';

const styles = {
  messages: {
    container: {
      width: '100%',
      heigth: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      height: '100%',
      overflowY: 'scroll'
    },
    header: {},
    subheader: {
      width: '100%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    attachments: {
      borderTop: '1px solid #e8e8e8',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      alignItems: 'flex-start',
      width: '100%',
      paddingTop: g.global.baseline,
      marginTop: g.global.baseline
    },

    type: {
      fontSize: g.global.baseline * 1.5,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      backgroundColor: '#e8e8e8',
      marginRight: g.global.baseline,
      paddingLeft: g.global.baseline,
      paddingRight: g.global.baseline,
      paddingTop: g.global.baseline * 0.5,
      paddingBottom: g.global.baseline * 0.5
    }
  }
};

class MessageBoard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showMessagesForm: false,

      loadingMessages: false
    };
  }

  isAssignedToMe = item => {
    return item?.assignee?._id === this.context?.userData?._id;
  };

  isOwnedByMe = item => {
    return item.owner === this.context?.userData?.id;
  };

  render() {
    return (
      <div style={styles.messages.container}>
        {this.state.showMessagesForm ? (
          <div style={{ width: '100%' }}>
            <MessageForm
              hide={() => this.setState({ showMessagesForm: false })}
            />
          </div>
        ) : (
          <div style={styles.messages.subheader}>
            {this.context?.allowCreation !== false && (
              <Button
                type="primary"
                style={{
                  width: '100%',
                  marginRight: g.global.baseline
                }}
                onClick={() => {
                  this.setState({ showMessagesForm: true });
                }}
              >
                New entry
              </Button>
            )}
            <Button
              type="secondary"
              onClick={async () => {
                this.setState({ loadingMessages: true });
                await this.context?.operations?.refreshMessages();
                this.setState({ loadingMessages: false });
              }}
            >
              Refresh
            </Button>
          </div>
        )}
        {this.state.loadingMessages ? (
          <Loading />
        ) : this.context?.messages ? (
          <List
            style={{ width: '100%' }}
            itemLayout="horizontal"
            dataSource={
              this.context?.types
                ? this.context?.messages.filter(item =>
                    this.context?.types?.includes?.(item.type)
                  )
                : this.context?.messages
            }
            renderItem={item => (
              <div>
                <EditableMessage
                  showUnread={
                    item.type !== 'AUDIT' &&
                    (!item.assignee || this.isAssignedToMe(item))
                  }
                  isOwner={this.isOwnedByMe(item)}
                  editable={
                    item.type !== 'AUDIT' &&
                    item.owner === this.context?.userData?.id
                  }
                  deletable={
                    this.context.allowDelete !== false &&
                    item.type !== 'AUDIT' &&
                    item.owner === this.context?.userData?.id
                  }
                  values={item}
                />
              </div>
            )}
          />
        ) : (
          <NoData></NoData>
        )}
      </div>
    );
  }
}

MessageBoard.contextType = MessagesContext;

class MessagesContextWrapper extends React.Component {
  render() {
    return (
      <MessagesContext.Provider
        value={{
          operations: this.props.operations,
          ...this.props.data
        }}
      >
        <MessageBoard></MessageBoard>
      </MessagesContext.Provider>
    );
  }
}
export default MessagesContextWrapper;
export { MessageBoard };

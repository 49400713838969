import mapFields from '../../util/mapFields';
import EditableField from '../../../../components/util/EditableField';
import DocumentsReceivedColumns from '../columns/permanentResidency/DocumentsReceivedColumns';
import DocumentsPreparedColumns from '../columns/permanentResidency/DocumentsPreparedColumns';

//Reuse this:
import { concessionFields, renewalFields } from './goldenVisaFields';

const { Types } = EditableField;

const baseFields = ({
  data,
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {},
  onSave,
  editable = true
}) => {
  const fieldMap = [
    {
      title: 'Notes',
      key: 'base.notes',
      span: 24,
      type: Types.textarea,
      editable: editable
    },
    {
      key: 'base.documents_prepared',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Documents Prepared',
      template: { title: '', date: null },
      getColumns: DocumentsPreparedColumns,
      editable: editable
    },
    {
      key: 'base.documents_signed_received',
      type: Types.array,
      span: 24,
      extras: 'client',
      title: 'Documents Signed / Received',
      template: {
        title: '',
        type: '',
        date: null,
        validity: null,
        formalities: ''
      },
      getColumns: DocumentsReceivedColumns,
      editable: editable
    },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false },
    { placeholder: 24, hide: false }
  ];

  return mapFields(
    data,
    fieldMap,
    onSave,
    onSaveSuccess,
    onSaveError,
    style,
    true
  );
};

export { baseFields, concessionFields, renewalFields };

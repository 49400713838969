import React from 'react';
import { Helmet } from 'react-helmet';
import { Table, message, notification, Icon } from 'antd';

import getColumns from './dossierColumns';
import { updateDossier } from '../network/fin';
import validator from 'validator';

import globalStyles from '../styles/global';

const styles = {
  table: {
    width: '100%',
    fontSize: globalStyles.global.baseline * 1.2,
    fontWeight: 100,
    border: '1px solid #e8e8e8'
  }
};

const scopes = {
  DEFAULT: 'default',
  CONSISTENCY: 'consistency',
  REPORT: 'REPORT'
};

export { scopes };

const clean = value => value.replace(/ +/g, ' ');

export default class DossierTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.setWarned = this.setWarned.bind(this);
    this.consistencyWarning = this.consistencyWarning.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.state = {
      searchText: '',
      data: this.props.dossiers,
      cacheInit: this.cacheData && this.cacheData.length,
      warned: false
    };
    // options = { scope, state, handleChange, edit, save, cancel, consistencyWarning, setWarned }

    this.state.columns = getColumns({
      scope: this.props.scope,
      handleChange: this.handleChange,
      edit: this.edit,
      save: this.save,
      cancel: this.cancel,
      consistencyWarning: this.consistencyWarning,
      isWarned: this.state.warned,
      setWarned: this.setWarned,
      searchText: this.state.searchText,
      handleSearch: this.handleSearch,
      handleReset: this.handleReset
    });

    this.cacheData = JSON.parse(JSON.stringify(props.dossiers));
    this.state.cacheInit = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dossiers !== prevState.data) {
      this.setState({ data: this.props.dossiers });
      if (!this.state.cacheInit) {
        this.cacheData = JSON.parse(JSON.stringify(this.props.dossiers));
        if (this.cacheData && this.cacheData.length) {
          this.setState({ cacheInit: true });
        }
      }
    }
    if (prevState.searchText !== this.state.searchText) {
      this.setState({
        columns: getColumns({
          scope: this.props.scope,
          handleChange: this.handleChange,
          edit: this.edit,
          save: this.save,
          cancel: this.cancel,
          consistencyWarning: this.consistencyWarning,
          isWarned: this.state.warned,
          setWarned: this.setWarned,
          searchText: this.state.searchText,
          handleSearch: this.handleSearch,
          handleReset: this.handleReset
        })
      });
    }
  }
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  setWarned() {
    this.setState({ warned: true });
  }

  consistencyWarning() {
    if (!this.state.warned) {
      notification.open({
        duration: 6,
        message: 'Informação Importante',
        description: (
          <span>
            <span>
              As alterações feitas durante processo de envio são temporárias e{' '}
              <u>são guardadas apenas durante o mesmo</u>, desaparecendo no
              final do processo.
            </span>
            <br />
            <br />
            <span>
              Para efectuar alterações permanentes utilize a lista de dossiers
            </span>
          </span>
        ),
        icon: (
          <Icon
            type="warning"
            style={{
              color: globalStyles.colors.background.pink
            }}
          />
        )
      });
      this.setState({ warned: true });
    }
  }

  handleChange(value, key, column, isRadio = false) {
    const newData = this.state.data;
    const target = newData.filter(item => key === item.dossier)[0];
    if (target) {
      target[column] = value;
      this.setState({ data: newData });
    }
  }

  edit(key) {
    const newData = [...this.state.data];
    const target = newData.filter(item => key === item.dossier)[0];
    if (target) {
      let old = this.cacheData.filter(item => key === item.dossier)[0];

      if (!old) {
        this.cacheData.push(JSON.parse(JSON.stringify(target)));
      } else {
        Object.assign(old, JSON.parse(JSON.stringify(target)));
      }

      target.editable = true;
      this.setState({ data: newData });
    }
  }
  async save(key) {
    const newData = [...this.state.data];
    const target = newData.filter(item => key === item.dossier)[0];
    if (target) {
      for (let field in target) {
        if (!(typeof target[field] === 'string')) {
          continue;
        }
        target[field] = clean(target[field]);
      }
      target.saving = true;
      let response;
      delete target.editable;

      // validate emails:

      let value = target.email_contacts;
      if (!value || value === '') {
        message.error(
          'Lista de contactos inválida! Insira uma lista de emails separados por "," ou ";"'
        );
        target.saving = true;
        return;
      }
      let arr = value.replace(/ /g, '').split(/[,;]/);
      if (!arr) {
        target.saving = true;
        message.error(
          'Lista de contactos inválida! Insira uma lista de emails separados por "," ou ";"'
        );
        return;
      }
      let result = true;
      arr.forEach(email => {
        email = email.trim();
        result = result && validator.isEmail(email);
      });

      if (!result) {
        target.saving = true;
        message.error(
          'Lista de contactos inválida! Insira uma lista de emails separados por "," ou ";"'
        );
        return;
      }

      value = target.dossier;
      // validate dossier ID:
      if (!value || value === '') {
        message.error('Num dossier inválido. Formato: X.Y');
        target.saving = true;
        return;
      }
      // there are dossies =  "0116.040.01"
      // if (!value.match(/^[\w\d]+\.[\w\d]+$/)) {
      //   message.error('Num dossier inválido. Formato: X.Y');
      //   target.saving = true;
      //   return;
      // }

      const cleanTarget = JSON.parse(JSON.stringify(target));
      delete cleanTarget.createdAt;
      delete cleanTarget.updatedAt;
      delete cleanTarget.dossier_state;
      delete cleanTarget.edited;
      delete cleanTarget.has_errors;
      delete cleanTarget.saving;
      delete cleanTarget.warning;

      response = await updateDossier(target.dossier, target, this.props.scope);

      if (response && response.result === 'OK') {
        target.edited = true;
        this.setState({ data: newData });
        this.cacheData = JSON.parse(JSON.stringify(newData));
        message.success('Alterações Guardadas', 3);
        if (this.props.onEdit) {
          this.props.onEdit();
        }
      } else {
        message.error(
          'Ocorreu um erro, as alterações não foram guardadas! Verifique a sua ligação à internet e/ou permissões de acesso.',
          8
        );
        console.log(response);
      }
      target.saving = false;
    }
  }
  cancel(key) {
    const newData = JSON.parse(JSON.stringify(this.state.data));
    let target = newData.filter(item => key === item.dossier)[0];
    if (target) {
      const old = this.cacheData.filter(item => key === item.dossier)[0];
      if (old) {
        Object.assign(target, old);

        target.info_to_send = old.info_to_send;
        target.send_method = old.send_method;

        delete target.editable;
        delete target.edited;
        delete target.saving;
        this.setState({ data: newData });
      }
    }
  }
  render() {
    return (
      <>
        <Helmet>
          {/* antd overrides - I should get shot for this */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${globalStyles.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${globalStyles.global.baseline}px;
              line-height: 1;
            }
            .ant-table-tbody > tr.error {
              background-color: ${globalStyles.colors.foreground.error}
            }

            .ant-table-tbody > tr.edited {
              background-color: ${globalStyles.colors.foreground.edited}
            }
            .ant-table-tbody > tr.warning {
              background-color: ${globalStyles.colors.foreground.warning}
            }
            .ant-table-tbody > tr.success {
              background-color: ${globalStyles.colors.foreground.success}
            }
          `}</style>
        </Helmet>
        <Table
          loading={!this.state.data.length}
          style={styles.table}
          rowKey={record => record.dossier}
          columns={this.state.columns}
          dataSource={this.state.data}
          rowClassName={(record, index) => {
            if (record.edited) {
              return 'edited';
            } else if (record.has_errors) {
              return 'error';
            } else if (record.warning) {
              return 'warning';
            } else if (record.success) {
              return 'success';
            }
            return '';
          }}
          pagination={{
            position: 'both',
            showSizeChanger: true,
            total: this.state.data.length || 0,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
            onShowSizeChange: (current, size) => {
              //TODO: save page size change on user settings
            }
          }}
        />
      </>
    );
  }
}

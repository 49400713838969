import { bindGroupEnums } from './helpers';

const constants = {
  roles: {
    user: { key: 'user', label: 'User', numeric: 0 },
    backoffice: { key: 'backoffice', label: 'Backoffice', numeric: 1 },
    admin: { key: 'admin', label: 'Administrator', numeric: 2 },
    dev: { key: 'dev', label: 'Developer', numeric: 3 }
  },
  departments: {
    client: { key: 'client', label: 'Client', numeric: 0 },
    confidant: { key: 'confidant', label: 'Confidant', numeric: 1 }
  },
  scopes: {
    external: { key: 'external', label: 'External', numeric: 0 },
    internal: { key: 'internal', label: 'Internal', numeric: 1 }
  }
};

bindGroupEnums(constants, 'key', 'label', 'numeric');

export default constants;

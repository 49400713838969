import React from 'react';
import { Button, message } from 'antd';
//import { FilePond, File, registerPlugin } from 'react-filepond';

import { uploadZipFile } from '../network/fin';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import Loading from './Loading';
import useFileHandlers from './useFileHandlers';

import globalStyles from '../styles/global';
import { mrg } from '../common/util';

import Dropzone from 'react-dropzone';
// FilePond.setOptions({
//   server: {
//     url: 'http://localhost:3000/financeiro/upload',
//     process: {
//       url: './process',
//       method: 'POST',
//       withCredentials: false,
//       headers: {},
//       timeout: 7000,
//       onload: null,
//       onerror: null,
//       ondata: null
//     }
//   }
// });

const styles = {
  form: {
    width: '100%',
    height: '100%'
  },
  uploader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 10
  },
  uploaderInput: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: '3px dashed rgb(180,185,196)',
    backgroundColor: 'rgb(234,237,244)',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  hiddenInput: {
    position: 'absolute',
    top: '-9999px'
  },
  feedback: {
    idle: {
      backgroundColor: 'transparent'
    },
    loaded: { backgroundColor: 'transparent' },
    pending: { backgroundColor: 'transparent' },
    success: { backgroundColor: 'transparent' },
    error: { backgroundColor: 'transparent' },
    title: {
      fontSize: globalStyles.global.baseline * 3,
      fontWeight: 100,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      textAlign: 'center'
    },
    info: {
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none'
    },
    code: {
      fontSize: globalStyles.global.baseline,
      fontWeight: 100,
      color: '#444444',
      textTransform: 'none',
      fontFamily: 'monospace'
    },
    all: {
      zIndex: 20,
      width: '100%',
      height: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexCenter,
      ...globalStyles.layout.alignCenter,
      fontSize: globalStyles.global.baseline * 2,
      fontWeight: 700,
      color: '#666666',
      textTransform: 'uppercase',
      paddingTop: globalStyles.global.baseline,
      paddingBottom: globalStyles.global.baseline,
      textAlign: 'center'
    }
  }
};

const Init = ({ multiple, onChange, onReject, accept, title, description }) => {
  console.log('ACCEPT: ', accept);
  return (
    <>
      <Dropzone
        accept={accept ? accept : null}
        style={styles.uploader}
        id="uploader-root"
        onDropAccepted={onChange}
        onDropRejected={onReject}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            style={mrg([styles.feedback.idle, styles.feedback.all])}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {title && <h2 style={styles.feedback.title}>{title}</h2>}
            <span style={styles.feedback.info}>
              {description
                ? description
                : 'Clique ou arraste um arquivo para esta caixa para iniciar o processo'}
            </span>
          </div>
        )}
      </Dropzone>
    </>
  );
};

const Loaded = () => (
  <div style={mrg([styles.feedback.loaded, styles.feedback.all])}>
    <h2 style={styles.feedback.title}>Ficheiro ZIP Seleccionado</h2>
    <Button type="secondary" style={styles.feedback.info} htmlType="submit">
      Submeter
    </Button>
  </div>
);

const Pending = ({ files, pending }) => {
  return (
    <div style={mrg([styles.feedback.pending, styles.feedback.all])}>
      <span style={styles.feedback.info}>A transferir ficheiros</span>
      <Loading style={{ margin: 0 }} />
    </div>
  );
};

const Success = redo => {
  return (
    <div style={mrg([styles.feedback.success, styles.feedback.all])}>
      <h2 style={styles.feedback.title}>Ficheiros Transferidos</h2>
    </div>
  );
};

const Error = ({ uploadError }) => (
  <div>
    <h2 style={mrg([styles.feedback.error, styles.feedback.all])}>
      Ocorreu um erro
    </h2>
    {uploadError && (
      <span style={styles.feedback.code}>{uploadError.message}</span>
    )}
  </div>
);
let selectedFile = null;

const onChangeHandler = event => {
  selectedFile = event.target.files[0];

  // this.setState({
  //   selectedFile:event.target.files[0] ,
  //   loaded: 0
  // });
};

const WA_uploadButton = async () => {
  let result = await uploadZipFile(selectedFile);
  console.log(result);
  await window.location.reload();
};

const onReject = err => {
  console.log(err);
  return message.error('Ficheiro Inválido: ' + JSON.stringify(err, null, 2));
};
const Upload = props => {
  const {
    files,
    pending,
    uploadError,
    status,
    // onSubmit,
    onChange
  } = useFileHandlers(props.uploadApi);

  if (status === 'FILES_UPLOADED') {
    setTimeout(() => props.done(), 1000);
  }

  const accept = props.accept ? props.accept : null;

  return (
    // <form style={styles.form} onSubmit={onSubmit}>
    <div id="uploaderInput" style={styles.uploaderInput}>
      {status === 'IDLE' && (
        <>
          <div style={{ width: '400px' }}>
            {/* <form method="POST" action="127.0.0.1:3000/financeiro/upload">
              Select a file: <input type="file" name="myFile" />
              <br />
              <br />
              <input type="submit" />
            </form> */}

            <span>
              Upload de ficheiro ZIP: Se necessário, recarregar a página depois
              do upload.
            </span>
            <br />
            <input type="file" name="file" onChange={onChangeHandler} />

            <button
              type="button"
              className="btn btn-success btn-block"
              onClick={WA_uploadButton}
            >
              Upload
            </button>

            {/* <FilePond
                server=""
                files={testFiles}
                allowMultiple={true}
                onupdatefiles={setFiles}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              /> */}
          </div>

          {/* <Init
              title="Transferir Ficheiros"
              multiple={props.multiple === true}
              onChange={onChange}
              onReject={onReject}
              accept={accept}
            /> */}
        </>
      )}
      {status === 'LOADED' && <Loaded />}
      {status === 'PENDING' && <Pending files={files} pending={pending} />}
      {status === 'FILES_UPLOADED' &&
        (props.redo ? (
          <Init
            title={null}
            description="Clique ou arraste um arquivo para esta caixa para transferir mais ficheiros"
            multiple={props.multiple === true}
            onChange={onChange}
            onReject={onReject}
            accept={accept}
          />
        ) : (
          <Success />
        ))}
      {status === 'UPLOAD_ERROR' && <Error uploadError={uploadError} />}
    </div>
    // </form>
  );
};

export default Upload;

import Excel from 'exceljs';
import defaults from 'lodash.defaults';
import moment from 'moment';
import op from 'object-path';

const heightBaseline = 20;

/* FYI */
// const excelColumnSchema = [
//   {
//     header: String,
//     key: String,
//     dataPath: String,
//     transform: Function,
//     width: Number
//   }
// ];

export const prepareWorkbook = options => {
  const workbook = new Excel.Workbook();

  // The bible says "use sensible defaults, but allow customization everywhere" (it doesn't, but it should)
  const defaultOpts = {
    creator: 'Vicki Finpartner',
    created: moment(),
    sheetTitle: 'Sheet 1',
    workbookViews: [
      {
        x: 0,
        y: 0,
        height: 10000,
        width: 10000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible'
      }
    ],
    worksheetViews: [{ state: 'frozen', ySplit: 1 }],
    worksheetProperties: { defaultRowHeight: 15 },
    worksheetColumns: null
  };

  const opts = options ? { ...options } : {}; // clone options. we don't want to mutate external objects
  defaults(opts, defaultOpts);

  workbook.creator = opts.creator;
  workbook.created = opts.created;
  workbook.views = opts.workbookViews;

  const worksheet = workbook.addWorksheet(opts.sheetTitle, {
    views: opts.worksheetViews,
    properties: opts.worksheetProperties
  });

  if (opts.worksheetColumns) {
    worksheet.columns = opts.worksheetColumns;
  }
  worksheet.getRow(1).height = heightBaseline;
  return { workbook, worksheet };
};

export const workbook2Blob = async workbook => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return blob;
};

export const getRowHeight = (item, baseline = heightBaseline) => {
  try {
    const consistencyHeightMultiplier = Math.max(
      item.consistency.warningList.length,
      item.consistency.errorList.length
    );
    const reportHeightMultiplier = Math.max(
      item.report.warningList.length,
      item.report.errorList.length
    );
    const heightMultiplier =
      Math.max(consistencyHeightMultiplier, reportHeightMultiplier) || 1;

    return heightBaseline * heightMultiplier;
  } catch (error) {
    return heightBaseline * 2;
  }
};

export const prepareExcelData = (rawData, excelColumns) => {
  return rawData.map(item => {
    const result = {};
    for (const datum of excelColumns) {
      let value = op.get(item, datum.dataPath);
      if (datum.transform) {
        value = datum.transform(value);
      }
      result[datum.key] = value;
    }
    result.rowHeight = getRowHeight(item);
    return result;
  });
};

export const insertExcelData = (worksheet, arrayData) => {
  for (let i = 0; i < arrayData.length; i++) {
    const item = arrayData[i];
    const row = worksheet.addRow(item, 'n');
    row.height = item.rowHeight;
    row.style.font = { size: 16 };
    row.commit(); // used for exceljs streaming api only
  }
};

import React, { Component } from 'react';

import { notification } from 'antd';
//import './App.css';
import Login from './pages/Login';
import Root from './Root';
import AppContext from './context/AppContextBase';

import Amplify, { Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
Amplify.configure(awsConfig);
//const logger = new Logger('App');

const debug = false;
//eslint-disable-next-line
const log = debug ? console.log.bind(window.console) : () => {};

class App extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { authenticated: false };
    this.handleHubAuthCapsule = this.handleHubAuthCapsule.bind(this);
    this.authenticate = this.context.authenticate;
    this.logOut = this.context.logOut;
    this.auth = this.auth.bind(this);
    Hub.listen('auth', this.handleHubAuthCapsule, 'signOut');
  }

  handleHubAuthCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      if (payload.event === 'signOut') {
        this.logOut();
      } else if (payload.errors) {
        let msg = '';
        for (let outer in payload.errors) {
          const innerErrors = payload.errors[outer].errors;
          if (innerErrors) {
            for (let inner in innerErrors) {
              msg += innerErrors[inner];
            }
            msg += '\n';
          }
        }
        notification.warning('AWS Hub Error: \n' + msg, 8);
      }
    } else {
      log('hub: ', payload);
    }
  }

  async auth(data) {
    if (data) {
      try {
        await this.authenticate(data);
      } catch (err) {
        notification.error({
          message: 'Authentication Error',
          description: (
            <div>
              <span>Something went wrong. Error details:</span>
              <br />
              <span style={{ color: '#999' }}>{err.toString()}</span>
            </div>
          ),
          onClick: () => {}
        });
        console.error(err);
      }
    } else {
      this.logOut();
    }
  }

  render() {
    return (
      <div>
        {this.context.state.authenticated ? (
          <Root />
        ) : (
          <Login setAuthenticated={this.auth} />
        )}
      </div>
    );
  }
}

App.contextType = AppContext;

export default App;

import React from 'react';

import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { Layout, List, Typography, message } from 'antd';

import Editor from '../../components/Editor';

import AppContext from '../../context/AppContextBase';
import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

import { getTemplates, saveTemplate } from '../../network/fin';

const { Content, Sider } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  layout: {
    padding: globalStyles.global.baseline,
    backgroundColor: 'transparent'
  },
  content: {
    padding: globalStyles.global.baseline,
    paddingTop: 0,
    backgroundColor: 'transparent'
  },
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  templates: {
    list: {
      backgroundColor: '#fff',
      marginBottom: globalStyles.global.baseline
    }
  },
  sider: {
    backgroundColor: 'transparent',
    maxHeight: '100%',
    overflowY: 'scroll'
  },
  helpBox: {
    marginBottom: globalStyles.global.baseline,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    backgroundColor: '#fff',
    width: '100%',

    padding: globalStyles.global.baseline,
    border: '1px dashed #dddddd',
    color: '#888888'
  },
  variableBox: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter,
    flexWrap: 'wrap',
    width: '100%',
    padding: globalStyles.global.baseline,
    paddingLeft: 0
  },
  variable: {
    marginBottom: globalStyles.global.baseline,
    marginRight: globalStyles.global.baseline,
    padding: globalStyles.global.baseline,
    border: '1px dashed #cccccc'
  }
};

class Templates extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { templates: null, currentTemplate: null, availableVars: [] };
    this.saveTemplate = this.saveTemplate.bind(this);
  }

  async componentDidMount() {
    const request = await getTemplates();
    if (request && request.result === 'OK' && request.data.docs.length > 0) {
      this.setState({
        templates: request.data.docs
      });
    }
  }

  async saveTemplate(template) {
    const request = await saveTemplate(template);
    if (request && request.result === 'OK') {
      this.state.templates.forEach(templ => {
        if (templ.id === template.id) {
          templ = template;
        }
      });
      return request;
    } else {
      message.error(
        <span>
          Erro! Verifique a sua ligação à internet e/ou permissões de acesso.
        </span>
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>FIN - Templates</title>
        </Helmet>

        <Route
          path={'/fin/templates'}
          render={() => (
            <Layout style={mrg([styles.fullWidth, styles.layout])}>
              <Sider style={styles.sider}>
                {this.state.templates && (
                  <List
                    header={
                      <span style={styles.pageHeaderContent}>Modelos</span>
                    }
                    style={styles.templates.list}
                    bordered
                    dataSource={this.state.templates}
                    renderItem={item => (
                      <List.Item
                        onClick={() => {
                          const current = this.state.templates.filter(
                            templ => item.id === templ.id
                          );
                          if (current.length) {
                            let availableVars = [];
                            if (current[0].vars) {
                              availableVars = current[0].vars;
                              //deduplicate:
                              availableVars = [...new Set(availableVars)];
                            }
                            this.setState({
                              currentTemplate: current[0],
                              availableVars: availableVars
                            });
                          }
                        }}
                      >
                        <Typography.Text>
                          <b> {item.language}</b> {item.type}
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                )}
                <div style={styles.helpBox}>
                  <span style={styles.pageHeaderContent}>
                    Variáveis disponíveis
                  </span>
                  {this.state.availableVars && (
                    <div style={styles.variableBox}>
                      {this.state.availableVars.map(variable => (
                        <div key={variable} style={styles.variable}>
                          <code>{variable}</code>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Sider>

              <Content style={styles.content}>
                <Editor
                  onSave={saveTemplate}
                  template={this.state.currentTemplate}
                />
              </Content>
            </Layout>
          )}
        />
      </>
    );
  }
}

export default Templates;

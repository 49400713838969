import React from 'react';
import { Tabs } from 'antd';
import op from 'object-path';
import moment from 'moment';

import AppContext from '../../../context/AppContextBase';

import NoData from '../../../components/util/NoData';
import {
  baseFields
  // concessionFields,
  // renewalFields
} from './fields/standardFields';

import { putProcess } from '../../../network/sef';
import g from '../../../styles/global';

const { TabPane } = Tabs;

const styles = {
  title: {
    fontWeight: 300,
    paddingRight: g.global.baseline * 2,
    marginBottom: 0
  },
  tableContainer: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    flex: 1,
    width: '100%',
    height: 'auto',
    overflowY: 'scroll'
  },
  titleContainer: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignCenter,
    width: '100%',
    paddingBottom: g.global.baseline
  },
  tabs: {
    main: {
      width: '100%',
      height: '100%'
    },
    label: {
      marginRight: g.global.baseline
    },
    pane: { height: 'auto', overflowY: 'scroll', position: 'relative' },
    extra: {
      container: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexBetween,
        ...g.layout.alignCenter,
        width: '100%',
        borderBottom: '1px solid #eaebeb',
        paddingBottom: g.global.baseline,
        marginBottom: g.global.baseline
      },
      left: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        width: '100%'
      },
      right: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexEnd,
        ...g.layout.alignCenter,
        width: '100%'
      }
    }
  }
};

// const fieldMap = (data, onSave, editable) => ({
//   concession: index =>
//     concessionFields({
//       index: index,
//       data: data,
//       style: {
//         paddingLeft: 0,
//         paddingRight: 0,
//         paddingBottom: g.global.baseline * 4
//       },
//       onSaveSuccess: () => message.success('Value saved'),
//       onSave: onSave,
//       editable: editable
//     }),
//   renewal: index =>
//     renewalFields({
//       index: index,
//       data: data,
//       style: {
//         paddingLeft: 0,
//         paddingRight: 0,
//         paddingBottom: g.global.baseline * 4
//       },
//       onSaveSuccess: () => message.success('Value saved'),
//       onSave: onSave,
//       editable: editable
//     })
// });

export default class Process extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  processExceptions = (fieldPath, data, value) => {
    const newNoteRegex = /base\.notes\.\d+$/;
    if (fieldPath.match(newNoteRegex)) {
      // Manually add createdAt and owner value to new notes
      value.createdAt = moment();
      value.owner = {
        id: this.context.state.userData.id,
        name: this.context.state.userData.name
      };
    }
  };

  save = async (fieldPath, data, value) => {
    // _id and kind are REQUIRED for backend to update value.
    // This is a differential update. Only updated field is changed.
    this.processExceptions(fieldPath, data, value);

    const dataToSave = {
      _id: this.state.data._id,
      kind: this.state.data.kind,
      [fieldPath]: value
    };
    dataToSave.editedField = fieldPath;

    const request = await putProcess(dataToSave);
    if (!request || request.result !== 'OK') {
      throw request;
    } else {
      this.setState(prevState => {
        const dataToShow = { ...prevState.data };
        op.set(dataToShow, fieldPath, value);
        return {
          data: dataToShow
        };
      });
      return request;
    }
  };

  render() {
    // const map = fieldMap(
    //   { ...this.state.data, client: this.props.client },
    //   this.save,
    //   this.context.checkPermission('id:client:processes:editable')
    // );
    return this.state.data ? (
      <Tabs
        style={styles.tabs.main}
        defaultActiveKey="pagamentos"
        /*tabBarExtraContent={
          this.props.editable && (
            <div style={styles.tabs.extra.left}>
              <Select
                placeholder={'New Stage'}
                disabled={this.state.loading}
                style={{
                  minWidth: g.global.baseline * 15,
                  backgroundColor: 'transparent',
                  marginRight: g.global.baseline
                }}
                onChange={(value, option) =>
                  this.setState({ newPipelineStage: option.key })
                }
                value={this.state.newPipelineStage}
              >
                {Object.keys(this.props.pipelineStages).map(key => {
                  const stage = this.props.pipelineStages[key];
                  return (
                    <Option key={stage.key} value={stage.label}>
                      {stage.label}
                    </Option>
                  );
                })}
              </Select>
              <Button
                style={{
                  width: g.global.baseline * 3
                }}
                loading={this.state.loading}
                disabled={!this.state.newPipelineStage}
                onClick={async () => {
                  if (this.props.newPipelineStage) {
                    this.setState({ loading: true });
                    const response = await this.props.newPipelineStage(
                      this.state.data._id,
                      this.state.newPipelineStage
                    );
                    this.setState({ newPipelineStage: undefined });
                    if (response && response.result === 'OK') {
                      this.setState({ loading: false });
                      return await this.props.onSaveSuccess();
                    } else {
                      this.setState({ loading: false });
                      return await this.props.onSaveError();
                    }
                  }
                }}
                type="primary"
                icon="plus"
              ></Button>
            </div>
          )
        }*/
      >
        <TabPane
          style={styles.tabs.pane}
          tab={
            <span>
              <span style={styles.tabs.label}>Base</span>
            </span>
          }
          key="base"
        >
          {this.state.data.base && Object.keys(this.state.data.base).length ? (
            baseFields({
              data: {
                ...this.state.data,
                client: this.props.client
              },
              style: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: g.global.baseline
              },
              onSave: this.save,
              onSaveSuccess: this.props.onSaveSuccess,
              onSaveError: this.props.onSaveError,
              editable: this.context.checkPermission(
                'id:client:processes:editable'
              )
            })
          ) : (
            <NoData></NoData>
          )}
        </TabPane>
        {/* {Array.isArray(this.state.data.pipeline) &&
          this.state.data.pipeline.map((stage, index) => {
            return map[stage.type] ? (
              <TabPane
                style={styles.tabs.pane}
                tab={
                  <span>
                    <span style={styles.tabs.label}>{stage.type}</span>
                  </span>
                }
                key={stage._id}
              >
                {map[stage.type](index)}
              </TabPane>
            ) : (
              <TabPane
                tab={
                  <span>
                    <span style={styles.tabs.label}>Invalid Stage</span>
                  </span>
                }
                key={stage._id}
              >
                Invalid Stage
              </TabPane>
            );
          })} */}
        {this.context.checkPermission('id:client:json') && (
          <TabPane
            style={{
              height: '100%',
              width: '100%',
              overflowY: 'scroll',
              overflowWrap: 'normal'
            }}
            tab="JSON"
            key="client_json"
          >
            <pre>{JSON.stringify(this.state.data, null, 2)}</pre>
          </TabPane>
        )}
      </Tabs>
    ) : (
      <NoData></NoData>
    );
  }
}

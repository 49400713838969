import React from 'react';
import autobind from 'auto-bind/react';
import Helmet from 'react-helmet';
import { Tabs, Button, Select } from 'antd';
import op from 'object-path';

import AppContext from '../../../context/AppContextBase';
import NoData from '../../../components/util/NoData';
import ConfirmDeleteButton from '../../../components/util/ConfirmDeleteButton';

import g from '../../../styles/global';

const { TabPane } = Tabs;
const { Option } = Select;

const styles = {
  container: {
    full: {
      width: '100%',
      height: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter,
      padding: g.global.baseline,
      paddingTop: 0
    }
  },
  markedForDeletion: {
    textTransform: 'uppercase',
    color: g.colors.feedback.error,
    fontSize: g.global.baseline,
    fontWeight: 100,
    padding: g.global.baseline * 2,
    paddingLeft: 0
  },
  tabs: {
    main: {
      width: '100%',
      height: '100%'
    },
    container: {
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignStart,
      //paddingBottom: globalStyles.global.baseline * 3,
      paddingBottom: 0,
      paddingTop: 0,
      height: '100%',
      width: '100%',
      overflowY: 'scroll'
    },
    label: {},
    tabBar: {
      height: '100%',
      overflowY: 'scroll',
      textOverflow: 'ellipsis',
      maxWidth: '30%'
    },
    extra: {
      container: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexBetween,
        ...g.layout.alignCenter,
        width: '100%',
        borderBottom: '1px solid #eaebeb',
        marginBottom: g.global.baseline,
        paddingBottom: g.global.baseline
      },
      left: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexStart,
        ...g.layout.alignCenter,
        width: '60%'
      },
      right: {
        ...g.layout.flexHorizontal,
        ...g.layout.flexEnd,
        ...g.layout.alignCenter,
        width: '40%'
      }
    }
  }
};

class ProcessTabs extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      newProcessKind: undefined,
      activeTabKey: props.data?.processes?.[0]?._id,
      processes: props.data.processes
    };
    autobind(this);
  }

  getLabel(process) {
    if (process.customName) {
      return process.customName;
    }
    const processKind = process.kind;
    const data = this.props.types[processKind];
    const label = data ? data.label : 'Unknown: ' + processKind;
    return label || 'Unknown' + processKind;
  }

  getTag(kind) {
    const data = this.props.types[kind];
    const TagName = data
      ? data.component
      : props => <div>Unknow process Type</div>;

    return TagName;
  }

  async onSaveSuccess(saveValues, processId) {
    const { key: fieldPath, value } = saveValues ?? {};

    if (fieldPath) {
      this.setState(oldState => {
        const newProcesses = Array.from(oldState.processes);

        const target = newProcesses.find(proc => proc._id === processId);
        if (target) {
          op.set(target, fieldPath, value);
        }

        return { processes: newProcesses };
      });
    }

    return await this.props.onSaveSuccess?.(saveValues);
  }

  async onSaveError(error) {
    return await this.props.onSaveError?.(error);
  }

  render() {
    return (
      <>
        <Helmet>
          <style type="text/css">
            {`.ant-tabs .ant-tabs-left-bar .ant-tabs-tab, .ant-tabs
            .ant-tabs-right-bar .ant-tabs-tab {
              width: 100%;
              padding-left: 0;
            }
            .ant-tabs-tabpane .ant-tabs-tabpane-active { height: 100%}`}
          </style>
        </Helmet>
        {this.state.processes ? (
          <div style={styles.container.full}>
            <div style={styles.tabs.extra.container}>
              <div style={styles.tabs.extra.left}>
                {this.context.checkPermission(
                  'id:client:processes:editable'
                ) && (
                  <>
                    <Select
                      disabled={this.state.loading}
                      style={{
                        minWidth: g.global.baseline * 15,
                        backgroundColor: 'transparent',
                        marginRight: g.global.baseline
                      }}
                      onChange={(value, option) =>
                        this.setState({ newProcessKind: option.key })
                      }
                      value={this.state.newProcessKind}
                      placeholder="New Process"
                    >
                      {Object.keys(this.props.types).map(procKey => (
                        <Option
                          key={this.props.types[procKey].key}
                          value={this.props.types[procKey].label}
                        >
                          {this.props.types[procKey].label}
                        </Option>
                      ))}
                    </Select>
                    <Button
                      style={{
                        width: g.global.baseline * 3
                      }}
                      loading={this.state.loading}
                      disabled={!this.state.newProcessKind}
                      onClick={async () => {
                        if (this.props.newProcess) {
                          const response = await this.props.newProcess(
                            this.state.newProcessKind
                          );
                          this.setState({ newProcessKind: undefined });
                          if (response && response.result === 'OK') {
                            this.setState({ activeTabKey: response.data._id });
                            return await this.onSaveSuccess();
                          } else {
                            return await this.onSaveError();
                          }
                        }
                      }}
                      type="primary"
                      icon="plus"
                    ></Button>
                  </>
                )}
              </div>
              <div style={styles.tabs.extra.right}>
                {this.context.checkPermission(
                  'id:client:processes:editable'
                ) && (
                  <ConfirmDeleteButton
                    deleteLabel="Delete this process"
                    confirmLabel="Confirm deletion!"
                    disabled={!this.state.activeTabKey}
                    buttonStyle={{ marginLeft: g.global.baseline * 4 }}
                    onDelete={async () => {
                      if (this.props.deleteProcess) {
                        const response = await this.props.deleteProcess(
                          this.state.activeTabKey
                        );
                        this.setState({
                          markedForDeletion: null,
                          activeTabKey: null
                        });
                        if (response && response.result === 'OK') {
                          return await this.onSaveSuccess();
                        } else {
                          return await this.onSaveError();
                        }
                      }
                    }}
                    onConfirm={() =>
                      this.setState((state, props) => ({
                        markedForDeletion: state.activeTabKey
                      }))
                    }
                    isConfirm={
                      this.state.markedForDeletion === this.state.activeTabKey
                    }
                  ></ConfirmDeleteButton>
                )}
              </div>
            </div>
            <Tabs
              onChange={activeKey => {
                this.setState((state, props) => ({
                  activeTabKey: activeKey,
                  markedForDeletion: null
                }));
              }}
              style={styles.tabs.main}
              tabPosition={'left'}
              activeKey={this.state.activeTabKey}
              tabBarStyle={styles.tabs.tabBar}
            >
              {this.state.processes.map(datum => {
                const TagName = this.getTag(datum.kind);
                return (
                  <TabPane
                    style={styles.tabs.pane}
                    tab={
                      <span style={styles.tabs.label}>
                        {this.getLabel(datum)}
                      </span>
                    }
                    key={datum._id}
                  >
                    <div style={styles.tabs.container}>
                      {this.state.markedForDeletion ===
                        this.state.activeTabKey && (
                        <span style={styles.markedForDeletion}>
                          marked for deletion
                        </span>
                      )}
                      <TagName
                        onSaveSuccess={async result =>
                          await this.onSaveSuccess(result, datum._id)
                        }
                        editable={this.context.checkPermission(
                          'id:client:processes:editable'
                        )}
                        onSaveError={this.onSaveError}
                        data={datum}
                        client={this.props.data}
                        newPipelineStage={this.props.newPipelineStage}
                        pipelineStages={
                          this.props.types[datum.kind]
                            ? this.props.types[datum.kind].pipelineStages
                            : []
                        }
                      ></TagName>
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        ) : (
          <NoData></NoData>
        )}
      </>
    );
  }
}

export default ProcessTabs;

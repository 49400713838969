import React from 'react';
import AppContext from '../../../context/AppContextBase';
import { Modal } from 'antd';
import globalStyles from '../../../styles/global';

class ManagePermissions extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = { visible: true };

    this.goBack = this.props.handleGoBack || (() => {});
  }

  render() {
    return (
      <Modal
        width="90vw"
        style={{ top: '5vh', textAlign: 'center' }}
        buttonProps={{ size: 'small' }}
        bodyStyle={{
          minHeight: '90vh',
          width: '100%',
          paddingTop: globalStyles.global.baseline * 4,
          paddingBottom: globalStyles.global.baseline * 4,
          ...globalStyles.layout.flexVertical,
          ...globalStyles.layout.flexStart,
          ...globalStyles.layout.alignCenter
        }}
        visible={this.state.visible}
        onOk={this.goBack}
        onCancel={this.goBack}
        footer={null}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default ManagePermissions;

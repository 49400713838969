import React from 'react';
import { Form, Button, Input, Select, message } from 'antd';
// eslint-disable-next-line
import validator from 'validator';

import AppContext from '../context/AppContextBase';
import globalStyles from '../styles/global';

// eslint-disable-next-line
const { Option } = Select;

const styles = {
  container: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignStart,
    marginBottom: globalStyles.global.baseline,
    marginTop: globalStyles.global.baseline
  },
  form: {
    body: {
      width: '100%',
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    section: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignStart
    },
    item: {
      paddingRight: globalStyles.global.baseline,
      marginBottom: globalStyles.global.baseline
    }
  }
};

class InsertFolder extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = { sending: false, folders: [] };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ sending: true });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          console.log('Received values of form: ', values);
          if (this.props.action) {
            const request = await this.props.action(values);

            if (request && request.result === 'OK') {
              if (this.props.done) {
                this.props.done();
              } else {
                message.success(<span>Sucesso</span>);
              }
              //clear form:
              this.props.form.resetFields();
            } else {
              if (this.props.error) {
                this.props.error(request);
              } else {
                message.error(
                  <span>
                    Erro na inserção. Verifique a sua ligação à internet e tente
                    de novo.
                  </span>
                );
              }
            }
          }
        } catch (err) {
          if (this.props.error) {
            this.props.error(err);
          } else {
            message.error(
              <span>
                Erro na inserção. Verifique a sua ligação à internet e tente de
                novo.
              </span>
            );
          }
        }
      }
      this.setState({ sending: false });
    });
  };

  handleCancel() {
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        style={{
          borderBottom: '1px solid #e8e8e8',
          marginBottom: globalStyles.global.baseline
        }}
      >
        <span>Criar Pasta</span>
        <div style={styles.container}>
          <Form onSubmit={this.handleSubmit} style={styles.form.body}>
            <div style={styles.form.section}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('id', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(
                  <Input
                    disabled={this.state.sending}
                    placeholder="ID (único) da pasta"
                  />
                )}
              </Form.Item>

              <Form.Item style={styles.form.item}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(<Input disabled={this.state.sending} placeholder="Nome" />)}
              </Form.Item>

              <Form.Item style={{ ...styles.form.item, width: '33%' }}>
                <Button
                  loading={this.state.sending}
                  type="primary"
                  ghost
                  className="login-form-button"
                  onClick={this.handleCancel}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </div>
            <div style={styles.form.section}>
              {/* hidden / automatic values */}
              <Form.Item style={{ ...styles.form.item, display: 'none' }}>
                {getFieldDecorator('portfolio', {
                  initialValue: this.props.portfolio.id
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, display: 'none' }}>
                {getFieldDecorator('portfolioName', {
                  initialValue: this.props.portfolio.name.toUpperCase()
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('type', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(<Input disabled={this.state.sending} placeholder="Tipo" />)}
              </Form.Item>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('sub_type', {
                  rules: [{ required: true, message: 'Inválido' }]
                })(
                  <Input disabled={this.state.sending} placeholder="Sub Tipo" />
                )}
              </Form.Item>
              <Form.Item style={{ ...styles.form.item, width: '33%' }}>
                <Button
                  loading={this.state.sending}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Submeter
                </Button>
              </Form.Item>
            </div>
            {false && (
              <div style={styles.form.section}>
                {/* RESERVED (hidden) fields */}
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('ext_lawyer', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Advogado Externo"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('int_lawyer', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Advogado Externo"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('visibility', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Visibilidade"
                    />
                  )}
                </Form.Item>
                <Form.Item style={styles.form.item}>
                  {getFieldDecorator('description', {
                    rules: [{ required: false, message: 'Inválido' }]
                  })(
                    <Input
                      disabled={this.state.sending}
                      placeholder="Descrição"
                    />
                  )}
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
const InsertFolderForm = Form.create({ name: 'insert_folder' })(InsertFolder);
export default InsertFolderForm;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Link } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Statistic,
  List,
  Avatar,
  Tooltip,
  Button,
  message,
  Modal,
  Icon
} from 'antd';
import moment from 'moment';

import AppContext from '../../context/AppContextBase';
import InsertPortfolio from '../../components/InsertPortfolio';
import DetailedMessage from '../../components/util/DetailedMessage';
import ConfirmLogin from '../../components/auth/ConfirmLogin';
import g from '../../styles/global';
import { mrg } from '../../common/util';
import {
  getPortfolios,
  deletePortfolio,
  postPortfolio
} from '../../network/cm';

const { Content } = Layout;
const { confirm } = Modal;

const styles = {
  fullWidth: {
    width: '100%'
  },
  content: {
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2
  },
  listItemContainer: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2,
    paddingTop: g.global.baseline,
    paddingBottom: g.global.baseline
  },
  listAnchor: {
    width: '100%'
  },
  listItem: {
    width: '100%',
    borderBottom: '1px solid #E3E7ED'
  },
  itemMeta: { width: '90%' },
  portfolioDetails: {
    height: '100%',
    width: '10%'
  },
  detailsButton: {
    fontSize: g.global.baseline * 1.5,
    fontWeight: 100
  },
  action: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    width: '100%',
    marginBottom: g.global.baseline
  },
  tag: {
    marginBottom: g.global.baseline
  },
  info: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    fontWeight: 100,
    color: '#444'
  },
  pageHeaderBox: {
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexBetween,
    ...g.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: g.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: g.global.baseline,
    paddingBottom: g.global.baseline
  },
  modal: {
    body: {
      borderTop: '1px solid #e2e2e2',
      borderBottom: '1px solid #e2e2e2',
      marginTop: g.global.baseline,
      paddingTop: g.global.baseline,
      paddingBottom: g.global.baseline * 2,
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      textAlign: 'left',
      fontSize: g.global.baseline * 1.2
    },
    list: {
      listStylePosition: 'inside',
      padding: 0,
      paddingLeft: g.global.baseline
    },
    message: {
      marginBottom: g.global.baseline
    },
    error: {
      marginBottom: g.global.baseline,
      color: g.colors.feedback.error
    }
  }
};

class Portfolios extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      portfolios: []
    };
  }

  refresh = async () => {
    let portfolios = await getPortfolios(this.props.match.params.id);
    if (portfolios && portfolios.result === 'OK') {
      this.setState({
        portfolios: portfolios.data.docs
      });
    }
  };

  async componentDidMount() {
    await this.refresh();
  }

  render() {
    return (
      <>
        <Helmet>
          <title>CM - Portfolios</title>
        </Helmet>
        <Layout style={styles.fullWidth}>
          <Content
            style={mrg([
              g.layout.flexVertical,
              g.layout.flexStart,
              styles.fullWidth,
              {
                height: 'calc(100vh - 12vh)',
                overflowY: 'scroll',
                paddingTop: 0
              },
              styles.content
            ])}
          >
            <Route
              exact
              path={'/cm/portfolios'}
              render={() => (
                <div
                  style={mrg([
                    g.layout.flexVertical,
                    g.layout.flexStart,
                    styles.fullWidth,
                    { height: '100%', alignItems: 'flex-start' }
                  ])}
                >
                  <div style={styles.pageHeaderBox}>
                    <span style={styles.pageHeaderTitle}>Carteiras</span>
                    {this.state.showCreatePortfolio ? (
                      <InsertPortfolio
                        action={async data => {
                          const request = await postPortfolio(data);
                          if (!request || !request.result === 'OK') {
                            DetailedMessage.error(
                              'Erro a gravar Carteira',
                              request
                            );
                          }
                          return request;
                        }}
                        cancel={() =>
                          this.setState({ showCreatePortfolio: false })
                        }
                        done={async () => {
                          await this.refresh();
                          this.setState({ showCreatePortfolio: false });
                          message.success(<span>Sucesso</span>);
                        }}
                      />
                    ) : (
                      <Button
                        style={styles.pageHeaderButton}
                        ghost
                        type="primary"
                        onClick={() => {
                          this.setState({ showCreatePortfolio: true });
                        }}
                      >
                        Nova Carteira
                      </Button>
                    )}
                  </div>

                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      overflowY: 'scroll'
                    }}
                  >
                    <List
                      loading={!this.state.portfolios.length}
                      style={mrg([styles.fullWidth, styles.list])}
                      pagination={false}
                      dataSource={this.state.portfolios}
                      itemLayout="horizontal"
                      renderItem={item => (
                        <ListItem record={item} onChange={this.refresh} />
                      )}
                    />
                  </div>
                </div>
              )}
            />
          </Content>
        </Layout>
      </>
    );
  }
}

class ListItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { hover: false };
  }

  removePortfolio = async id => {
    const self = this;
    return await new Promise(r =>
      confirm({
        okButtonProps: { ghost: true },
        title: (
          <span style={{ fontSize: g.global.baseline * 2 }}>
            Remover Carteira
          </span>
        ),
        icon: (
          <Icon
            type="warning"
            style={{
              fontSize: g.global.baseline * 2.5,
              color: g.colors.feedback.error
            }}
          />
        ),
        content: (
          <div style={styles.modal.body}>
            <span style={styles.modal.message}>
              Tem a certeza que pretende apagar esta carteira?
            </span>
            <ul style={styles.modal.list}>
              <li style={{ ...styles.modal.error }}>
                Esta operação é irreversível!
              </li>
              <li style={{ ...styles.modal.error, fontWeight: 700 }}>
                Todos os processos e pastas da carteira serão apagados!
              </li>
            </ul>
          </div>
        ),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancelar',
        async onOk() {
          const response = await deletePortfolio(id);
          if (!response || !response.result === 'OK') {
            DetailedMessage.error('Erro a apagar registo', response);
          } else {
            message.success('Apagado com sucesso');
            await self.props?.onChange?.();
          }
          return r();
        }
      })
    );
  };

  render() {
    const record = this.props.record;

    const canDelete = this.context.checkPermission('cm:portfolios:delete');
    const confirmDelete = (
      <Button
        type="danger"
        icon="delete"
        ghost
        disabled={!canDelete}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          this.setState({ stage: 'try-delete' });
        }}
      ></Button>
    );

    return (
      <List.Item
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={mrg([
          styles.listItem,
          { backgroundColor: this.state.hover ? '#E3E7ED' : '#fff' }
        ])}
      >
        <div style={styles.listItemContainer}>
          <Link
            to={{
              pathname: '/cm/processes/portfolio/' + record.id,
              state: { portfolio_name: record.name }
            }}
            style={styles.listAnchor}
          >
            <List.Item.Meta
              style={styles.itemMeta}
              avatar={
                <Avatar
                  style={{
                    backgroundColor: '#D0D7E1',
                    marginRight: g.global.baseline * 3
                  }}
                  size={g.global.baseline * 6}
                  icon="bank"
                />
              }
              description={
                <Row type={'flex'} style={{ height: '100%' }}>
                  <Col span={6}>
                    <Statistic
                      valueStyle={styles.info}
                      title="Nome"
                      value={record.name.toUpperCase()}
                    />
                  </Col>
                  <Col span={6}>
                    <Statistic
                      valueStyle={styles.info}
                      title="Num. Processos"
                      value={record.num_cases}
                    />
                  </Col>
                  <Col span={6}>
                    <Statistic
                      valueStyle={styles.info}
                      title={
                        <span>
                          <span>Data de Início</span>
                          <span
                            style={{
                              fontSize: '8px',
                              color: '#888',
                              marginLeft: g.global.baseline
                            }}
                          >
                            YYYY/MM/DD
                          </span>
                        </span>
                      }
                      value={moment(record.createdAt).format('YYYY / MM /  DD')}
                    />
                  </Col>
                  <Col
                    style={{
                      height: '100%',
                      ...g.layout.flexVertical,
                      ...g.layout.flexCenter,
                      ...g.layout.alignStart,
                      paddingLeft: g.global.baseline * 2
                    }}
                    span={6}
                  >
                    <Statistic
                      valueStyle={styles.info}
                      title={<span>Operações</span>}
                      formatter={() =>
                        canDelete ? (
                          confirmDelete
                        ) : (
                          <Tooltip
                            style={{ zIndex: 10000000 }}
                            title={'Permissões insuficientes'}
                          >
                            {confirmDelete}
                          </Tooltip>
                        )
                      }
                    />
                  </Col>
                </Row>
              }
            />
          </Link>

          {this.state.stage === 'try-delete' && (
            <Modal
              footer={null}
              visible
              onCancel={() => this.setState({ stage: 'initial' })}
            >
              <ConfirmLogin
                success={async () => {
                  this.setState({ stage: 'confirm-delete' });
                  return await this.removePortfolio(record.id);
                }}
                error={async () => {
                  message.error('Credenciais inválidas');
                }}
                username={this.context.state.userData.email}
              ></ConfirmLogin>
            </Modal>
          )}
          {/* <Button style={styles.detailsButton} size={'large'}>
            Detalhes
            <Icon theme="filled" type="pie-chart" />
          </Button> */}
        </div>
      </List.Item>
    );
  }
}

Portfolios.contextType = AppContext;
ListItem.contextType = AppContext;

export default Portfolios;

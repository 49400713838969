import React from 'react';
import { List, Row, Col, Statistic, Card, Icon, Skeleton, Affix } from 'antd';

import op from 'object-path';

import AppContext from '../../context/AppContextBase';
import { getIDAccounting } from '../../network/sef';
import g from '../../styles/global';

import DebitHeader from './DebitHeader';
import CreditHeader from './CreditHeader';
import DebitEntry from './DebitEntry';
import CreditEntry from './CreditEntry';

const styles = {
  container: {
    width: '100%',
    heigth: '100%',
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignStart,
    height: '100%',
    overflowY: 'scroll'
  },
  row: {
    width: '100%',
    marginBottom: g.global.baseline
  },
  col: {
    ...g.layout.alignCenter
  },
  header: {
    width: '100%',
    marginBottom: g.global.baseline
  },
  action: {},
  card: {
    overflow: 'scroll'
  },
  cardColumn: {
    ...g.layout.flexVertical,
    ...g.layout.flexStart,
    ...g.layout.alignCenter
  },
  subheader: {
    body: {
      width: '100%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    left: {
      width: '50%',
      ...g.layout.flexVertical,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    },
    right: {
      width: '50%',
      ...g.layout.flexHorizontal,
      ...g.layout.flexStart,
      ...g.layout.alignCenter
    }
  },
  list: {
    body: {
      width: '100%',
      paddingBottom: g.global.baseline * 4
    }
  }
};

const getPositiveValueStyle = value => {
  return {
    color:
      value === 0 || isNaN(value) ? '#444' : value > 0 ? '#3f8600' : '#cf1322'
  };
};

const getNegativeValueStyle = value => {
  return {
    color:
      value === 0 || isNaN(value) ? '#444' : value > 0 ? '#cf1322' : '#3f8600'
  };
};

class Accounting extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showCreateDebit: false,
      showCreateCredit: false,
      credit: null,
      debit: null,
      loading: false
    };

    this.scrollableAreaRef = React.createRef();
  }

  recalculate = () => {
    let totalCredit = 0;
    let totalDebit = 0;

    if (this.state.credit) {
      for (const item of this.state.credit) {
        totalCredit += item.value;
      }
    }
    if (this.state.debit) {
      for (const item of this.state.debit) {
        totalDebit += item.value;
      }
    }

    this.setState({ totalCredit, totalDebit });
  };

  async componentDidMount() {
    const request = await getIDAccounting(this.props.client._id);
    if (request && request.result === 'OK') {
      this.setState((state, props) => {
        const credit = request.data.filter(entry => entry.kind === 'id_credit');
        const debit = request.data.filter(entry => entry.kind === 'id_debit');

        let totalCredit = 0;
        let totalDebit = 0;

        for (const item of credit) {
          totalCredit += item.value;
        }
        for (const item of debit) {
          totalDebit += item.value;
        }

        return { credit, debit, totalCredit, totalDebit };
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const diffCredit =
      op.get(this.state, 'credit.length', 0) !==
      op.get(prevState, 'credit.length', 0);

    const diffDebit =
      op.get(this.state, 'debit.length', 0) !==
      op.get(prevState, 'debit.length', 0);

    if (diffDebit || diffCredit) {
      this.recalculate();
    }
  }

  deleteCredit = _id => {
    console.log('deleting ', _id);
    return this.setState(prevState => {
      const credit = Array.from(prevState.credit);
      const result = credit.filter(item => item._id !== _id);

      return {
        credit: result
      };
    });
  };

  deleteDebit = _id => {
    return this.setState(prevState => {
      const debit = Array.from(prevState.debit);
      const result = debit.filter(item => item._id !== _id);
      return {
        debit: result
      };
    });
  };

  render() {
    const balance = this.state.totalCredit - this.state.totalDebit;
    return (
      <div style={styles.container} ref={this.scrollableAreaRef}>
        <Row type="flex" style={styles.header} gutter={g.global.baseline}>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Credit / Money In"
                value={this.state.totalCredit}
                valueStyle={getPositiveValueStyle(this.state.totalCredit)}
                precision={2}
                prefix={<Icon type="caret-up" />}
                suffix="€"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Debit / Money Out"
                value={this.state.totalDebit}
                valueStyle={getNegativeValueStyle(this.state.totalDebit)}
                precision={2}
                prefix={<Icon type="caret-down" />}
                suffix="€"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card style={{ borderColor: '#aaa' }}>
              <Statistic
                title="Balance"
                value={balance || 0}
                precision={2}
                valueStyle={getPositiveValueStyle(balance)}
                prefix={<Icon type="caret-right" />}
                suffix="€"
              />
            </Card>
          </Col>
        </Row>

        <Row type="flex" style={styles.row} gutter={g.global.baseline}>
          <Col span={12}>
            <Affix offsetTop={0} target={() => this.scrollableAreaRef.current}>
              <CreditHeader
                allowCreation={this.props.allowCreation}
                currentUserId={this.context.state.userData.id}
                currentUsername={this.context.state.userData.name}
                clientId={this.props.client._id}
                onSaveSuccess={creditEntry => {
                  this.setState(currentState => {
                    let { credit } = currentState;
                    if (!credit) {
                      credit = [];
                    }
                    credit.push(creditEntry);
                    return {
                      credit
                    };
                  });
                  this.recalculate();
                }}
              ></CreditHeader>
            </Affix>
          </Col>
          <Col span={12}>
            <Affix offsetTop={0} target={() => this.scrollableAreaRef.current}>
              <DebitHeader
                allowCreation={this.props.allowCreation}
                currentUserId={this.context.state.userData.id}
                currentUsername={this.context.state.userData.name}
                clientId={this.props.client._id}
                onSaveSuccess={debitEntry => {
                  let { debit } = this.state;
                  if (!debit) {
                    debit = [];
                  }
                  debit.push(debitEntry);
                  this.setState({
                    debit: debit
                  });
                  this.recalculate();
                }}
              ></DebitHeader>
            </Affix>
          </Col>
        </Row>

        <Row type="flex" gutter={g.global.baseline} style={styles.row}>
          <Col style={styles.col} span={12}>
            {this.state.credit ? (
              <List
                style={styles.list.body}
                itemLayout="horizontal"
                dataSource={this.state.credit}
                rowKey={item => item._id}
                renderItem={item => (
                  <CreditEntry
                    entry={item}
                    onDelete={() => this.deleteCredit(item._id)}
                    onEdit={data => this.editCredit(item._id, data)}
                    currentUser={this.context.state.userData}
                  ></CreditEntry>
                )}
              />
            ) : (
              <Skeleton active></Skeleton>
            )}
          </Col>
          <Col style={styles.col} span={12}>
            {this.state.debit ? (
              <List
                style={styles.list.body}
                itemLayout="horizontal"
                dataSource={this.state.debit}
                rowKey={item => item._id}
                renderItem={item => (
                  <DebitEntry
                    entry={item}
                    onDelete={entry => this.deleteDebit(entry._id)}
                    onEdit={data => this.editDebit(item._id, data)}
                    currentUser={this.context.state.userData}
                  ></DebitEntry>
                )}
              />
            ) : (
              <Skeleton active></Skeleton>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

Accounting.contextType = AppContext;

export default Accounting;

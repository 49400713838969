import React from 'react';
//import { Helmet } from 'react-helmet';
import {
  List,
  Skeleton,
  Avatar,
  Icon,
  Popconfirm,
  Button,
  Upload,
  Radio,
  Tag
} from 'antd';
import moment from 'moment';
import AppContext from '../context/AppContextBase';
import {
  fetchDocuments,
  uploadFile,
  downloadFile,
  removeFile
} from '../network/documentUploads';
import constants from '../common/constants';
import g from '../styles/global';

const styles = {
  rowItem: {
    paddingLeft: g.global.baseline,
    paddingRight: g.global.baseline,
    width: '100%',
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart,
    ...g.layout.alignStart
  }
};

export default class DocumentUploadsList extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      initLoading: true,
      loading: false,
      uploading: false,
      data: [],
      list: [],

      selectedFile: null,
      visibility: null
    };
    if (!context.checkPermission('uploads:selectVisibility')) {
      this.state.visibility = constants.scopes.external.key;
    }
    const acceptedRealms = ['investor', 'process'];
    if (!acceptedRealms.includes(this.props.realm)) {
      throw new Error('DocumentsUploadsList: Missing required prop realm');
    }
  }
  async uploadFiletoS3AndMongo() {
    this.setState({ uploading: true });

    await uploadFile(
      this.state.selectedFile,
      this.props.client,
      this.props.realm,
      this.props.s3Folder
    );

    const documents = await this.fetchAllDocuments(this.props.client);
    this.setState({ uploading: false, selectedFile: null, visibility: null });
    await this.props.onUpdate?.(documents);
  }

  async fetchAllDocuments(investor) {
    let response = await fetchDocuments(this.props.realm, investor._id);
    if (response.result === 'OK' && response.data.docs) {
      let listOfDocuments = response.data.docs;

      this.setState({
        initLoading: false,
        list: listOfDocuments
      });
    } else {
      this.setState({
        initLoading: false,
        list: []
      });
    }
    return response.data.docs;
  }

  async componentDidMount() {
    const documents = await this.fetchAllDocuments(this.props.client);
    await this.props.onUpdate?.(documents);
  }

  render() {
    return (
      <div style={{ overflowY: 'scroll', height: '90%' }}>
        <List
          loading={this.state.initLoading}
          itemLayout="horizontal"
          dataSource={this.state.list}
          renderItem={item => (
            <List.Item
              style={{
                borderBottom: '1px solid #eaebeb',
                height: '100%',
                alignItems: 'stretch'
              }}
              actions={[
                this.context.checkPermission('uploads:download') ? (
                  <Button
                    shape="circle"
                    style={{ backgroundColor: '#228aff' }}
                    onClick={async () => {
                      return await downloadFile(
                        item.s3_name,
                        this.props.s3Folder
                      );
                    }}
                  >
                    <Icon
                      style={{
                        color: 'white',
                        fontSize: g.global.baseline * 1.5
                      }}
                      type="download"
                    />
                  </Button>
                ) : null,
                this.context.checkPermission('uploads:delete') ? (
                  <Popconfirm
                    placement="left"
                    title="Are you sure delete this file?"
                    onConfirm={async () => {
                      await removeFile(item.s3_name, this.props.s3Folder);
                      await this.fetchAllDocuments(this.props.client);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      shape="circle"
                      style={{ backgroundColor: g.colors.feedback.error }}
                    >
                      <Icon
                        style={{
                          color: 'white',
                          fontSize: g.global.baseline * 1.5
                        }}
                        type="delete"
                      />
                    </Button>
                  </Popconfirm>
                ) : null
              ]}
            >
              <Skeleton
                avatar
                title={false}
                loading={this.state.initLoading}
                active
              >
                <List.Item.Meta
                  style={{ height: '100%' }}
                  avatar={
                    <Avatar
                      style={{ backgroundColor: '#0353a4', height: '100%' }}
                      icon={<Icon type="file-done" />}
                    />
                  }
                  title={item.owner_name}
                  description={moment(item.createdAt).format('YY/MM/DD HH:mm')}
                />
                <div style={{ ...styles.rowItem, flex: 7 }}>
                  <span
                    style={{
                      fontSize: g.global.baseline * 1.25,
                      fontWeight: 300
                    }}
                  >
                    {item.name}
                  </span>
                </div>
                {this.context.checkPermission('uploads:selectVisibility') && (
                  <div style={{ ...styles.rowItem, flex: 1 }}>
                    <span
                      style={{
                        fontSize: g.global.baseline * 1.25,
                        fontWeight: 300
                      }}
                    >
                      <Tag
                        color={
                          item.visibility === 'internal' ? 'green' : 'orange'
                        }
                      >
                        {item.visibility}
                      </Tag>
                    </span>
                  </div>
                )}
              </Skeleton>
            </List.Item>
          )}
        />

        <div
          style={{
            fontSize: '15px',
            position: 'fixed',
            right: '50px',
            bottom: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: '#ffffff',
            border: '1px solid #eaebeb',
            padding: g.global.baseline * 2,
            borderRadius: g.global.baseline / 3
          }}
        >
          <div
            style={{
              marginBottom: this.state.selectedFile ? g.global.baseline : 0
            }}
          >
            <Upload
              name="logo"
              beforeUpload={file => {
                this.setState({
                  selectedFile: file
                });
                return false;
              }}
              fileList={
                this.state.selectedFile ? [this.state.selectedFile] : []
              }
              onRemove={() => {
                this.setState({ selectedFile: null, visibility: null });
              }}
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                showRemoveIcon: true
              }}
              listType="text"
            >
              <Button disabled={!!this.state.selectedFile}>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </div>
          {this.state.selectedFile && (
            <>
              {this.context.checkPermission('uploads:selectVisibility') && (
                <Radio.Group
                  style={{ marginBottom: g.global.baseline }}
                  onChange={e => {
                    this.setState({ visibility: e.target.value });
                  }}
                  value={this.state.visibility}
                >
                  <Radio value={'internal'}>Internal</Radio>
                  <Radio value={'external'}>External</Radio>
                </Radio.Group>
              )}
              <div
                style={{
                  ...g.layout.flexHorizontal,
                  ...g.layout.flexStart,
                  ...g.layout.alignCenter
                }}
              >
                <Button
                  style={{ marginRight: g.global.baseline }}
                  disabled={
                    !this.state.selectedFile ||
                    (this.context.checkPermission('uploads:selectVisibility') &&
                      !this.state.visibility)
                  }
                  type="primary"
                  onClick={() => this.uploadFiletoS3AndMongo()}
                >
                  {this.state.uploading ? (
                    <span>
                      <Icon
                        style={{ marginRight: g.global.baseline }}
                        type="sync"
                        spin
                      />
                      Uploading
                    </span>
                  ) : (
                    <span>
                      <Icon
                        style={{ marginRight: g.global.baseline }}
                        type="upload"
                      />
                      Upload
                    </span>
                  )}
                </Button>
                <Button
                  type="dashed"
                  onClick={() =>
                    this.setState({ selectedFile: null, visibility: null })
                  }
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

DocumentUploadsList.contextType = AppContext;

import React from 'react';
import { Route, NavLink, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';
import { Layout, Menu, Icon } from 'antd';

import AppContext from '../../context/AppContextBase';
import Dossiers from './Dossiers';
import Report from './Report';
import Templates from './Templates';

import breakpoints from '../../styles/breakpoints';
import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const { Header, Content } = Layout;

const styles = {
  header: {
    height: globalStyles.global.baseline * 4,
    backgroundColor: globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    padding: 0
  },
  menu: {
    fontSize: globalStyles.typography.size.base * 1.5,
    height: globalStyles.global.baseline * 3,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    borderBottom: '1px solid ' + globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  subMenu: {
    height: 48,
    backgroundColor: globalStyles.colors.background.queenBlue
  },
  menuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    minWidth: '20%'
  },
  subMenuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100
  },
  menuLink: {
    color: '#fff'
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  }
};
class FinMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <Menu
        onClick={this.handleClick}
        activeKey={this.state.currentMenuItem}
        mode="horizontal"
        style={styles.menu}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={globalStyles.global.menuIcon} type="more" />
          </div>
        }
      >
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="1"
        >
          <NavLink
            style={styles.menuLink}
            onClick={() => this.setState({ currentMenuItem: '1' })}
            to={'/fin/dossiers'}
          >
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="home" />
              Dossiers
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="home" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="2"
        >
          <NavLink
            style={styles.menuLink}
            onClick={() => this.setState({ currentMenuItem: '2' })}
            to={'/fin/reports'}
          >
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="cloud-upload" />
              Envio
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="cloud-upload" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="3"
        >
          <NavLink
            style={styles.menuLink}
            onClick={() => this.setState({ currentMenuItem: '3' })}
            to={'/fin/templates'}
          >
            <MediaQuery minWidth={breakpoints.lgMin}>
              <Icon style={styles.menuIcon} type="form" />
              Modelos
            </MediaQuery>
            <MediaQuery maxWidth={breakpoints.mdMax}>
              <Icon style={styles.menuIcon} type="form" />
            </MediaQuery>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      citiusCases: [
        {
          Origem: 'N.A',
          'Data Elaboração': 'N.A',
          Acto: 'N.A',
          Tribunal: 'N.A',
          'Un Orgânica': 'N.A',
          Processo: 'N.A',
          Espécie: 'N.A',
          Referência: 'N.A',
          tags: ['nice', 'developer']
        }
      ]
    };
  }

  async componentDidMount() {
    /* nothing */
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CG - Financeiro</title>
        </Helmet>
        <Header style={styles.header}>{<FinMenu />}</Header>
        <Content
          style={{
            height:
              'calc( 100vh - ' + globalStyles.global.baseline * 10 + 'px)',
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexCenter
          }}
        >
          <Route
            exact
            path={'/fin'}
            render={() => (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  ...globalStyles.layout.flexVertical,
                  ...globalStyles.layout.flexCenter
                }}
              >
                <h1> Fin Root/Home</h1>
                <Redirect to="/fin/dossiers" />
              </div>
            )}
          />
          <Route path={'/fin/dossiers'} component={Dossiers} />
          <Route path={'/fin/reports/'} component={Report} />
          <Route path={'/fin/templates'} component={Templates} />
        </Content>
      </>
    );
  }
}

export default Home;

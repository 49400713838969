import React from 'react';

import { Row, Col, Card, Form, Select, Input, Button, message } from 'antd';

import AppContext from '../../../context/AppContextBase';

import DetailedMessage from '../../../components/util/DetailedMessage';
import validator from 'validator';
import { mrg } from '../../../common/util';
import globalStyles from '../../../styles/global';

import { getGroups, postUser } from '../../../network/users';

const { Option } = Select;

const styles = {
  pageHeaderBox: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    marginRight: globalStyles.global.baseline * 2
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none'
  },
  attachments: {
    borderTop: '1px solid #e8e8e8',
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    alignItems: 'flex-start',
    marginTop: globalStyles.global.baseline * 2,
    width: '100%'
  },
  card: {
    body: {
      width: '100%',
      paddingBottom: globalStyles.global.baseline
    }
  },
  type: {
    fontSize: globalStyles.global.baseline * 1.5,
    fontWeight: 700,
    color: '#666666',
    textTransform: 'uppercase',
    backgroundColor: '#e8e8e8',
    marginRight: globalStyles.global.baseline,
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline * 0.5,
    paddingBottom: globalStyles.global.baseline * 0.5
  },
  form: {
    item: {
      marginBottom: 0,
      paddingRight: globalStyles.global.baseline,
      width: '100%'
    },
    actions: {
      width: '100%',
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexBetween
    },
    body: {
      margin: '2%',
      width: '96%'
    }
  }
};

class CreateUser extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { departments: [], groups: null, loading: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.state = { loading: false };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    // User groups:
    if (this.context.checkPermission('menu:settings:users:groups')) {
      let groups = await getGroups();
      if (groups && groups.result === 'OK' && groups.data.length > 0) {
        groups.data.unshift({
          _id: null,
          name: 'None'
        });
        this.setState({
          groups: groups.data
        });
      } else if (groups && groups.result === 'OK') {
        this.setState({
          groups: null
        });
      } else {
        this.setState({
          groups: null
        });
      }
    }

    // Current user dept:
    const dept = this.context.getUserAttribute('department');

    if (dept !== '*') {
      this.setState({ departments: [dept] });
    } else {
      this.setState({ departments: ['*', 'CM', 'FIN', 'ID'] });
    }

    this.setState({ loading: false });
  }

  resetFields() {
    this.props.form.resetFields([
      'firstName',
      'lastName',
      'role',
      'scope',
      'department',
      'email',
      'group'
    ]);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const newValues = { ...values };
        newValues.name = values.firstName + ' ' + values.lastName;
        newValues.permissions = {
          department: values.department,
          scope: values.scope
        };
        delete newValues.department;
        delete newValues.scope;

        const response = await postUser(newValues);
        if (response && response.result === 'OK') {
          message.success('User Created Successfully');
          this.resetFields();
        } else {
          DetailedMessage.error(
            'Error saving data - ' + response.message,
            response
          );
        }
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const scopes = ['Internal', 'External'];
    const roles = ['User', 'Admin'];
    return (
      <Form style={styles.form.body} onSubmit={this.handleSubmit}>
        <Card
          bodyStyle={styles.card.body}
          headStyle={{}}
          title={'New User'}
          bordered={true}
        >
          <Row>
            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('firstName', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="First Name"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={styles.card.body}>
            <Col span={8}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('lastName', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    placeholder="Last Name"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={styles.card.body}>
            <Col span={8} offset={0}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    },
                    {
                      validator: async (rule, value, callback) => {
                        if (value && !validator.isEmail(value)) {
                          callback('Invalid Email');
                        }
                        callback();
                      }
                    }
                  ]
                })(<Input disabled={this.state.loading} placeholder="Email" />)}
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input.Password
                    disabled={this.state.loading}
                    placeholder="Temp. Password"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row style={styles.card.body}>
            <Col span={6}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('role', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Select disabled={this.state.loading} placeholder="Role">
                    {roles.map(option => (
                      <Option key={option.toLowerCase()}>{option}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('department', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Select
                    disabled={this.state.loading || !this.state.departments}
                    placeholder="Department"
                  >
                    {this.state.departments
                      ? this.state.departments.map(option => (
                          <Option key={option}>{option}</Option>
                        ))
                      : null}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item style={styles.form.item}>
                {getFieldDecorator('scope', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Select disabled={this.state.loading} placeholder="Scope">
                    {scopes.map(option => (
                      <Option key={option.toLowerCase()}>{option}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {this.context.checkPermission('menu:settings:users:groups') &&
              this.state.groups && (
                <Col span={6}>
                  <Form.Item style={styles.form.item}>
                    {getFieldDecorator('group')(
                      <Select
                        disabled={this.state.loading}
                        placeholder="User Group"
                      >
                        {this.state.groups.map(option => (
                          <Option key={option._id}>{option.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              )}

            <Col style={styles.form.actions}>
              <Form.Item
                style={mrg([
                  styles.form.item,
                  { width: '100%', marginBottom: 0 },
                  globalStyles.layout.flexHorizontal,
                  globalStyles.layout.flexEnd
                ])}
              >
                <Button
                  style={{
                    marginBottom: globalStyles.global.baseline,
                    marginTop: globalStyles.global.baseline,
                    marginRight: globalStyles.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="secondary"
                  onClick={() => this.resetFields()}
                >
                  Clear
                </Button>
                <Button
                  style={{
                    marginBottom: globalStyles.global.baseline,
                    marginTop: globalStyles.global.baseline
                  }}
                  disabled={this.state.loading}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const crmForm = Form.create({ name: 'create_user' })(CreateUser);

export default crmForm;

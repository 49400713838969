import React from 'react';
import { Collapse, message } from 'antd';

const Panel = Collapse.Panel;

export default class DetailedMessage {
  static _base(type, msg, detail, isCode = true) {
    let processed;
    if (detail instanceof String) {
      processed = detail;
    } else {
      processed = JSON.stringify(detail, null, 2);
    }
    const content = (
      <span>
        <span>{msg}</span>
        <br />
        <Collapse bordered={false} defaultActiveKey={[]}>
          <Panel header="+ Info" key="1">
            {isCode ? (
              <code style={{ color: '#888888' }}>{processed}</code>
            ) : (
              <span style={{ color: '#888888' }}>{processed}</span>
            )}
          </Panel>
        </Collapse>
      </span>
    );

    switch (type) {
      case 'error':
        return message.error(content);

      case 'success':
        return message.success(content);
      case 'info':
        return message.info(content);
      case 'warn':
        return message.warn(content);
      default:
        return message.success(content);
    }
  }

  static error(msg, detail, isCode = true) {
    return this._base('error', msg, detail, isCode);
  }
  static success(msg, detail, isCode = true) {
    return this._base('success', msg, detail, isCode);
  }
  static info(msg, detail, isCode = true) {
    return this._base('info', msg, detail, isCode);
  }
  static warn(msg, detail, isCode = true) {
    return this._base('warn', msg, detail, isCode);
  }
}

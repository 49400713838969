import React from 'react';
import { Column } from 'react-virtualized';

import { mrg } from '../../../common/util';
import { tableStyles, translateColumnHeader, renderers } from './common';
import g from '../../../styles/global';

const scheduleColumns = (onSave, extras) => [
  <Column
    width={50}
    flexGrow={2}
    dataKey="Situação"
    key="Situação"
    disableSort={true}
    headerRenderer={translateColumnHeader}
    style={tableStyles.column}
  />,
  // <Column
  //   width={50}
  //   flexGrow={4}
  //   dataKey="url"
  //   key="url"
  //   disableSort={true}
  //   style={tableStyles.column}
  //   headerRenderer={columnHeader}
  // />,
  <Column
    width={50}
    flexGrow={6}
    dataKey="Add to Calendar"
    key="Data"
    //cellDataGetter={renderers.date}
    disableSort={true}
    style={mrg([
      tableStyles.column,
      {
        overflowY: 'visible',
        overflowX: 'visible',
        height: '50px',
        ...g.layout.flexHorizontal,
        ...g.layout.flexCenter,
        ...g.layout.alignCenter,
        textOverflow: 'unset',
        whiteSpace: 'unset'
      }
    ])}
    headerRenderer={translateColumnHeader}
    cellRenderer={({ dataKey, rowData }) => {
      return (
        <div>
          {renderers.addToCalendar({ client: extras.client, dataKey, rowData })}
        </div>
      );
    }}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Assunto"
    key="Assunto"
    //cellDataGetter={renderers.date}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Data"
    key="Data"
    //cellDataGetter={renderers.date}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Hora"
    key="Hora"
    //cellDataGetter={renderers.date}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="Local"
    key="Local"
    //cellDataGetter={renderers.date}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,

  <Column
    width={50}
    flexGrow={10}
    dataKey="Morada"
    key="Morada"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
  />,
  <Column
    width={100}
    flexGrow={2}
    dataKey="assignee"
    key="assignee"
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={translateColumnHeader}
    cellRenderer={value => {
      return (
        <div>
          {renderers.assignee({
            user: extras.user,
            users: extras.users,
            onSave: onSave,
            loading: extras.loading
          })(value)}
        </div>
      );
    }}
  />,
  <Column
    width={50}
    flexGrow={2}
    dataKey="_id"
    key="_id"
    label={'Acções'}
    disableSort={true}
    style={tableStyles.column}
    headerRenderer={() => 'Acções'}
    cellRenderer={values => {
      return (
        <div>
          {renderers.unread({
            user: extras.user,
            onSave: onSave
          })(values)}
        </div>
      );
    }}
  />
];

export default scheduleColumns;

import React from 'react';

import { Row } from 'antd';

import g from '../../../styles/global';
import { mrg } from '../../../common/util';
import mapFields from '../util/mapFields';

import { save } from './operations';

const basic = ({
  data,
  onSave = () => {},
  onSaveSuccess = () => {},
  onSaveError = () => {},
  style = {}
}) => {
  const fieldMap = [
    {
      title: 'ID',
      key: 'id',
      editable: false
    },
    {
      title: 'Name',
      key: 'Nome'
    },
    {
      title: 'Work Lang.',
      key: 'excel.work_language'
    }
  ];

  return (
    <div
      style={mrg([
        g.layout.flexHorizontal,
        g.layout.flexStart,
        g.layout.alignStart,
        {
          padding: g.global.baseline,
          width: '100%'
        },
        style
      ])}
    >
      <Row style={{ width: '100%' }}>
        {mapFields(data, fieldMap, save, onSaveSuccess, onSaveError, style)}
      </Row>
    </div>
  );
};

export default basic;
